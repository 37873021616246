import React from 'react';

const GrayPencilIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 25 25' fill='none'>
    <path
      d='M24.2423 3.40638L21.6057 0.769799C21.3791 0.543272 21.0718 0.416016 20.7514 0.416016C20.431 0.416016 20.1237 0.543272 19.8971 0.769799L9.35202 11.3161C9.21895 11.4479 9.11913 11.6094 9.06081 11.7874L7.74252 15.7423C7.68205 15.9238 7.66557 16.1171 7.69442 16.3063C7.72328 16.4955 7.79665 16.6751 7.9085 16.8304C8.02035 16.9856 8.16748 17.1121 8.33778 17.1994C8.50807 17.2867 8.69666 17.3323 8.88802 17.3324C9.01787 17.3324 9.14685 17.3112 9.26985 17.2696L13.2247 15.9513C13.4027 15.893 13.5642 15.7932 13.696 15.6601L24.2423 5.11497C24.4688 4.88837 24.5961 4.58108 24.5961 4.26067C24.5961 3.94027 24.4688 3.63298 24.2423 3.40638ZM12.1892 13.7497L10.7984 14.2137L11.2624 12.8229L20.7514 3.33267L21.6794 4.26067L12.1892 13.7497ZM0.429688 5.24909C0.429688 4.92862 0.556994 4.62128 0.7836 4.39467C1.01021 4.16806 1.31755 4.04076 1.63802 4.04076H11.3047C11.6252 4.04076 11.9325 4.16806 12.1591 4.39467C12.3857 4.62128 12.513 4.92862 12.513 5.24909C12.513 5.56956 12.3857 5.8769 12.1591 6.10351C11.9325 6.33012 11.6252 6.45742 11.3047 6.45742H2.84635V22.1658H18.5547V13.7074C18.5547 13.387 18.682 13.0796 18.9086 12.853C19.1352 12.6264 19.4426 12.4991 19.763 12.4991C20.0835 12.4991 20.3908 12.6264 20.6174 12.853C20.844 13.0796 20.9714 13.387 20.9714 13.7074V23.3741C20.9714 23.6946 20.844 24.0019 20.6174 24.2285C20.3908 24.4551 20.0835 24.5824 19.763 24.5824H1.63802C1.31755 24.5824 1.01021 24.4551 0.7836 24.2285C0.556994 24.0019 0.429688 23.6946 0.429688 23.3741V5.24909Z'
      fill='#8F8F8F'
    />
  </svg>
);

export default GrayPencilIcon;
