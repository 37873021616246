import React from 'react';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';

import { useTranslation } from 'react-i18next';

const HeaderChallengeLogRow: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);

  return (
    <tr className='flex w-full pb-[0.5em] sticky top-0 z-20'>
      <th className={`${headerStyle} desktop:block md:block tablet:block hidden`}>&nbsp;</th>
      <th className={headerStyle}>{t('NAME')}</th>
      <th className={headerStyle}>{t('DATE')}</th>
      <th className={headerStyle}>{t('TARGET')}</th>
      <th className={headerStyle}>{t('VALUE')}</th>
      <th className={`${headerStyle}  lg:flex hidden `}>{t('LINK')}</th>
      <th className={`${headerStyle}  lg:flex hidden `}>{t('PHOTO')}</th>
      <th className={`${headerStyle} lg:w-full sm:w-[60px] md:w-[60px] phone:w-[60px] w-[60px]`}></th>
    </tr>
  );
};

export default HeaderChallengeLogRow;
