import React from 'react';
const MobileNavManuIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='28' height='26' viewBox='0 0 28 26' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 2.3175C0 1.16594 0.89543 0.232422 2 0.232422H26C27.1046 0.232422 28 1.16594 28 2.3175C28 3.46905 27.1046 4.40257 26 4.40257H2C0.89543 4.40257 0 3.46905 0 2.3175Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 12.7429C0 11.5913 0.89543 10.6578 2 10.6578H26C27.1046 10.6578 28 11.5913 28 12.7429C28 13.8944 27.1046 14.8279 26 14.8279H2C0.89543 14.8279 0 13.8944 0 12.7429Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 23.1682C0 22.0167 0.89543 21.0832 2 21.0832H26C27.1046 21.0832 28 22.0167 28 23.1682C28 24.3198 27.1046 25.2533 26 25.2533H2C0.89543 25.2533 0 24.3198 0 23.1682Z'
      fill='white'
    />
  </svg>
);
export default MobileNavManuIcon;
