import React from 'react';

const CancelIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 21 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.36853 0.86902C1.99337 0.244181 3.00643 0.244181 3.63127 0.86902L10.4999 7.73765L17.3685 0.86902C17.9934 0.244181 19.0064 0.244181 19.6313 0.86902C20.2561 1.49386 20.2561 2.50692 19.6313 3.13176L12.7626 10.0004L19.6313 16.869C20.2561 17.4939 20.2561 18.5069 19.6313 19.1318C19.0064 19.7566 17.9934 19.7566 17.3685 19.1318L10.4999 12.2631L3.63127 19.1318C3.00643 19.7566 1.99337 19.7566 1.36853 19.1318C0.743693 18.5069 0.743693 17.4939 1.36853 16.869L8.23716 10.0004L1.36853 3.13176C0.743693 2.50692 0.743693 1.49386 1.36853 0.86902Z'
      fill='#15CDCA'
    />
  </svg>
);

export default CancelIcon;
