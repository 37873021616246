import React from 'react';

const RunManIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='39' height='34' viewBox='0 0 39 34' fill='none'>
    <path
      d='M5.97405 31.4013C5.16828 30.5864 5.26649 29.3515 6.1934 28.6431L18.6023 19.1594C19.5292 18.451 20.9338 18.5374 21.7396 19.3523C22.5454 20.1672 22.4472 21.4021 21.5203 22.1105L9.11134 31.5942C8.18442 32.3026 6.77981 32.2162 5.97405 31.4013Z'
      fill='white'
    />
    <path
      d='M6.62632 13.806C6.46516 13.643 6.48481 13.396 6.67019 13.2544L12.1118 9.09558C12.2972 8.95391 12.5782 8.97118 12.7393 9.13416C12.9005 9.29714 12.8808 9.54412 12.6954 9.6858L7.25377 13.8446C7.06839 13.9863 6.78747 13.969 6.62632 13.806Z'
      fill='white'
    />
    <path
      d='M0.39963 27.4918C0.238479 27.3288 0.258123 27.0818 0.443506 26.9401L5.88516 22.7813C6.07055 22.6397 6.35147 22.6569 6.51262 22.8199C6.67377 22.9829 6.65413 23.2299 6.46875 23.3715L1.02709 27.5303C0.841704 27.672 0.560782 27.6547 0.39963 27.4918Z'
      fill='white'
    />
    <path
      d='M6.62632 16.5432C6.46516 16.3802 6.48481 16.1332 6.67019 15.9915L12.1118 11.8327C12.2972 11.6911 12.5782 11.7083 12.7393 11.8713C12.9005 12.0343 12.8808 12.2813 12.6954 12.423L7.25377 16.5817C7.06839 16.7234 6.78747 16.7061 6.62632 16.5432Z'
      fill='white'
    />
    <path
      d='M0.39963 30.2289C0.238479 30.0659 0.258123 29.8189 0.443506 29.6773L5.88516 25.5185C6.07055 25.3768 6.35147 25.3941 6.51262 25.5571C6.67377 25.72 6.65413 25.967 6.46875 26.1087L1.02709 30.2675C0.841704 30.4092 0.560782 30.3919 0.39963 30.2289Z'
      fill='white'
    />
    <path
      d='M34.641 20.7226C33.9538 21.6175 32.5715 21.8533 31.5536 21.2492L24.1809 16.8737C23.1629 16.2696 22.8948 15.0543 23.5819 14.1594C24.2691 13.2644 25.6514 13.0286 26.6693 13.6328L34.042 18.0083C35.06 18.6124 35.3281 19.8276 34.641 20.7226Z'
      fill='white'
    />
    <path
      d='M23.5024 26.9006C22.7175 26.0971 22.8148 24.8877 23.7205 24.1893L31.2812 18.359C32.116 17.7153 33.36 17.7031 34.2109 18.3303C35.2752 19.1149 35.2848 20.5515 34.2311 21.3471L26.6015 27.1076C25.6807 27.8029 24.2932 27.7102 23.5024 26.9006Z'
      fill='white'
    />
    <path
      d='M25.6757 7.99719C26.3492 8.55372 26.3071 9.50534 25.5866 10.0143L17.0092 16.0729L16.1197 16.6595L15.8266 16.8436C15.1337 17.2787 14.1692 17.1267 13.6913 16.5072C13.5335 16.3028 13.4435 16.0638 13.4313 15.817L13.4308 15.806C13.4091 15.3669 13.628 14.9453 14.0195 14.6721L23.688 7.92423C24.2793 7.51152 25.1255 7.54258 25.6757 7.99719Z'
      fill='white'
    />
    <path
      d='M26.0039 2.00678C27.2871 0.165315 30.0252 -0.412951 32.1198 0.715183C34.2144 1.84332 34.8721 4.25065 33.5889 6.09211C32.3057 7.93357 29.5675 8.51183 27.473 7.3837C25.3784 6.25557 24.7207 3.84824 26.0039 2.00678Z'
      fill='white'
    />
    <path
      d='M30.8225 12.9793C30.3171 12.3678 30.4712 11.512 31.1667 11.0677L35.8442 8.07953C36.5397 7.63521 37.5132 7.77072 38.0186 8.38218C38.5239 8.99364 38.3698 9.84951 37.6743 10.2938L32.9969 13.282C32.3014 13.7263 31.3279 13.5908 30.8225 12.9793Z'
      fill='white'
    />
    <path
      d='M46.4339 26.1042C45.3818 26.1042 44.4059 25.9422 43.5059 25.6182C42.6187 25.2817 41.8455 24.8081 41.1864 24.1975C40.5399 23.5868 40.0329 22.8702 39.6653 22.0477C39.2978 21.2127 39.114 20.2967 39.114 19.2997C39.114 18.3027 39.2978 17.393 39.6653 16.5704C40.0329 15.7355 40.5399 15.0126 41.1864 14.402C41.8455 13.7913 42.6187 13.324 43.5059 12.9999C44.4059 12.6635 45.3818 12.4952 46.4339 12.4952C47.6634 12.4952 48.7598 12.7071 49.7231 13.1308C50.6991 13.5545 51.5103 14.1652 52.1567 14.9628L49.7611 17.0939C49.3302 16.5954 48.8548 16.2153 48.3352 15.9535C47.8282 15.6918 47.2578 15.561 46.624 15.561C46.079 15.561 45.5783 15.6482 45.122 15.8227C44.6657 15.9972 44.2728 16.2526 43.9432 16.5891C43.6263 16.9132 43.3728 17.3057 43.1827 17.7668C43.0053 18.228 42.9165 18.7389 42.9165 19.2997C42.9165 19.8605 43.0053 20.3715 43.1827 20.8326C43.3728 21.2937 43.6263 21.6925 43.9432 22.029C44.2728 22.353 44.6657 22.6023 45.122 22.7768C45.5783 22.9512 46.079 23.0385 46.624 23.0385C47.2578 23.0385 47.8282 22.9076 48.3352 22.6459C48.8548 22.3842 49.3302 22.0041 49.7611 21.5056L52.1567 23.6367C51.5103 24.4218 50.6991 25.0325 49.7231 25.4686C48.7598 25.8924 47.6634 26.1042 46.4339 26.1042Z'
      fill='white'
    />
    <path
      d='M60.2805 15.5423C61.0791 15.5423 61.8015 15.7043 62.448 16.0283C63.0944 16.3399 63.6014 16.8259 63.969 17.4864C64.3493 18.1469 64.5394 19.0006 64.5394 20.0475V25.8425H60.9269V20.627C60.9269 19.9042 60.7748 19.3807 60.4706 19.0567C60.1791 18.7202 59.7672 18.552 59.2348 18.552C58.8546 18.552 58.506 18.6392 58.1891 18.8137C57.8722 18.9757 57.6251 19.2312 57.4476 19.5801C57.2702 19.9291 57.1814 20.384 57.1814 20.9448V25.8425H53.569V11.9718H57.1814V18.5894L56.3449 17.7481C56.7378 17.0129 57.2765 16.4645 57.961 16.1031C58.6454 15.7292 59.4186 15.5423 60.2805 15.5423Z'
      fill='white'
    />
    <path
      d='M73.0055 25.8425V23.9545L72.7584 23.4871V20.0101C72.7584 19.4493 72.5809 19.0193 72.226 18.7202C71.8838 18.4087 71.3324 18.2529 70.5719 18.2529C70.0776 18.2529 69.5769 18.3339 69.0699 18.4959C68.5629 18.6454 68.1319 18.8573 67.777 19.1315L66.5602 16.72C67.1433 16.3461 67.8404 16.0595 68.6516 15.8601C69.4755 15.6482 70.2931 15.5423 71.1043 15.5423C72.7774 15.5423 74.0703 15.9224 74.9829 16.6826C75.9082 17.4304 76.3708 18.6081 76.3708 20.2157V25.8425H73.0055ZM69.9635 26.0108C69.1396 26.0108 68.4425 25.8737 67.8721 25.5995C67.3017 25.3253 66.8644 24.9515 66.5602 24.4779C66.2687 24.0043 66.1229 23.4747 66.1229 22.8889C66.1229 22.2658 66.2814 21.7299 66.5983 21.2813C66.9278 20.8201 67.4285 20.4712 68.1003 20.2344C68.772 19.9852 69.6403 19.8605 70.705 19.8605H73.1386V21.7112H71.1993C70.6163 21.7112 70.2043 21.8047 69.9635 21.9916C69.7353 22.1786 69.6213 22.4278 69.6213 22.7394C69.6213 23.0509 69.7417 23.3002 69.9825 23.4871C70.2233 23.6741 70.5529 23.7675 70.9712 23.7675C71.3641 23.7675 71.719 23.6741 72.0359 23.4871C72.3654 23.2877 72.6063 22.9886 72.7584 22.5898L73.2527 23.8984C73.0626 24.5963 72.6887 25.1259 72.131 25.4873C71.5859 25.8363 70.8634 26.0108 69.9635 26.0108Z'
      fill='white'
    />
    <path d='M78.5559 25.8425V11.9718H82.1684V25.8425H78.5559Z' fill='white' />
    <path d='M84.4209 25.8425V11.9718H88.0333V25.8425H84.4209Z' fill='white' />
    <path
      d='M95.6855 26.0108C94.4687 26.0108 93.404 25.7864 92.4914 25.3378C91.5915 24.8767 90.888 24.2536 90.381 23.4684C89.8866 22.6708 89.6395 21.7673 89.6395 20.7578C89.6395 19.7484 89.8803 18.8511 90.362 18.0659C90.8563 17.2683 91.5344 16.6514 92.3963 16.2153C93.2582 15.7666 94.2279 15.5423 95.3053 15.5423C96.3193 15.5423 97.2446 15.7479 98.0811 16.1592C98.9177 16.558 99.5831 17.1499 100.077 17.9351C100.572 18.7202 100.819 19.6736 100.819 20.7952C100.819 20.9198 100.813 21.0632 100.8 21.2252C100.787 21.3872 100.775 21.5367 100.762 21.6738H92.6055V19.8045H98.8416L97.4727 20.3279C97.4854 19.8668 97.3967 19.468 97.2066 19.1315C97.0291 18.795 96.7756 18.5333 96.446 18.3463C96.1292 18.1594 95.7552 18.0659 95.3243 18.0659C94.8933 18.0659 94.5131 18.1594 94.1835 18.3463C93.8666 18.5333 93.6195 18.8012 93.442 19.1502C93.2646 19.4867 93.1758 19.8855 93.1758 20.3466V20.8887C93.1758 21.3872 93.2773 21.8171 93.4801 22.1786C93.6955 22.54 93.9997 22.8204 94.3927 23.0198C94.7856 23.2067 95.2546 23.3002 95.7996 23.3002C96.3066 23.3002 96.7376 23.2316 97.0925 23.0945C97.4601 22.945 97.8213 22.7207 98.1762 22.4216L100.077 24.3657C99.5831 24.9016 98.9747 25.3129 98.2523 25.5995C97.5298 25.8737 96.6742 26.0108 95.6855 26.0108Z'
      fill='white'
    />
    <path
      d='M109.101 15.5423C109.899 15.5423 110.622 15.7043 111.268 16.0283C111.915 16.3399 112.422 16.8259 112.789 17.4864C113.17 18.1469 113.36 19.0006 113.36 20.0475V25.8425H109.747V20.627C109.747 19.9042 109.595 19.3807 109.291 19.0567C109 18.7202 108.588 18.552 108.055 18.552C107.675 18.552 107.326 18.6392 107.01 18.8137C106.693 18.9757 106.446 19.2312 106.268 19.5801C106.091 19.9291 106.002 20.384 106.002 20.9448V25.8425H102.389V15.7105H105.831V18.5894L105.165 17.7481C105.558 17.0129 106.097 16.4645 106.781 16.1031C107.466 15.7292 108.239 15.5423 109.101 15.5423Z'
      fill='white'
    />
    <path
      d='M120.701 29.6373C119.712 29.6373 118.768 29.5252 117.868 29.3009C116.981 29.0765 116.22 28.7463 115.587 28.3101L116.899 25.8238C117.33 26.1603 117.862 26.422 118.496 26.609C119.142 26.8084 119.769 26.9081 120.378 26.9081C121.354 26.9081 122.057 26.6962 122.488 26.2725C122.919 25.8612 123.135 25.2568 123.135 24.4592V23.2628L123.325 20.4213L123.306 17.5612V15.7105H126.747V23.9918C126.747 25.9111 126.221 27.3318 125.169 28.254C124.117 29.1762 122.628 29.6373 120.701 29.6373ZM120.074 25.3191C119.161 25.3191 118.318 25.1197 117.545 24.7209C116.784 24.3096 116.17 23.7426 115.701 23.0198C115.244 22.2845 115.016 21.4183 115.016 20.4213C115.016 19.4243 115.244 18.5644 115.701 17.8416C116.17 17.1063 116.784 16.5393 117.545 16.1405C118.318 15.7417 119.161 15.5423 120.074 15.5423C120.948 15.5423 121.696 15.7168 122.317 16.0657C122.938 16.4022 123.407 16.9319 123.724 17.6547C124.054 18.3775 124.218 19.2997 124.218 20.4213C124.218 21.543 124.054 22.4652 123.724 23.188C123.407 23.9108 122.938 24.4467 122.317 24.7957C121.696 25.1446 120.948 25.3191 120.074 25.3191ZM120.929 22.4963C121.36 22.4963 121.74 22.4091 122.07 22.2346C122.412 22.0602 122.678 21.8171 122.869 21.5056C123.071 21.194 123.173 20.8326 123.173 20.4213C123.173 20.0101 123.071 19.6487 122.869 19.3371C122.678 19.0255 122.412 18.7888 122.07 18.6268C121.74 18.4523 121.36 18.365 120.929 18.365C120.498 18.365 120.112 18.4523 119.769 18.6268C119.427 18.7888 119.155 19.0255 118.952 19.3371C118.762 19.6487 118.667 20.0101 118.667 20.4213C118.667 20.8326 118.762 21.194 118.952 21.5056C119.155 21.8171 119.427 22.0602 119.769 22.2346C120.112 22.4091 120.498 22.4963 120.929 22.4963Z'
      fill='white'
    />
    <path
      d='M134.373 26.0108C133.156 26.0108 132.091 25.7864 131.179 25.3378C130.279 24.8767 129.575 24.2536 129.068 23.4684C128.574 22.6708 128.327 21.7673 128.327 20.7578C128.327 19.7484 128.568 18.8511 129.049 18.0659C129.544 17.2683 130.222 16.6514 131.084 16.2153C131.945 15.7666 132.915 15.5423 133.993 15.5423C135.007 15.5423 135.932 15.7479 136.768 16.1592C137.605 16.558 138.27 17.1499 138.765 17.9351C139.259 18.7202 139.506 19.6736 139.506 20.7952C139.506 20.9198 139.5 21.0632 139.487 21.2252C139.475 21.3872 139.462 21.5367 139.449 21.6738H131.293V19.8045H137.529L136.16 20.3279C136.173 19.8668 136.084 19.468 135.894 19.1315C135.716 18.795 135.463 18.5333 135.133 18.3463C134.816 18.1594 134.442 18.0659 134.012 18.0659C133.581 18.0659 133.2 18.1594 132.871 18.3463C132.554 18.5333 132.307 18.8012 132.129 19.1502C131.952 19.4867 131.863 19.8855 131.863 20.3466V20.8887C131.863 21.3872 131.964 21.8171 132.167 22.1786C132.383 22.54 132.687 22.8204 133.08 23.0198C133.473 23.2067 133.942 23.3002 134.487 23.3002C134.994 23.3002 135.425 23.2316 135.78 23.0945C136.147 22.945 136.509 22.7207 136.863 22.4216L138.765 24.3657C138.27 24.9016 137.662 25.3129 136.939 25.5995C136.217 25.8737 135.361 26.0108 134.373 26.0108Z'
      fill='white'
    />
    <path
      d='M141.248 25.8425V12.7569H144.347L149.861 21.6551H148.225L153.587 12.7569H156.686L156.724 25.8425H153.264L153.226 17.9351H153.815L149.804 24.5527H148.13L144.005 17.9351H144.727V25.8425H141.248Z'
      fill='white'
    />
    <path
      d='M164.521 26.0108C163.305 26.0108 162.24 25.7864 161.327 25.3378C160.427 24.8767 159.724 24.2536 159.217 23.4684C158.722 22.6708 158.475 21.7673 158.475 20.7578C158.475 19.7484 158.716 18.8511 159.198 18.0659C159.692 17.2683 160.37 16.6514 161.232 16.2153C162.094 15.7666 163.064 15.5423 164.141 15.5423C165.155 15.5423 166.08 15.7479 166.917 16.1592C167.754 16.558 168.419 17.1499 168.913 17.9351C169.408 18.7202 169.655 19.6736 169.655 20.7952C169.655 20.9198 169.648 21.0632 169.636 21.2252C169.623 21.3872 169.61 21.5367 169.598 21.6738H161.441V19.8045H167.677L166.309 20.3279C166.321 19.8668 166.232 19.468 166.042 19.1315C165.865 18.795 165.611 18.5333 165.282 18.3463C164.965 18.1594 164.591 18.0659 164.16 18.0659C163.729 18.0659 163.349 18.1594 163.019 18.3463C162.702 18.5333 162.455 18.8012 162.278 19.1502C162.1 19.4867 162.012 19.8855 162.012 20.3466V20.8887C162.012 21.3872 162.113 21.8171 162.316 22.1786C162.531 22.54 162.836 22.8204 163.228 23.0198C163.621 23.2067 164.09 23.3002 164.635 23.3002C165.142 23.3002 165.573 23.2316 165.928 23.0945C166.296 22.945 166.657 22.7207 167.012 22.4216L168.913 24.3657C168.419 24.9016 167.811 25.3129 167.088 25.5995C166.366 25.8737 165.51 26.0108 164.521 26.0108Z'
      fill='white'
    />
    <path
      d='M137.341 32.0738C137.341 31.4285 137.873 30.9054 138.529 30.9054H169.425C170.081 30.9054 170.613 31.4285 170.613 32.0738C170.613 32.719 170.081 33.2421 169.425 33.2421H138.529C137.873 33.2421 137.341 32.719 137.341 32.0738Z'
      fill='white'
    />
  </svg>
);

export default RunManIcon;
