import React from 'react';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from 'src/api/client';
import glowFieldLoginPage from 'src/components/css/GlowField/GlowFieldLoginPage';
import textQuestionStyle from 'src/components/css/LinksTextStyle/TextQuestionStyle';
import titleStyle from 'src/components/css/TitleStyleLoginRegisterPage/TytleStyleLogRegPage';
import distanceFields from 'src/components/DistanceRegLoginFPass/DistanceFields';
import gapStyle from 'src/components/GapBetweenElements/GapBetweenElements';
import RegisterLink from 'src/components/RegisterLinks/RegisterLink';
import RegisterLoginPasswordField from 'src/components/RegisterLoginPasswordField';
import RegisterLoginTextField from 'src/components/RegisterLoginTextField';
import { useAuth } from 'src/store/authContext';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import { useTranslation } from 'react-i18next';
import emailValidationRegex from 'src/validation/emailValidationRegex';
import HoverBlueButtons from 'src/pages/HoverButtonsstyle/HoverBlueButtons';
import { useLoginContext } from 'src/store/loginPageContext';
import formStyle from '../css/PaddingForLoginRegisterElements/paddingElementStyle/formStyle';

type LoginForm = {
  email: string;
  password: string;
  canSaveToken: boolean;
};
const Login: React.FC = () => {
  const Client = useApiClient();

  const { handleSubmit, control } = useForm<LoginForm>();

  const navigate = useNavigate();

  const { setAuthToken } = useAuth();
  const { t, i18n } = useTranslation(['home', 'main']);
  const [isLoginInvalid, setIsLoginInvalid] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const [language, setLanguage] = useState('');

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.language;
    const userLanguage = browserLanguage.slice(0, 2).toUpperCase();
    i18n.changeLanguage(userLanguage);
    setLanguage(userLanguage);
  }, []);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    data.email = data.email.trim();
    if (!emailValidationRegex.test(data.email)) {
      setIsLoginInvalid(true);
    } else {
      setIsLoginInvalid(false);
    }
    try {
      const response = await Client.post('user/login', {
        userEmail: data.email.toLocaleLowerCase().trim(),
        userPsw: data.password.trim(),
        language: language,
      });

      setAuthToken(response.data.token, data.canSaveToken);

      const responseTeams = await Client.get(`team/loginUser/teams/${data.email}`);
      const teams = responseTeams.data;

      const responseLanguage = await Client.get(`user/getLanguage/${data.email}`);
      if (responseLanguage.data) {
        i18n.changeLanguage(responseLanguage.data.language);
      }
      localStorage.setItem('removedNotification', JSON.stringify(false));
      if (teams) {
        navigate('/challenge/logs/');
      } else {
        navigate('/newChallenge/team&Challenge/');
      }
    } catch (error) {
      let err = 'We encountered a problem!';
      if (error instanceof AxiosError) {
        err = error.response?.data.message;
      }
      if (err === 'INVALID_CREDENTIALS_EMAIL') {
        setWrongEmail(true);
      }

      if (err === 'INVALID_CREDENTIALS_PASSWORD') {
        setWrongPassword(true);
      }

      if (err[0] === 'userPsw must be longer than or equal to 4 characters') {
        setWrongPassword(true);
      }
    }
  };

  const useLogin = useLoginContext();
  return (
    <div className={`z-30 flex flex-col w-full md:flex-1 justify-end items-center h-full`}>
      <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
        <div className='flex-row justify-evenly w-full lg:flex tablet:flex hidden'>
          <h1 className={titleStyle}>{t('Log in')}</h1>
        </div>
        <div className='w-full h-fit flex justify-end items-end flex-col gap-5'>
          <div className={`w-full h-fit flex flex-col justify-evenly ${distanceFields} ${gapStyle} `}>
            <div className={`flex flex-col justify-evenly h-fit w-full ${gapStyle}`}>
              <div className='w-full h-full flex flex-col'>
                <div className='w-full flex flex-row'>
                  <label className='text-white font-medium 2xl:text-2xl md:text-xl text-xl w-1/2'>{t('Email')}</label>
                  <div className='text-primary font-medium 2xl:text-2xl md:text-xl text-xl w-full h-full flex justify-end'>
                    {isLoginInvalid ? `${t('INVALID DATA')}` : wrongEmail && `${t('E-MAIL NOT EXIST')}`}
                  </div>
                </div>

                <div className='h-fit w-full'>
                  <Controller
                    control={control}
                    name='email'
                    render={({ field }) => (
                      <RegisterLoginTextField
                        className={`${glowFieldLoginPage}  ${
                          isLoginInvalid || wrongEmail
                            ? ' border-primary border-[1.5px]'
                            : '  border-lightGreen border-2'
                        }`}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={`flex flex-col justify-evenly h-fit w-full ${gapStyle}`}>
              <div className='w-full h-full flex flex-col'>
                <div className='w-full flex flex-row'>
                  <label className='text-white font-medium 2xl:text-2xl md:text-xl text-xl w-1/2'>
                    {t('Password')}
                  </label>
                  <div className='text-primary font-medium 2xl:text-2xl md:text-xl text-xl w-full h-full flex justify-end'>
                    {isLoginInvalid ? '' : wrongPassword && `${t('WRONG PASSWORD')}`}
                  </div>
                </div>

                <div className='h-fit w-full'>
                  <Controller
                    control={control}
                    name='password'
                    render={({ field }) => (
                      <RegisterLoginPasswordField
                        className={`${glowFieldLoginPage}  ${
                          isLoginInvalid || wrongPassword
                            ? ' border-primary border-[1.5px]'
                            : ' border-lightGreen border-2'
                        } 
                             }`}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex flex-row items-center w-full h-fit xl:pt-0 desktop:pt-0 lg:pt-2 tablet:pt-2 ${gapStyle}`}
            >
              <div className='w-fit h-fit flex'>
                <Controller
                  control={control}
                  name='canSaveToken'
                  render={({ field }) => (
                    <>
                      <Checkbox className='bg-white rounded h-6 w-6  cursor-pointer' {...field} />
                    </>
                  )}
                />
              </div>
              <div className={`w-full h-fit ${textQuestionStyle}`}>
                <span className={textQuestionStyle}>{t('Remember me')}</span>
              </div>
              <div className='w-full h-fit flex-row justify-end'>
                <div className='text-right'>
                  <span
                    onClick={() => {
                      useLogin.setIsLogin(false);
                      useLogin.setIsForgotPassword(false);
                    }}
                    className={`${textQuestionStyle} underline cursor-pointer`}
                  >
                    {t('Forgot password?')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full 2xl:h-fit md:h-fit sm:h-fit 2xl:justify-center h-full flex flex-col justify-end 2xl:pt-8 desktop:pt-8 md:pt-1 sm:pt-1 pb-0 `}
          >
            <div className='h-14 items-center justify-center flex'>
              <Button
                type='submit'
                className={HoverBlueButtons}
                onClick={() => {
                  setWrongEmail(false);
                  setWrongPassword(false);
                  setIsLoginInvalid(false);
                }}
              >
                {t('Log in')}
              </Button>
            </div>
            <RegisterLink />
          </div>
        </div>
      </form>
    </div>
  );
};
export default Login;
