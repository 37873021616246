import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEnglish from './components/Translation/English/translation.json';
import translationCzech from './components/Translation/Czech/translation.json';
import translationBulgarian from './components/Translation/Bulgarian/translation.json';

const resources = {
  EN: {
    home: translationEnglish,
  },
  CZ: {
    home: translationCzech,
  },
  CS: {
    home: translationCzech,
  },
  BG: {
    home: translationBulgarian,
  },
};

i18next.use(initReactI18next).use(LanguageDetector).init({
  resources,
  fallbackLng: 'EN',
});

export default i18next;
