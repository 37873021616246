import React from 'react';

const ServiceListIconWhite: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='26' viewBox='0 0 20 26' fill='none'>
    <path
      d='M8.40039 0.199219C7.51674 0.199219 6.80039 0.915563 6.80039 1.79922C6.80039 2.68287 7.51674 3.39922 8.40039 3.39922H11.6004C12.484 3.39922 13.2004 2.68287 13.2004 1.79922C13.2004 0.915563 12.484 0.199219 11.6004 0.199219H8.40039Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.400391 4.99922C0.400391 3.23191 1.83308 1.79922 3.60039 1.79922C3.60039 4.45019 5.74942 6.59922 8.40039 6.59922H11.6004C14.2514 6.59922 16.4004 4.45019 16.4004 1.79922C18.1677 1.79922 19.6004 3.23191 19.6004 4.99922V22.5992C19.6004 24.3665 18.1677 25.7992 16.4004 25.7992H3.60039C1.83308 25.7992 0.400391 24.3665 0.400391 22.5992V4.99922ZM15.9318 14.1306C16.5566 13.5058 16.5566 12.4927 15.9318 11.8678C15.3069 11.243 14.2939 11.243 13.669 11.8678L8.40039 17.1365L6.33176 15.0678C5.70692 14.443 4.69386 14.443 4.06902 15.0678C3.44418 15.6927 3.44418 16.7058 4.06902 17.3306L7.26902 20.5306C7.89386 21.1554 8.90692 21.1554 9.53176 20.5306L15.9318 14.1306Z'
      fill='white'
    />
  </svg>
);

export default ServiceListIconWhite;
