import React, { useEffect, useState } from 'react';
import EditButton from '../../../components/EditButton';
import ServiceFormCard from '../ServiceFormCard';
import RowButton from 'src/components/RowButton';
import { ActivityLogRequest } from 'src/api/types/activityForm/ActivityEditRequest';
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { StatusWatcher } from '../ActivityForm/StatusWatcher';
import Select from 'src/components/Select';
import { useApiClient } from 'src/api/client';
import { TargetLogsListItem } from 'src/api/types/targets/targetResponse';
import { useActivity } from 'src/store/activityCardContext';
import Swal from 'sweetalert2';
import PictureIcon from 'src/components/Icons/pictureIcon';
import DeleteIcon from 'src/components/Icons/deleteIcon';
import PictureSVGIcon from 'src/components/Icons/PictureSVGIcon';
import { AxiosError } from 'axios';
import className from '../ActivityEditFormClassName/ClassNameActivityEditForm';
import buttonSwapColor from 'src/pages/HoverButtonsstyle/HooverButtonSwapColor';
import AddEditTextField from 'src/components/AddEditTextField/AddEditTextField';
import NumberFieldWithUnit from 'src/components/NumberFieldWithUnit/NumberFieldWithUnit';
import { useTranslation } from 'react-i18next';
import { resizeFile } from 'src/utils/resizeImgFile';
import { useToast } from 'src/store/toastContext';
import EditActivityDatePicker from 'src/components/AddEditActivityDatePicker/EditActivityDatePicker';

interface PreviewServiceFormProps {
  data?: ActivityLogRequest & { id: number };
  closeModal?: () => void;
}
interface EditChallengeLogsForm {
  workout: number;
  date: string;
  distance: string;
  link: string | null;
  description: string | null;
  img: File | null;
}
interface ISelect {
  target: {
    value: string;
  };
}
const PreviewServiceForm: React.FC<PreviewServiceFormProps> = ({ closeModal }) => {
  const form = useForm<EditChallengeLogsForm>();
  const { t } = useTranslation(['home', 'main']);
  const { showToastHandler } = useToast();
  const activity = useActivity();
  const { handleSubmit, control, setValue } = form;
  const Client = useApiClient();
  const [items, setItems] = useState<TargetLogsListItem[]>([]);
  const [delImgConfirm, setDelImgConfirm] = useState(false);
  const [unit, setUnit] = useState<string>(activity.unit);
  const [select, setSelected] = useState<number>(activity.workout);
  const [isAllowed, setIsAllowerd] = useState<boolean>(false);
  const [challengeStart, setChallengeStart] = useState<string>();
  const [challengeEnd, setChallengeEnd] = useState<string>();

  const handleChange = (event: ISelect): void => {
    const eventChlngId = parseInt(event.target.value);
    for (let item = 0; item < items.length; item++) {
      const element = items[item];
      setSelected(eventChlngId);
      if (element.id == eventChlngId) {
        setIsAllowerd(items[item].chall_insert_log_days_after_activity);
        setChallengeStart(items[item].chall_start);
        setChallengeEnd(items[item].chall_end);
        setUnit(items[item].chlgTargetUnit);
        break;
      } else if (eventChlngId == 0) {
        setUnit('');
        break;
      }
    }
  };
  useEffect(() => {
    Client.get('activity/targets')
      .then((response) => {
        const responseItems = response.data.allTargets;
        setItems(responseItems);
        for (let item = 0; item < responseItems.length; item++) {
          const element = responseItems[item];
          setSelected(activity.workout);
          if (element.id == activity.workout) {
            setIsAllowerd(responseItems[item].chall_insert_log_days_after_activity);
            setChallengeStart(responseItems[item].chall_start);
            setChallengeEnd(responseItems[item].chall_end);
            break;
          } else if (activity.workout == 0) {
            setUnit('');
            break;
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    setValue('workout', activity.workout);
    setValue('link', activity.link ? activity.link : '');
    setValue('description', activity.description ? activity.description : '');
    setValue('date', activity.date.toString());
    setValue('distance', parseFloat(activity.value).toString());
    setDistanceValue(parseFloat(activity.value).toString());
  }, [activity]);
  const [distanceValue, setDistanceValue] = useState<string>('0');
  const changeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = event.target.value;
    if (!/^\d+([.,]\d{0,3})?$/.test(val)) {
      if (/([.,]\d{0,3})/.test(val)) {
        return;
      }
      setDistanceValue('');
      return;
    }
    if (val === '') {
      setDistanceValue('');
      return;
    }
    const formatInputValue = parseFloat(val.replace(',', '.').replace(' ', ''));
    setDistanceValue(val.toString());
    setValue('distance', formatInputValue.toString());
  };
  const deleteImg = async (): Promise<void> => {
    try {
      Swal.fire({
        title: "<h5 style='color: #F16B6B;'>" + `${t('Are you sure you want to delete this photo?')}` + '</h5>',
        showCancelButton: true,
        confirmButtonText: `${t('YES')}`,
        cancelButtonText: `${t('CANCEL')}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire(`${t('DELETED')}`, `${t('Photo will be deleted after saving on data!')}`);
          setDelImgConfirm(true);
        }
      });
    } catch (error) {
      setDelImgConfirm(false);
      console.error(
        Swal.fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
          html: "<div style='color: #F16B6B;'>" + `${t('Enter the correct information!')}` + '</div> ',
        }),
        error,
      );
    }
  };
  const [fileName, setFileName] = useState<string>();
  const [img, setImg] = useState<File>();
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      if (files[0].type === 'image/heic') {
        setImg(files[0]);
        setValue('img', files[0]);
        setFileName(files[0].name.toString());
      } else {
        const resized = (await resizeFile(files[0])) as File;
        setImg(resized);
        setValue('img', resized);
        setFileName(files[0].name.toString());
      }
    }
  };
  const onSubmit: SubmitHandler<EditChallengeLogsForm> = async (data) => {
    try {
      if (delImgConfirm && activity.img && !data.img && !data.link && data.link === '') {
        throw new Error(`${t('Missing Photo/Link')}`);
      }
      if (!activity.img && !data.img && !data.link && data.link === '') {
        throw new Error(`${t('Missing Photo/Link')}`);
      } else if (!data.img && (!data.link || data.link === '') && !activity.img) {
        throw new Error(`${t('Missing Photo/Link')}`);
      }

      const bodyFormData = new FormData();
      if (img != null) {
        bodyFormData.append('img', img);
      }
      if (data.link != null) {
        bodyFormData.append('link', data.link);
      }
      bodyFormData.append('workout', select + '');
      bodyFormData.append('date', data.date);
      bodyFormData.append('value', data.distance + '');
      bodyFormData.append('description', data.description + '');
      if (delImgConfirm) {
        await Client.delete(`/challenge/deleteImg/${activity.logId}`);
      }
      const result = await Client.put(`/challenge/editLog/${activity.logId}`, bodyFormData);
      if (result.status == 200) {
        showToastHandler(`${t('Your activity has been changed!')}`, 'success');
        closeModal && closeModal(), activity.setReload(true);
      }
    } catch (error) {
      let errorMsg = 'We encountered a problem!';
      if (error instanceof AxiosError) {
        errorMsg = error.response?.data.message;
      } else if (error instanceof Error) {
        errorMsg = error.message;
      }

      if (errorMsg === 'Chybí Fotka/Odkaz') {
        errorMsg = `${t('Missing Photo/Link')}`;
      } else if (errorMsg === 'Datum vaší aktivity je mimo dobu výzvy') {
        errorMsg = `${t('Your activity date is outside the challenge period')}`;
      } else if (errorMsg === 'Target not found!') {
        errorMsg = `${t('Target not found!')}`;
      }

      Swal.fire({
        title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
        html: "<div style='color: #F16B6B;'>" + errorMsg + '</div> ',
      });
    }
  };
  const getImg = async (): Promise<void> => {
    const response = await Client.get(`challenge/logImg/${activity.logId}`);
    showModal(response.data.photo);
  };
  const showModal = (picture: string): void => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      timer: 3000,
      closeButtonHtml: PictureSVGIcon,
      html: `<img class='w-full h-fit' src="data:image/jpeg;base64,${picture}" />`,
    });
  };
  const onError: SubmitErrorHandler<EditChallengeLogsForm> = (errors) => {
    console.log(errors);
  };
  const gridClassName = 'grid grid-cols-2 desktop:pr-4 tablet:pr-4 mb-5';

  return (
    <div className='flex-col w-full tablet:w-full'>
      <FormProvider {...form}>
        <form className='w-full' onSubmit={handleSubmit(onSubmit, onError)}>
          <StatusWatcher />
          <div className='flex space-x-44 mb-2 '>
            <div className='2xl:text-3xl md:text-3xl sm:text-3xl text-2xl text-black font-semibold '>
              {t('Edit activity')}
            </div>
          </div>
          <ServiceFormCard>
            <div className={gridClassName}>
              <div className={className}>{t('TARGET')}</div>
              <div className='h-full w-full flex flex-row justify-end'>
                <Controller
                  control={control}
                  name='workout'
                  render={({ field }) => (
                    <>
                      <Select {...field} onChange={handleChange} value={select}>
                        <option value={0}></option>
                        {items.map((target) => (
                          <option key={target.name} value={target.id}>
                            {target.name}/{target.challenge}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </div>
            </div>
            <Controller
              control={control}
              name='date'
              render={({ field }) => {
                return (
                  <>
                    <div className={gridClassName}>
                      <div className={className}>{t('DATE')}</div>
                      <EditActivityDatePicker
                        logCurrentDate={activity.date}
                        challengeStart={challengeStart}
                        challengeEnd={challengeEnd}
                        isAllowed={isAllowed}
                        {...field}
                        onChange={(date) => {
                          if (!date) {
                            field.onChange(null);
                            return;
                          }
                          field.onChange(date);
                        }}
                        value={new Date(field.value)}
                      />
                    </div>
                  </>
                );
              }}
            />

            <div className={gridClassName}>
              <div className={className}>{t('VALUE')}</div>
              <div className='flex flex-row w-fit h-fit relative'>
                <NumberFieldWithUnit type={'text'} value={distanceValue} onChange={changeValue} required={true} />
                <span className='w-fit h-full m-auto flex text-darkGrey absolute top-0 right-2 justify-center  items-center'>
                  {t(`${unit}`)}
                </span>
              </div>
            </div>
            <div className={gridClassName}>
              <div className={className}>{t('LINK')}</div>
              <Controller
                control={control}
                name='link'
                render={({ field, fieldState }) => (
                  <>
                    <AddEditTextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <div className={gridClassName}>
              <div className={className}>{t('Description')}</div>
              <Controller
                control={control}
                name='description'
                render={({ field, fieldState }) => (
                  <>
                    <AddEditTextField maxLength={50} {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <div className='flex flex-row justify-center items-center text-center'>
              <div className={`flex justify-start lg:w-3/12`}>{t('PHOTO')}</div>
              <div className='flex flex-row items-center gap-3 w-full'>
                <div className='flex w-full justify-center  desktop:pl-24 md:pl-0 tablet:pl-24 '>
                  <input
                    type='file'
                    id='inputPicture'
                    className='hidden w-full bg-lightBlueButton p-4'
                    onChange={onChange}
                  />
                  <label
                    htmlFor='inputPicture'
                    className={`${buttonSwapColor}  desktop:full  w-full mx-1 items-center flex flex-row cursor-pointer justify-evenly desktop:h-14 tablet:h-14 h-10 desktop:px-2 tablet:px-5 px-2`}
                  >
                    {t('SELECT FILE')}
                  </label>
                </div>
              </div>
              <div className='2xl:w-8/12 dekstop:w-5/12 md:w-6/12 tablet:w-4/12 sm:w-full w-4/12 h-full flex flex-col items-center justify-evenly text-center'>
                {activity.img ? (
                  <div className='w-full h-full desktop:justify-between tablet:justify-between gap-10 justify-end items-center flex flex-row'>
                    <div className='w-full  justify-center font-normal desktop:flex tablet:flex hidden'>
                      {' '}
                      {fileName ? fileName : `${t('YOU HAVE A SELECTED PHOTO')}`}
                    </div>
                    <div className='w-full h-full'>
                      {activity.img && (
                        <div className='flex flex-row justify-end place-items-end'>
                          <span
                            className='flex float-right desktop:mr-2 tablet:mr-2 mr-4 cursor-pointer'
                            onClick={getImg}
                          >
                            <PictureIcon />
                          </span>
                          <span
                            className='flex float-right desktop:mr-7 tablet:mr-7 cursor-pointer'
                            onClick={deleteImg}
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <span id='fileName' className='font-normal desktop:text-base tablet:text-base text-sm pr-4'>
                      {fileName ? fileName : `${t('FILE NOT SELECTED')}`}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </ServiceFormCard>
          <div className='mt-10 grid place-items-end'>
            <div className='flex lex-row w-full justify-end gap-10 h-full mt-1 mb-6'>
              <EditButton
                type='button'
                className={`${buttonSwapColor} desktop:w-5/10 tablet:w-5/10 w-full`}
                onClick={closeModal}
              >
                {t('CANCEL')}
              </EditButton>
              <RowButton className={`${buttonSwapColor} desktop:w-5/10 tablet:w-5/10 w-full`} type='submit'>
                {t('EDIT')}
              </RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default PreviewServiceForm;
