import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import { useNavigate } from 'react-router-dom';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import { useTranslation } from 'react-i18next';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import glowField from 'src/components/css/GlowField/GlowField';
import Tooltip from 'src/utils/Tooltip';
import TargetsTooltip from 'src/utils/TargetsTooltip';
import PencilIcon from 'src/components/Icons/PencilIcon';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import buttonEditChallenge from '../ButtonEditChallenge/buttonEditChallenge';
import { Spinner } from 'src/utils/Spinner';
import { useToast } from 'src/store/toastContext';
export interface ChallengeTarget {
  id?: number;
  targetName: string;
  targetValue: number;
  targetUnit: string;
}

interface ITarget {
  targetId: number;
  name: string;
  targetValue: number;
  targetUnit: string;
}
interface IChallengeResponse {
  chall_id: number;
  chall_name: string;
  chall_start: Date;
  chall_end: Date;
  chall_insert_log_till: Date;
  chall_insert_log_days_after_activity: boolean;
  licenceValidTo: Date | undefined;
  team_name: string;
  team_id: number;

  team_emails: string[];

  targets: ITarget[];
}

export interface NewChallengeForm {
  teamName: string;
  challengeName: string;
  dateFrom: Date;
  dateTo: Date;
  deadlineDate: Date;
  targets: ChallengeTarget[];
  emails: string;
}

interface IButtonHandleProps {
  editChallengeCard: () => void;
  editUserPage: () => void;
  editTargets: () => void;
  data: IChallengeResponse | undefined;
}

const EditChallenge = ({ editTargets, editChallengeCard, editUserPage, data }: IButtonHandleProps): JSX.Element => {
  const { setValue } = useForm<NewChallengeForm>();
  const navigate = useNavigate();
  const { t } = useTranslation(['home', 'main']);
  const challengeData = useChallengeData();
  const { showToastHandler } = useToast();
  const onSubmit = (): void => {
    showToastHandler(`${t('Challenge saved successfully')}`, 'success');
    navigate('/myChallenges/allUsers');
  };

  useEffect(() => {
    if (data) {
      const { team_name, chall_name, chall_start, chall_end, chall_insert_log_till } = data;
      setValue('teamName', team_name);
      setValue('challengeName', chall_name);
      setValue('dateFrom', chall_start);
      setValue('dateTo', new Date(chall_end));
      setValue('deadlineDate', new Date(chall_insert_log_till));
    }
  }, []);

  return (
    <div className='w-full phone:h-[90vh] h-[90vh] lg:h-[80vh] flex flex-col justify-center items-center lg:px-10 phone:px-5 px-5 overflow-y-auto overflow-hidden'>
      <div className={`${titleStyle} ${mobileTitleStyle}  uppercase `}>{t('Edit challenge')}</div>
      <div
        className={`lg:mt-3 phone:mt-10 mt-10 shadow-2xl lg:shadow-none flex flex-col w-full lg:h-full items-start justify-end overflow-hidden ${padding} ${roundingForm}`}
      >
        {!(challengeData.emails.length !== 0) ||
        !(challengeData.challengeTargets.length !== 0) ||
        !challengeData.teamName ? (
          <div className='flex h-full w-full items-center justify-center'>
            <Spinner />
          </div>
        ) : (
          <>
            <div className='h-full w-full flex lg:flex-row flex-col  lg:justify-between lg:px-0 phone:pl-10 pl-8 overflow-hidden overflow-y-auto '>
              <div className='2xl:w-[550px] xl:w-5/12 lg:w-full space-y-4  pt-4 flex flex-col  lg:h-full h-fit text-DarkPrimary '>
                <div className='flex flex-row w-full h-fit justify-center space-y-4  items-center group'>
                  <div className='flex flex-col h-full w-full space-y-4'>
                    <div className='w-full h-fit'>
                      <div className={newChallengeTitleStyle}>{t('TEAM')}:</div>
                      <div className={`${glowField} bg-lightGrayBg`}>{challengeData.teamName}</div>
                    </div>
                    <div className='w-full h-fit'>
                      <div className={newChallengeTitleStyle}>{t('CHALLENGE NAME')}:</div>
                      <div className={`${glowField} bg-lightGrayBg`}>{challengeData.challengeName}</div>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:space-y-0 space-y-4 2xl:pb-[6px] lg:space-x-1 pb-0 w-full lg:text-'>
                      <div className='flex flex-col w-full justify-end items-center overflow-hidden '>
                        <div className={newChallengeTitleStyle}>{t('DATE FROM')}:</div>
                        <div className={`${glowField} bg-lightGrayBg lg:pl-1 xl:pl-2 2xl:pl-2`}>
                          {challengeData.dateFrom.toLocaleDateString().slice(0, 10)}
                        </div>
                      </div>
                      <div className='flex flex-col w-full justify-end overflow-hidden'>
                        <div className={newChallengeTitleStyle}>{t('DATE TO')}:</div>
                        <div className={`${glowField} bg-lightGrayBg lg:pl-1 xl:pl-2 2xl:pl-2`}>
                          {challengeData.dateTo.toLocaleDateString().slice(0, 10)}
                        </div>
                      </div>
                      <div className='flex flex-col w-full justify-end overflow-hidden '>
                        <div className={newChallengeTitleStyle}>{t('DEADLINE')}:</div>
                        <div
                          className={`${glowField}  ${
                            challengeData.checkBoxDeadlineDate ? 'text-gray' : 'text-DarkPrimary'
                          } bg-lightGrayBg lg:pl-1 xl:pl-2 2xl:pl-2`}
                        >
                          {challengeData.deadLineDate.toLocaleDateString().slice(0, 10)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className='lg:mx-2 phone:mx-1 mx-1 lg:opacity-0 opacity-100 phone:opacity-100 items-center justify-center transition-opacity group-hover:opacity-100 '
                    onClick={editChallengeCard}
                  >
                    <PencilIcon />
                  </button>
                </div>
                <div className='group flex flex-row w-full lg:h-full lg:pb-2 phone:h-[30vh] h-[30vh] overflow-hidden'>
                  <div
                    className={`border-lightBlueButton text-DarkPrimary pb-8  lg:bg-lightGrayBg bg-inactiveFields outline-none lg:border-2 phone:border-0 border-0 lg:px-5 w-full  h-full  overflow-hidden sm:rounded-none md:rounded-none phone:rounded-none rounded-none ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
                  >
                    <div
                      className={`phone:pl-4 lg:mt-1  font-bold 2xl:text-base border-b-[1px]  border-DarkPrimary ${newChallengeTitleStyle}`}
                    >
                      {t('E-MAILS')}: {challengeData.emails.length}
                    </div>

                    <div className='w-full h-full overflow-hidden overflow-y-auto space-y-2 py-2 '>
                      {challengeData.emails.map((email, index) => {
                        return (
                          <span
                            key={index}
                            className={`px-4 py-2 lg:w-[20vw] 2xl:w-[17vw] phone:w-full w-full  justify-between rounded-full bg-lightGrayBg lg:bg-white text-DarkPrimary  font-semibold border-lightGreen border-[2px] text-sm flex align-center ${glowField}`}
                          >
                            <Tooltip tooltip={email} tooltipContent={email} />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    className='lg:mx-2 phone:mx-1 mx-1 sticky lg:opacity-0 opacity-100 phone:opacity-100 items-center justify-center transition-opacity group-hover:opacity-100 '
                    onClick={editUserPage}
                  >
                    <PencilIcon />
                  </button>
                </div>
              </div>
              <div className='flex relative 2xl:w-[550px] xl:w-5/12 lg:w-full pt-4  flex-row group'>
                <div className='flex flex-col justify-start w-full items-center  '>
                  <div className='font-normal flex justify-center w-full border-DarkPrimary text-DarkPrimary lg:pt-0  h-fit 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px] 2xl:pl-3'>
                    <div className='flex font-bold'>{t('TARGET')}/</div>
                    <div className='flex'>{t('GOAL')}</div>
                    <div className='flex font-bold'>/{t('UNIT OF MEASURE')}</div>
                  </div>
                  <div
                    className={`lg:border-2 overflow-hidden phone:border-0 border-0 w-full lg:h-full lg:mb-2 h-[20vh] phone:h-[25vh] sm:h-[33vh]  lg:bg-lightGrayBg border-lightBlueButton flex-col py-1 lg:pr-3 sm:rounded-none md:rounded-none  phone:rounded-none rounded-none ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
                  >
                    <div className='overflow-y-auto  w-full flex flex-col justify-start items-center space-y-2  h-full phone:text-sm'>
                      {challengeData.challengeTargets.map((target, index) => {
                        return (
                          <span
                            key={index}
                            className={`px-4 py-1 lg:w-[20vw] 2xl:w-[17vw]  w-full phone:w-full justify-between rounded-full bg-lightGrayBg lg:bg-white text-DarkPrimary font-semibold border-lightGreen border-[2px] text-sm flex align-center ${glowField}`}
                          >
                            <TargetsTooltip
                              targetName={target.targetName}
                              targetValue={target.targetValue}
                              targetUnit={t(`${target.targetUnit}`)}
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <button
                  className='lg:mx-2 phone:mx-1 mx-1 flex flex-row h-full lg:opacity-0 opacity-100 phone:opacity-100 items-center justify-center transition-opacity group-hover:opacity-100 '
                  onClick={editTargets}
                >
                  <PencilIcon />
                </button>
              </div>
            </div>
            <div className='flex my-4 text-center w-full h-fit justify-center items-center'>
              <Button className={buttonEditChallenge} type={'submit'} onClick={() => onSubmit()}>
                {t('CONFIRM')}
              </Button>
            </div>{' '}
          </>
        )}
      </div>
    </div>
  );
};
export default EditChallenge;
