import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import PreviewServiceForm from '../../pages/HomePage/EditForm';
import '../css/Popup.css';
import swal from 'sweetalert2';
import PictureIcon from '../Icons/pictureIcon';
import ExternalLink from '../Icons/ExternalLinkIcon';
import PictureSVGIcon from '../Icons/PictureSVGIcon';
import { Dialog } from '@headlessui/react';
import ModalFromLibrary from '../ModalFromLibrary';
import moment from 'moment';
import activityLogStyle from '../css/ActivityLogsStyle/ActivityLogStyle';
import scaleTextElements from '../css/ScaleTextAndElements/ScaleTextElements';
import { IDeletedLogs } from 'src/api/types/deletedLogs/DeletedLogsResponse';
import RenovationIcon from '../Icons/RenovationIcon';
import { useApiClient } from 'src/api/client';
import { useTranslation } from 'react-i18next';
import { AccordionBody, AccordionHeader } from '@material-tailwind/react';
import { useToast } from 'src/store/toastContext';
import overflowText from '../css/MyChallengeLogsStyle/OverflowText';

interface DeletedLogsItemsProps {
  item: IDeletedLogs;
  loadDeletedLogs: () => void;
}

const DeletedLogsMobileVersion: React.FC<DeletedLogsItemsProps> = ({ item, loadDeletedLogs }) => {
  const { chall_name, chl_date, chl_id, chl_img, chl_link, chl_value, cht_name, user_email, user_name } = item;
  const { t } = useTranslation(['home', 'main']);
  const Client = useApiClient();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState<boolean>(true);
  const { showToastHandler } = useToast();

  const closeModal = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    loadDeletedLogs();
  }, [reload]);

  const addLinkHttps = useMemo(() => (chl_link?.match(/^https?:\/\//i) ? chl_link : `http://${chl_link}`), [chl_link]);
  const getImg = async (): Promise<void> => {
    const response = await Client.get(`/reportedActivity/reportedLogImg/${chl_id}`);
    showModal(response.data.photo);
  };
  const showModal = (photo: string): void => {
    swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: PictureSVGIcon,
      html: `<img class='w-full h-full' src="data:image/jpeg;base64,${photo}" />`,
    });
  };

  const confirmationModel = async (): Promise<void> => {
    try {
      swal
        .fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Do you really want to resume the activity?')}` + '</h5>',
          showCancelButton: true,
          confirmButtonText: `${t('YES')}`,
          cancelButtonText: `${t('CANCEL')}`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await Client.put(`/challenge/activity/recovery/${chl_id}`);
              showToastHandler(`${t('Activity has been resumed')}`, 'success');
            } catch (error) {
              console.log(error);
            }
          }
          setReload(false);
        });
    } catch (error) {
      console.error(
        swal.fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
          html: "<div style='color: #F16B6B;'>" + `${t('Enter the correct information!')}` + '</div> ',
        }),
        error,
      );
    }
  };

  return (
    <>
      <div className='shadow-sm shadow-offset-x-2 '>
        {' '}
        <AccordionHeader className='border-none  justify-between item w-full'>
          <tr
            key={cht_name}
            className={`w-full justify-center items-center flex-row py-1 F flex ${
              typeof chl_id === 'number' && pathname.includes(chl_id.toString()) ? 'bg-white' : ''
            }`}
          >
            <td className={activityLogStyle}>
              <div className={overflowText}>{user_name ? user_name : user_email}</div>
            </td>
            <td className={activityLogStyle}>
              <div className={overflowText}>{cht_name}</div>
            </td>
            <td className={activityLogStyle}>{chl_value}</td>
            <td className={activityLogStyle}>{moment(chl_date).format('DD.MM.yyyy')}</td>
          </tr>
        </AccordionHeader>
        <AccordionBody className='flex flex-row w-full  justify-between items-center'>
          <td className={activityLogStyle}>{chall_name}</td>
          <td className={activityLogStyle}>
            {(chl_link && (
              <a target={'_blank'} rel='noreferrer' className={scaleTextElements} href={addLinkHttps}>
                <ExternalLink />
              </a>
            )) || <span className='font-bold'>-</span>}
          </td>
          <td className={activityLogStyle}>
            {(chl_img && (
              <span className={`font-bold cursor-pointer ${scaleTextElements}`} onClick={getImg}>
                <PictureIcon />
              </span>
            )) || <span className='font-bold'>-</span>}
          </td>
          <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
              <button
                type='button'
                className={`text-lightBlueButton btn bg-white btn-sm border-none rounded-lg btn-circle absolute right-2 top-2 px-2`}
                onClick={closeModal}
              >
                x
              </button>
            </Dialog.Title>
            <PreviewServiceForm closeModal={closeModal} />
          </ModalFromLibrary>
          <td className={`${activityLogStyle} flex justify-center cursor-pointer`}>
            <span onClick={confirmationModel}>
              <RenovationIcon />
            </span>
          </td>
        </AccordionBody>
      </div>
    </>
  );
};

export default DeletedLogsMobileVersion;
