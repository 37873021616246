import SidePanel, { SidePanelRoutes } from 'src/components/SidePanel';
import { TeamDetailProvider } from 'src/store/teamDetailContext';
import TeamDetail from './TeamDetail';
import TeamTable from './TeamTable';
import BlueWavesBG from 'src/components/BlueWavesBGimg/BlueWavesBG';

const MyTeamsPage: React.FC<unknown> = () => {
  return (
    <div className='h-screen'>
      <TeamDetailProvider>
        <div className='flex h-screen z-0 bg-gradient-to-r from-lightBlue to-darkBlue'>
          <BlueWavesBG />
          <div className='z-10'>
            <SidePanel activeRoute={SidePanelRoutes.MY_TEAMS} />
          </div>
          <div className='z-20 flex flex-col h-full  w-full overflow-y-auto'>
            <div className='h-full w-full'>
              <TeamTable />
            </div>
            <div className='w-full h-full'>
              <TeamDetail />
            </div>
          </div>
        </div>
      </TeamDetailProvider>
    </div>
  );
};
export default MyTeamsPage;
