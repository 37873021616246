import React from 'react';

export type EditButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const EditButton = React.forwardRef((props: EditButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button
    className='bg-errorRed text-white text-sm font-medium w-[160px] h-[40px] rounded-[8px] ml-4 mr-2'
    ref={ref}
    {...props}
  >
    {props.children}
  </button>
));

EditButton.displayName = 'EditButton';

export default EditButton;
