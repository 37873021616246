import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IToastProps {
  showNotification: boolean;
  name: string;
}
const Notifications: React.FC<IToastProps> = ({ showNotification, name }) => {
  const { t } = useTranslation(['home', 'main']);
  const [notification, setNotification] = useState<boolean>(showNotification);
  useEffect(() => {
    if (showNotification === true) {
      setTimeout(() => {
        localStorage.setItem(name, JSON.stringify(true));
        setNotification(false);
      }, 9000);
    }
  }, [showNotification]);
  const handleDismiss = (): void => {
    localStorage.setItem(name, JSON.stringify(true));
    setNotification(false);
  };

  return (
    <>
      {notification && (
        <div className='text-white cursor-default animate-pulse hover:animate-none w-fit p-4 h-5 bg-primary absolute -top-7 -right-9 z-40 hover:scale-105 ease-out duration-500 text-center flex items-center justify-center rounded-tl-full rounded-tr-full rounded-br-full'>
          <span className='text-white absolute top-0 right-[6px] cursor-pointer' onClick={handleDismiss}>
            <span>x</span>
          </span>
          <span>{`${t('NEW')}`}</span>
        </div>
      )}
    </>
  );
};
export default Notifications;
