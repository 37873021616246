import { Dialog } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiClient } from 'src/api/client';
import ServiceForm from 'src/pages/HomePage/ActivityForm';
import { useActivity } from 'src/store/activityCardContext';
import { Spinner } from 'src/utils/Spinner';
import ChallengeInfoModal from '../ChallengeInfoModal/ChallengeInfoModal';
import ModalFromLibrary from '../ModalFromLibrary';

const MissingChallenge: React.FC = () => {
  const Client = useApiClient();
  const [challengeLenght, setChallengeLenght] = useState<boolean>();
  const [anyLogs, setAnyLogs] = useState<boolean>();
  const [inactiveChallenge, setInactiveChallenge] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    Client.get('challenge/count')
      .then((response) => {
        setIsLoading(true);
        const { anyChallenge, anyLogs, inactiveChallenge } = response.data;
        setChallengeLenght(anyChallenge);
        setInactiveChallenge(inactiveChallenge);
        setAnyLogs(anyLogs);
        setIsLoading(false);
      })
      .catch((exc) => {
        console.error(exc);
      });
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const activity = useActivity();
  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    activity.setReload(false);
    setIsOpen(true);
  };
  const { t } = useTranslation(['home', 'main']);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {!challengeLenght && !anyLogs && !inactiveChallenge && (
            <div className='w-full h-full justify-center flex items-center text-center'>
              <p className='self-center w-9/12 items-center lg:text-[14px] text-[10px] text-center'>
                {t("You haven't created any challenges yet. Now prepare it ")}
                <a className='underline cursor-pointer' href='/newChallenge/'>
                  {t('here')}
                </a>
                {t(' and get your body moving!')}
              </p>
            </div>
          )}
          {!anyLogs && challengeLenght && !inactiveChallenge && (
            <div className='w-full h-full justify-center flex items-center text-center'>
              <p className='self-center w-9/12 items-center lg:text-[14px] text-[10px] text-center'>
                {t('Active Challenge Without logs')}
                <a className='underline cursor-pointer' onClick={openModal}>
                  {t('here')}
                </a>
              </p>
            </div>
          )}
          {challengeLenght && inactiveChallenge && (
            <div className='w-full h-full justify-center flex items-center text-center'>
              <ChallengeInfoModal
                chllngInfo={`${t('ANY ACTIVE CHALLENGE')}`}
                insertLogDaysAfterActivityInfo={undefined}
                closeModal={closeModal}
                buttonName={`${t('CREATE')}`}
                urlLink='/newChallenge/'
              />
            </div>
          )}
        </>
      )}
      <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
          <button
            type='button'
            className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
            onClick={closeModal}
          >
            x
          </button>
        </Dialog.Title>
        <ServiceForm closeModal={closeModal} />
      </ModalFromLibrary>
    </>
  );
};
export default MissingChallenge;
