import React from 'react';

const AddAvatarIconMobile: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='46' height='46' viewBox='0 0 46 46' fill='none'>
    <rect x='1' y='0.5' width='44' height='45' rx='7.5' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.5 22.9991C35.5 26.3482 34.183 29.5602 31.8388 31.9284C29.4946 34.2966 26.3152 35.6271 23 35.6271C19.6848 35.6271 16.5054 34.2966 14.1612 31.9284C11.817 29.5602 10.5 26.3482 10.5 22.9991C10.5 19.6499 11.817 16.438 14.1612 14.0697C16.5054 11.7015 19.6848 10.3711 23 10.3711C26.3152 10.3711 29.4946 11.7015 31.8388 14.0697C34.183 16.438 35.5 19.6499 35.5 22.9991ZM26.125 18.2636C26.125 19.1009 25.7958 19.9039 25.2097 20.4959C24.6237 21.088 23.8288 21.4206 23 21.4206C22.1712 21.4206 21.3763 21.088 20.7903 20.4959C20.2042 19.9039 19.875 19.1009 19.875 18.2636C19.875 17.4263 20.2042 16.6233 20.7903 16.0313C21.3763 15.4392 22.1712 15.1066 23 15.1066C23.8288 15.1066 24.6237 15.4392 25.2097 16.0313C25.7958 16.6233 26.125 17.4263 26.125 18.2636ZM23 24.5776C21.5041 24.5773 20.0395 25.0109 18.7803 25.8268C17.5212 26.6428 16.5204 27.8068 15.8969 29.1805C16.7761 30.2138 17.866 31.0428 19.0921 31.6106C20.3182 32.1784 21.6513 32.4716 23 32.4701C24.3487 32.4716 25.6818 32.1784 26.9079 31.6106C28.134 31.0428 29.2239 30.2138 30.1031 29.1805C29.4796 27.8068 28.4788 26.6428 27.2197 25.8268C25.9605 25.0109 24.4959 24.5773 23 24.5776Z'
      fill='#0EF0EC'
    />
    <rect x='1' y='0.5' width='44' height='45' rx='7.5' stroke='#15CDCA' />
  </svg>
);

export default AddAvatarIconMobile;
