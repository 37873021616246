import * as React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

interface tooltipProps {
  targetName: string;
  targetValue: number;
  targetUnit: string;
}

const TargetsTooltip: React.FC<tooltipProps> = (props) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();
  const { targetName, targetValue, targetUnit } = props;
  return (
    <>
      <div className='w-full'>
        <button
          type='button'
          className='overflow-hidden text-ellipsis w-full text-center text-base'
          ref={setTriggerRef}
        >
          <div className='2xl:w-full xl:w-11/12 flex flex-row items-center justify-evenly px-2 whitespace-nowrap text-center 2xl:text-base text-xs'>
            <span className='w-full text-ellipsis overflow-hidden'>{targetName}</span>
            <span className='w-full text-ellipsis overflow-hidden'>{targetValue}</span>
            <span className='w-full'>{targetUnit}</span>
          </div>
        </button>
        {visible && (
          <div>
            <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container  w-max' })}>
              <div className='w-full flex flex-rol items-center justify-between gap-2 text-center 2xl:text-base text-xs'>
                <span className='w-full'>{targetName}</span>
                <span className='w-full'>{targetValue}</span>
                <span className='w-full'>{targetUnit}</span>
              </div>
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TargetsTooltip;
