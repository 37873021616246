import * as React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

interface tooltipProps {
  tooltipContent: string;
  tooltip: string;
}

const Tooltip: React.FC<tooltipProps> = (props) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();
  const { tooltipContent, tooltip } = props;
  return (
    <>
      <div className='w-full flex items-center justify-center'>
        <button
          type='button'
          className='overflow-hidden text-ellipsis xl:w-[205px] lg:w-[190px] 2xl:w-[250px] tablet:w-[140px] md:w-[140px] sm:w-[120px] phone:w-[140px] w-[110px] text-center text-base'
          ref={setTriggerRef}
        >
          {tooltipContent}
        </button>
        {visible && (
          <div>
            <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container w-max' })}>
              {tooltip}
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Tooltip;
