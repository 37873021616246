import React, { ReactNode } from 'react';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';

type ChangePasswordFormCardProps = {
  children?: ReactNode;
};

const ChangePasswordFormCard: React.FC<ChangePasswordFormCardProps> = ({ children }) => (
  <div className={`h-full flex flex-col ${roundingForm} bg-white`}>{children}</div>
);

export default ChangePasswordFormCard;
