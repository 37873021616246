import React from 'react';

const LoginRegisterFooter: React.FC = () => (
  <div className='h-[10%] w-full'>
    <footer className='w-full h-full flex justify-center items-center flex-col'>
      <p className='text-white text-center lg:text-base tablet:text-sm md:text-sm sm:text-sm text-xs'>
        <u>
          <a href='https://www.evalue.cz/'>eValue.cz</a>
        </u>
        , Jičínská 1616/29, <br className='lg:hidden tablet:hidden flex' /> Praha 3, IČ: 03841812, e-mail:{' '}
        <a className='underline' href='mailto:mail@evalue.cz'>
          mail@evalue.cz
        </a>
      </p>
    </footer>
  </div>
);

export default LoginRegisterFooter;
