import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useApiClient } from 'src/api/client';
import CancelIcon from 'src/components/Icons/CancelIcon';
import CheckCircleIcon from 'src/components/Icons/CheckCircle';
import PencilIcon from 'src/components/Icons/PencilIcon';
import { useTeam } from 'src/store/teamDetailContext';
import TextField from '../../../components/Textfield';
import { useToast } from 'src/store/toastContext';
import { useTranslation } from 'react-i18next';

interface TeamNameEditorProps {
  teamId: number;
}

interface TeamNameForm {
  teamName: string;
}

const TeamNameEditor: React.FC<TeamNameEditorProps> = ({ teamId }) => {
  const { t } = useTranslation(['home', 'main']);
  const { handleSubmit, control, setValue } = useForm<TeamNameForm>();
  const { showToastHandler } = useToast();
  const [isError, setIsError] = useState<boolean>(false);

  const Client = useApiClient();

  const team = useTeam();

  const onSubmit: SubmitHandler<TeamNameForm> = async (data) => {
    if (team.teamName === data.teamName) {
      setIsEditing(false);
      setIsError(false);
      return;
    }
    try {
      await Client.post(
        '/team/rename',
        { team_id: teamId, team_name: data.teamName },
        { headers: { team_id: teamId } },
      );
      setIsEditing(false);
      setIsError(false);
      team.setTeamName(data.teamName);
      showToastHandler(`${t('Team name successfully changed')}`, 'success');
    } catch (exc) {
      setIsError(true);
    }
  };

  const [isEditing, setIsEditing] = useState<boolean>(false);
  if (isEditing) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex lg:w-fit tablet:w-full'>
          <Controller
            control={control}
            name='teamName'
            defaultValue={team.teamName}
            rules={{ required: { value: true, message: 'Zadejte jméno týmu' } }}
            render={({ field }) => (
              <>
                <TextField type={'text'} {...field} />
              </>
            )}
          />
          <div className='w-fit flex flex-row gap-3'>
            <button
              onClick={() => {
                setValue('teamName', team.teamName);
                setIsEditing(false);
                setIsError(false);
              }}
            >
              <CancelIcon />
            </button>
            <button type='submit'>
              <CheckCircleIcon />
            </button>
          </div>
        </div>
        {isError && <div className='text-primary text-sm'>Tým se stejným jménem již existuje</div>}
      </form>
    );
  }

  return (
    <div className='w-full text-ellipsis flex'>
      <div className='phone:w-9/12 w-9/12 sm:w-fit md:w-fit lg:w-fit text-ellipsis  overflow-hidden'>
        {team.teamName}
      </div>
      <button className='pl-2' onClick={() => setIsEditing(true)}>
        <PencilIcon />
      </button>
    </div>
  );
};

export default TeamNameEditor;
