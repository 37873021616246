import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiClient } from 'src/api/client';
import { IDeletedLogs } from 'src/api/types/deletedLogs/DeletedLogsResponse';
import BlueWavesBG from 'src/components/BlueWavesBGimg/BlueWavesBG';
import componentSpacing from 'src/components/css/ComponentSpacing/ComponentSpacing';
import glowField from 'src/components/css/GlowField/GlowField';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import DeletedLogs from 'src/components/DeletedLogs/DeletedLogs';
import DeletedLogsHeader from 'src/components/DeletedLogs/DeletedLogsHeader';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import HeaderReportedActivity from 'src/components/ReportedActivityHeader/HeaderRepActivity';
import Select from 'src/components/Select';
import SidePanel, { SidePanelRoutes } from 'src/components/SidePanel';
import { Spinner } from 'src/utils/Spinner';
import swal from 'sweetalert2';
import ReportedActivityRow from './ReportedActivityRow';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@material-tailwind/react';
import Arrow from 'src/components/Icons/Arrow';
import DeletedLogsMobileVersion from 'src/components/DeletedLogs/DeletedLogsMobileVersion';
import ReportedActivityRowMobileVersion from './ReportedActivityRow/ReportedActivityRowMobileVersion';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import { useToast } from 'src/store/toastContext';

interface NewTargetForm {
  selectReason: string;
}
export interface IReportedActivityLog {
  user_name: string;
  user_email: string;

  chl_date: Date;
  cht_name: string;
  chl_value: number;
  cht_unit: string;
  chl_link: string | null;
  chl_img: string | null;
  chl_negative_feedback: number;
  chl_id: number;
}

interface ISelect {
  target: {
    value: string;
  };
}
const Parameters = {
  heightSize: 'h-5/6',
  flexCol: 'flex-col',
  flexRow: 'flex-row',
  vhSize: '[70vh]',
  pb: 'pb-0',
  pbTitleAndButton: 'pb-2',
  hidden: 'hidden',
  flex: 'flex',
  flexTitle: 'flex',
  ptZero: 'pt-0',
  pbZero: 'pb-0',
};
interface IChallenge {
  role: 'MEMBER' | 'ADMIN';
  id: number;
  name: string;
}
function ReportedActivityPage(): React.ReactElement {
  const { control } = useForm<NewTargetForm>();
  const [logs, setLogs] = useState<IReportedActivityLog[]>([]);
  const [deletedLogs, setDeletedLogs] = useState<IDeletedLogs[]>([]);
  const [loadingReportedLogs, setLoadingReportedLogs] = useState(true);
  const [loadingDeletedLogs, setLoadingDeletedLogs] = useState(true);
  const Client = useApiClient();
  const navigate = useNavigate();
  const [isReportedLogs, setIsReportedLogs] = useState<boolean>(true);
  const [userChallenges, setUserChallenges] = useState<IChallenge[]>([]);
  const { pathname } = useLocation();
  const { t } = useTranslation(['home', 'main']);
  const [reload, setReload] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [isAdminLoading, setIsAdminLoading] = useState<boolean>(false);
  const [selectedChallengeId, setSelectedChallengeId] = useState<number>(0);
  const [open, setOpen] = useState(0);
  const { showToastHandler } = useToast();

  const handleOpen = (value: number): void => {
    setOpen(open !== value ? value : 0);
  };
  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };
  const loadChallenges = async (): Promise<void> => {
    try {
      const resp = await Client.get('/evaluation/userChallenges');
      const uch = resp.data.challenges as IChallenge[];
      setSelectedChallengeId(uch.length > 0 ? uch[0].id : 0);
      setUserChallenges(uch);
      if (uch.length === 0) {
        setIsAdminLoading(true);
      }
    } catch (exc) {
      console.error('Failed fetching challenges');
    }
  };
  useEffect(() => {
    const c = userChallenges.find((uc) => uc.id === selectedChallengeId);
    if (c) {
      setIsAdmin(c.role === 'ADMIN');
      setIsAdminLoading(true);
    }
  }, [selectedChallengeId]);

  const options = [
    { value: 'platné aktivity', text: `${t('valid activities')}` },
    { value: 'smazané aktivity', text: `${t('deleted activities')}` },
  ];
  const [select, setSelected] = useState(options[0].value);

  const loadData = async (): Promise<void> => {
    logs;
  };

  const loadDeletedLogs = async (): Promise<void> => {
    deletedLogs;
    setReload(false);
  };
  useEffect(() => {
    if (pathname == '/reportedActivity/deletedLogs' || pathname == '/reportedActivity/deletedLogs/recovery') {
      setIsReportedLogs(false);
      setSelected(options[1].value);
    } else {
      setIsReportedLogs(true);
      setSelected(options[0].value);
    }
    loadChallenges();
    Client.get('/reportedActivity/logs')
      .then((response) => {
        setLogs(response.data.logs);
        setLoadingReportedLogs(false);
      })
      .catch(() => {
        console.error('Failed fetching logs');
      });
    Client.get('/reportedActivity/deletedLogs')
      .then((response) => {
        setDeletedLogs(response.data.logs);
        setLoadingDeletedLogs(false);
      })
      .catch(() => {
        console.error('Failed fetching logs');
      });
    setReload(true);
  }, [reload, pathname]);

  const handleChange = async (event: ISelect): Promise<void> => {
    if (event.target.value == options[0].value) {
      navigate(`/reportedActivity/logs/`);
    } else if (event.target.value == options[1].value) {
      navigate(`/reportedActivity/deletedLogs`);
    }
    setSelected(event.target.value);
  };
  const deleteLog = (chl_id: number): void => {
    swal
      .fire({
        title: `${t('Are you sure you want to remove the entry?')}`,
        showCancelButton: true,
        cancelButtonText: `${t('CANCEL')}`,
        confirmButtonText: `${t('Yes')}`,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await Client.post('reportedActivity/deleteLog', { chl_id: chl_id });
            showToastHandler(`${t('Entry deleted!')}`, 'success');
            loadData();
            loadDeletedLogs();
            setReload(false);
          } catch (exc) {
            console.error('Erorr while deleting log');
          }
        }
      });
  };
  return (
    <div className='flex 2xl:flex-row md:flex-row sm:flex-row flex-col z-0 bg-gradient-to-r from-lightBlue h-screen to-darkBlue'>
      <BlueWavesBG />
      <div className='z-20 '>
        <SidePanel activeRoute={SidePanelRoutes.REPORTED_ACTIVITY} />
      </div>
      <div className={`flex flex-col h-full justify-start z-20 desktop:w-full tablet:w-full ${componentSpacing} `}>
        <MobileNav />
        <div className='w-full h-fit flex flex-row justify-between lg:items-end phone:items-center items-center'>
          <div className={`${titleStyle} ${mobileTitleStyle} `}> {t('Reported activities')}</div>
          <Controller
            control={control}
            name='selectReason'
            render={({ field }) => (
              <Select
                className={`${glowField} w-4/6 phone:1/2 lg:w-1/3 sm:w-1/3 md:w-1/3`}
                {...field}
                value={select}
                onChange={handleChange}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </Select>
            )}
          />
        </div>

        <div className='w-full h-full  pt-2'>
          <table
            className={`h-fit flex flex-col   desktop:h-${Parameters.vhSize} 2xl:h-${Parameters.vhSize} lg:h-${Parameters.vhSize} xl:h-${Parameters.vhSize} tablet:h-[100vh]  md:h-[73vh] sm:h-[70vh] w-full h-[77vh] phone:h-[82vh] overflow-y-auto ${roundingForm} `}
          >
            {isReportedLogs ? (
              logs.length < 1 ? (
                <div className='text-center flex h-full justify-center items-center lg:text-[14px] text-[10px]'>
                  {loadingReportedLogs === true ? <Spinner /> : `${t('No activity has been reported.')}`}
                </div>
              ) : (
                <>
                  <HeaderReportedActivity />
                  <div
                    className={`flex-col flex  mb-4 overflow-y-auto overflow-x-hidden desktop:h-50vh 2xl:h-50vh lg:h-50vh xl:h-50vh tablet:h-[50vh] overflow: auto;  md:h-[73vh] sm:h-[70vh] h-[50vh] phone:h-[82vh]`}
                  >
                    {logs.map((log, index) => {
                      return window.innerWidth <= 820 ? (
                        <>
                          <Fragment key={log.cht_name + index}>
                            <Accordion
                              open={open === log.chl_id}
                              animate={customAnimation}
                              onClick={() => handleOpen(log.chl_id)}
                              icon={<Arrow id={log.chl_id} open={open} />}
                            >
                              <ReportedActivityRowMobileVersion
                                deleteLog={deleteLog}
                                key={log.cht_name + index}
                                {...log}
                                isLastItem={logs.length - 1 === index}
                                isFirstItem={logs.length - logs.length === index}
                              />
                            </Accordion>
                          </Fragment>
                        </>
                      ) : (
                        <>
                          <ReportedActivityRow
                            deleteLog={deleteLog}
                            key={log.cht_name + index}
                            {...log}
                            isLastItem={logs.length - 1 === index}
                            isFirstItem={logs.length - logs.length === index}
                          />
                        </>
                      );
                    })}
                  </div>
                </>
              )
            ) : (
              <>
                {isAdminLoading ? (
                  isAdmin ? (
                    <>
                      {deletedLogs.length < 1 ? (
                        <div className='text-center flex h-full justify-center items-center lg:text-[14px] text-[10px]'>
                          {loadingDeletedLogs === true ? <Spinner /> : `${t('No activity has been reported.')}`}
                        </div>
                      ) : (
                        <>
                          <DeletedLogsHeader />
                          <div
                            className={`flex-col flex  mb-4 overflow-y-auto overflow-x-hidden desktop:h-50vh 2xl:h-50vh lg:h-50vh xl:h-50vh tablet:h-[50vh] overflow: auto;  md:h-[73vh] sm:h-[70vh] h-[50vh] phone:h-[82vh]`}
                          >
                            {deletedLogs.map((item, index) => {
                              return window.innerWidth <= 820 ? (
                                <>
                                  <Fragment key={item.chl_id}>
                                    <Accordion
                                      open={open === item.chl_id}
                                      animate={customAnimation}
                                      onClick={() => handleOpen(item.chl_id)}
                                      icon={<Arrow id={item.chl_id} open={open} />}
                                    >
                                      <DeletedLogsMobileVersion
                                        key={item.chl_id}
                                        item={item}
                                        loadDeletedLogs={loadDeletedLogs}
                                      />
                                    </Accordion>
                                  </Fragment>
                                </>
                              ) : (
                                <>
                                  <DeletedLogs
                                    key={item.chl_id}
                                    item={item}
                                    isLastItem={deletedLogs.length - 1 === index}
                                    loadDeletedLogs={loadDeletedLogs}
                                  />
                                </>
                              );
                            })}
                          </div>
                        </>
                      )}{' '}
                    </>
                  ) : (
                    <tbody className='text-center flex h-full justify-center items-center'>
                      {t('You do not have administrator privileges')}
                    </tbody>
                  )
                ) : (
                  <div className='text-center flex h-full justify-center items-center'>
                    <Spinner />
                  </div>
                )}
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReportedActivityPage;
