import React from 'react';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';
import { useTranslation } from 'react-i18next';

const DeletedLogsHeader: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);

  return (
    <tr className='flex flex-row w-full z-20 py-[1em] items-center sticky top-0'>
      <th className={`${headerStyle} lg:flex hidden`}>{t('CHALLENGE')}</th>
      <th className={headerStyle}>{t('NAME')}</th>
      <th className={headerStyle}>{t('TARGET')}</th>
      <th className={headerStyle}>{t('VALUE')}</th>
      <th className={headerStyle}>{t('DATE')}</th>
      <th className={`${headerStyle} lg:flex hidden`}>{t('LINK')}</th>
      <th className={`${headerStyle} lg:flex hidden`}>{t('PHOTO')}</th>
      <th className={`${headerStyle} lg:flex hidden`}>{t('DELETED')}</th>
      <th className={`${headerStyle} lg:hidden flex phone:w-[50px] w-[50px]`}></th>
    </tr>
  );
};

export default DeletedLogsHeader;
