import React from 'react';
import textQuestionStyle from '../css/LinksTextStyle/TextQuestionStyle';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from 'src/store/loginPageContext';

const ToLoginPage: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);
  const useLogin = useLoginContext();
  return (
    <div className='w-full h-fit flex justify-end items-end desktop:mt-5 tablet:mt-5'>
      <span
        onClick={() => {
          useLogin.setIsLogin(true);
          useLogin.setIsForgotPassword(false);
        }}
        className={`w-full h-fit 2xl:flex md:flex sm:flex flex-row  ${textQuestionStyle} underline cursor-pointer text-center items-center justify-center`}
      >
        {t('Back to sign in')}
      </span>
    </div>
  );
};

export default ToLoginPage;
