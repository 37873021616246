import Resizer from 'react-image-file-resizer';

export const resizeFile = async (file: File): Promise<Partial<string | File | Blob | ProgressEvent<FileReader>>> =>
  await new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      720,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });
