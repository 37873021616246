import React from 'react';
const GreenMountains: React.FC = () => (
  <div className='z-10'>
    <img
      src='/pictures/TabletGreenMountains.png'
      className='fixed bottom-0 right-0 left-0 w-full h-full object-cover'
    />
  </div>
);
export default GreenMountains;
