import React, { createContext, useContext, useState } from 'react';
interface LoginContextProps {
  isLogin: boolean;
  setIsLogin: (value: boolean) => void;
  isForgotPassword: boolean;
  setIsForgotPassword: (value: boolean) => void;
}

interface LoginProviderProps {
  children: React.ReactNode;
}

const defaultState: LoginContextProps = {
  isLogin: true,
  isForgotPassword: true,

  setIsLogin: () => {
    return;
  },
  setIsForgotPassword: () => {
    return;
  },
};

export const LoginContext = createContext<LoginContextProps>(defaultState);

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const [login, setIsLogin] = useState<boolean>(true);
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(true);

  const handleSetIsLogin = (login: boolean): void => {
    setIsLogin(login);
  };
  const handleSetIsForgotenPassword = (isForgotPassword: boolean): void => {
    setIsForgotPassword(isForgotPassword);
  };

  return (
    <LoginContext.Provider
      value={{
        isLogin: login,
        setIsLogin: handleSetIsLogin,
        isForgotPassword: isForgotPassword,
        setIsForgotPassword: handleSetIsForgotenPassword,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = (): LoginContextProps => useContext(LoginContext);
