import React, { createContext, useContext, useState } from 'react';
import { IFavoriteUser, IEvaluation, IChallenge, IRewardUser } from 'src/pages/MyChallengesPage/MyChallengesPage';
interface MyChallengeContextProps {
  select: number;

  sort: boolean;

  isAdmin: boolean;

  selectedChallengeId: number;

  favoriteUsers: IFavoriteUser[];

  rewardUsers: IRewardUser[];

  evaluationRows: IEvaluation[];

  userChallenges: IChallenge[];

  setSelect: (value: number) => void;

  setSort: (value: boolean) => void;

  setIsAdmin: (value: boolean) => void;

  setSelectedChallengeId: (value: number) => void;

  setFavoriteUsers: (value: IFavoriteUser[]) => void;

  setRewardUsers: (value: IRewardUser[]) => void;

  setEvaluationRows: (value: IEvaluation[]) => void;

  setUserChallenges: (value: IChallenge[]) => void;
}

interface MyChallengeDetailProviderProps {
  children: React.ReactNode;
}

const defaultState: MyChallengeContextProps = {
  select: 1,
  sort: false,
  isAdmin: false,
  selectedChallengeId: 0,
  favoriteUsers: [],
  rewardUsers: [],
  evaluationRows: [],
  userChallenges: [],

  setFavoriteUsers: () => {
    return;
  },
  setRewardUsers: () => {
    return;
  },
  setEvaluationRows: () => {
    return;
  },
  setSelect: () => {
    return;
  },

  setSort: () => {
    return;
  },

  setIsAdmin: () => {
    return;
  },

  setSelectedChallengeId: () => {
    return;
  },
  setUserChallenges: () => {
    return;
  },
};

export const MyChallengeDetailContext = createContext<MyChallengeContextProps>(defaultState);

export const MyChallengeDetailProvider: React.FC<MyChallengeDetailProviderProps> = ({ children }) => {
  const [select, setSelect] = useState<number>(1);
  const [sort, setSort] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [selectedChallengeId, setSelectedChallengeId] = useState<number>(0);
  const [favoriteUsers, setFavoriteUsers] = useState<IFavoriteUser[]>([]);
  const [rewardUsers, setRewardUsers] = useState<IRewardUser[]>([]);
  const [evaluationRows, setEvaluationRows] = useState<IEvaluation[]>([]);
  const [userChallenges, setUserChallenges] = useState<IChallenge[]>([]);

  const handleSetSelect = (select: number): void => {
    setSelect(select);
  };

  const handleSetSort = (sort: boolean): void => {
    setSort(sort);
  };
  const handleSetIsAdmin = (isAdmin: boolean): void => {
    setIsAdmin(isAdmin);
  };
  const handleSetSelectedChallengeId = (selectedChallengeId: number): void => {
    setSelectedChallengeId(selectedChallengeId);
  };

  const handleSetFavoritUsers = (value: IFavoriteUser[]): void => {
    setFavoriteUsers(value);
  };

  const handleSetRewardUsers = (value: IRewardUser[]): void => {
    setRewardUsers(value);
  };

  const handleSetEvaluationRows = (value: IEvaluation[]): void => {
    setEvaluationRows(value);
  };

  const handleSetUserChallenges = (value: IChallenge[]): void => {
    setUserChallenges(value);
  };

  return (
    <MyChallengeDetailContext.Provider
      value={{
        select: select,
        setSelect: handleSetSelect,
        selectedChallengeId: selectedChallengeId,
        setSelectedChallengeId: handleSetSelectedChallengeId,
        favoriteUsers: favoriteUsers,
        setFavoriteUsers: handleSetFavoritUsers,
        rewardUsers: rewardUsers,
        setRewardUsers: handleSetRewardUsers,
        evaluationRows: evaluationRows,
        setEvaluationRows: handleSetEvaluationRows,
        userChallenges: userChallenges,
        setUserChallenges: handleSetUserChallenges,
        sort: sort,
        setSort: handleSetSort,
        isAdmin: isAdmin,
        setIsAdmin: handleSetIsAdmin,
      }}
    >
      {children}
    </MyChallengeDetailContext.Provider>
  );
};

export const myChallengeData = (): MyChallengeContextProps => useContext(MyChallengeDetailContext);
