import Button from 'src/components/Button';
import React, { useEffect, useState } from 'react';
import glowField from 'src/components/css/GlowField/GlowField';
import TrashIcon from 'src/components/Icons/TrashIcon';
import rounding from 'src/components/RoundingOfAllForms/Rounding';
import roundingFields from 'src/components/RoundingOfAllForms/RoundingFields';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import Textfield from 'src/components/Textfield';
import buttonSwapColor from 'src/pages/HoverButtonsstyle/HooverButtonSwapColor';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import Tooltip from 'src/utils/Tooltip';
import swal from 'sweetalert2';
import buttonStyleNewChallenge from '../NewChallengeButtonStyle/ButtonStyle';
import GetValidEmails from './SeparateMails';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RemoveIcon } from '../../../images/icons/RemoveIcon.svg';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import { IButton } from '../ButtonInterface/IButton';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import fontColor from 'src/components/css/TitleColor/TitleColor';
import { motion } from 'framer-motion';
import { checkCountOfMembers, checkLicenceIsInvalid, checkMembersAndLicence } from 'src/utils/LicenceValidations';
import { useApiClient } from 'src/api/client';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import Payment from 'src/components/Payment/Payment';
import { PaymentDetailProvider } from 'src/store/paymentContext';

const AddUsers = ({ handleBack, handleNext }: IButton): JSX.Element => {
  const [inputEmail, setInputEmail] = useState<string>('');
  const { t } = useTranslation(['home', 'main']);
  const challengeData = useChallengeData();

  const [emails, setEmails] = useState<string[]>(challengeData.emails || []);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isNotEmail, setIsNotEmail] = useState<boolean>(false);

  useEffect(() => {
    const isLicenceInvalid = checkLicenceIsInvalid(challengeData.licence);
    const isValidLength = checkCountOfMembers(emails.length);
    if (isLicenceInvalid && isValidLength && !challengeData.confirmUserLicence) {
      openModal();
    }
    challengeData.setEmails(emails);
  }, [emails]);
  const pasteHandler = (event: React.ClipboardEvent): void => {
    event.preventDefault();
    const pastedEmails = event.clipboardData.getData('text');
    EmailValidator(pastedEmails);
  };
  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLElement>): void => {
    const key = event.key;
    if (['Enter', 'Tab'].includes(key)) {
      onClick();
    }
  };
  const onClick = (): void => {
    EmailValidator(inputEmail);
  };
  function EmailValidator(email: string): void {
    if (!email.length) {
      setIsEmpty(true);
      return;
    }
    const validEmails = GetValidEmails(email.trim());
    if (!validEmails.length) {
      setIsNotEmail(true);
      return;
    }

    const containsEmails: string[] = [];
    validEmails.forEach((email) => {
      const isContains = emails.find((x) => x == email);
      if (!isContains) {
        localStorage.removeItem('emails');
        setIsEmpty(false);
        setIsNotEmail(false);
        setEmails((oldArray) => [...oldArray, email]);
      } else {
        containsEmails.push(email);
      }
    });

    if (containsEmails.length) {
      swal.fire({
        title: `${containsEmails.length} email/s is exist!`,
        text: `${containsEmails.map((currentMail) => {
          return ' ' + currentMail;
        })}`,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    setInputEmail('');
  }
  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const enteredName = event.target.value;
    setIsEmpty(false);
    setIsNotEmail(false);
    setInputEmail(enteredName);
  };
  const Client = useApiClient();

  useEffect(() => {
    Client.get('/user/email')
      .then((response) => {
        const email = response.data.logedUser;
        if (!emails.includes(email)) {
          emails.push(email);
          challengeData.setAdminTeamEmail(email);
        }
      })
      .catch(() => {
        console.error('Error');
      });
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };

  return (
    <>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
        className={`w-full h-full flex flex-col justify-center items-center lg:px-10 lg:py-5 relative`}
      >
        <div className={`${titleStyle} lg:flex hidden uppercase`}>{t('Add users')}</div>
        <div
          className={`z-10 w-full h-full flex flex-col justify-between ${padding} ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
        >
          <div className={`w-full h-fit flex flex-col ${rounding} justify-between gap-2`}>
            <div className='w-full h-fit'>
              <div className='w-5/12 flex flex-row'>
                <p className={`${newChallengeTitleStyle} ${fontColor} w-full item-center`}>{t('ADD USERS:')}</p>
              </div>

              <div className='lg:w-full w-full 2xl:h-14 xl:h-12 lg:h-10 flex h-fit flex-row gap-2'>
                <div className='w-full h-fit flex gap-2'>
                  <div className='w-1/3 relative h-fit'>
                    <Textfield
                      className={`${glowField} text-DarkPrimary box-border p-2 flex-row pr-8 ${
                        isEmpty || isNotEmail ? 'border-primary' : 'border-lightBlueButton'
                      }  outline-none border-2 w-full 2xl:h-14 h-12 ${roundingFields}`}
                      placeholder={`${t('Add user email')}`}
                      type='text'
                      id='email'
                      name='email'
                      value={inputEmail}
                      onPaste={pasteHandler}
                      onKeyDown={onKeyDownHandler}
                      onChange={inputHandler}
                    />
                    <div className='w-full flex justify-start h-fit'>
                      <span className='text-primary  text-sm w-full'>{isEmpty ? `${t('Field is empty!')}` : ''}</span>
                      <span className='text-primary  text-sm w-full justify-end flex'>
                        {isNotEmail ? `${t('Invalid email!')}` : ''}
                      </span>
                    </div>
                    <div className={`absolute right-2 top-0 flex justify-center items-center  2xl:h-14 h-10  `}>
                      <span
                        className='flex justify-center bg-lightBlueButton rounded-full my-auto w-6 text-white cursor-pointer'
                        onClick={() => {
                          setInputEmail('');
                          setIsEmpty(false);
                          setIsNotEmail(false);
                        }}
                      >
                        x
                      </span>
                    </div>
                  </div>

                  <div className='w-1/3 2xl:h-14 h-10 flex lg:justify-start justify-end items-center'>
                    <Button
                      className={`${buttonSwapColor} lg:w-1/2 w-full h-full  text-center flex justify-center items-center 2xl:text-xl xl:text-md phone:text text-[14px]`}
                      onClick={onClick}
                    >
                      {t('ADD')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='h-full flex flex-col items-left lg:pt-5 space-y-1'>
              <p className={`${newChallengeTitleStyle} ${fontColor}`}>
                {t('EMAILS')}: {emails.length}
              </p>
              <div
                className={`border-lightBlueButton border-2 flex flex-row 2xl:h-[44vh] xl:h-[40vh] phone:h-[40vh] lg:h-[32vh] h-[30vh] pr-3 items-start w-full ${roundingForm} 2xl:pr-4 xl:pr-2 py-2 overflow-hidden`}
              >
                <div className='flex lg:flex-row tablet:flex-row md:flex-row flex-col 2xl:h-[42vh] xl:h-[38vh] lg:h-[32vh] tablet:h-[43vh] phone:h-[39vh] h-[38vh] overflow-y-auto overflow-hidden w-full items-start'>
                  <div className='w-full flex justify-center lg:py-1 phone:py-0 py-0 items-center'>
                    <p className='w-8/12 flex items-center flex-col' placeholder={`${t('All added users....')}`}>
                      {emails.map((email, index) => {
                        if (index % 2 == 0) {
                          return (
                            <>
                              <div className='flex w-full flex-wrap relative justify-center space-x-2 pb-2' key={index}>
                                <span
                                  className={`w-full px-4 py-2 justify-between rounded-full text-DarkPrimary font-semibold border-lightGreen border-[2px] text-sm flex align-center ${glowField} ${
                                    email == challengeData.adminTeamEmail
                                      ? 'bg-grayColor bg-opacity-50'
                                      : 'cursor-pointer bg-white'
                                  } active:bg-gray transition duration-300 ease `}
                                >
                                  <Tooltip tooltip={email} tooltipContent={email} />
                                  <button
                                    className='bg-transparent hover focus:outline-none'
                                    onClick={() => {
                                      const result = challengeData.emails.filter((x) => x !== email);
                                      setEmails(result);
                                    }}
                                  >
                                    <div>{email == challengeData.adminTeamEmail ? '' : <RemoveIcon />}</div>
                                  </button>
                                </span>
                              </div>
                            </>
                          );
                        }
                      })}
                    </p>
                  </div>
                  <div className='w-full flex justify-center lg:pt-1 phone:pt-0 pt-0 items-center'>
                    <p className='w-8/12 flex items-center flex-col' placeholder='All added users....'>
                      {emails.map((email, index) => {
                        if (index % 2 != 0) {
                          return (
                            <>
                              <div className='flex w-full flex-wrap relative justify-center space-x-2 pb-2' key={index}>
                                <span
                                  className={`px-4 py-2 w-full justify-between rounded-full text-DarkPrimary bg-white font-semibold border-lightGreen border-[2px] text-sm flex align-center cursor-pointer active:bg-gray transition duration-300 ease ${glowField}`}
                                >
                                  <Tooltip tooltip={email} tooltipContent={email} />
                                  <button
                                    className='bg-transparent hover focus:outline-none'
                                    onClick={() => {
                                      const result = challengeData.emails.filter((x) => x !== email);
                                      setEmails(result);
                                    }}
                                  >
                                    <div className='pr-1'>
                                      <RemoveIcon />
                                    </div>
                                  </button>
                                </span>
                              </div>
                            </>
                          );
                        }
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full h-fit justify-center flex lg:items-end items-center lg:justify-end'>
                <span className={`${newChallengeTitleStyle} underline justify-end lg:flex hidden text-DarkPrimary`}>
                  {t('CLEAR ALL EMAILS:')}
                </span>
                <span
                  className='cursor-pointer'
                  onClick={() => {
                    swal
                      .fire({
                        title: `${t('Are you sure you want to delete all emails?')}`,
                        text: `${t("You won't be able to revert this!")}`,
                        showCancelButton: true,
                        cancelButtonText: `${t('CANCEL')}`,
                        confirmButtonText: `${t('YES')}`,
                      })
                      .then((result) => {
                        if (result.isConfirmed) {
                          localStorage.removeItem('emails');
                          if (challengeData.adminTeamEmail) {
                            setEmails([`${challengeData.adminTeamEmail}`]);
                          } else {
                            setEmails([]);
                          }
                        }
                      });
                  }}
                >
                  <div className='pl-1 2xl:scale-110 lg:scale-75 lg:pt-0 pt-3'>
                    <TrashIcon />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className='pb-3 relative h-fit flex flex-row  lg:pt-0 pt-4 w-full justify-evenly'>
            <div className='text-center w-full  flex-col justify-start items-start m-auto h-full lg:flex hidden '>
              <Button
                className={`${buttonStyleNewChallenge}`}
                type={'button'}
                onClick={() => {
                  const emailsData = {
                    emails: emails,
                  };
                  localStorage.setItem('emails', JSON.stringify(emailsData));
                  challengeData.setEmails(emails);
                  handleBack();
                }}
              >
                {t('PREVIOUS')}
              </Button>
            </div>

            <div className='text-center w-full flex flex-col justify-end lg:items-end items-center  m-auto h-full '>
              <Button
                className={`${buttonStyleNewChallenge}`}
                type={'button'}
                onClick={() => {
                  challengeData.setEmails(emails);
                  const emailsData = {
                    emails: emails,
                  };
                  localStorage.setItem('emails', JSON.stringify(emailsData));
                  const isLicenceValid = checkMembersAndLicence(emails.length, challengeData.licence);
                  if (isLicenceValid) {
                    handleNext();
                  } else if (!isLicenceValid && challengeData.confirmUserLicence) {
                    handleNext();
                  } else {
                    openModal();
                  }
                }}
              >
                {t('NEXT')}
              </Button>
            </div>
          </div>
        </div>
      </motion.div>
      <PaymentDetailProvider>
        <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
          <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
            <button
              type='button'
              className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
              onClick={closeModal}
            >
              x
            </button>
          </Dialog.Title>
          <Payment
            closeModal={closeModal}
            teamId={challengeData.teamId}
            teamName={challengeData.selectedTeamName ?? challengeData.teamName}
            paymentInstructions={false}
          />
        </ModalFromLibrary>
      </PaymentDetailProvider>
    </>
  );
};
export default AddUsers;
