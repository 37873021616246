import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useApiClient } from 'src/api/client';
import { ChallengeLogsListItem } from 'src/api/types/challenges/ChallengeLogsResponse';
import PreviewServiceForm from '../../pages/HomePage/EditForm';
import DeleteIcon from '../Icons/deleteIcon';
import '../css/Popup.css';
import swal from 'sweetalert2';
import PictureIcon from '../Icons/pictureIcon';
import ExternalLink from '../Icons/ExternalLinkIcon';
import { useActivity } from 'src/store/activityCardContext';
import PictureSVGIcon from '../Icons/PictureSVGIcon';
import PencilIcon from '../Icons/PencilIcon';
import { Dialog } from '@headlessui/react';
import ModalFromLibrary from '../ModalFromLibrary';
import moment from 'moment';
import { Spinner } from 'src/utils/Spinner';
import activityLogStyle from '../css/ActivityLogsStyle/ActivityLogStyle';
import scaleTextElements from '../css/ScaleTextAndElements/ScaleTextElements';
import { useTranslation } from 'react-i18next';
import InformationIcon from '../Icons/InformationIcon';
import { rowTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/RowsTransition';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import RightSideBottomIconTooltip from 'src/utils/RightSideBottomIconTooltipUtils';
import RightSideTopIconTooltip from 'src/utils/RightSideTopIconTooltipUtils';
import LeftSideTopIconTooltip from 'src/utils/LeftSideTopIconTooltipUtils';
import { useToast } from 'src/store/toastContext';
import GrayPencilIcon from '../Icons/GrayPencilIcon';
import LeftSideToolTipGrayBottom from 'src/utils/LeftSideToolTipGrayBottom';
import LeftSideToolTipGrayTop from 'src/utils/LeftSideToolTipGrayTop';
import overflowText from '../css/MyChallengeLogsStyle/OverflowText';

interface ChallengeLogsItemsProps {
  item: ChallengeLogsListItem;
  getItemList: () => void;
  isLastItem?: boolean;
  index: number;
}
const Parameters = {
  borderNone: 'border-none',
  py: 'py-2',
  pySmallScreens: 'py-[0.4em]',
};

const ChallengeLogService: React.FC<ChallengeLogsItemsProps> = ({ item, getItemList, isLastItem }) => {
  const { showToastHandler } = useToast();
  const activity = useActivity();
  const Client = useApiClient();
  const { t } = useTranslation(['home', 'main']);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = (): void => {
    setIsOpen(false);
  };
  const openModal = (): void => {
    setIsOpen(true);
  };

  const getImg = async (): Promise<void> => {
    showModal();
  };

  useEffect(() => {
    getItemList();
  }, [pathname]);

  const deleteLog = async (logId: number): Promise<void> => {
    try {
      swal
        .fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Are you sure you want to delete this activity?')}` + '</h5>',
          showCancelButton: true,
          confirmButtonText: `${t('YES')}`,
          cancelButtonText: `${t('CANCEL')}`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            showToastHandler(`${t('Your activity has been deleted.')}`, 'success');
            await Client.delete(`/challenge/deleteLog/${logId}`);
            activity.setReload(true);
          }
        });
    } catch (error) {
      console.error(
        swal.fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
          html: "<div style='color: #F16B6B;'>" + `${t('Enter the correct information!')}` + '</div> ',
        }),
        error,
      );
    }
  };
  const addLinkHttps = useMemo(
    () => (item.link?.match(/^https?:\/\//i) ? item.link : `http://${item.link}`),
    [item.link],
  );
  const showModal = (): void => {
    swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: PictureSVGIcon,
      html: `<img src="${item.imgUrl}" />`,
    });
  };
  return (
    <>
      {!item ? (
        <Spinner />
      ) : (
        <td
          key={item.chlngName}
          className={`w-full ${rowTransition} justify-center items-center flex-row 2xl:${Parameters.py} desktop:${
            Parameters.py
          } xl:${Parameters.py} lg:${Parameters.py} sm:${Parameters.pySmallScreens} md:${
            Parameters.pySmallScreens
          } tablet:${Parameters.pySmallScreens} py-${
            Parameters.pySmallScreens
          } flex 2xl:border-none desktop:border-none md:border-none lg:border-none xl:border-none border-b-[1px] ${
            typeof item.id === 'number' && pathname.includes(item.id.toString()) ? 'bg-white' : ''
          }`}
        >
          <td className={`${activityLogStyle} md:flex sm:flex  justify-center ${isLastItem ? 'rounded-tr-2xl' : ''}`}>
            <div className={overflowText}>{item.chlngName}</div>
          </td>
          <td className={`${activityLogStyle} md:flex sm:flex justify-center`}>
            <div className={overflowText}>{item.targetName}</div>
          </td>
          <td className={`${activityLogStyle} text-center`}>
            {item.logedValue.slice(0, 2) + t(`${item.logedValue.slice(2)}`)}
          </td>
          <td className={`${activityLogStyle} 2xl:flex desktop:flex lg:flex xl:flex tablet:flex hidden justify-center`}>
            {moment(item.date, 'DD.MM.yyyy').format('DD.MM.yyyy')}
          </td>
          <td
            className={`${activityLogStyle} 2xl:hidden desktop:hidden lg:hidden xl:hidden tablet:hidden flex justify-center`}
          >
            {moment(item.date, 'DD.MM').format('DD.MM')}
          </td>
          <td className={`${activityLogStyle} overflow-visible lg:flex hidden phone:hidden justify-center`}>
            <div className='cursor-pointer group relative'>
              <InformationIcon />
              {!isLastItem ? (
                <RightSideBottomIconTooltip
                  tooltipContent={item.description ? item.description : `${t('MISSING DESCRIPTION')}`}
                />
              ) : (
                <RightSideTopIconTooltip
                  tooltipContent={item.description ? item.description : `${t('MISSING DESCRIPTION')}`}
                />
              )}
            </div>
          </td>
          <td
            className={`${activityLogStyle}  lg:flex hidden photo:hidden justify-center items-center overflow-visible`}
          >
            {(item.link && (
              <a
                target={'_blank'}
                rel='noreferrer'
                className={`cursor-pointer group ${scaleTextElements}`}
                href={addLinkHttps}
              >
                <ExternalLink />
                {!isLastItem ? (
                  <LeftSideBottomIconTooltip tooltipContent={item.link} />
                ) : (
                  <LeftSideTopIconTooltip tooltipContent={item.link} />
                )}
              </a>
            )) || <span className='font-bold'>-</span>}
          </td>
          <td className={`${activityLogStyle} justify-center items-center flex overflow-visible`}>
            {(
              <span className={`font-bold cursor-pointer group ${scaleTextElements}`} onClick={getImg}>
                <PictureIcon />
                {!isLastItem ? (
                  <LeftSideBottomIconTooltip tooltipContent={t('PHOTO')} />
                ) : (
                  <LeftSideTopIconTooltip tooltipContent={t('PHOTO')} />
                )}
              </span>
            ) || <span className='font-bold'>-</span>}
          </td>
          <td className={`${activityLogStyle} flex  overflow-visible `}>
            <span className={`group ${scaleTextElements}`}>
              <button
                type='button'
                className={`${item.isDeadlineExpired ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => {
                  if (!item.isDeadlineExpired) {
                    const formattedDate = new Date(moment(item.date, 'DD.MM.yyyy').format('YYYY-MM-DD'));
                    activity.setWorkout(item.chlngTargetId);
                    activity.setDate(formattedDate);
                    activity.setValue(item.logedValue);
                    activity.setLink(item.link);
                    activity.setDescription(item.description);
                    activity.setImg(item.photo);
                    activity.setlogId(item.id);
                    activity.setUnit(item.targetUnit);
                    openModal();
                  }
                }}
              >
                {item.isDeadlineExpired ? <GrayPencilIcon /> : <PencilIcon />}

                {!item.isDeadlineExpired ? (
                  !isLastItem ? (
                    <LeftSideBottomIconTooltip tooltipContent={t('EDIT')} />
                  ) : (
                    <LeftSideTopIconTooltip tooltipContent={t('EDIT')} />
                  )
                ) : !isLastItem ? (
                  <LeftSideToolTipGrayBottom tooltipContent={t('CHALLEXPIRED')} />
                ) : (
                  <LeftSideToolTipGrayTop tooltipContent={t('CHALLEXPIRED')} />
                )}
              </button>
            </span>
            <span className={`desktop:ml-3 md:ml-3 sm:ml-2 ml-[2px] overflow-visible group ${scaleTextElements}`}>
              <button onClick={() => deleteLog(item.id)}>
                <DeleteIcon />
                {!isLastItem ? (
                  <LeftSideBottomIconTooltip tooltipContent={t('Delete')} />
                ) : (
                  <LeftSideTopIconTooltip tooltipContent={t('Delete')} />
                )}
              </button>
            </span>
          </td>
          <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
              <button
                type='button'
                className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
                onClick={closeModal}
              >
                x
              </button>
            </Dialog.Title>
            <PreviewServiceForm closeModal={closeModal} />
          </ModalFromLibrary>
        </td>
      )}
    </>
  );
};

export default ChallengeLogService;
