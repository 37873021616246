import React from 'react';
import PencilIcon from 'src/components/Icons/PencilIcon';
import { useAuth } from 'src/store/authContext';
import { useTranslation } from 'react-i18next';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import RightSideBottomIconTooltip from 'src/utils/RightSideBottomIconTooltipUtils';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import TrashIcon from 'src/components/Icons/TrashIcon';
import { rowTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/RowsTransition';
import LeftSideTopIconTooltip from 'src/utils/LeftSideTopIconTooltipUtils';
import RightSideTopIconTooltip from 'src/utils/RightSideTopIconTooltipUtils';
import ApproveGreenIcon from 'src/components/Icons/ApproveGreenIcon';
import ApproveGreyIcon from 'src/components/Icons/ApproveGreyIcon';

interface TeamMemberRowProps {
  userId: number;
  isApprovedToJoinTeam: boolean;
  email: string;
  role: 'ADMIN' | 'MEMBER';
  memberId: number;
  isLastItem: boolean;
  user_name: string;
  kickMember: (memberId: number) => void;
  changeRole: (memberId: number, title: string, currentRole: string) => void;
  approveMember: (memberId: number) => void;
}

const TeamMemberRow: React.FC<TeamMemberRowProps> = ({
  userId,
  role,
  isApprovedToJoinTeam,
  isLastItem,
  email,
  user_name,
  memberId,
  kickMember,
  changeRole,
  approveMember,
}) => {
  const auth = useAuth();

  const { t } = useTranslation(['home', 'main']);
  const authUserId = auth.getUserId();

  const className =
    'text-text desktop:my-0 tablet:my-5 my-5 flex break-words text-ellipsis justify-center text-center desktop:w-full tablet:w-full w-full desktop:h-20 tablet:h-20 h-14 desktop:scale-100 tablet:scale-100 scale-[0.8] desktop:text-base tablet:text-base text-xs items-center desktop:font-normal tablet:font-normal font-normal';

  return (
    <div className={`${rowTransition} h-20 flex flex-row justify-center items-center w-full lg:pr-0 phone:pr-2 pr-2`}>
      <div className={`text-text justify-center w-full h-full items-center flex`}>
        <span className='h-full w-full flex flex-row items-center justify-center '>
          {userId !== authUserId && (
            <span
              className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit`}
              onClick={() => !isApprovedToJoinTeam && approveMember(memberId)}
            >
              {isApprovedToJoinTeam ? <ApproveGreenIcon /> : <ApproveGreyIcon />}
              {!isLastItem ? (
                <RightSideBottomIconTooltip
                  tooltipContent={isApprovedToJoinTeam ? `${t('APPROVED')}` : `${t('APPROVE MEMBER')}`}
                />
              ) : (
                <RightSideTopIconTooltip
                  tooltipContent={isApprovedToJoinTeam ? `${t('APPROVED')}` : `${t('APPROVE MEMBER')}`}
                />
              )}
            </span>
          )}
        </span>
      </div>
      <div className={`${className} w-full break-all relative text-center  ${isLastItem ? 'rounded-bl-2xl' : ''}`}>
        <div className='group'>
          {user_name ? user_name : '-'}{' '}
          {!isLastItem ? (
            <RightSideBottomIconTooltip tooltipContent={user_name ? user_name : `${t('MISSING NAME')}`} />
          ) : (
            <RightSideTopIconTooltip tooltipContent={user_name ? user_name : `${t('MISSING NAME')}`} />
          )}
        </div>
      </div>
      <div className={`${className} w-full  break-all relative text-center   ${isLastItem ? 'rounded-bl-2xl' : ''}`}>
        <div className='group'>
          {email}
          {!isLastItem ? (
            <RightSideBottomIconTooltip tooltipContent={email} />
          ) : (
            <RightSideTopIconTooltip tooltipContent={email} />
          )}
        </div>
      </div>
      <div className={className}>{role}</div>
      <div className='text-text flex justify-center w-full h-full items-center '>
        <span className='h-full w-full flex flex-row items-center justify-center '>
          {userId !== authUserId && (
            <span
              onClick={() => changeRole(memberId, user_name ? user_name : email, role)}
              className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit`}
            >
              <PencilIcon />
              {!isLastItem ? (
                <LeftSideBottomIconTooltip tooltipContent={t('Select a role')} />
              ) : (
                <LeftSideTopIconTooltip tooltipContent={t('Select a role')} />
              )}
            </span>
          )}
          {userId !== authUserId && (
            <button
              onClick={() => kickMember(memberId)}
              className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit lg:ml-2 phone:ml-2 ml-0 `}
            >
              <TrashIcon />
              {!isLastItem ? (
                <LeftSideBottomIconTooltip tooltipContent={t('REMOVE MEMBER')} />
              ) : (
                <LeftSideTopIconTooltip tooltipContent={t('REMOVE MEMBER')} />
              )}
            </button>
          )}
        </span>
      </div>
    </div>
  );
};

export default TeamMemberRow;
