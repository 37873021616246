import React from 'react';
import buttonStyleNewChallenge from 'src/pages/NewChallengePage/NewChallengeButtonStyle/ButtonStyle';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
interface ServiceFormProps {
  closeModal?: () => void;
  chllngInfo: string | undefined;
  insertLogDaysAfterActivityInfo: string | undefined;
  buttonName: string;
  urlLink: string | undefined;
}
const ChallengeInfoModal: React.FC<ServiceFormProps> = ({
  closeModal,
  chllngInfo,
  insertLogDaysAfterActivityInfo,
  buttonName,
  urlLink,
}) => {
  const { t } = useTranslation(['home', 'main']);
  return (
    <div className='w-full h-full flex flex-col gap-10 p-5'>
      <div className='text-center text-primary w-full text-sm'>
        {insertLogDaysAfterActivityInfo ? insertLogDaysAfterActivityInfo : undefined}
      </div>
      <div className='justify-center flex flex-col h-full w-full items-center text-center self-center lg:text-[14px] text-[10px]'>
        {chllngInfo ? chllngInfo : `${t('MISSING CHALLENGE INFO')}`}
      </div>
      <div className='w-full h-fit flex justify-center items-center'>
        <a href={urlLink} className='w-2/4 flex justify-center items-center'>
          <Button
            className={`${buttonStyleNewChallenge}`}
            type={'button'}
            onClick={() => {
              closeModal ? closeModal() : null;
            }}
          >
            {buttonName}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default ChallengeInfoModal;
