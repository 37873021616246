import React, { useEffect, useState } from 'react';
import activityLogStyle from 'src/components/css/ActivityLogsStyle/ActivityLogStyle';
import EyeIcon from 'src/components/Icons/EyeIcon';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { IFavoriteUser } from '../MyChallengesPage';
import { ReactComponent as DefaultUserAvatar } from '../../../images/icons/DefaultUserAvatar.svg';
import ButtonStyle from './ButtonStyle';
import { useTranslation } from 'react-i18next';
import { rowTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/RowsTransition';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import { myChallengeData } from 'src/store/myChallengePageContex';

const Properties = {
  height: 'h-12',
  width: 'w-12',
  thumbIconsScale: 'desktop:scale-[1.6] md:scale-[1.4] tablet:scale-[1.4] scale-125',
  cursorPointer: 'cursor-pointer',
};

const SwalEval = withReactContent(Swal);
const FavoriteEvaluationRow: React.FC<IFavoriteUser & { isLastItem: boolean; index: number }> = ({
  user_name,
  user_email,
  isLastItem,
  avatar,
  favoriteUserId,
}) => {
  const { t } = useTranslation(['home', 'main']);
  const myChallengeContext = myChallengeData();
  const showModal = (): void => {
    SwalEval.fire({
      html: (
        <div>
          <div>
            <div className='flex-col w-full tablet:w-full'>
              <div className='desktop:ml-6 desktop:mt-4 tablet:w-full tablet:p-3'>
                <div>
                  <div className='flex'>
                    <div className='font-bold  w-full text-center h-10 rounded-tl-2xl pt-2'>{t('TARGET')}</div>
                    <div className='font-bold  w-full text-center h-10 pt-2'>{t('COMPLETION')}</div>

                    <div className=' text-center  h-10 rounded-tr-2xl pt-2 flex justify-center pr-3'></div>
                  </div>
                </div>
                <div className='h-72 overflow-y-auto rounded-b-2xl '>
                  <>
                    {myChallengeContext.evaluationRows.map((current) => (
                      <>
                        {current.targets.map((row, index) => {
                          if (current.user_id == favoriteUserId)
                            return (
                              <div key={index} className={`h-12 flex rounded-bl-2xl`}>
                                <div
                                  className={`text-text text-center w-full self-center pt-3  h-12 ${
                                    isLastItem ? 'rounded-bl-2xl' : ''
                                  }`}
                                >
                                  {row.target_name}
                                </div>
                                <div className='text-text text-center w-full  pt-3'>{row.percent}%</div>
                              </div>
                            );
                        })}
                      </>
                    ))}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      title: user_name ? user_name : user_email,
      showCancelButton: false,
      showConfirmButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: ButtonStyle,
      },
    });
  };
  const [avgValueUser, setAvgValueUser] = useState<number>();
  useEffect(() => {
    const user = myChallengeContext.evaluationRows.find((currentUser) => {
      return currentUser.user_id == favoriteUserId;
    });

    if (user) {
      setAvgValueUser(user.completeAvg);
    } else {
      setAvgValueUser(0);
    }
  }, []);

  return (
    <tr
      className={`${rowTransition} flex flex-row items-center justify-center w-full py-[0.7em] h-fit 2xl:shadow-none desktop:shadow-none md:shadow-none lg:shadow-none xl:shadow-none shadow-sm shadow-offset-x-2`}
    >
      <td className='w-5/12  justify-center desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden'>
        &nbsp;
      </td>
      <td
        className={`justify-start w-7/12 desktop:${Properties.height} md:${Properties.height} tablet:${
          Properties.height
        } h-10 desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden ${isLastItem ? 'rounded-bl-lg' : ''}`}
      >
        <div>
          {avatar ? (
            <img
              className='rounded-full desktop:h-12 desktop:w-12 md:w-12 md:h-12 tablet:h-12 tablet:w-12 w-8 h-8'
              src={'data:image/png;base64, ' + avatar}
            />
          ) : (
            <div className='rounded-full desktop:h-12 desktop:w-12 md:w-12 md:h-12 tablet:h-12 tablet:w-12 w-8 h-8'>
              {' '}
              <DefaultUserAvatar />
            </div>
          )}
        </div>
      </td>
      <td className={activityLogStyle}>{user_name ? user_name : user_email}</td>
      <td className={activityLogStyle}>{avgValueUser}%</td>
      <td className='flex justify-center text-text text-center w-full'>
        <span
          className={`${iconTransition} cursor-pointer desktop:scale-100 tablet:scale-100 scale-75`}
          onClick={showModal}
        >
          <EyeIcon />
        </span>
      </td>
      <td className='w-full phone:hidden lg:flex hidden'></td>
    </tr>
  );
};

export default FavoriteEvaluationRow;
