import React from 'react';

const ApproveGreenIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='30' viewBox='0 0 26 30' fill='none'>
    <path
      d='M20.72 23.638C20.173 23.71 19.786 24.212 19.859 24.759C19.952 25.48 20 26.234 20 27C20 27.34 19.986 27.677 19.967 28H2.033C2.014 27.677 2 27.34 2 27C2 19.832 6.037 14 11 14C13.044 14 15.054 15.037 16.659 16.919C17.016 17.339 17.648 17.389 18.069 17.031C18.489 16.673 18.539 16.041 18.18 15.621C17.162 14.428 15.997 13.513 14.751 12.899C16.701 11.655 18 9.479 18 7C18 3.14 14.859 0 11 0C7.141 0 4 3.14 4 7C4 9.486 5.307 11.667 7.265 12.91C3.035 15.001 0 20.52 0 27C0 27.76 0.0579998 28.484 0.114 29.093C0.162 29.607 0.594 30 1.11 30H20.89C21.407 30 21.838 29.607 21.886 29.093C21.942 28.484 22 27.76 22 27C22 26.148 21.946 25.307 21.841 24.5C21.77 23.953 21.269 23.561 20.72 23.638ZM6 7C6 4.243 8.243 2 11 2C13.757 2 16 4.243 16 7C16 9.757 13.757 12 11 12C8.243 12 6 9.757 6 7Z'
      fill='#15CDCA'
    />
    <path
      d='M25.7301 14.3162C25.3511 13.9132 24.7201 13.8932 24.3161 14.2692L14.3121 23.6292L9.68307 19.2972C9.27907 18.9202 8.64707 18.9402 8.26907 19.3442C7.89207 19.7472 7.91307 20.3802 8.31607 20.7572L13.6291 25.7282C13.8211 25.9082 14.0671 25.9982 14.3131 25.9982C14.5591 25.9982 14.8041 25.9082 14.9971 25.7282L25.6851 15.7282C26.0871 15.3522 26.1071 14.7192 25.7301 14.3162Z'
      fill='#15CDCA'
    />
  </svg>
);

export default ApproveGreenIcon;
