import React from 'react';

const ThumbDownIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
    <path
      d='M18.057 3.5803V3.58076V12.2688C18.057 13.4372 17.6781 14.574 16.977 15.5088L14.7371 18.4942L14.737 18.4944C13.7763 19.7753 13.257 21.3332 13.257 22.9344V24.0016C13.257 24.1607 13.1938 24.3133 13.0813 24.4258C12.9688 24.5383 12.8162 24.6016 12.657 24.6016C12.0736 24.6016 11.514 24.3698 11.1014 23.9572C10.6888 23.5446 10.457 22.985 10.457 22.4016V16.0016V15.0016H9.45703H3.76113C3.7611 15.0016 3.76106 15.0016 3.76103 15.0016C3.43561 15.0015 3.11426 14.9293 2.82013 14.79C2.52596 14.6508 2.26636 14.448 2.06002 14.1964C1.85369 13.9447 1.70576 13.6503 1.62691 13.3346C1.54807 13.0189 1.54025 12.6896 1.60401 12.3705C1.60402 12.3704 1.60403 12.3704 1.60403 12.3704L3.52401 2.77048L3.52404 2.77036C3.62376 2.27145 3.89332 1.82253 4.28682 1.50002C4.68033 1.17751 5.17346 1.00136 5.68223 1.00156H5.68263H14.3479C14.348 1.00156 14.3481 1.00156 14.3482 1.00156C15.1855 1.00188 16.0113 1.19689 16.7602 1.5712C16.7603 1.57122 16.7603 1.57124 16.7604 1.57126L16.8402 1.61119L16.8404 1.6113C17.2061 1.79404 17.5137 2.07508 17.7286 2.42287L18.5793 1.89724L17.7286 2.42288C17.9435 2.77067 18.0572 3.17147 18.057 3.5803ZM22.0671 1.41162L21.36 0.704508L22.0671 1.41161C22.3296 1.14906 22.6857 1.00156 23.057 1.00156C23.4283 1.00156 23.7844 1.14906 24.047 1.41161L24.747 0.711636L24.047 1.41162C24.3095 1.67417 24.457 2.03026 24.457 2.40156V12.0016C24.457 12.1854 24.4208 12.3675 24.3505 12.5373C24.2801 12.7072 24.177 12.8615 24.047 12.9915C23.917 13.1215 23.7626 13.2246 23.5928 13.295C23.4229 13.3654 23.2409 13.4016 23.057 13.4016C22.8732 13.4016 22.6911 13.3654 22.5213 13.295C22.3514 13.2246 22.1971 13.1215 22.0671 12.9915C21.9371 12.8615 21.834 12.7072 21.7636 12.5373C21.6932 12.3675 21.657 12.1854 21.657 12.0016V2.40156C21.657 2.03026 21.8045 1.67417 22.0671 1.41162Z'
      stroke='#15CDCA'
      strokeWidth='2'
    />
  </svg>
);

export default ThumbDownIcon;
