import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from 'src/api/client';

import { useToast } from 'src/store/toastContext';
import swal from 'sweetalert2';

interface MagicProps {
  children: React.ReactNode;
}
interface IError {
  message: string;
}

const ProtectedRoute: React.FC<MagicProps> = ({ children }) => {
  const { showToastHandler } = useToast();
  const token = Cookies.get('token');
  const Client = useApiClient();
  const { t } = useTranslation(['home', 'main']);
  const navigate = useNavigate();

  useEffect(() => {
    const magicLink = localStorage.getItem('magicLink');
    if (magicLink && token) {
      const joinTeamIndex = magicLink.indexOf('joinTeam/');
      const invitationToken = magicLink.slice(joinTeamIndex + 'joinTeam/'.length);
      swal
        .fire({
          title: `${t('Do you want to join to team?')}`,
          showCancelButton: true,
          confirmButtonText: `${t('YES')}`,
          cancelButtonText: `${t('CANCEL')}`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await Client.post('team/joinTeam/', {
                token: invitationToken,
              });
              showToastHandler(response?.data?.message, 'success');
            } catch (exc) {
              const err = exc as AxiosError<IError>;
              const error = err?.response?.data.message;

              if (err.response?.status == 400) {
                showToastHandler(`${error}`, 'error');
              }
            }
          }
          localStorage.removeItem('magicLink');
          navigate('/challenge/logs/');
        });
    }
  }, [location.pathname]);
  return <>{children}</>;
};

export default ProtectedRoute;
