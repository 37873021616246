import React from 'react';

export const ProcessSpiner: React.FC = () => {
  return (
    <td className='flex items-center justify-center h-1/2 scale-[0.4]'>
      <div className='flex flex-col'>
        <div className='flex space-x-24'>
          <div className='container space-y-10 relative'>
            <div className='w-16 h-16 border-b-4 border-b-white rounded-full animate-spin'></div>
          </div>
        </div>
      </div>
    </td>
  );
};
