import React from 'react';
import { useState } from 'react';
import MobileNavManuIcon from '../Icons/MobileNavManuIcon';
import { ReactComponent as WhiteCancel } from '../../images/icons/WhiteCancel.svg';
import RunManIcon from '../Icons/RunManIcon';
import MobileUl from './MobileUl/MobileUl';

const MobileNav: React.FC = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const onClick = (): void => setIsClicked((prev) => !prev);

  return (
    <div className='2xl:hidden lg:hidden desktop:hidden xl:hidden tablet:flex md:flex sm:flex flex z-40 left-2 top-4'>
      <div className='z-40 w-fit  flex flex-row '>
        <div className='w-fit flex absolute left-2 top-4'>
          <RunManIcon />
        </div>
        <div className='w-fit  flex absolute right-2 top-5 justify-center flex-col' onClick={onClick}>
          {isClicked ? <WhiteCancel /> : <MobileNavManuIcon />}
        </div>
      </div>
      <div className='h-full w-fit absolute'>
        <nav
          className={`bg-sideMenuColor bg-opacity-[0.98] h-full tablet:w-1/3 sm:w-2/5 w-2/3 right-0  top-0 fixed z-30 ${
            isClicked ? 'block' : 'hidden'
          }`}
        >
          <MobileUl />
        </nav>
      </div>
    </div>
  );
};

export default MobileNav;
