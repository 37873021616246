import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import PreviewServiceForm from '../../pages/HomePage/EditForm';
import '../css/Popup.css';
import swal from 'sweetalert2';
import PictureIcon from '../Icons/pictureIcon';
import ExternalLink from '../Icons/ExternalLinkIcon';
import PictureSVGIcon from '../Icons/PictureSVGIcon';
import { Dialog } from '@headlessui/react';
import ModalFromLibrary from '../ModalFromLibrary';
import moment from 'moment';
import activityLogStyle from '../css/ActivityLogsStyle/ActivityLogStyle';
import scaleTextElements from '../css/ScaleTextAndElements/ScaleTextElements';
import { IDeletedLogs } from 'src/api/types/deletedLogs/DeletedLogsResponse';
import RenovationIcon from '../Icons/RenovationIcon';
import { useApiClient } from 'src/api/client';
import { useTranslation } from 'react-i18next';
import RightSideBottomIconTooltip from 'src/utils/RightSideBottomIconTooltipUtils';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import LeftSideTopIconTooltip from 'src/utils/LeftSideTopIconTooltipUtils';
import RightSideTopIconTooltip from 'src/utils/RightSideTopIconTooltipUtils';
import { useToast } from 'src/store/toastContext';
import overflowText from '../css/MyChallengeLogsStyle/OverflowText';

interface DeletedLogsItemsProps {
  item: IDeletedLogs;
  isLastItem?: boolean;
  loadDeletedLogs: () => void;
}

const DeletedLogs: React.FC<DeletedLogsItemsProps> = ({ item, loadDeletedLogs, isLastItem }) => {
  const { chall_name, chl_date, chl_id, chl_img, chl_link, chl_value, cht_name, user_email, user_name } = item;

  const { t } = useTranslation(['home', 'main']);
  const Client = useApiClient();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState<boolean>(true);
  const { showToastHandler } = useToast();

  const closeModal = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    loadDeletedLogs();
  }, [reload]);

  const addLinkHttps = useMemo(() => (chl_link?.match(/^https?:\/\//i) ? chl_link : `http://${chl_link}`), [chl_link]);
  const getImg = async (): Promise<void> => {
    const response = await Client.get(`/reportedActivity/reportedLogImg/${chl_id}`);
    showModal(response.data.photo);
  };
  const showModal = (photo: string): void => {
    swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: PictureSVGIcon,
      html: `<img class='w-full h-full' src="data:image/jpeg;base64,${photo}" />`,
    });
  };

  const confirmationModel = async (): Promise<void> => {
    try {
      swal
        .fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Do you really want to resume the activity?')}` + '</h5>',
          showCancelButton: true,
          confirmButtonText: `${t('YES')}`,
          cancelButtonText: `${t('CANCEL')}`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await Client.put(`/challenge/activity/recovery/${chl_id}`);
              showToastHandler(`${t('Activity has been resumed')}`, 'success');
            } catch (error) {
              console.log(error);
            }
          }
          setReload(false);
        });
    } catch (error) {
      console.error(
        swal.fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
          html: "<div style='color: #F16B6B;'>" + `${t('Enter the correct information!')}` + '</div> ',
        }),
        error,
      );
    }
  };

  return (
    <>
      <tr
        key={cht_name}
        className={`w-full justify-center items-center flex-row flex py-2 ${
          typeof chl_id === 'number' && pathname.includes(chl_id.toString()) ? 'bg-white' : ''
        }`}
      >
        <td className={activityLogStyle}>
          <div className={overflowText}>{chall_name}</div>
        </td>
        <td className={activityLogStyle}>
          <div className={overflowText}>{user_name ? user_name : user_email}</div>
        </td>
        <td className={activityLogStyle}>
          <div className={overflowText}>{cht_name}</div>
        </td>
        <td className={activityLogStyle}>{chl_value}</td>
        <td className={activityLogStyle}>{moment(chl_date).format('DD.MM.yyyy')}</td>
        <td className={`${activityLogStyle} group overflow-visible flex justify-center`}>
          {(chl_link && (
            <a target={'_blank'} rel='noreferrer' className={scaleTextElements} href={addLinkHttps}>
              <ExternalLink />
              {!isLastItem ? (
                <LeftSideBottomIconTooltip tooltipContent={chl_link} />
              ) : (
                <LeftSideTopIconTooltip tooltipContent={chl_link} />
              )}
            </a>
          )) || <span className='font-bold'>-</span>}
        </td>
        <td className={`${activityLogStyle}  overflow-visible group flex justify-center`}>
          {(chl_img && (
            <span className={`${scaleTextElements}`} onClick={getImg}>
              <PictureIcon />
              {!isLastItem ? (
                <RightSideBottomIconTooltip tooltipContent={t('PHOTO')} />
              ) : (
                <RightSideTopIconTooltip tooltipContent={t('PHOTO')} />
              )}
            </span>
          )) || <span className='font-bold'>-</span>}
        </td>
        <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
          <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
            <button
              type='button'
              className={`text-lightBlueButton btn bg-white btn-sm border-none rounded-lg btn-circle absolute right-2 top-2 px-2`}
              onClick={closeModal}
            >
              x
            </button>
          </Dialog.Title>
          <PreviewServiceForm closeModal={closeModal} />
        </ModalFromLibrary>
        <td className={`${activityLogStyle} ${iconTransition}  cursor-pointer overflow-visible relative`}>
          <span onClick={confirmationModel} className='group flex justify-center h-full w-full items-center'>
            <RenovationIcon />
            {!isLastItem ? (
              <LeftSideBottomIconTooltip tooltipContent={t('RECOVERY')} />
            ) : (
              <LeftSideTopIconTooltip tooltipContent={t('RECOVERY')} />
            )}
          </span>
        </td>
      </tr>
    </>
  );
};

export default DeletedLogs;
