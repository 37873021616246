import React from 'react';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';
import { useTranslation } from 'react-i18next';
import { myChallengeData } from 'src/store/myChallengePageContex';
import SelectButtonFavorites from 'src/components/FavoritesUsersSelect/FavoritesUsersSelect';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import SortIcon from 'src/components/Icons/SortIcon';
import RightSideBottomIconTooltip from 'src/utils/RightSideBottomIconTooltipUtils';

const HeaderUsers: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);
  const myChallengeContext = myChallengeData();

  const onChange = async (): Promise<void> => {
    myChallengeContext.setSort(!myChallengeContext.sort);

    if (myChallengeContext.sort) {
      myChallengeContext.evaluationRows.sort((a, b) => b.completeAvg - a.completeAvg);
    } else {
      myChallengeContext.evaluationRows.sort((a, b) => a.completeAvg - b.completeAvg);
    }
  };

  return (
    <tr className='flex flex-row w-full py-[0.5em] sticky top--0'>
      <th className={`w-full desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden`}>&nbsp;</th>
      {myChallengeContext.select === 1 ? (
        <>
          <th className={`${headerStyle}  desktop:hidden xl:hidden lg:hidden 2xl:hidden md:hidden tablet:hidden flex`}>
            {t('FAVOURITES')}
          </th>
          <th className={`${headerStyle}  desktop:hidden xl:hidden lg:hidden 2xl:hidden md:hidden tablet:hidden flex`}>
            {t('REWARDED')}
          </th>
        </>
      ) : null}
      <th className={headerStyle}>{t('NAME')}</th>
      <th className={`${headerStyle} flex desktop:space-x-2  tablet:space-x-2`}>
        <th> {t('COMPLETION')}</th>

        <button className={`group  ${iconTransition}`} onClick={() => onChange()}>
          <div
            className={`${
              !myChallengeContext.sort && 'rotate-180'
            } desktop:scale-100 tablet:scale-100 scale-[0.7] desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden`}
          >
            <SortIcon />
          </div>
          <RightSideBottomIconTooltip
            tooltipContent={myChallengeContext.sort ? `${t('FILTER Z-A')}` : `${t('FILTER A-Z')}`}
          />
        </button>
      </th>
      <th
        className={` justify-center items-center w-fit desktop:hidden xl:hidden lg:hidden 2xl:hidden md:hidden tablet:hidden flex`}
      >
        <button onClick={() => onChange()}>
          <div className={`${!myChallengeContext.sort && 'rotate-180'}  scale-[0.7] justify-end items-end`}>
            <SortIcon />
          </div>
        </button>
      </th>
      <th className={headerStyle}>{t('DETAIL')}</th>
      <th className={`w-full phone:hidden lg:flex hidden`}>
        <SelectButtonFavorites />
      </th>
    </tr>
  );
};

export default HeaderUsers;
