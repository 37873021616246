import React, { useEffect, useState } from 'react';
import { useApiClient } from 'src/api/client';
import activityLogStyle from 'src/components/css/ActivityLogsStyle/ActivityLogStyle';
import EyeIcon from 'src/components/Icons/EyeIcon';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ReactComponent as DefaultUserAvatar } from '../../../images/icons/DefaultUserAvatar.svg';
import FilledStarIcon from '../../../components/Icons/FilledStarIcon';
import EmptyStarIcon from '../../../components/Icons/EmptyStarIcon';
import { IEvaluation } from '../MyChallengesPage';
import ButtonStyle from './ButtonStyle';
import { useTranslation } from 'react-i18next';
import { rowTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/RowsTransition';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import { myChallengeData } from 'src/store/myChallengePageContex';
import { useToast } from 'src/store/toastContext';
import ActiveReward from 'src/components/Icons/ActiveReward';
import InactiveReward from 'src/components/Icons/InactiveReward';
import SortIcon from 'src/components/Icons/SortIcon';
const Properties = {
  height: 'h-12',
  width: 'w-12',
  thumbIconsScale: 'desktop:scale-[1.6] md:scale-[1.4] tablet:scale-[1.4] scale-125',
  cursorPointer: 'cursor-pointer',
};

const SwalEval = withReactContent(Swal);
const ChallengeEvaluationRow: React.FC<IEvaluation & { isLastItem: boolean; index: number }> = ({
  completeAvg,
  user_id,
  cht_challenge_id,
  loged_user_id,
  team_id,
  user_name,
  user_email,
  user_avatar,
  targets,
  isLastItem,
  index,
}) => {
  const { t } = useTranslation(['home', 'main']);
  const showModal = (): void => {
    SwalEval.fire({
      html: (
        <div>
          <div>
            <div className='flex-col w-full tablet:w-full'>
              <div className='tablet:w-full '>
                <div>
                  <div className='flex'>
                    <div className='font-bold  w-full text-center h-10 rounded-tl-2xl pt-2'>{t('TARGET')}</div>
                    <div className='font-bold  w-full text-center h-10 pt-2'>{t('COMPLETION')}</div>

                    <div className=' text-center  h-10 rounded-tr-2xl pt-2 flex justify-center pr-3'></div>
                  </div>
                </div>
                <div className='h-72 overflow-y-auto rounded-b-2xl '>
                  {targets.map((row, index) => {
                    return (
                      <div key={index} className={`h-12 flex rounded-bl-2xl`}>
                        <div
                          className={`text-text text-center w-full self-center pt-3  h-12 ${
                            isLastItem ? 'rounded-bl-2xl' : ''
                          }`}
                        >
                          {row.target_name}
                        </div>
                        <div className='text-text text-center w-full  pt-3'>{row.percent}%</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      title: user_name ? user_name : user_email,
      showCancelButton: false,
      showConfirmButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: ButtonStyle,
      },
    });
  };
  const Client = useApiClient();
  const myChallengeContext = myChallengeData();
  const [swapStarIcon, setSwapStarIcon] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isReward, setIsReward] = useState<boolean>(false);
  const [rewardIcon, setRewardIcon] = useState<boolean>(false);
  const { showToastHandler } = useToast();

  const onChange = async (): Promise<void> => {
    setSwapStarIcon(!swapStarIcon);
    try {
      const favoriteUser = {
        favUserId: user_id,
        chlngId: cht_challenge_id,
        teamId: team_id,
      };

      if (!isFavorite) {
        const result = await Client.post('/user/addFavorite', favoriteUser);
        if (result.status == 200) {
          setIsFavorite(true);
          showToastHandler(
            `${user_name ? user_name : user_email}` + ' ' + `${t('was added as favorites!')}`,
            'success',
          );
        }
      } else if (isFavorite) {
        const result = await Client.delete('/user/removeFavorite?removeFavorite', { data: favoriteUser });
        if (result.status == 200) {
          setIsFavorite(false);
          showToastHandler(
            `${user_name ? user_name : user_email}` + ' ' + `${t('was removed as favorites!')}`,
            'success',
          );
        }
      }
    } catch (error) {
      console.error('Something wrong');
    }
  };

  const onReward = async (): Promise<void> => {
    if (myChallengeContext.isAdmin) {
      setRewardIcon(!rewardIcon);
      try {
        const rewardUser = {
          rewUserId: user_id,
          chlngId: cht_challenge_id,
          teamId: team_id,
        };

        if (!isReward) {
          const result = await Client.post('/user/addReward', rewardUser);
          if (result.status == 200) {
            setIsReward(true);
            showToastHandler(
              `${t('User')}` + ' ' + `${user_name ? user_name : user_email}` + ' ' + `${t('was added as rewarded!')}`,
              'success',
            );
          }
        } else if (isReward) {
          const result = await Client.delete('/user/removeReward?removeReward', { data: rewardUser });
          if (result.status == 200) {
            setIsReward(false);
            showToastHandler(
              `${t('User')}` + ' ' + `${user_name ? user_name : user_email}` + ' ' + `${t('was removed as rewarded!')}`,
              'success',
            );
          }
        }
      } catch (error) {
        console.error('Something wrong');
      }
    }
  };

  useEffect(() => {
    const userExist = myChallengeContext.favoriteUsers.find((user) => user.favoriteUserId === user_id);
    if (userExist) {
      setIsFavorite(true);
      setSwapStarIcon(true);
    } else {
      setIsFavorite(false);
      setSwapStarIcon(false);
    }
  }, [myChallengeContext.favoriteUsers]);

  useEffect(() => {
    const userRewardExist = myChallengeContext.rewardUsers.find((user) => user.rewardUserId === user_id);
    if (userRewardExist) {
      setIsReward(true);
      setRewardIcon(true);
    } else {
      setIsReward(false);
      setRewardIcon(false);
    }
  }, [myChallengeContext.rewardUsers]);

  return (
    <tr
      className={`${rowTransition} flex flex-row w-full h-fit items-center py-[0.7em] 2xl:shadow-none desktop:shadow-none md:shadow-none lg:shadow-none xl:shadow-none shadow-sm shadow-offset-x-2 `}
    >
      <td className='w-full h-full  flex-row hidden desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex '>
        <div className='group relative h-full w-full flex flex-row justify-center'>
          {loged_user_id != user_id ? (
            <span className='cursor-pointer my-auto' onClick={onChange}>
              {swapStarIcon ? <FilledStarIcon /> : <EmptyStarIcon />}
            </span>
          ) : null}
          {loged_user_id != user_id ? (
            <span
              className={`pointer-events-none absolute  ${
                index === 0 ? 'top-10' : '-top-6'
              } left-0 w-max rounded 	bg-lightBlueButton  px-2 py-1 text-sm font-medium text-white opacity-0 shadow transition-opacity group-hover:opacity-100`}
            >
              {swapStarIcon ? `${t('ROMOVE FROM FAVORITES')}` : `${t('ADD TO FAVORITES')}`}
            </span>
          ) : null}
        </div>
        <div className='group relative h-full w-full flex flex-row justify-center'>
          <span className='cursor-pointer my-auto' onClick={onReward}>
            {rewardIcon ? <ActiveReward /> : <InactiveReward />}
          </span>

          {myChallengeContext.isAdmin ? (
            <span
              className={`pointer-events-none absolute  ${
                index === 0 ? 'top-10' : '-top-6'
              } left-0 w-max rounded 	bg-lightBlueButton  px-2 py-1 text-sm font-medium text-white opacity-0 shadow transition-opacity group-hover:opacity-100`}
            >
              {rewardIcon ? `${t('REMOVE FROM REWARDED')}` : `${t('ADD TO REWARDED')}`}
            </span>
          ) : null}
        </div>
        <div
          className={`items-start w-full flex flex-row justify-center desktop:${Properties.height} md:${
            Properties.height
          } tablet:${Properties.height} h-10 desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden ${
            isLastItem ? 'rounded-bl-lg' : ''
          }`}
        >
          {user_avatar ? (
            <img
              className={`rounded-full desktop:${Properties.height}  desktop:${Properties.width}  md:${Properties.height} md:${Properties.width} tablet:${Properties.width} tablet:${Properties.height} w-8 h-8`}
              src={'data:image/png;base64, ' + user_avatar}
            />
          ) : (
            <div
              className={`rounded-full desktop:${Properties.height}  desktop:${Properties.width}  md:${Properties.height} md:${Properties.width} tablet:${Properties.width} tablet:${Properties.height} w-8 h-8`}
            >
              <DefaultUserAvatar />
            </div>
          )}
        </div>
      </td>{' '}
      <td
        className={`${activityLogStyle} w-full desktop:hidden xl:hidden lg:hidden 2xl:hidden md:hidden tablet:hidden flex justify-center text-text text-center `}
      >
        {loged_user_id != user_id ? (
          <span className='cursor-pointer my-auto' onClick={onChange}>
            {swapStarIcon ? <FilledStarIcon /> : <EmptyStarIcon />}
          </span>
        ) : null}
      </td>
      <td
        className={`${activityLogStyle} w-full desktop:hidden xl:hidden lg:hidden 2xl:hidden md:hidden tablet:hidden flex justify-center text-text text-center `}
      >
        <span className='cursor-pointer my-auto' onClick={onReward}>
          {rewardIcon ? <ActiveReward /> : <InactiveReward />}
        </span>
      </td>
      <td className={`${activityLogStyle}`}>{user_name ? user_name : user_email}</td>
      <td className={`${activityLogStyle} flex  justify-center lg:space-x-2 tablet:space-x-2`}>
        <td>{completeAvg}% </td>
        <td className='w-[31px] desktop:scale-100 tablet:scale-100 scale-[0.8] desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden'>
          &nbsp;
        </td>
      </td>
      <td
        className={` justify-center scale-0 w-fit desktop:hidden xl:hidden lg:hidden 2xl:hidden md:hidden tablet:hidden flex`}
      >
        <SortIcon />
      </td>
      <td className='flex justify-center text-text text-center w-full'>
        <span
          className={`${iconTransition} cursor-pointer desktop:scale-100 tablet:scale-100 scale-75`}
          onClick={showModal}
        >
          <EyeIcon />
        </span>
      </td>
      <td className='w-full phone:hidden lg:flex hidden'></td>
    </tr>
  );
};

export default ChallengeEvaluationRow;
