import React from 'react';

const HideEyeIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M3.52985 2.47082C3.38767 2.33834 3.19963 2.26622 3.00532 2.26965C2.81102 2.27308 2.62564 2.35179 2.48822 2.4892C2.35081 2.62661 2.2721 2.812 2.26867 3.0063C2.26524 3.2006 2.33737 3.38865 2.46985 3.53082L20.4698 21.5308C20.5385 21.6045 20.6213 21.6636 20.7133 21.7046C20.8053 21.7456 20.9046 21.7676 21.0053 21.7694C21.106 21.7712 21.2061 21.7527 21.2994 21.7149C21.3928 21.6772 21.4777 21.6211 21.5489 21.5499C21.6201 21.4786 21.6762 21.3938 21.714 21.3004C21.7517 21.207 21.7702 21.107 21.7684 21.0063C21.7667 20.9056 21.7446 20.8063 21.7036 20.7143C21.6626 20.6223 21.6035 20.5395 21.5298 20.4708L3.52985 2.47082ZM22.6758 12.5538C22.1368 14.1713 21.2371 15.6451 20.0448 16.8638L16.9458 13.7648C17.2802 12.8287 17.3419 11.817 17.1239 10.8472C16.9058 9.87742 16.4169 8.98947 15.7141 8.28661C15.0112 7.58375 14.1232 7.09486 13.1535 6.8768C12.1837 6.65875 11.1719 6.72048 10.2358 7.05482L7.75885 4.57782C9.10587 4.02978 10.5466 3.7489 12.0008 3.75082C16.9708 3.75082 21.1858 6.97382 22.6758 11.4408C22.7958 11.8028 22.7958 12.1928 22.6758 12.5538Z'
      fill='#80A2C4'
    />
    <path
      d='M15.7498 12.0008C15.7498 12.1808 15.7368 12.3578 15.7128 12.5308L11.4688 8.28779C12.0008 8.21169 12.5429 8.25082 13.0584 8.40253C13.5739 8.55424 14.0508 8.81498 14.4568 9.16708C14.8628 9.51919 15.1883 9.95444 15.4114 10.4433C15.6345 10.9323 15.7499 11.4634 15.7498 12.0008ZM12.5298 15.7138L8.28677 11.4698C8.20437 12.0462 8.25731 12.6338 8.4414 13.1862C8.62548 13.7386 8.93567 14.2405 9.34737 14.6522C9.75908 15.0639 10.261 15.3741 10.8134 15.5582C11.3657 15.7423 11.9534 15.7952 12.5298 15.7128V15.7138Z'
      fill='#80A2C4'
    />
    <path
      d='M6.75037 12.0007C6.75037 11.3817 6.85737 10.7877 7.05437 10.2367L3.95438 7.13672C2.76248 8.35556 1.86316 9.82934 1.32437 11.4467C1.20437 11.8087 1.20437 12.1987 1.32437 12.5607C2.81337 17.0277 7.02837 20.2507 11.9994 20.2507C13.4994 20.2507 14.9324 19.9567 16.2414 19.4237L13.7644 16.9467C12.972 17.2294 12.1234 17.3175 11.2898 17.2036C10.4563 17.0898 9.66237 16.7773 8.97485 16.2925C8.28734 15.8077 7.7264 15.1647 7.33927 14.4179C6.95215 13.671 6.75018 12.842 6.75037 12.0007Z'
      fill='#80A2C4'
    />
  </svg>
);

export default HideEyeIcon;
