import React, { createContext, useContext, useState } from 'react';
interface PaymentContextProps {
  currency: string;
  setCurrency: (value: string) => void;
  selectedCurrency: string;
  setSelectedCurrency: (value: string) => void;
  clearPaymentData: () => void;
}

interface PaymentDetailProviderProps {
  children: React.ReactNode;
}

const defaultState: PaymentContextProps = {
  currency: '',
  selectedCurrency: '',
  clearPaymentData: () => {
    return;
  },
  setCurrency: () => {
    return;
  },
  setSelectedCurrency: () => {
    return;
  },
};

export const PaymentDetailContext = createContext<PaymentContextProps>(defaultState);

export const PaymentDetailProvider: React.FC<PaymentDetailProviderProps> = ({ children }) => {
  const [currency, setCurrency] = useState<string>('');
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');

  const clearAllData = (): void => {
    setCurrency('');
    setSelectedCurrency('');
  };

  const handleClearPaymentData = (): void => {
    clearAllData();
  };
  const handleSetCurrency = (currency: string): void => {
    setCurrency(currency);
  };
  const handleSetSelectCurrency = (selectedCurrency: string): void => {
    setSelectedCurrency(selectedCurrency);
  };

  return (
    <PaymentDetailContext.Provider
      value={{
        clearPaymentData: handleClearPaymentData,
        currency: currency,
        setCurrency: handleSetCurrency,
        selectedCurrency: selectedCurrency,
        setSelectedCurrency: handleSetSelectCurrency,
      }}
    >
      {children}
    </PaymentDetailContext.Provider>
  );
};

export const usePaymentData = (): PaymentContextProps => useContext(PaymentDetailContext);
