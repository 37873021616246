import * as React from 'react';
import 'react-popper-tooltip/dist/styles.css';

interface tooltipProps {
  tooltipContent: string;
}

const LeftSideToolTipGrayBottom: React.FC<tooltipProps> = (props) => {
  const { tooltipContent } = props;
  return (
    <div className='hidden lg:group-hover:flex phone:group-hover:hidden group-hover:hidden pointer-events-auto absolute top-7 items-center right-8 justify-start text-left whitespace-nowrap w-auto h-auto rounded bg-darkGrey px-2 py-1 text-sm font-medium text-white shadow transition-opacity'>
      {tooltipContent}
    </div>
  );
};

export default LeftSideToolTipGrayBottom;
