import React, { createContext, useContext, useState } from 'react';
interface ActivityDetailContextProps {
  workout: number;
  date: Date;
  logId: number;
  value: string;
  link: string | null;
  description: string | null;
  img: boolean | undefined;
  unit: string;
  reload: boolean;
  setWorkout: (value: number) => void;
  setlogId: (value: number) => void;
  setDate: (value: Date) => void;
  setValue: (value: string) => void;
  setLink: (value: string | null) => void;
  setDescription: (value: string | null) => void;
  setImg: (value: boolean) => void;
  setUnit: (value: string) => void;
  setReload: (value: boolean) => void;
}

interface LogsDetailProviderProps {
  children: React.ReactNode;
}

const defaultState: ActivityDetailContextProps = {
  reload: true || false,
  workout: 0,
  logId: 0,
  date: new Date(),
  value: '',
  link: '',
  description: '',
  unit: '',
  img: true,
  setReload: () => {
    return;
  },
  setWorkout: () => {
    return;
  },

  setlogId: () => {
    return;
  },

  setDate: () => {
    return;
  },
  setValue: () => {
    return;
  },
  setLink: () => {
    return;
  },
  setDescription: () => {
    return;
  },
  setImg: () => {
    return;
  },
  setUnit: () => {
    return;
  },
};

export const ActivityDetailContext = createContext<ActivityDetailContextProps>(defaultState);

export const LogsDetailProvider: React.FC<LogsDetailProviderProps> = ({ children }) => {
  const [workout, setWorkout] = useState<number>(0);
  const [logId, setlogId] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());
  const [value, setValue] = useState<string>('');
  const [link, setLink] = useState<string | null>('');
  const [description, setDescription] = useState<string | null>('');
  const [img, setImg] = useState<boolean>();
  const [unit, setUnit] = useState<string>('');
  const [reload, setReload] = useState<boolean>(true);

  const handleSetReload = (reload: boolean): void => {
    setReload(reload);
  };
  const handleSetWrokout = (workout: number): void => {
    setWorkout(workout);
  };
  const handleSetlogId = (logId: number): void => {
    setlogId(logId);
  };
  const handleSetDate = (date: Date): void => {
    setDate(date);
  };
  const handleSetValue = (value: string): void => {
    setValue(value);
  };
  const handleSetLink = (link: string | null): void => {
    setLink(link);
  };
  const handleSetDescription = (description: string | null): void => {
    setDescription(description);
  };
  const handleSetImg = (img: boolean): void => {
    setImg(img);
  };
  const handleSetUnit = (unit: string): void => {
    setUnit(unit);
  };

  return (
    <ActivityDetailContext.Provider
      value={{
        reload: reload,
        setReload: handleSetReload,
        workout: workout,
        setWorkout: handleSetWrokout,
        logId: logId,
        setlogId: handleSetlogId,
        date: date,
        setDate: handleSetDate,
        value: value,
        setValue: handleSetValue,
        link: link,
        setLink: handleSetLink,
        description: description,
        setDescription: handleSetDescription,
        img: img,
        setImg: handleSetImg,
        unit: unit,
        setUnit: handleSetUnit,
      }}
    >
      {children}
    </ActivityDetailContext.Provider>
  );
};

export const useActivity = (): ActivityDetailContextProps => useContext(ActivityDetailContext);
