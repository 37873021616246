export function Speed(): number {
  const width = window.innerWidth;
  if (width == 2560) {
    return 2.7;
  } else if (width == 1920) {
    return 2.7;
  } else if (width == 1600) {
    return 2.3;
  } else if (width == 1512) {
    return 2.5;
  } else if (width == 1280) {
    return 2.5;
  } else if (width == 1024) {
    return 3.5;
  } else {
    return 2.3;
  }
}
