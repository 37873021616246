import React from 'react';

const ViewListIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='23' viewBox='0 0 24 23' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.799805 1.90078C0.799805 1.01713 1.51615 0.300781 2.3998 0.300781H21.5998C22.4835 0.300781 23.1998 1.01713 23.1998 1.90078C23.1998 2.78444 22.4835 3.50078 21.5998 3.50078H2.3998C1.51615 3.50078 0.799805 2.78444 0.799805 1.90078ZM0.799805 8.30078C0.799805 7.41713 1.51615 6.70078 2.3998 6.70078H21.5998C22.4835 6.70078 23.1998 7.41713 23.1998 8.30078C23.1998 9.18444 22.4835 9.90078 21.5998 9.90078H2.3998C1.51615 9.90078 0.799805 9.18444 0.799805 8.30078ZM0.799805 14.7008C0.799805 13.8171 1.51615 13.1008 2.3998 13.1008H21.5998C22.4835 13.1008 23.1998 13.8171 23.1998 14.7008C23.1998 15.5844 22.4835 16.3008 21.5998 16.3008H2.3998C1.51615 16.3008 0.799805 15.5844 0.799805 14.7008ZM0.799805 21.1008C0.799805 20.2171 1.51615 19.5008 2.3998 19.5008H21.5998C22.4835 19.5008 23.1998 20.2171 23.1998 21.1008C23.1998 21.9844 22.4835 22.7008 21.5998 22.7008H2.3998C1.51615 22.7008 0.799805 21.9844 0.799805 21.1008Z'
      fill='white'
    />
  </svg>
);

export default ViewListIcon;
