import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useApiClient } from 'src/api/client';
import { ActivityLogRequest } from 'src/api/types/activityForm/ActivityEditRequest';
import { TargetLogsListItem } from 'src/api/types/targets/targetResponse';
import Select from 'src/components/Select';
import swal from 'sweetalert2';
import EditButton from '../../../components/EditButton';
import RowButton from '../../../components/RowButton';
import ServiceFormCard from '../ServiceFormCard';
import { StatusWatcher } from './StatusWatcher';
import './Arrows.css';
import className from '../ActivityEditFormClassName/ClassNameActivityEditForm';
import buttonSwapColor from 'src/pages/HoverButtonsstyle/HooverButtonSwapColor';
import AddEditTextField from 'src/components/AddEditTextField/AddEditTextField';
import ActivityDatePicker from 'src/components/AddEditActivityDatePicker/ActivityDatePicker';
import NumberFieldWithUnit from 'src/components/NumberFieldWithUnit/NumberFieldWithUnit';
import { useTranslation } from 'react-i18next';
import { useActivity } from 'src/store/activityCardContext';
import { useToast } from 'src/store/toastContext';
import { ProcessSpiner } from 'src/utils/ProcessSpiner';
import { Spinner } from 'src/utils/Spinner';
import ChallengeInfoModal from 'src/components/ChallengeInfoModal/ChallengeInfoModal';

interface ISelect {
  target: {
    value: string;
  };
}

interface ServiceFormProps {
  defaultValues?: Partial<ActivityLogRequest>;
  closeModal?: () => void;
}
interface CreateChallengeLogsForm {
  workout: number;
  date: string;
  distance: string;
  link: string | null;
  description: string | null;
  files: File[] | null;
}
interface IAnyActiveChallenge {
  inactiveChallenge: boolean;
  anyChallenge: boolean;
}

const ServiceForm: React.FC<ServiceFormProps> = ({ closeModal }) => {
  const { showToastHandler } = useToast();
  const activity = useActivity();
  const { t } = useTranslation(['home', 'main']);
  const [unit, setUnit] = useState<string>();
  const [select, setSelected] = useState<number>(0);
  const [isAllowed, setIsAllowerd] = useState<boolean>(false);
  const [challengeStart, setChallengeStart] = useState<string>();
  const [challengeEnd, setChallengeEnd] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [inactiveChallenge, setInactiveChallenge] = useState<boolean>(false);
  const [anyChallenge, setAnyChallenge] = useState<boolean>(false);
  const handleChange = (event: ISelect): void => {
    const eventChlngId = parseInt(event.target.value);

    for (let item = 0; item < items.length; item++) {
      const element = items[item];
      setSelected(eventChlngId);
      setIsAllowerd(items[item].chall_insert_log_days_after_activity);
      setChallengeStart(items[item].chall_start);
      setChallengeEnd(items[item].chall_end);
      if (element.id == eventChlngId) {
        setUnit(element.chlgTargetUnit);
        break;
      } else if (eventChlngId == 0) {
        setUnit('');
        break;
      }
    }
  };
  const Client = useApiClient();
  const [items, setItems] = useState<TargetLogsListItem[]>([]);
  const { setValue } = useForm<CreateChallengeLogsForm>();

  useEffect(() => {
    Client.get('activity/targets')
      .then((response) => {
        setItems(response.data.allTargets);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  const form = useForm<CreateChallengeLogsForm>();
  const { handleSubmit, control, register } = form;
  const [distanceValue, setDistanceValue] = useState<string>('');
  const changeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = event.target.value;
    if (!/^\d+([.,]\d{0,3})?$/.test(val)) {
      if (/([.,]\d{0,3})/.test(val)) {
        return;
      }
      setDistanceValue('');

      return;
    }
    if (val === '') {
      setDistanceValue('');
      return;
    }
    const formatInputValue = parseFloat(val.replace(',', '.').replace(' ', ''));
    setDistanceValue(val.toString());
    setValue('distance', formatInputValue.toString());
  };

  const [fileName, setFileName] = useState<string>();
  const [img, setImg] = useState<File[]>();
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      if (files[0].type === 'image/heic') {
        setImg(files);
        setValue('files', files);
        setFileName(files[0].name.toString());
      } else {
        console.log('files', files);
        setImg(files);
        setValue('files', files);
        setFileName('test');
      }
    }
  };
  const today = new Date().toISOString();
  const onSubmit: SubmitHandler<CreateChallengeLogsForm> = async (data) => {
    try {
      setIsSubmitting(true);
      const licence = items.find((x) => x.id == select);
      if (licence?.isLicenceExpired) {
        swal.fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
          html: "<div style='color: #F16B6B;'>" + `expired` + '</div> ',
        });
        return;
      }
      const bodyFormData = new FormData();
      if (img != null) {
        img.forEach((value) => bodyFormData.append('files', value));
      }
      if (data.link != null) bodyFormData.append('link', data.link);
      if (data.description != null) bodyFormData.append('description', data.description);
      if (!data.distance) {
        swal.fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
          html: "<div style='color: #F16B6B;'>" + `${t('An empty field is not allowed')}` + '</div> ',
        });
        return;
      }
      data.workout = select;
      const value = parseFloat(data.distance.replace(',', '.').replace(' ', ''));
      bodyFormData.append('workout', data.workout + '');
      bodyFormData.append('date', data.date || today);
      bodyFormData.append('value', value + '');

      const result = await Client.post('/activity/create', bodyFormData);
      if (result.status == 200) {
        showToastHandler(`${t('Your activity has been saved!')}`, 'success');
        closeModal && closeModal();
        activity.setReload(true);
      }
      setIsSubmitting(true);
    } catch (error) {
      setIsSubmitting(false);
      let errorMsg = 'We encountered a problem!';
      if (error instanceof AxiosError) {
        errorMsg = error.response?.data.message;
      }
      swal.fire({
        title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
        html: "<div style='color: #F16B6B;'>" + errorMsg + '</div> ',
      });
    }
  };
  useEffect(() => {
    Client.get('challenge/count').then((response) => {
      const { inactiveChallenge, anyChallenge } = response.data as IAnyActiveChallenge;
      setInactiveChallenge(inactiveChallenge);
      setAnyChallenge(anyChallenge);
      setIsloading(false);
    });
  }, []);

  const onError: SubmitErrorHandler<CreateChallengeLogsForm> = (errors) => {
    console.log(errors);
  };
  const gridClassName = 'flex flex-row items-center w-full h-full mb-5';
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : !inactiveChallenge && anyChallenge ? (
        <FormProvider {...form}>
          <form className='w-full  items-center' onSubmit={handleSubmit(onSubmit, onError)}>
            <StatusWatcher />
            <div className='flex space-x-44 mb-2'>
              <div className='2xl:text-3xl md:text-3xl sm:text-3xl text-2xl text-black font-semibold'>
                {t('ADD ACTIVITY')}
              </div>
            </div>
            <ServiceFormCard>
              <div className={gridClassName}>
                <div className={className}>{t('TARGET')}</div>
                <div className='h-full w-full flex flex-row justify-end'>
                  <Controller
                    control={control}
                    name='workout'
                    render={({ field }) => (
                      <Select {...field} onChange={handleChange} value={select}>
                        <option value={0}></option>
                        {items.map((target) => (
                          <option
                            key={target.name}
                            value={target.id}
                            disabled={target.isLicenceExpired == null ? false : target.isLicenceExpired ? true : false}
                          >
                            {target.name}/{target.challenge}
                            {target.isLicenceExpired && `${t('EXPIRED LICENCE')}`}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
              <Controller
                control={control}
                name='date'
                render={({ field }) => {
                  return (
                    <>
                      <div className={gridClassName}>
                        <div className={className}>{t('DATE')}</div>
                        <div className='h-full w-full flex justify-end'>
                          <ActivityDatePicker
                            challengeStart={challengeStart}
                            challengeEnd={challengeEnd}
                            isAllowed={isAllowed}
                            {...field}
                            onChange={(date) => {
                              if (!date) {
                                field.onChange(null);
                                return;
                              }
                              field.onChange(date);
                            }}
                            value={new Date(field.value || today)}
                          />
                        </div>
                      </div>
                    </>
                  );
                }}
              />
              <div className={gridClassName}>
                <div className={className}>{t('VALUE')}</div>
                <div className='flex flex-row w-full h-fit relative'>
                  <NumberFieldWithUnit
                    type={'number'}
                    {...register('distance', { validate: () => parseFloat(distanceValue).toString() !== '' })}
                    value={distanceValue}
                    onChange={changeValue}
                    required={true}
                  />
                  <span className='w-fit h-full m-auto text-darkGrey absolute top-0 right-2 justify-center flex items-center'>
                    {unit !== undefined ? t(`${unit}`) : ''}
                  </span>
                </div>
              </div>
              <div className={gridClassName}>
                <div className={className}>{t('LINK')}</div>
                <Controller
                  control={control}
                  name='link'
                  render={({ field, fieldState }) => <AddEditTextField {...field} error={fieldState.error?.message} />}
                />
              </div>
              <div className={gridClassName}>
                <div className={className}>{t('Description')}</div>
                <Controller
                  control={control}
                  name='description'
                  render={({ field, fieldState }) => (
                    <AddEditTextField maxLength={50} {...field} error={fieldState.error?.message} />
                  )}
                />
              </div>
              <div className='flex flex-row justify-evenly w-full items-center text-center'>
                <div className={`flex justify-start w-full`}>{t('PHOTO')}</div>
                <div className='flex flex-row items-center gap-3 desktop:w-full tablet:w-full w-full h-full'>
                  <div className='flex w-full h-full justify-center'>
                    <input
                      type='file'
                      id='inputPicture'
                      className='hidden w-full bg-lightBlueButton p-4'
                      onChange={onChange}
                      multiple={true}
                    />
                    <label
                      htmlFor='inputPicture'
                      className={`${buttonSwapColor} w-full px-5 items-center flex flex-row cursor-pointer justify-evenly h-14`}
                    >
                      {t('SELECT FILE')}
                    </label>
                  </div>
                </div>
                <div className='w-full h-full flex-col items-center justify-evenly'>
                  <span id='fileName' className='font-normal desktop:text-base tablet:text-base text-sm'>
                    {fileName ? fileName : `${t('FILE NOT SELECTED')}`}
                  </span>
                </div>
              </div>
            </ServiceFormCard>
            <div className='mt-10 grid place-items-end'>
              <div className='flex lex-row w-full justify-end gap-10 h-full mt-1 mb-6'>
                <EditButton
                  type='button'
                  className={`${buttonSwapColor} desktop:w-5/10 tablet:w-5/10 w-full`}
                  onClick={closeModal}
                >
                  {t('CANCEL')}
                </EditButton>
                <RowButton
                  className={`${buttonSwapColor} desktop:w-5/10 tablet:w-5/10 w-full`}
                  type='submit'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className='w-full h-fit text-xs flex flex-row items-center justify-center cursor-not-allowed'>
                      <div className='w-3/12 h-6 items-center justify-center flex'>
                        <ProcessSpiner />
                      </div>
                      <div className='w-full h-fit'>{t('PROCESSING...')}</div>
                    </div>
                  ) : (
                    `${t('INSERT')}`
                  )}
                </RowButton>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        <ChallengeInfoModal
          chllngInfo={anyChallenge ? (inactiveChallenge ? `${t('ANY ACTIVE CHALLENGE')}` : '') : `${t('NO CHALLENGE')}`}
          insertLogDaysAfterActivityInfo={undefined}
          closeModal={closeModal}
          buttonName={`${t('CREATE')}`}
          urlLink='/newChallenge/'
        />
      )}
    </>
  );
};
export default ServiceForm;
