import React from 'react';
import { useApiClient } from 'src/api/client';
import { useTeam } from 'src/store/teamDetailContext';
import swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { checkLicenceIsInvalid } from 'src/utils/LicenceValidations';
import { rowTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/RowsTransition';
import activityLogStyle from 'src/components/css/ActivityLogsStyle/ActivityLogStyle';
import { useToast } from 'src/store/toastContext';
import { ReactComponent as LeaveTeamIcon } from '../../../images/icons/LeaveTeamIcon.svg';
import { ReactComponent as DetailIcon } from '../../../images/icons/DetailIcon.svg';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import LeftSideTopIconTooltip from 'src/utils/LeftSideTopIconTooltipUtils';
import ExternalLink from '../../../components/Icons/ExternalLinkIcon';
import LinkGreyIcon from 'src/components/Icons/LinkGreyIcon';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Controller, useForm } from 'react-hook-form';
import AddEditTextField from 'src/components/AddEditTextField/AddEditTextField';
import CopyToClipboard from 'react-copy-to-clipboard';
import ApproveGreyIcon from 'src/components/Icons/ApproveGreyIcon';
import ApproveGreenIcon from 'src/components/Icons/ApproveGreenIcon';
import overflowText from 'src/components/css/MyChallengeLogsStyle/OverflowText';
import NotificationDot from 'src/components/Notification/NotificationDot';

interface TeamRowProps {
  teamInvitationLink: string;
  teamName: string;
  role: 'MEMBER' | 'ADMIN';
  teamId: number;
  isLastItem: boolean;
  licenceValidTo: Date | null;
  unApproved_authuser_id: number;
  auto_approve: boolean;
  anyUserWaitingForApproval: boolean;
  loadData: () => void;
}
interface CreateChallengeLogsForm {
  link: string;
}
const SwalEval = withReactContent(Swal);
const TeamRow: React.FC<TeamRowProps> = ({
  licenceValidTo,
  role,
  teamName,
  isLastItem,
  teamInvitationLink,
  teamId,
  auto_approve,
  unApproved_authuser_id,
  anyUserWaitingForApproval,
  loadData,
}) => {
  const cTeamId = useTeam();
  const { showToastHandler } = useToast();
  const Client = useApiClient();
  const form = useForm<CreateChallengeLogsForm>();
  const { control } = form;
  const { t } = useTranslation(['home', 'main']);
  const leaveTeam = (): void => {
    try {
      swal
        .fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Do you really want to leave team')} "${teamName}"?` + '</h5>',
          showCancelButton: true,
          confirmButtonText: `${t('YES')}`,
          cancelButtonText: `${t('CANCEL')}`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            Client.post('/team/leaveTeam', { team_id: teamId });
            loadData();
            showToastHandler(`${t('Leave the team successfully')}`, 'success');
          }
        });
    } catch (exc) {
      console.error(exc);
    }
  };

  const changeAutoApprove = async (teamId: number, auto_approve: boolean): Promise<void> => {
    await Client.post('/team/autoApprove', { team_id: teamId, auto_approve: !auto_approve });
    loadData();
  };

  const link = (teamInvitationLink?: string): void => {
    {
      SwalEval.fire({
        html: (
          <div className='flex flex-col '>
            <div className='h-fit w-full '>
              {!licenceValidTo || !checkLicenceIsInvalid(licenceValidTo) ? (
                <div className={` m-3 px-2 whitespace-nowrap flex `}>
                  <Controller
                    control={control}
                    name='link'
                    defaultValue={teamInvitationLink}
                    render={({ field }) => <AddEditTextField {...field} />}
                  />
                </div>
              ) : (
                <div className={` m-1 w-[210px] whitespace-nowrap  `}>{`${t('You don`t have licence')}`}</div>
              )}
            </div>
            <div className='h-fit my-5 w-full flex-row flex items-center justify-center overflow-hidden '>
              <CopyToClipboard
                text={!licenceValidTo || !checkLicenceIsInvalid(licenceValidTo) ? `${teamInvitationLink}` : ''}
              >
                <button
                  onClick={() => {
                    (!checkLicenceIsInvalid(licenceValidTo) || !licenceValidTo) &&
                      showToastHandler(`${t('You copy the link successfully')}`, 'success'),
                      swal.close();
                  }}
                  className='bg-lightGreen  text-white text-sm font-medium w-[100px] h-[34px] rounded-[8px] flex items-center justify-center'
                >
                  {!licenceValidTo || !checkLicenceIsInvalid(licenceValidTo) ? `${t('COPY')}` : `${t('YES')}`}
                </button>
              </CopyToClipboard>
              <button
                onClick={() => swal.close()}
                className='bg-errorRed text-white text-sm font-medium w-[100px] h-[34px] rounded-[8px] flex items-center justify-center ml-4 mr-2'
              >{`${t('CANCEL')}`}</button>
            </div>
          </div>
        ),
        title: `${t('INVITATION LINK')}`,
        showCancelButton: false,
        showConfirmButton: false,
        buttonsStyling: false,
      });
    }
  };

  return (
    <div
      className={`${rowTransition} relative desktop:h-20 2xl:h-20 xl:h-20 lg:h-20 tablet:h-20 md:h-16 sm:h-16 h-16 flex flex-row justify-center items-center`}
    >
      <div
        className={`${activityLogStyle} ${isLastItem ? 'rounded-bl-lg ' : ''} ${
          unApproved_authuser_id && 'text-gray '
        }`}
      >
        <div className={overflowText}>{teamName}</div>
      </div>
      <div className={`${activityLogStyle} ${unApproved_authuser_id && 'text-gray '}`}>{role}</div>
      <div className={activityLogStyle}>
        <span
          className={`${
            unApproved_authuser_id
              ? 'text-gray '
              : licenceValidTo
              ? checkLicenceIsInvalid(licenceValidTo)
                ? 'text-primary'
                : 'text-darkGreenHoverButton'
              : 'text-text font-semibold'
          }`}
        >
          {licenceValidTo ? licenceValidTo.toString() : `${t('TRIAL VERSION')}`}
        </span>
      </div>
      <div className='text-text flex justify-center w-full h-full items-center '>
        <button
          className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit`}
          onClick={() => {
            !unApproved_authuser_id && link(teamInvitationLink);
          }}
        >
          {(!licenceValidTo || !checkLicenceIsInvalid(licenceValidTo)) && !unApproved_authuser_id ? (
            <ExternalLink />
          ) : (
            <LinkGreyIcon />
          )}
          {!isLastItem ? (
            <LeftSideBottomIconTooltip tooltipContent={t('INVITATION LINK')} />
          ) : (
            <LeftSideTopIconTooltip tooltipContent={t('INVITATION LINK')} />
          )}
        </button>
      </div>
      <div className='text-text flex justify-center w-full h-full items-center '>
        <button
          className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit`}
          onClick={() => {
            role === 'ADMIN' && !unApproved_authuser_id ? changeAutoApprove(teamId, auto_approve) : null;
          }}
        >
          {(!licenceValidTo || !checkLicenceIsInvalid(licenceValidTo)) &&
          !unApproved_authuser_id &&
          role === 'ADMIN' ? (
            auto_approve ? (
              <ApproveGreyIcon />
            ) : (
              <ApproveGreenIcon />
            )
          ) : auto_approve ? (
            <ApproveGreyIcon />
          ) : (
            <ApproveGreenIcon />
          )}
          {!isLastItem ? (
            <LeftSideBottomIconTooltip tooltipContent={auto_approve ? t('AUTO APPROVAL') : t('ADMIN APPROVAL')} />
          ) : (
            <LeftSideTopIconTooltip tooltipContent={t('ADMIN APPROVAL')} />
          )}
        </button>
      </div>
      <div className='text-text flex justify-center w-full h-full items-center relative'>
        {role === 'ADMIN' && (
          <button
            className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit`}
            onClick={() => {
              cTeamId.setTeamId(teamId);
            }}
          >
            <DetailIcon />
            <div className='w-full h-full absolute flex bottom-0 left-1 justify-end'>
              {anyUserWaitingForApproval && <NotificationDot />}
            </div>
            {!isLastItem ? (
              <LeftSideBottomIconTooltip tooltipContent={t('DETAIL')} />
            ) : (
              <LeftSideTopIconTooltip tooltipContent={t('DETAIL')} />
            )}
          </button>
        )}

        {role === 'MEMBER' && (
          <button
            className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit`}
            onClick={() => leaveTeam()}
          >
            {!isLastItem ? (
              <LeftSideBottomIconTooltip tooltipContent={t('LEAVE TEAM')} />
            ) : (
              <LeftSideTopIconTooltip tooltipContent={t('LEAVE TEAM')} />
            )}
            <LeaveTeamIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default TeamRow;
