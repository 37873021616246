import React from 'react';

const ShowEyeIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.32259 11.447C2.81059 6.976 7.02759 3.75 12.0006 3.75C16.9706 3.75 21.1856 6.973 22.6756 11.44C22.7956 11.802 22.7956 12.192 22.6756 12.553C21.1886 17.024 16.9706 20.25 11.9986 20.25C7.02859 20.25 2.81259 17.027 1.32359 12.56C1.20334 12.1987 1.20334 11.8083 1.32359 11.447H1.32259ZM17.2496 12C17.2496 13.3924 16.6965 14.7277 15.7119 15.7123C14.7273 16.6969 13.392 17.25 11.9996 17.25C10.6072 17.25 9.27184 16.6969 8.28728 15.7123C7.30271 14.7277 6.74959 13.3924 6.74959 12C6.74959 10.6076 7.30271 9.27226 8.28728 8.28769C9.27184 7.30312 10.6072 6.75 11.9996 6.75C13.392 6.75 14.7273 7.30312 15.7119 8.28769C16.6965 9.27226 17.2496 10.6076 17.2496 12V12Z'
      fill='#01272E'
    />
  </svg>
);

export default ShowEyeIcon;
