import { createRef, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DatePicker from 'src/components/DatePicker';
import TextField from 'src/components/Textfield';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import addDays from 'date-fns/addDays';
import swal from 'sweetalert2';
import Button from 'src/components/Button';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import dateSize from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/DateSize';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import { useTranslation } from 'react-i18next';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import buttonStyleNewChallenge from 'src/pages/NewChallengePage/NewChallengeButtonStyle/ButtonStyle';
import glowField from 'src/components/css/GlowField/GlowField';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import { useApiClient } from 'src/api/client';
import { AxiosError } from 'axios';
import ActiveCheckBox from 'src/components/Icons/ActiveCheckBox';
import InactiveCheckbox from 'src/components/Icons/InactiveCheckbox';
import errorGlowField from 'src/components/css/GlowField/ErrorGlowField';
import roundingFields from 'src/components/RoundingOfAllForms/RoundingFields';
import { PaymentDetailProvider } from 'src/store/paymentContext';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import Payment from 'src/components/Payment/Payment';
import { useToast } from 'src/store/toastContext';
export interface ChallengeTarget {
  id?: number;
  targetName: string;
  targetValue: number;
  targetUnit: string;
}

export interface NewChallengeForm {
  teamName: string;
  challengeName: string;
  dateFrom: Date;
  dateTo: Date;
  deadlineDate: Date;
  targets: ChallengeTarget[];
  emails: string;
}
interface IButtonHandleProps {
  editChallenge: () => void;
  loadData: () => void;
}
const EditChallengeCard = ({ editChallenge, loadData }: IButtonHandleProps): JSX.Element => {
  const { t } = useTranslation(['home', 'main']);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<NewChallengeForm>();

  const { showToastHandler } = useToast();
  const challengeData = useChallengeData();
  const [dateFrom, setDateFrom] = useState<Date>(challengeData.dateFrom || new Date());
  const [dateTo, setDateTo] = useState<Date>(challengeData.dateTo || addDays(new Date(), 14));
  const [dateError, setDateError] = useState(false);
  const [dateToError, setDateToError] = useState(false);
  const [deadlineDateError, setDeadlineDateError] = useState(false);
  const [errorMsgDateFrom, setErrorMsgDateFrom] = useState('');
  const [dateToErrMsg, setDateToErrMsg] = useState('');
  const [deadlineErrMsg, setDeadlineErrMsg] = useState('');
  const autocompleteRef = createRef<HTMLInputElement>();
  const Client = useApiClient();

  useEffect(() => {
    setDeadlineDateError(false);
    setDateToError(false);
    setDateError(false);
    setErrorMsgDateFrom('');
    setDateToErrMsg('');
    setDeadlineErrMsg('');

    if (!challengeData.checkBoxDeadlineDate) {
      if (challengeData.deadLineDate < dateFrom || challengeData.deadLineDate < dateTo) {
        setDeadlineErrMsg(`${t('Deadline date to be after end date!')}`);
        setDeadlineDateError(true);
      }
      if (dateFrom > challengeData.deadLineDate) {
        setDeadlineErrMsg(`${t('Deadline date is before than start date!')}`);
        setDeadlineDateError(true);
      }
      if (dateTo > challengeData.deadLineDate) {
        setDateToErrMsg(`${t('Тhe last date is after then deadline!')}`);
        setDateToError(true);
      }
    }
    if (dateFrom > dateTo) {
      setErrorMsgDateFrom(`${t('Start date is after than end date!')}`);
      setDateError(true);
    }
  }, [challengeData.deadLineDate, dateTo, dateFrom, challengeData.checkBoxDeadlineDate]);

  const activeDeadlineDate = async (): Promise<void> => {
    challengeData.setCheckBoxDeadlineDate(!challengeData.checkBoxDeadlineDate);
  };

  const onSubmit: SubmitHandler<NewChallengeForm> = async (data) => {
    if ((dateError || dateToError || deadlineDateError) && !challengeData.checkBoxDeadlineDate) {
      return;
    }

    if (!data.challengeName) {
      return;
    }

    if (dateTo > challengeData.deadLineDate && !challengeData.checkBoxDeadlineDate) {
      swal.fire({
        title: `${t("The option to insert 'DEADLINE' is invalid")}`,
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (dateFrom > dateTo) {
      swal.fire({
        title: `${'Cannot insert this date'}`,
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    challengeData.setDeadLineDate(challengeData.checkBoxDeadlineDate ? addDays(dateTo, 1) : challengeData.deadLineDate);
    try {
      const result = await Client.post('/challenge/edit', {
        challenge_id: challengeData.challengeId,
        team_id: challengeData.teamId,
        team_name: challengeData.teamName,
        challenge_name: data.challengeName,
        date_from: dateFrom,
        date_to: dateTo,
        deadline_Date: challengeData.checkBoxDeadlineDate ? addDays(dateTo, 1) : challengeData.deadLineDate,
        chall_insert_log_days_after_activity: challengeData.checkBoxDeadlineDate,
        emails: challengeData.emails.toString(),
        targets: challengeData.challengeTargets,
        confirmUserLicence: challengeData.confirmUserLicence,
      });
      reset();

      challengeData.setChallengeTargets([]);
      if (result.status === 200) {
        challengeData.clearAllData();
        editChallenge();
      }
    } catch (exc) {
      const err = exc as AxiosError;
      if (err?.response?.status === 403) {
        showToastHandler(t('You don`t have licence'), 'error');
        autocompleteRef.current?.focus();
      }
      openModal();
    } finally {
      loadData();
    }

    challengeData.setTeamName(data.teamName);
    challengeData.setChallengeName(data.challengeName);
  };

  challengeData.setDateFrom(dateFrom);
  challengeData.setDateTo(dateTo);
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };
  return (
    <div
      className={`w-full lg:h-[80vh] phone:h-[90vh] h-[90vh] flex  flex-col justify-center items-center lg:px-10 phone:px-5 px-5`}
    >
      <div className={`${titleStyle}  ${mobileTitleStyle} uppercase `}>{t('Edit challenge')}</div>
      <div
        className={`lg:mt-3 phone:mt-10 mt-10 shadow-2xl lg:shadow-none flex flex-col w-full h-full overflow-hidden  justify-center ${padding} ${roundingForm} `}
      >
        <div className='h-full flex  flex-col lg:mb-0  lg:justify-between lg:px-0 phone:px-9 px-7 lg:py-0 phone:py-5 py-5   '>
          <form onSubmit={handleSubmit(onSubmit)} className='h-full flex flex-col  lg:space-y-5 space-y-0 '>
            <div
              className={`h-full lg:space-y-4 2xl:space-y-10  space-y-4 phone:space-y-4 ${padding} lg:h-5/6 tablet:h-full flex flex-col lg:justify-center phone:justify-start rounded-[1.125rem]  w-full items-center `}
            >
              <div className='flex lg:w-9/12 w-full h-fit flex-col'>
                <div className='flex flex-col w-full h-fit items-center justify-center'>
                  <div className='w-full h-full flex flex-col justify-center items-center'>
                    <div className='pl-2 h-fit w-full flex justify-start items-start 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px] text-DarkPrimary'>
                      {t('TEAM')}:
                    </div>
                    <div className={`${glowField} bg-lightGrayBg text-DarkPrimary`}>{challengeData.teamName}</div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col lg:w-9/12 w-full h-fit justify-center items-center'>
                <div
                  className={`pl-2 h-fit w-full flex justify-start items-start 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px] ${
                    errors.challengeName?.message ? 'text-errorRed' : 'text-DarkPrimary'
                  }`}
                >
                  {t('CHALLENGE NAME')}:
                </div>
                <div className='w-full flex flex-row h-fit justify-start items-start'>
                  <Controller
                    control={control}
                    name='challengeName'
                    defaultValue={challengeData.challengeName}
                    rules={{ required: { value: true, message: `${t('The challenge field is empty!')}` } }}
                    render={({ field }) => (
                      <>
                        <TextField {...field} error={errors.challengeName?.message} />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className='flex flex-col lg:w-9/12 w-full '>
                <div className='flex  lg:space-y-0 space-y-4 phone:space-y-4 lg:flex-row flex-col  w-full justify-between h-fit'>
                  <div className={dateSize}>
                    <div className={`${newChallengeTitleStyle} ${dateError ? 'text-errorRed' : 'text-DarkPrimary'}`}>
                      {t('DATE FROM')}:
                    </div>
                    <div className='w-full flex flex-row h-fit'>
                      <Controller
                        control={control}
                        defaultValue={dateFrom}
                        name='dateFrom'
                        rules={{ required: { value: false, message: `${t('ENTER DATE FROM!')}` } }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              value={dateFrom}
                              error={errorMsgDateFrom}
                              onChange={(date) => {
                                if (date) {
                                  setDateFrom(date);
                                }
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    {dateError ? (
                      <span className='w-full h-fit flex justify-center text-errorRed text-base italic'>
                        {errorMsgDateFrom}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={dateSize}>
                    <div className={`${newChallengeTitleStyle} ${dateToError ? 'text-errorRed' : 'text-DarkPrimary'}`}>
                      {t('DATE TO')}:
                    </div>
                    <div className='w-full flex flex-col h-fit'>
                      <Controller
                        control={control}
                        defaultValue={challengeData.dateTo || addDays(new Date(), 14)}
                        name='dateTo'
                        rules={{ required: { value: false, message: `${t('ENTER DATE TO!')}` } }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              value={dateTo}
                              error={dateToErrMsg}
                              onChange={(date) => {
                                if (date) {
                                  setDateTo(date);
                                  challengeData.setDeadLineDate(addDays(date, 2));
                                }
                              }}
                            />
                          </>
                        )}
                      />
                      {dateToError ? (
                        <span className='w-full h-fit flex justify-center text-errorRed text-xs italic'>
                          {dateToErrMsg}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className={dateSize}>
                    <div
                      className={`${newChallengeTitleStyle} ${
                        deadlineDateError ? 'text-errorRed' : 'text-DarkPrimary'
                      }`}
                    >
                      {t('DEADLINE')}:
                    </div>
                    <div className='w-full flex flex-col h-fit'>
                      <Controller
                        control={control}
                        defaultValue={
                          challengeData.checkBoxDeadlineDate
                            ? addDays(dateTo, 1)
                            : challengeData.deadLineDate || addDays(new Date(), 14)
                        }
                        name='deadlineDate'
                        rules={{ required: { value: false, message: `${t('ENTER DEADLINE!')}` } }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              value={
                                challengeData.checkBoxDeadlineDate ? addDays(dateTo, 1) : challengeData.deadLineDate
                              }
                              className={`${deadlineErrMsg ? errorGlowField : glowField} ${
                                challengeData.checkBoxDeadlineDate ? 'text-gray' : 'text-DarkPrimary'
                              }  ${roundingFields}`}
                              error={deadlineErrMsg}
                              disabled={challengeData.checkBoxDeadlineDate}
                              onChange={(date) => {
                                if (date) {
                                  challengeData.setDeadLineDate(date);
                                }
                              }}
                            />
                          </>
                        )}
                      />
                      {deadlineDateError ? (
                        <span className='w-full h-fit flex justify-center text-errorRed text-xs italic'>
                          {deadlineErrMsg}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className='w-full h-fit flex justify-end flex-col items-center'>
                  <div
                    className='flex flex-row lg:w-full w-full h-fit justify-start items-center pt-2'
                    onClick={activeDeadlineDate}
                  >
                    <div className='w-fit mr-1 h-fit flex justify-end items-center lg:justify-start lg:items-center'>
                      <span className='hover:ring-lightBlueButton hover:shadow-lightBlueButton hover:shadow-[0_0_15px_0.1px_rgba(0,0,0,0.3)] w-6 h-6 rounded-md'>
                        {challengeData.checkBoxDeadlineDate ? <ActiveCheckBox /> : <InactiveCheckbox />}
                      </span>
                    </div>
                    <div className=' tablet:justify-end tablet:items-center md:justify-end md:items-center lg:justify-start lg:items-start lg:w-full text-DarkPrimary w-fit flex justify-end items-center 2xl:text-base xl:text-base lg:text-[14px] tablet:text-[10px] md:text-[13px] phone:text-[12px] text-[11px]'>
                      {t('Allow to add log only one day after activity latest')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' flex flex-col 2xl:h-fit lg:h-fit lg:w-1/2 w-full text-center justify-end items-center m-auto mt-2'>
              <Button className={buttonStyleNewChallenge} datatype={'submit'}>
                {t('CONFIRM')}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <PaymentDetailProvider>
        <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
          <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
            <button
              type='button'
              className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
              onClick={closeModal}
            >
              x
            </button>
          </Dialog.Title>
          <Payment
            closeModal={closeModal}
            teamId={challengeData.teamId}
            teamName={challengeData.teamName}
            paymentInstructions={false}
          />
        </ModalFromLibrary>
      </PaymentDetailProvider>
    </div>
  );
};
export default EditChallengeCard;
