import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { Fragment, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from 'src/api/client';
import BlueWavesBG from 'src/components/BlueWavesBGimg/BlueWavesBG';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import PencilIcon from 'src/components/Icons/PencilIcon';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import Select from 'src/components/Select';
import SidePanel, { SidePanelRoutes } from 'src/components/SidePanel';
import { Spinner } from 'src/utils/Spinner';
import ChallengeEvaluationRow from './ChallengeEvaluationRow/ChallengeEvaluationRow';
import FavoriteEvaluationRow from './ChallengeEvaluationRow/FavoriteEvaluationRow';
import colorsForGraph from './ChallengeLogRow/Colors/ColorForGraphs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import SelectButtonFavorites from 'src/components/FavoritesUsersSelect/FavoritesUsersSelect';
import { myChallengeData } from 'src/store/myChallengePageContex';
import ChallengeLogRowMobileVersion from './ChallengeLogRow/ChallengeLogRowMobileVersion';
import { Accordion } from '@material-tailwind/react';
import Arrow from 'src/components/Icons/Arrow';
import { iconTransition } from '../NewChallengePage/NewChallengeButtonStyle/IconTransition';
import RightSideBottomIconTooltip from 'src/utils/RightSideBottomIconTooltipUtils';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import MissingChallenge from 'src/components/MissingChallenge/MissingChallenge';
import HeaderChallengeLogRow from './HeaderChallengeLogRow';
import HeaderFavoriteUsers from './HeaderFavoriteUsers';
import HeaderUsers from './HeaderUsers';
import InformationIcon from 'src/components/Icons/InformationIcon';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import ChallengeInfoModal from 'src/components/ChallengeInfoModal/ChallengeInfoModal';

const ChallengeLogRow = React.lazy(() => import('./ChallengeLogRow/ChallengeLogRow'));

export interface IEvaluation {
  user_id: number;
  isAdmin: boolean;
  user_email: string;
  team_id: number;
  cht_challenge_id: number;
  user_name: string;
  user_avatar: string;
  loged_user_id: number;
  completeAvg: number;
  favorit_user_id: number;
  targets: {
    percent: number;
    target_id: number;
    target_name: string;
  }[];
}
export interface IFavoriteUser {
  user_name: string;
  user_email: string;
  user_id: number;
  favoriteUserId: number;
  teamId: number;
  challengeId: number;
  isLastItem: boolean;
  avatar: string;
}

export interface IRewardUser {
  user_name: string;
  user_email: string;
  user_id: number;
  rewardUserId: number;
  teamId: number;
  challengeId: number;
  isLastItem: boolean;
  avatar: string;
}
export interface IUsersAvatar {
  avatar: string;
  userId: number;
}
export interface IChallengeLog {
  user_name: string;
  user_id: number;

  user_email: string;
  chl_date: Date;
  cht_name: string;
  chl_value: number;
  cht_unit: string;
  chl_link: string | null;
  chl_img: string | null;
  chl_feedback: 'positive' | 'negative';
  chl_id: number;
}

interface ILog {
  labels: { user_id: number; user_email: string; user_name: string }[];
  values: number[];
  target_name: string;
  target_id: number;
}
export interface FavoriteUsers {
  userId: number;
  favoriteUserId: number;
  teamId: number;
  challengeId: number;
}
export interface IChallenge {
  role: 'MEMBER' | 'ADMIN';
  id: number;
  name: string;
  challengeDescription: string | undefined;
  insertLogDayAfterActivity: string | undefined;
}
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
function MyChallengesPage(): React.ReactElement {
  const [loading, setLoading] = useState(true);
  const [loadingEvaluete, setLoadingEvaluete] = useState(true);
  const [logs, setLogs] = useState<IChallengeLog[]>([]);
  const [logsLoading, setLogsLoading] = useState(true);
  const [graphData, setGraphData] = useState<ChartData<'bar'>>({ datasets: [], labels: [] });
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [avatars, setAvatars] = useState<IUsersAvatar[]>([]);
  const [logsAtScroll, setShowPerPage] = useState(15);
  const [open, setOpen] = useState(0);
  const [isHasMoreData, setIsHasMoreData] = useState(true);
  const [chllngDescription, setChallengeDescription] = useState<string | undefined>('');
  const [insertLogDaysAfterActivity, setInsertLogDaysAfterActivity] = useState<string | undefined>('');
  const navigate = useNavigate();
  const Client = useApiClient();
  const myChallengeContext = myChallengeData();
  const { t } = useTranslation(['home', 'main']);

  const handleOpen = (value: number): void => {
    setOpen(open !== value ? value : 0);
  };
  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  useEffect(() => {
    Client.get('/evaluation/userChallenges')
      .then((response) => {
        const uch = response.data.challenges as IChallenge[];
        myChallengeContext.setSelect(1);
        myChallengeContext.setSelectedChallengeId(uch.length > 0 ? uch[0].id : 0);
        myChallengeContext.setUserChallenges(uch);
        setLoading(false);
      })
      .catch(() => {
        console.error('Failed fetching challenges');
      });
  }, []);

  useEffect(() => {
    if (myChallengeContext.selectedChallengeId === 0) return;
    if (myChallengeContext.select === 1) {
      Client.get('/evaluation/evaluation/' + myChallengeContext.selectedChallengeId)
        .then((response) => {
          myChallengeContext.setEvaluationRows(response.data.rows);
          setLoading(false);
          setLoadingEvaluete(false);
          myChallengeContext.setSort(false);
        })
        .catch((error) => {
          console.log(error.message);
        });

      Client.get('evaluation/reward/' + myChallengeContext.selectedChallengeId)
        .then((response) => {
          myChallengeContext.setRewardUsers(response.data.rewardUser);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    Client.get('evaluation/favorite/' + myChallengeContext.selectedChallengeId)
      .then((response) => {
        myChallengeContext.setFavoriteUsers(response.data.favoriteUser);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [myChallengeContext.select, myChallengeContext.selectedChallengeId]);

  useEffect(() => {
    if (myChallengeContext.selectedChallengeId === 0) return;
    const chllnInfo = myChallengeContext.userChallenges.find((x) => x.id == myChallengeContext.selectedChallengeId);
    setChallengeDescription(chllnInfo?.challengeDescription);
    setInsertLogDaysAfterActivity(chllnInfo?.insertLogDayAfterActivity);
    Client.get('/evaluation/challenge/' + myChallengeContext.selectedChallengeId)
      .then((response) => {
        const randomNum = (): number => Math.floor(Math.random() * (235 - 52 + 1) + 52);
        const randomRGB = (): string => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
        let nextColor = 0;
        const graphLogsInfo = response.data.logs;
        if (Array.isArray(graphLogsInfo)) {
          const data: ChartData<'bar'> = {
            labels: (graphLogsInfo as ILog[])[0].labels
              .filter((favUser) => {
                if (myChallengeContext.select === 2 && myChallengeContext.favoriteUsers.length) {
                  const result = myChallengeContext.favoriteUsers.filter((b) => {
                    return b.favoriteUserId === favUser.user_id;
                  });
                  if (result.length) {
                    return result;
                  }
                } else {
                  return favUser;
                }
              })
              .map((l) => {
                return l.user_name ? l.user_name : l.user_email;
              }),
            datasets: (graphLogsInfo as ILog[]).map((gd) => {
              const currentBar = {
                label: gd.target_name,
                data: gd.values,
                categoryPercentage: 0.9,
                barPercentage: 1,
                backgroundColor: colorsForGraph[nextColor],
                minBarLength: 2,
                borderRadius: 10,
                hoverBackgroundColor: randomRGB(),
              };
              if (nextColor >= colorsForGraph.length - 1) {
                nextColor = 0;
              } else {
                nextColor++;
              }
              return currentBar;
            }),
          };
          setGraphData(data);
        }
      })
      .catch(() => {
        console.error('Failed fetching evaluation graph');
      });
  }, [myChallengeContext.select, myChallengeContext.favoriteUsers, myChallengeContext.selectedChallengeId]);

  const options: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        maxHeight: 30,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
        },
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label(this, tooltipItems) {
            return `${tooltipItems.dataset.label}: ${tooltipItems.raw}%`;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (myChallengeContext.selectedChallengeId === 0) return;
    const c = myChallengeContext.userChallenges.find((uc) => uc.id === myChallengeContext.selectedChallengeId);
    if (c) setIsAdmin(c.role === 'ADMIN');
    setLoading(false);
    myChallengeContext.setSelect(1);
    Client.get('/challenge/loadAvatars')
      .then((response) => {
        setAvatars(response.data.usersAvatar);
      })
      .catch((error) => {
        console.log(error.message);
      });

    Client.get(`/evaluation/logs/${myChallengeContext.selectedChallengeId}/${logsAtScroll}/${0}`)
      .then((response) => {
        setLogs(response.data.logs);
        setLogsLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [myChallengeContext.selectedChallengeId]);

  const changeLog = (feedback: IChallengeLog['chl_feedback'], chl_id: number): void => {
    const to = { chl_feedback: feedback };

    const temp = logs.map((t) => (t.chl_id === chl_id ? { ...t, ...to } : t));
    setLogs(temp);
  };

  const fetchMoreData = async (): Promise<void> => {
    const skippedLogs = logs.length;
    const response = await Client.get(
      `/evaluation/logs/${myChallengeContext.selectedChallengeId}/${logsAtScroll}/${skippedLogs}`,
    );

    const data = response.data.logs;
    if (data.length !== 0) {
      setShowPerPage(15);
      setIsHasMoreData(true);
      setTimeout(async () => {
        data.forEach((element: IChallengeLog) => {
          setLogs((logs) => [...logs, element]);
        });
      }, 300);
    } else {
      setIsHasMoreData(false);
    }
  };

  useEffect(() => {
    const userExist = myChallengeContext.evaluationRows.find((user) => user.loged_user_id === user.user_id);
    const userIsAdminExist = myChallengeContext.evaluationRows.find(
      (user) => user.loged_user_id === user.user_id && user.isAdmin,
    );

    const userExistFav = myChallengeContext.favoriteUsers.find((user) => user.favoriteUserId === user.user_id);

    const favoriteUserAdd = {
      favUserId: userExist?.user_id,
      chlngId: userExist?.cht_challenge_id,
      teamId: userExist?.team_id,
    };

    const favoriteUserDel = {
      favUserId: userExistFav?.user_id,
      chlngId: userExistFav?.challengeId,
      teamId: userExistFav?.teamId,
    };

    if (userIsAdminExist) {
      myChallengeContext.setIsAdmin(true);
    } else {
      myChallengeContext.setIsAdmin(false);
    }

    if (userExist && !userExistFav) {
      Client.post('/user/addFavorite', favoriteUserAdd);
    } else if (!userExist && userExistFav) {
      Client.delete('/user/removeFavorite', { data: favoriteUserDel });
    }
  }, [myChallengeContext.evaluationRows]);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };
  return (
    <div className='flex z-0 bg-gradient-to-r from-lightBlue to-darkBlue'>
      <BlueWavesBG />
      <div className='z-10'>
        <SidePanel activeRoute={SidePanelRoutes.MY_CHALLENGES} />
      </div>
      <MobileNav />
      <div className='flex-col h-[100vh] pt-14  w-full flex z-20'>
        <div
          className={`z-20 h-full w-[100%] pb-6 flex flex-col  rounded-lg overflow-y-auto  overflow-x-hidden desktop:mr-2 tablet:mr-2 px-2 `}
        >
          <div className={`w-full h-full flex flex-col gap-2`}>
            <div className={`${titleStyle} ${mobileTitleStyle} pb-10`}>{t('My challenges')}</div>
            <div className={`flex-col w-full overflow-hidden ${roundingForm}`}>
              <div className='flex w-full items-centerjustify-between '>
                <div className='justify-between  desktop:pl-6 2xl:pl-0 xl:pl-0 lg:pl-0 md:pl-0 tablet:pl-6 items-center desktop:mt-4 tablet:mt-4 phone:mt-4 mt-4 w-11/12 text-text  h-full font-normal flex'>
                  <div className='w-full flex flex-row items-center justify-start'>
                    <p className='whitespace-nowrap 2xl:px-4 desktop:px-4 md:px-4 tablet:px-4  sm:px-4 pr-2'>
                      {t('CHOICE OF CHALLENGE')}
                    </p>
                    <div className='lg:w-3/12 phone:w-1/2 w-1/2'>
                      <Select
                        value={myChallengeContext.selectedChallengeId}
                        onChange={(e) => myChallengeContext.setSelectedChallengeId(parseInt(e.target.value))}
                      >
                        {myChallengeContext.userChallenges.map((ch) => (
                          <option key={ch.id} value={ch.id}>
                            {ch.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                    {isAdmin && (
                      <button
                        className={`mx-3 group desktop:scale-100 tablet:scale-100 scale-75 ${iconTransition}`}
                        onClick={() => navigate('/editChallenge/' + myChallengeContext.selectedChallengeId)}
                      >
                        <PencilIcon />
                        <RightSideBottomIconTooltip tooltipContent={t('Edit challenge')} />
                      </button>
                    )}
                  </div>

                  <div
                    className={`w-fit justify-end items-end group h-fit relative desktop:scale-100 tablet:scale-100 scale-75 ${iconTransition}`}
                    onClick={openModal}
                  >
                    <InformationIcon />
                    <LeftSideBottomIconTooltip tooltipContent={`${t('CHALLENGE INFO')}`} />
                  </div>
                </div>
              </div>
              <table className='md:w-full tablet:w-full flex flex-col justify-start my-10'>
                <HeaderChallengeLogRow />
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      {myChallengeContext.userChallenges.length === 0 ? (
                        <MissingChallenge />
                      ) : (
                        <>
                          {logsLoading ? (
                            <Spinner />
                          ) : (
                            <>
                              {logs.length < 1 ? (
                                <div className='text-center flex h-full justify-center items-center lg:text-[14px] text-[10px]'>
                                  {logs.length === 0 ? t('No data entered yet!') : <Spinner />}
                                </div>
                              ) : (
                                <>
                                  <InfiniteScroll
                                    dataLength={logs.length}
                                    loader={<Spinner />}
                                    next={fetchMoreData}
                                    hasMore={isHasMoreData}
                                    height={'60vh'}
                                    endMessage={
                                      <p className='text-center'>
                                        <b className='text-gray 2xl:text-xl lg:text-sm xl:text-sm select-none'>
                                          You have seen it all...
                                        </b>
                                      </p>
                                    }
                                  >
                                    {logs.map((log, index) => {
                                      return window.innerWidth <= 820 ? (
                                        <>
                                          <Fragment key={log.cht_name + index}>
                                            <Accordion
                                              className='icon-2xl  '
                                              open={open === log.chl_id}
                                              animate={customAnimation}
                                              onClick={() => handleOpen(log.chl_id)}
                                              icon={<Arrow id={log.chl_id} open={open} />}
                                            >
                                              <ChallengeLogRowMobileVersion
                                                changeLog={changeLog}
                                                usersAvatar={avatars}
                                                key={log.cht_name + index}
                                                {...log}
                                                index={index}
                                                isLastItem={logs.length - 1 === index}
                                              />
                                            </Accordion>
                                          </Fragment>
                                        </>
                                      ) : (
                                        <>
                                          <ChallengeLogRow
                                            changeLog={changeLog}
                                            usersAvatar={avatars}
                                            key={log.cht_name + index}
                                            {...log}
                                            index={index}
                                            isLastItem={logs.length - 1 === index}
                                          />
                                        </>
                                      );
                                    })}
                                  </InfiniteScroll>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </table>
            </div>
          </div>

          <div className='h-full py-5 flex flex-col gap-2'>
            <div className='flex flex-row w-full'>
              <div className={`${titleStyle} flex flex-row w-full my-auto justify-start`}>
                {t('Evaluation of the challenge')}
              </div>
              <div className='flex flex-row w-full justify-end items-end phone:flex lg:hidden '>
                <SelectButtonFavorites />
              </div>
            </div>
            <table className={`flex flex-col h-[60vh] overflow-y-auto ${roundingForm}`}>
              {myChallengeContext.select === 1 ? <HeaderUsers /> : <HeaderFavoriteUsers />}
              <>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {myChallengeContext.userChallenges.length === 0 ? (
                      <MissingChallenge />
                    ) : (
                      <>
                        {logsLoading || loadingEvaluete ? (
                          <Spinner />
                        ) : (
                          <>
                            {logs.length < 1 ? (
                              <div className='text-center flex h-full justify-center items-center lg:text-[14px] text-[10px]'>
                                {logs.length === 0 ? t('No data entered yet!') : <Spinner />}
                              </div>
                            ) : (
                              <>
                                {myChallengeContext.select === 1 ? (
                                  myChallengeContext.evaluationRows.map((row, index) => {
                                    return (
                                      <ChallengeEvaluationRow
                                        key={row.user_id + '-' + index}
                                        {...row}
                                        index={index}
                                        isLastItem={myChallengeContext.evaluationRows.length - 1 === index}
                                      />
                                    );
                                  })
                                ) : (
                                  <>
                                    {myChallengeContext.favoriteUsers.length ? (
                                      <>
                                        {myChallengeContext.favoriteUsers.map((row, index) => {
                                          return (
                                            <FavoriteEvaluationRow
                                              key={row.user_id + '-' + index}
                                              {...row}
                                              index={index}
                                              isLastItem={myChallengeContext.favoriteUsers.length - 1 === index}
                                            />
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <p className='flex justify-center flex-col h-full items-center'>
                                        {t('No data yet. Add a user as a favorite to make something appear')}{' '}
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </table>
          </div>
          <div className={`--WIDTH h-full flex flex-col w-full rounded-2xl ${roundingForm}`}>
            <div className='h-[44vh] m-2 grid grid-cols-1'>
              <>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {myChallengeContext.userChallenges.length === 0 ? (
                      <div className='w-full flex justify-center'>
                        {' '}
                        <MissingChallenge />
                      </div>
                    ) : (
                      <>
                        {logsLoading ? (
                          <Spinner />
                        ) : (
                          <>
                            {logs.length < 1 ? (
                              <div className='text-center flex h-full justify-center items-center lg:text-[14px] text-[10px]'>
                                {logs.length === 0 ? t('No data entered yet!') : <Spinner />}
                              </div>
                            ) : (
                              <>
                                {myChallengeContext.select === 1 ? (
                                  <Bar data={graphData} options={options} />
                                ) : (
                                  <>
                                    {myChallengeContext.favoriteUsers.length ? (
                                      <Bar data={graphData} options={options} />
                                    ) : (
                                      <div className='text-center flex h-full justify-center items-center lg:text-[14px] text-[10px]'>
                                        {t('No data yet. Add a user as a favorite to make something appear')}{' '}
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
          <button
            type='button'
            className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
            onClick={closeModal}
          >
            x
          </button>
        </Dialog.Title>
        <ChallengeInfoModal
          chllngInfo={chllngDescription}
          insertLogDaysAfterActivityInfo={insertLogDaysAfterActivity}
          closeModal={closeModal}
          buttonName={`${t('CLOSE')}`}
          urlLink={undefined}
        />
      </ModalFromLibrary>
    </div>
  );
}

export default MyChallengesPage;
