import React from 'react';

const ExternalLinkIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 21 20' fill='none'>
    <path
      d='M13.6029 3.50333C14.5402 2.56608 16.0598 2.56608 16.9971 3.50333C17.9343 4.44059 17.9343 5.96019 16.9971 6.89745L13.3971 10.4974C12.4598 11.4347 10.9402 11.4347 10.0029 10.4974C9.53432 10.0288 8.77452 10.0288 8.30589 10.4974C7.83726 10.9661 7.83726 11.7259 8.30589 12.1945C10.1804 14.069 13.2196 14.069 15.0941 12.1945L18.6941 8.5945C20.5686 6.71999 20.5686 3.68079 18.6941 1.80628C16.8196 -0.0682385 13.7804 -0.0682385 11.9059 1.80628L10.1059 3.60628C9.63726 4.07491 9.63726 4.83471 10.1059 5.30333C10.5745 5.77196 11.3343 5.77196 11.8029 5.30333L13.6029 3.50333Z'
      fill='#15CDCA'
    />
    <path
      d='M7.60297 9.50333C8.54023 8.56608 10.0598 8.56608 10.9971 9.50333C11.4657 9.97196 12.2255 9.97196 12.6941 9.50333C13.1628 9.03471 13.1628 8.27491 12.6941 7.80628C10.8196 5.93176 7.78043 5.93176 5.90591 7.80628L2.30591 11.4063C0.431395 13.2808 0.431395 16.32 2.30591 18.1945C4.18043 20.069 7.21962 20.069 9.09414 18.1945L10.8941 16.3945C11.3628 15.9259 11.3628 15.1661 10.8941 14.6974C10.4255 14.2288 9.66571 14.2288 9.19708 14.6974L7.39708 16.4974C6.45982 17.4347 4.94023 17.4347 4.00297 16.4974C3.06571 15.5602 3.06571 14.0406 4.00297 13.1033L7.60297 9.50333Z'
      fill='#15CDCA'
    />
  </svg>
);

export default ExternalLinkIcon;
