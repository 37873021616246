import React, { createContext, useContext, useEffect, useState } from 'react';
import ToastMessage, { ToastType } from 'src/components/Toast/ToastMessage';

interface LogsDetailProviderProps {
  children: React.ReactNode;
}

interface ToastContextProps {
  toasts: ToastType[];
  showToastHandler: (message: string, type: string) => void;
  closeToastHandler: (index: number) => void;
}

export const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<LogsDetailProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const showToastHandler = (message: string, type: string): void => {
    const newToast: ToastType = {
      message,
      type,
    };

    setToasts((prevToasts) => [...prevToasts, newToast]);
  };

  const closeToastHandler = (index: number): void => {
    const updatedToasts = [...toasts];
    updatedToasts.splice(index, 1);
    setToasts(updatedToasts);
  };

  useEffect(() => {
    const toastTimeouts: NodeJS.Timeout[] = [];

    toasts.forEach((_, index) => {
      const timeout = setTimeout(() => {
        closeToastHandler(index);
      }, 2200);
      toastTimeouts.push(timeout);
    });

    return () => {
      toastTimeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [toasts]);

  return (
    <ToastContext.Provider
      value={{
        toasts,
        showToastHandler,
        closeToastHandler,
      }}
    >
      {children}
      {toasts.map((toast, index) => (
        <ToastMessage toasts={toasts} key={index} index={index} {...toast} />
      ))}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
