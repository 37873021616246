import BlueWavesBG from 'src/components/BlueWavesBGimg/BlueWavesBG';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import SidePanel, { SidePanelRoutes } from 'src/components/SidePanel';
import ChallengeTargetAndUserCard from './ChallengeTargetPicker';
import NewChallengeCard from './NewChallengeCard';
import './Steps/stepper.css';
import WhiteWave from 'src/components/WhiteWavesimg/WhiteWaves';
import Overview from './OverviewPage/Overview';
import AddUsers from './AddUsersPage/AddUsers';
import { useEffect, useState } from 'react';
import { ReactComponent as CheckMark } from '../../images/icons/CheckMark.svg';
import Description from './DescriptionPage/description';
import { useTranslation } from 'react-i18next';
import { useChallengeData } from 'src/store/challengeTargetsContext';
export default function NewChallengePage(): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation(['home', 'main']);

  const handleNext = (): void => {
    setCurrentStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (): void => {
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleConfirm = (): void => {
    setCurrentStep(0);
  };
  const challengeData = useChallengeData();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const newChallengeData = localStorage.getItem('data');
    const emailsData = localStorage.getItem('emails');
    const confirmLicence = localStorage.getItem('confirmLicence');
    const challengeTargets = localStorage.getItem('targets');
    const checkBoxState = localStorage.getItem('checkBoxState');
    const checkBoxDeadlineDate = localStorage.getItem('activeDeadlineDate');
    const checkBoxAutoApprove = localStorage.getItem('autoApprove');
    if (newChallengeData) {
      const { teamId, challengeName, dateFrom, dateTo, deadlineDate, teamName } = JSON.parse(newChallengeData);
      challengeData.setTeamid(teamId);
      challengeData.setChallengeName(challengeName);
      challengeData.setDateFrom(new Date(dateFrom));
      challengeData.setDateTo(new Date(dateTo));
      challengeData.setDeadLineDate(new Date(deadlineDate));
      challengeData.setTeamName(teamName);
    }
    if (confirmLicence) {
      const parsedConfirmLicence = JSON.parse(confirmLicence);
      challengeData.SetConfirmUserLicence(parsedConfirmLicence);
    }
    if (emailsData) {
      const emails = JSON.parse(emailsData);
      challengeData.setEmails(emails.emails);
    }
    if (challengeTargets) {
      const parsedTargets = JSON.parse(challengeTargets);
      challengeData.setChallengeTargets(parsedTargets);
    }
    if (checkBoxState) {
      const parsedCheckBoxState = JSON.parse(checkBoxState);
      challengeData.setCheckBoxState(parsedCheckBoxState);
    }
    if (checkBoxDeadlineDate) {
      const parsedCheckBoxDeadlineDate = JSON.parse(checkBoxDeadlineDate);
      challengeData.setCheckBoxDeadlineDate(parsedCheckBoxDeadlineDate);
    }
    if (checkBoxAutoApprove) {
      const parsedCheckBoxAutoApprove = JSON.parse(checkBoxAutoApprove);
      challengeData.setCheckBoxAutoApprove(parsedCheckBoxAutoApprove);
    }
    setLoad(true);
  }, []);
  const steps = [
    {
      label: `${t('TEAM')}`,
      content: <NewChallengeCard handleNext={handleNext} />,
    },
    {
      label: `${t('USERS')}`,
      content: <AddUsers handleBack={handleBack} handleNext={handleNext} />,
    },
    {
      label: `${t('TARGETS')}`,
      content: <ChallengeTargetAndUserCard handleBack={handleBack} handleNext={handleNext} />,
    },
    {
      label: `${t('DESCRIPTION')}`,
      content: <Description handleBack={handleBack} handleNext={handleNext} />,
    },
    {
      label: `${t('OVERVIEW')}`,
      content: <Overview handleBack={handleBack} handleConfirm={handleConfirm} />,
    },
  ];
  return (
    <div className='flex 2xl:flex-row md:flex-row sm:flex-row flex-col z-0 bg-gradient-to-r from-lightBlue h-screen to-darkBlue'>
      {window.innerWidth <= 1023 ? <WhiteWave /> : <BlueWavesBG />}
      <div className='z-20'>
        <SidePanel activeRoute={SidePanelRoutes.NEW_CHALLENGE} />
      </div>
      <div className='flex z-40 w-full flex-row tablet:flex-row h-full tablet:w-full 2xl:overflow-hidden md:overflow-hidden sm:overflow-hidden justify-evenly'>
        <MobileNav />
        <div className='w-full h-full  items-end mt-16 lg:pb-20 lg:flex-row overflow-hidden  overflow-y-auto'>
          {window.innerWidth <= 1023 ? (
            <div className='w-[90vw] h-[90vh] mx-auto px-4 py-4 flex flex-col justify-start rounded-[42px] bg-white bg-opacity-80 shadow-2xl overflow-y-auto'>
              {' '}
              {load ? (
                <>
                  {steps.map((step, index) => {
                    return (
                      <div key={index}>
                        <div
                          className={`step-item ${currentStep === index && 'active'} 
                        ${index < currentStep && 'complete'}`}
                        >
                          <div className='w-full justify-start flex items-start py-3'>
                            <div className='step'>{index < currentStep ? <CheckMark /> : index + 1}</div>
                            <div
                              onClick={() => {
                                setCurrentStep(index);
                              }}
                              className={`text-stepsLabelsColor my-auto ml-2 rounded-full`}
                            >
                              {step.label}
                            </div>
                          </div>
                          <div className='h-full w-full'>{currentStep === index ? step.content : null}</div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className='w-full h-full flex flex-col'>
              {load ? (
                <>
                  <div className='w-full h-fit flex flex-row'>
                    {steps.map((step, index) => {
                      return (
                        <div
                          key={index}
                          className={`step-item flex-row flex ${currentStep === index && 'active'} 
                        ${index < currentStep && 'complete'}`}
                        >
                          <div className='step'>{index < currentStep ? <CheckMark /> : index + 1}</div>
                          <p className={`text-white rounded-full`}>{step.label}</p>
                        </div>
                      );
                    })}
                  </div>
                  {steps[currentStep].content}
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
