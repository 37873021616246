import React from 'react';
import EditButton from '../EditButton';
import RowButton from '../RowButton';
import { useTranslation } from 'react-i18next';
import { ProcessSpiner } from 'src/utils/ProcessSpiner';

interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  children: React.ReactNode;
  hideButtons?: boolean;
  onSave: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { t } = useTranslation(['home', 'main']);
  const { isOpen, onClose, onConfirm, children, hideButtons, onSave } = props;
  return (
    <>
      {isOpen ? (
        <>
          <div className='flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none  focus:outline-none backdrop-blur-[10px] bg-white rgba bg-opacity-20'>
            <div className='relative my-6 mx-auto max-w-3xl lg:w-fit w-11/12'>
              <div className=' rounded-[46px] relative flex flex-col w-full  bg-white bg-opacity-80 outline-none focus:outline-none  p-4'>
                {children}
                <div className='flex justify-center mb-2 mt-4 ml-4 mr-4 rounded-lg'>
                  {!hideButtons && (
                    <>
                      <RowButton
                        type='button'
                        disabled={onSave ? true : false}
                        onClick={() => onConfirm && onConfirm()}
                      >
                        {onSave ? (
                          <div className='w-full h-full flex flex-row items-center justify-center cursor-not-allowed'>
                            <div className='w-3/12 h-5/6 items-center justify-center flex'>
                              <ProcessSpiner />
                            </div>
                            <div className='w-full h-fit'>{t('PROCESSING...')}</div>
                          </div>
                        ) : (
                          `${t('YES')}`
                        )}
                      </RowButton>
                      <EditButton type='button' onClick={() => onClose?.()}>
                        {t('CANCEL')}
                      </EditButton>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
