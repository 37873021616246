import React from 'react';

const AddAvatarIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='185' height='185' viewBox='0 0 185 185' fill='none'>
    <path
      d='M92.4995 95.7115C85.6882 95.7189 79.0276 93.7058 73.3606 89.927C67.6935 86.1482 63.2746 80.7734 60.6629 74.4826C58.0512 68.1918 57.364 61.2677 58.6883 54.5863C60.0126 47.9049 63.2889 41.7664 68.1027 36.9474C72.9164 32.1284 79.0513 28.8455 85.7313 27.5139C92.4113 26.1824 99.3361 26.8621 105.63 29.4669C111.923 32.0718 117.303 36.4849 121.088 42.1479C124.873 47.8108 126.893 54.4692 126.893 61.2805C126.883 70.4027 123.258 79.149 116.811 85.6028C110.364 92.0566 101.622 95.6917 92.4995 95.7115ZM92.4995 38.0901C87.9023 38.0827 83.4063 39.4396 79.5808 41.9891C75.7553 44.5386 72.7724 48.1659 71.0097 52.4117C69.247 56.6576 68.7839 61.331 69.6789 65.8402C70.574 70.3495 72.787 74.4917 76.0377 77.7424C79.2884 80.9931 83.4306 83.2061 87.9399 84.1012C92.4491 84.9962 97.1225 84.5331 101.368 82.7704C105.614 81.0077 109.242 78.0248 111.791 74.1993C114.34 70.3737 115.697 65.8777 115.69 61.2805C115.69 55.13 113.247 49.2315 108.898 44.8824C104.549 40.5333 98.65 38.0901 92.4995 38.0901Z'
      fill='#15CDCA'
    />
    <path
      d='M92.5 184.514C71.8865 184.495 51.8746 177.565 35.6628 164.834C35.0572 164.354 34.556 163.755 34.19 163.074C33.824 162.394 33.6008 161.645 33.5342 160.875C33.5342 160.278 33.5342 159.718 33.5342 159.12C33.5342 151.372 35.0603 143.699 38.0255 136.541C40.9907 129.382 45.3369 122.878 50.8158 117.399C56.2947 111.92 62.7992 107.574 69.9578 104.609C77.1164 101.643 84.7889 100.117 92.5373 100.117C100.286 100.117 107.958 101.643 115.117 104.609C122.275 107.574 128.78 111.92 134.259 117.399C139.738 122.878 144.084 129.382 147.049 136.541C150.014 143.699 151.54 151.372 151.54 159.12C151.54 159.718 151.54 160.278 151.54 160.875C151.474 161.645 151.251 162.394 150.885 163.074C150.519 163.755 150.017 164.354 149.412 164.834C133.18 177.581 113.139 184.511 92.5 184.514ZM44.7373 157.664C58.5884 167.827 75.3202 173.307 92.5 173.307C109.68 173.307 126.412 167.827 140.263 157.664C139.775 145.323 134.529 133.651 125.625 125.092C116.721 116.533 104.85 111.753 92.5 111.753C80.1497 111.753 68.2787 116.533 59.3749 125.092C50.471 133.651 45.2254 145.323 44.7373 157.664Z'
      fill='#15CDCA'
    />
    <path
      d='M92.4997 184.515C70.7294 184.526 49.6597 176.821 33.0324 162.769C16.405 148.716 5.29633 129.225 1.67913 107.757C-1.93806 86.2896 2.17036 64.2346 13.2748 45.5093C24.3792 26.7839 41.7608 12.6002 62.3328 5.47704C82.9048 -1.64612 105.336 -1.24764 125.642 6.60169C145.948 14.451 162.815 29.2432 173.247 48.3511C183.679 67.459 187.002 89.646 182.624 110.972C178.247 132.297 166.453 151.381 149.337 164.835C133.125 177.566 113.113 184.495 92.4997 184.515ZM92.4997 11.688C75.7009 11.6888 59.3202 16.9271 45.638 26.6739C31.9557 36.4206 21.6528 50.1908 16.1634 66.0674C10.6739 81.9441 10.2711 99.1373 15.011 115.254C19.751 131.37 29.3977 145.607 42.6085 155.984C54.527 165.338 68.835 171.153 83.8996 172.765C98.9642 174.377 114.179 171.722 127.807 165.103C141.435 158.484 152.928 148.166 160.974 135.329C169.02 122.491 173.295 107.65 173.312 92.4998C173.282 71.0763 164.758 50.5387 149.61 35.3899C134.461 20.2412 113.923 11.7176 92.4997 11.688Z'
      fill='#15CDCA'
    />
  </svg>
);

export default AddAvatarIcon;
