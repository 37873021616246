import { AxiosError } from 'axios';
import { createRef, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DatePicker from 'src/components/DatePicker';
import TextField from 'src/components/Textfield';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import addDays from 'date-fns/addDays';
import swal from 'sweetalert2';
import Button from 'src/components/Button';
import { useApiClient } from 'src/api/client';
import Select from 'src/components/Select';
import ActiveCheckBox from 'src/components/Icons/ActiveCheckBox';
import InactiveCheckbox from 'src/components/Icons/InactiveCheckbox';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import buttonStyleNewChallenge from '../NewChallengeButtonStyle/ButtonStyle';
import dateSize from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/DateSize';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import { useTranslation } from 'react-i18next';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import { motion } from 'framer-motion';
import errorGlowField from 'src/components/css/GlowField/ErrorGlowField';
import glowField from 'src/components/css/GlowField/GlowField';
import roundingFields from 'src/components/RoundingOfAllForms/RoundingFields';
import { useToast } from 'src/store/toastContext';
export interface ChallengeTarget {
  id?: number;
  targetName: string;
  targetValue: number;
  targetUnit: string;
}
export interface ChallengeTeam {
  teamName: string;
  challengeName: string;
  dateFrom: Date;
  dateTo: Date;
  deadlineDate: Date;
}
export interface NewChallengeForm {
  teamName: string;
  selectedTeamName: string;
  teamId: number | null;
  challengeName: string;
  dateFrom: Date;
  dateTo: Date;
  deadlineDate: Date;
  targets: ChallengeTarget[];
  emails: string;
}
interface Team {
  licenceValidTo: Date | undefined;
  teamName: string;
  teamId: number;
  role: string;
  approved_user_emails: string[];
}
interface IButtonHandleProps {
  handleNext: () => void;
}
const NewChallengeCard = ({ handleNext }: IButtonHandleProps): JSX.Element => {
  const { showToastHandler } = useToast();
  const { t } = useTranslation(['home', 'main']);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewChallengeForm>();
  const autocompleteRef = createRef<HTMLInputElement>();
  const challengeData = useChallengeData();
  const [currentTeamId, setCurrentTeamId] = useState<number>(challengeData.teamId || 0);
  const [stateCheckBox, setStateCheckBox] = useState(challengeData.checkBoxState || false);
  const [checkBoxDeadlineDate, setCheckBoxDeadlineDate] = useState(challengeData.checkBoxDeadlineDate || false);
  const [checkBoxAutoApprove, setCheckBoxAutoApprove] = useState(challengeData.checkBoxAutoApprove || false);
  const [dateFrom, setDateFrom] = useState<Date>(challengeData.dateFrom || new Date());
  const [dateTo, setDateTo] = useState<Date>(challengeData.dateTo || addDays(new Date(), 14));
  const [deadlineDate, setDeadlineDate] = useState<Date>(challengeData.deadLineDate || addDays(new Date(), 16));
  const [dateError, setDateError] = useState(false);
  const [dateToError, setDateToError] = useState(false);
  const [deadlineDateError, setDeadlineDateError] = useState(false);
  const [errorMsgDateFrom, setErrorMsgDateFrom] = useState('');
  const [dateToErrMsg, setDateToErrMsg] = useState('');
  const [deadlineErrMsg, setDeadlineErrMsg] = useState('');
  const [selectTeamIdErrMsg, setSelectTeamIdErrMsg] = useState('');
  const [teamNameErrMsg, setTeamNameErrMsg] = useState('');
  const Client = useApiClient();
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    setDeadlineDateError(false);
    setDateToError(false);
    setDateError(false);
    setErrorMsgDateFrom('');
    setDateToErrMsg('');
    setDeadlineErrMsg('');
    if (!checkBoxDeadlineDate) {
      if (deadlineDate > dateFrom && deadlineDate < dateTo) {
        setDeadlineErrMsg(`${t('Deadline date to be after end date!')}`);
        setDeadlineDateError(true);
      }
      if (dateFrom > deadlineDate) {
        setDeadlineErrMsg(`${t('Deadline date is before than start date!')}`);
        setDeadlineDateError(true);
      }
      if (dateTo > deadlineDate) {
        setDateToErrMsg(`${t('Тhe last date is after then deadline!')}`);
        setDateToError(true);
      }
    }
    if (dateFrom > dateTo) {
      setErrorMsgDateFrom(`${t('Start date is after than end date!')}`);
      setDateError(true);
    }
  }, [deadlineDate, dateTo, dateFrom, checkBoxDeadlineDate]);
  const onChange = async (): Promise<void> => {
    localStorage.setItem('checkBoxState', JSON.stringify(!stateCheckBox));
    challengeData.clearAllData();
    challengeData.removeLocalStorageData();
    setCurrentTeamId(0);
    setStateCheckBox(!stateCheckBox);
  };

  const activeDeadlineDate = async (): Promise<void> => {
    localStorage.setItem('activeDeadlineDate', JSON.stringify(!checkBoxDeadlineDate));
    setCheckBoxDeadlineDate(!checkBoxDeadlineDate);
  };

  const autoApprove = async (): Promise<void> => {
    localStorage.setItem('autoApprove', JSON.stringify(!checkBoxAutoApprove));
    setCheckBoxAutoApprove(!checkBoxAutoApprove);
  };

  const [userTeams, setUserTeams] = useState<Team[]>([]);

  useEffect(() => {
    Client.get('/team/userTeams')
      .then((response) => {
        setUserTeams(response.data.teams);
      })
      .catch(() => {
        console.error('Error fetching teams');
      });
  }, []);
  const onSubmit: SubmitHandler<NewChallengeForm> = async (data) => {
    try {
      if (!stateCheckBox && currentTeamId == 0 && userTeams.length && isAdmin) {
        setSelectTeamIdErrMsg(`${t('Select a team!')}`);
        return;
      }

      if (dateError || dateToError || deadlineDateError) {
        return;
      }
      const team = userTeams.find((x) => x.teamId == currentTeamId);
      if (currentTeamId != 0 && team) {
        data.teamId = currentTeamId;
        data.selectedTeamName = team.teamName;
      } else if (data.teamName === '' || data.teamName == undefined) {
        autocompleteRef.current?.focus();
        return;
      }

      if (userTeams.find((x) => x.teamName == data.teamName && x.role == 'ADMIN')) {
        setTeamNameErrMsg(`${t('Team with name')} "${data.teamName}" ${t('is exist!')}`);
        return;
      }
      if (!data.challengeName) {
        return;
      }
      if (!checkBoxDeadlineDate) {
        if (dateTo > deadlineDate) {
          swal.fire({
            showConfirmButton: false,
            timer: 3000,
            title: `${t("The option to insert 'DEADLINE' is invalid")}`,
          });
          return;
        }
      }
      if (dateFrom > dateTo) {
        swal.fire({
          showConfirmButton: false,
          timer: 3000,
          title: `${'Cannot insert this date'}`,
        });
        return;
      }
      if (team?.approved_user_emails && !stateCheckBox && isAdmin && !challengeData.emails.length) {
        challengeData.setEmails(team?.approved_user_emails);
      } else {
        challengeData.setEmails(challengeData.emails);
      }
      challengeData.setCheckBoxState(stateCheckBox);
      challengeData.setCheckBoxDeadlineDate(checkBoxDeadlineDate);
      challengeData.setCheckBoxAutoApprove(checkBoxAutoApprove);
      challengeData.setTeamName(data.teamName);
      challengeData.setSelectedTeamName(data.selectedTeamName);
      challengeData.setChallengeName(data.challengeName);
      challengeData.setDateFrom(dateFrom);
      challengeData.setDateTo(dateTo);
      challengeData.setDeadLineDate(checkBoxDeadlineDate ? addDays(dateTo, 1) : deadlineDate);
      challengeData.setTeamid(currentTeamId);
      challengeData.SetLicence(team?.licenceValidTo);

      if (team) {
        challengeData.setAdminTeamEmail(team.approved_user_emails[0]);
      }
      data.dateFrom = dateFrom;
      data.dateTo = dateTo;
      data.deadlineDate = checkBoxDeadlineDate ? addDays(dateTo, 1) : deadlineDate;
      localStorage.setItem('data', JSON.stringify(data));
      localStorage.setItem('checkBoxState', JSON.stringify(stateCheckBox));
      localStorage.setItem('autoApprove', JSON.stringify(checkBoxAutoApprove));
      if (challengeData.confirmUserLicence) {
        showToastHandler(t('5DAYS TOAST'), 'success');
      }
      handleNext();
    } catch (exc) {
      const err = exc as AxiosError;
      if (err?.response?.status === 403) {
        swal.fire({
          title: `${t('The team already exists or you are not an admin')}`,
          showConfirmButton: false,
          timer: 3000,
        });
        autocompleteRef.current?.focus();
      }
    }
  };
  useEffect(() => {
    getUserEmails();
  }, [currentTeamId]);

  useEffect(() => {
    const c = userTeams.find((c) => c.role == 'ADMIN');
    if (c) c?.role === 'ADMIN' ? setIsAdmin(true) : setIsAdmin(false);
  }, [userTeams]);

  const getUserEmails = (): string => {
    const ut = userTeams.find((ut) => ut.teamId === currentTeamId);

    setSelectTeamIdErrMsg('');

    if (userTeams.length != 0 && !ut) {
      if (currentTeamId == 0) {
        setSelectTeamIdErrMsg(`${t('Select a team!')}`);
      }
    }
    if (ut)
      return ut?.approved_user_emails.length === 1 ? ut.approved_user_emails[0] : ut?.approved_user_emails.join(', ');
    return '';
  };
  useEffect(() => {
    if (challengeData.teamId != currentTeamId && challengeData.teamId) {
      challengeData.clearAllData();
      challengeData.removeLocalStorageData();
    }
  }, [currentTeamId]);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className={`w-full h-full flex flex-col justify-center items-center lg:px-10 lg:py-5`}
    >
      <div className={`${titleStyle} lg:flex hidden uppercase font-bold`}>{t('Add challenge')}</div>

      <div className={`flex flex-col h-full w-full ${padding} ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}>
        <div className='h-full flex flex-col'>
          <form onSubmit={handleSubmit(onSubmit)} className='h-full items-center justify-center w-full flex flex-col'>
            <div
              className={`h-fit lg:space-y-4 2xl:space-y-10 space-y-2 ${padding} lg:h-5/6 tablet:h-full flex flex-col lg:justify-center phone:justify-evenly rounded-[1.125rem] w-[78vw] lg:w-full items-center `}
            >
              <div className='flex lg:w-9/12 w-full h-fit flex-col'>
                <div className='flex flex-col w-full h-fit items-center justify-center'>
                  <div className='w-full h-full flex flex-row justify-center items-center'>
                    <div
                      className={` ${
                        errors.teamName?.message ||
                        teamNameErrMsg ||
                        (selectTeamIdErrMsg && !stateCheckBox && userTeams && isAdmin)
                          ? 'text-errorRed'
                          : 'text-DarkPrimary'
                      } pl-2 h-fit w-full flex justify-start items-start 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px]`}
                    >
                      {t('TEAM')}:
                    </div>
                    {!stateCheckBox && isAdmin ? (
                      <>
                        {selectTeamIdErrMsg && !stateCheckBox && userTeams && isAdmin ? (
                          <span className='text-errorRed flex justify-end w-full pr-4 italic'>
                            {selectTeamIdErrMsg}
                          </span>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>

                  {stateCheckBox || !isAdmin ? (
                    <div className='w-full flex flex-row h-fit'>
                      <Controller
                        control={control}
                        defaultValue={challengeData.teamName || ''}
                        name='teamName'
                        rules={{ required: { value: true, message: `${t('The team name field is empty!')}` } }}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              error={errors.teamName?.message || teamNameErrMsg}
                              onClick={() => {
                                setTeamNameErrMsg('');
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                  ) : (
                    <div className='w-full flex flex-row h-fit'>
                      <Controller
                        control={control}
                        name='teamId'
                        defaultValue={challengeData.teamId || currentTeamId}
                        render={({ field }) => (
                          <Select
                            {...field}
                            error={selectTeamIdErrMsg}
                            value={currentTeamId}
                            onChange={(e) => {
                              setCurrentTeamId(parseInt(e.target.value));
                            }}
                          >
                            <option defaultChecked key={0} value={0}>
                              {t('select a team')}
                            </option>
                            {userTeams
                              .filter((ut) => ut.role === 'ADMIN')
                              .map((team) => (
                                <option key={team.teamId} value={team.teamId}>
                                  {team.teamName}
                                </option>
                              ))}
                          </Select>
                        )}
                      />
                    </div>
                  )}
                </div>
                {isAdmin && (
                  <div className='w-full h-fit flex justify-end items-center'>
                    <div
                      className='flex flex-row lg:w-full w-full h-fit justify-start items-center pt-2'
                      onClick={onChange}
                    >
                      <div className='w-fit mr-1 h-fit flex justify-end items-center lg:justify-start lg:items-center'>
                        <span className='hover:ring-lightBlueButton hover:shadow-lightBlueButton hover:shadow-[0_0_15px_0.1px_rgba(0,0,0,0.3)] w-6 h-6 rounded-md'>
                          {stateCheckBox ? <ActiveCheckBox /> : <InactiveCheckbox />}
                        </span>
                      </div>
                      <div className='tablet:justify-end tablet:items-center md:justify-end md:items-end lg:justify-start lg:items-center lg:w-full text-DarkPrimary w-fit flex justify-end items-center 2xl:text-base xl:text-base lg:text-[14px] tablet:text-[10px] md:text-[13px] phone:text-[12px] text-[11px]'>
                        {t('ADD A NEW TEAM')}
                      </div>
                    </div>
                  </div>
                )}
                {stateCheckBox || !isAdmin ? (
                  <div className='w-full h-fit flex justify-end items-center'>
                    <div
                      className='flex flex-row lg:w-full w-full h-fit justify-start items-center pt-2'
                      onClick={autoApprove}
                    >
                      <div className='w-fit mr-1 h-fit flex justify-end items-center lg:justify-start lg:items-center'>
                        <span className='hover:ring-lightBlueButton hover:shadow-lightBlueButton hover:shadow-[0_0_15px_0.1px_rgba(0,0,0,0.3)] w-6 h-6 rounded-md'>
                          {checkBoxAutoApprove ? <ActiveCheckBox /> : <InactiveCheckbox />}
                        </span>
                      </div>
                      <div className='tablet:justify-end tablet:items-center md:justify-end md:items-end lg:justify-start lg:items-center lg:w-full text-DarkPrimary w-fit flex justify-end items-center 2xl:text-base xl:text-base lg:text-[14px] tablet:text-[10px] md:text-[13px] phone:text-[12px] text-[11px]'>
                        {t('ADMIN APPROVAL')}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='flex flex-col lg:w-9/12 w-full h-fit justify-center items-center'>
                <div
                  className={`pl-2 h-fit w-full flex  justify-start items-start 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px] ${
                    errors.challengeName?.message ? 'text-errorRed' : 'text-DarkPrimary'
                  }`}
                >
                  {t('CHALLENGE NAME')}:
                </div>
                <div className='w-full flex flex-row h-fit justify-start items-start '>
                  <Controller
                    control={control}
                    name='challengeName'
                    defaultValue={challengeData.challengeName}
                    rules={{ required: { value: true, message: `${t('The challenge field is empty!')}` } }}
                    render={({ field }) => (
                      <>
                        <TextField {...field} error={errors.challengeName?.message} />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className='flex flex-col lg:w-9/12 w-full   '>
                <div className='flex  lg:space-y-0 space-y-2 lg:flex-row flex-col  w-full h-full justify-evenly lg:justify-between lg:h-fit'>
                  <div className={dateSize}>
                    <div className={`${newChallengeTitleStyle} ${dateError ? 'text-errorRed' : 'text-DarkPrimary'}`}>
                      {t('DATE FROM')}:
                    </div>
                    <div className='w-full flex flex-row h-fit'>
                      <Controller
                        control={control}
                        defaultValue={dateFrom}
                        name='dateFrom'
                        rules={{ required: { value: false, message: `${t('ENTER DATE FROM!')}` } }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              value={dateFrom}
                              error={errorMsgDateFrom}
                              onChange={(date) => {
                                if (date) {
                                  setDateFrom(date);
                                }
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    {dateError ? (
                      <span className='w-full h-fit flex justify-center text-errorRed text-base italic'>
                        {errorMsgDateFrom}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={dateSize}>
                    <div className={`${newChallengeTitleStyle} ${dateToError ? 'text-errorRed' : 'text-DarkPrimary'}`}>
                      {t('DATE TO')}:
                    </div>
                    <div className='w-full flex flex-col h-fit'>
                      <Controller
                        control={control}
                        defaultValue={challengeData.dateTo || addDays(new Date(), 14)}
                        name='dateTo'
                        rules={{ required: { value: false, message: `${t('ENTER DATE TO!')}` } }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              value={dateTo}
                              error={dateToErrMsg}
                              onChange={(date) => {
                                if (date) {
                                  setDateTo(date);
                                  setDeadlineDate(addDays(date, 2));
                                }
                              }}
                            />
                          </>
                        )}
                      />
                      {dateToError ? (
                        <span className='w-full h-fit flex justify-center text-errorRed text-xs italic'>
                          {dateToErrMsg}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className={dateSize}>
                    <div
                      className={`${newChallengeTitleStyle} ${
                        deadlineDateError ? 'text-errorRed' : 'text-DarkPrimary'
                      }`}
                    >
                      {t('DEADLINE')}:
                    </div>
                    <div className='w-full flex flex-col h-fit'>
                      <Controller
                        control={control}
                        defaultValue={
                          checkBoxDeadlineDate ? dateTo : challengeData.deadLineDate || addDays(new Date(), 14)
                        }
                        name='deadlineDate'
                        rules={{ required: { value: false, message: `${t('ENTER DEADLINE!')}` } }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              className={`${deadlineErrMsg ? errorGlowField : glowField} ${
                                checkBoxDeadlineDate ? 'text-gray' : 'text-DarkPrimary'
                              }  ${roundingFields}`}
                              value={checkBoxDeadlineDate ? addDays(dateTo, 1) : deadlineDate}
                              disabled={checkBoxDeadlineDate}
                              error={deadlineErrMsg}
                              onChange={(date) => {
                                if (date) {
                                  setDeadlineDate(checkBoxDeadlineDate ? addDays(dateTo, 1) : date);
                                }
                              }}
                            />
                          </>
                        )}
                      />
                      {deadlineDateError ? (
                        <span className='w-full h-fit flex justify-center text-errorRed text-xs italic'>
                          {deadlineErrMsg}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>{' '}
                <div className='w-full h-fit flex justify-start flex-col items-center'>
                  <div
                    className='flex flex-row lg:w-full w-full h-fit justify-start items-center  pt-2'
                    onClick={activeDeadlineDate}
                  >
                    <div className='w-fit mr-1 h-fit flex justify-start items-center lg:justify-center lg:items-center'>
                      <span className='hover:ring-lightBlueButton  hover:shadow-lightBlueButton hover:shadow-[0_0_15px_0.1px_rgba(0,0,0,0.3)] w-6 h-6 rounded-md'>
                        {checkBoxDeadlineDate ? <ActiveCheckBox /> : <InactiveCheckbox />}
                      </span>
                    </div>
                    <div className='tablet:justify-start tablet:items-center md:justify-center md:items-center lg:justify-start lg:items-center lg:w-full text-DarkPrimary w-fit flex justify-start items-center 2xl:text-base xl:text-base lg:text-[14px] tablet:text-[10px] md:text-[13px] phone:text-[12px] text-[11px]'>
                      {t('Allow to add log only one day after activity latest')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pb-3 flex flex-col 2xl:h-fit lg:h-fit lg:w-1/2 w-full text-center justify-end items-center m-auto mt-2'>
              <Button className={buttonStyleNewChallenge} type={'submit'}>
                {t('NEXT')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};
export default NewChallengeCard;
