import React from 'react';

const ChartBarIconWhite: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='23' viewBox='0 0 26 23' fill='none'>
    <path
      d='M0.200195 13.1008C0.200195 12.2171 0.91654 11.5008 1.8002 11.5008H5.0002C5.88385 11.5008 6.6002 12.2171 6.6002 13.1008V21.1008C6.6002 21.9844 5.88385 22.7008 5.0002 22.7008H1.8002C0.91654 22.7008 0.200195 21.9844 0.200195 21.1008V13.1008Z'
      fill='white'
    />
    <path
      d='M9.8002 6.70078C9.8002 5.81713 10.5165 5.10078 11.4002 5.10078H14.6002C15.4839 5.10078 16.2002 5.81713 16.2002 6.70078V21.1008C16.2002 21.9844 15.4839 22.7008 14.6002 22.7008H11.4002C10.5165 22.7008 9.8002 21.9844 9.8002 21.1008V6.70078Z'
      fill='white'
    />
    <path
      d='M19.4002 1.90078C19.4002 1.01713 20.1165 0.300781 21.0002 0.300781H24.2002C25.0839 0.300781 25.8002 1.01713 25.8002 1.90078V21.1008C25.8002 21.9844 25.0839 22.7008 24.2002 22.7008H21.0002C20.1165 22.7008 19.4002 21.9844 19.4002 21.1008V1.90078Z'
      fill='white'
    />
  </svg>
);

export default ChartBarIconWhite;
