import React from 'react';

const ThumbUpIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    stroke='#15CDCA'
    strokeWidth='2'
  >
    <path d='M0.857422 13.6C0.857422 13.2848 0.9195 12.9727 1.04011 12.6816C1.16072 12.3904 1.33751 12.1258 1.56037 11.9029C1.78323 11.6801 2.0478 11.5033 2.33898 11.3827C2.63016 11.2621 2.94225 11.2 3.25742 11.2C3.57259 11.2 3.88468 11.2621 4.17586 11.3827C4.46704 11.5033 4.73162 11.6801 4.95448 11.9029C5.17734 12.1258 5.35412 12.3904 5.47473 12.6816C5.59534 12.9727 5.65742 13.2848 5.65742 13.6V23.2C5.65742 23.8365 5.40457 24.447 4.95448 24.8971C4.50439 25.3471 3.89394 25.6 3.25742 25.6C2.6209 25.6 2.01045 25.3471 1.56037 24.8971C1.11028 24.447 0.857422 23.8365 0.857422 23.2V13.6ZM7.25742 13.3328V22.0208C7.25714 22.6155 7.42257 23.1984 7.73515 23.7043C8.04772 24.2102 8.49508 24.619 9.02702 24.8848L9.10702 24.9248C9.99484 25.3685 10.9737 25.5997 11.9662 25.6H20.6318C21.3719 25.6003 22.0891 25.3441 22.6615 24.875C23.2339 24.4059 23.626 23.7529 23.771 23.0272L25.691 13.4272C25.7838 12.963 25.7724 12.484 25.6577 12.0247C25.5431 11.5654 25.3279 11.1373 25.0278 10.7712C24.7276 10.4051 24.35 10.1102 23.9222 9.90766C23.4943 9.70513 23.0268 9.60005 22.5534 9.6H16.8574V3.2C16.8574 2.35131 16.5203 1.53737 15.9202 0.937258C15.32 0.337142 14.5061 0 13.6574 0C13.2331 0 12.8261 0.168571 12.5261 0.468629C12.226 0.768687 12.0574 1.17565 12.0574 1.6V2.6672C12.0574 4.05197 11.6083 5.39938 10.7774 6.5072L8.53742 9.4928C7.70656 10.6006 7.25742 11.948 7.25742 13.3328Z' />
  </svg>
);

export default ThumbUpIcon;
