import React from 'react';

const SortIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'>
    <path
      d='M15.4143 30.0422C11.7707 30.044 8.2444 28.7868 5.46158 26.4936C2.67876 24.2005 0.819568 21.02 0.214179 17.5169C-0.391209 14.0138 0.296392 10.4149 2.15487 7.35927C4.01335 4.30367 6.92241 1.98917 10.3654 0.826813C13.8084 -0.335542 17.5625 -0.270519 20.961 1.01033C24.3595 2.29119 27.1824 4.70497 28.9284 7.823C30.6744 10.941 31.2305 14.5615 30.4979 18.0414C29.7653 21.5213 27.7914 24.6355 24.9268 26.8308C22.2135 28.9083 18.8642 30.039 15.4143 30.0422ZM15.4143 1.84031C12.6028 1.84044 9.86122 2.69524 7.57131 4.28571C5.28139 5.87619 3.55705 8.1232 2.63832 10.7139C1.71958 13.3047 1.65217 16.1103 2.44546 18.7401C3.23875 21.37 4.85327 23.6933 7.06428 25.3866C9.05902 26.9129 11.4537 27.8617 13.9749 28.1248C16.4962 28.388 19.0426 27.9547 21.3234 26.8746C23.6043 25.7944 25.5278 24.1109 26.8744 22.016C28.221 19.9212 28.9365 17.4995 28.9393 15.0272C28.9343 11.5313 27.5078 8.17997 24.9724 5.708C22.4371 3.23603 18.9998 1.84515 15.4143 1.84031Z'
      fill='#15CDCA'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.38076 14.3808C9.88844 13.8731 10.7116 13.8731 11.2192 14.3808L15.5 18.6615L19.7808 14.3808C20.2884 13.8731 21.1116 13.8731 21.6192 14.3808C22.1269 14.8884 22.1269 15.7116 21.6192 16.2192L16.4192 21.4192C15.9116 21.9269 15.0884 21.9269 14.5808 21.4192L9.38076 16.2192C8.87308 15.7116 8.87308 14.8884 9.38076 14.3808Z'
      fill='#15CDCA'
    />
  </svg>
);
export default SortIcon;
