import React, { ReactNode } from 'react';
import rounding from 'src/components/RoundingOfAllForms/Rounding';

type ServiceFormCardProps = {
  children?: ReactNode;
};

const ServiceFormCard: React.FC<ServiceFormCardProps> = ({ children }) => (
  <div className={`h-full lg:gap-5 sm:gap-5 lg:py-3 flex flex-col justify-evenly sm:py-0 ${rounding}`}>{children}</div>
);

export default ServiceFormCard;
