import React from 'react';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';
import { useTranslation } from 'react-i18next';
import { myChallengeData } from 'src/store/myChallengePageContex';
import SelectButtonFavorites from 'src/components/FavoritesUsersSelect/FavoritesUsersSelect';

const HeaderFavoriteUsers: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);
  const myChallengeContext = myChallengeData();

  return (
    <tr className='flex flex-row w-full py-[0.5em] sticky top--0'>
      <th className={`w-full desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden`}>&nbsp;</th>
      {myChallengeContext.select === 1 ? (
        <th className={`${headerStyle}  desktop:hidden xl:hidden lg:hidden 2xl:hidden md:hidden tablet:hidden flex`}>
          {t('FAVOURITES')}
        </th>
      ) : null}
      <th className={headerStyle}>{t('NAME')}</th>
      <th className={headerStyle}>{t('COMPLETION')}</th>
      <th className={headerStyle}>{t('DETAIL')}</th>
      <th className={`w-full phone:hidden lg:flex hidden`}>
        <SelectButtonFavorites />
      </th>
    </tr>
  );
};

export default HeaderFavoriteUsers;
