import glowField from '../css/GlowField/GlowField';
import Select from '../Select';
import { useTranslation } from 'react-i18next';
import { myChallengeData } from 'src/store/myChallengePageContex';

const SelectButtonFavorites: React.FC = () => {
  const myChallengeContex = myChallengeData();
  const { t } = useTranslation(['home', 'main']);

  return (
    <Select
      defaultValue={myChallengeContex.select}
      className={`${glowField} w-full lg:mr-2 phone:mr-0 mr-0`}
      value={myChallengeContex.select}
      onChange={(e) => myChallengeContex.setSelect(parseInt(e.target.value))}
    >
      <option key={1} value={1}>
        {t('Users')}
      </option>
      <option key={2} value={2}>
        {t('Favorites')}
      </option>
    </Select>
  );
};

export default SelectButtonFavorites;
