import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import glowField from 'src/components/css/GlowField/GlowField';
import TrashIcon from 'src/components/Icons/TrashIcon';
import chipsRounding from 'src/components/RoundingOfAllForms/ChipsRounding';
import rounding from 'src/components/RoundingOfAllForms/Rounding';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import Select from 'src/components/Select';
import TextField from 'src/components/Textfield';
import buttonSwapColor from 'src/pages/HoverButtonsstyle/HooverButtonSwapColor';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import TargetsTooltip from 'src/utils/TargetsTooltip';
import buttonStyleNewChallenge from '../NewChallengeButtonStyle/ButtonStyle';
import { ChallengeTarget } from '../NewChallengeCard';
import { ReactComponent as RemoveIcon } from '../../../images/icons/RemoveIcon.svg';
import { useTranslation } from 'react-i18next';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import stepButton from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/StepButtonsStyle';
import { IButton } from '../ButtonInterface/IButton';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import { motion } from 'framer-motion';
import { buttonTransition } from '../NewChallengeButtonStyle/ButtonTransition';

interface NewTargetForm {
  targetName: string;
  targetValue: number;
  targetUnit: ChallengeTarget['targetUnit'];
}

const ChallengeTargetAndUserCard = ({ handleNext, handleBack }: IButton): JSX.Element => {
  const { handleSubmit, control, setValue, register } = useForm<NewTargetForm>();
  const [targetValue, setTargetValue] = useState<string>('');
  const { t } = useTranslation(['home', 'main']);

  const challengeData = useChallengeData();

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = event.target.value;
    if (val === '') {
      setTargetValue('');
      return;
    }
    const iVal = parseFloat(val);
    if (iVal > Number.MAX_SAFE_INTEGER) return;
    setTargetValue(iVal.toString());
    setValue('targetValue', iVal);
  };
  const onSubmit: SubmitHandler<NewTargetForm> = async (data) => {
    if (data.targetValue == undefined || data.targetValue <= 0) return;
    challengeData.add({
      targetName: data.targetName,
      targetUnit: data.targetUnit as ChallengeTarget['targetUnit'],
      targetValue: data.targetValue,
    });
    setTargetValue('');
    setValue('targetName', '');
  };

  const removeTarget = (index: number): void => {
    challengeData.setChallengeTargets(challengeData.challengeTargets.filter((v, i) => i !== index));
  };
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      className='w-full h-full lg:px-10 lg:py-5 flex  flex-col justify-center items-center'
    >
      <div className={`${titleStyle} lg:flex hidden uppercase`}>{t('Add targets')}</div>
      <div
        className={`flex flex-col h-full w-full justify-end ${padding} ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
      >
        <div className='h-full flex lg:flex-row flex-col lg:mb-0 phone:mb-10  mb-10 justify-between '>
          <div className='h-fit 2xl:w-[512px] xl:w-5/12 lg:w-5/12 '>
            <form onSubmit={handleSubmit(onSubmit)} className='h-fit w-full'>
              <div className='h-full w-full flex flex-col phone:w-full text-DarkPrimary'>
                <div className='flex flex-col mb-4 h-full w-full '>
                  <div className={newChallengeTitleStyle}>{t('TARGET NAME')}</div>
                  <Controller
                    control={control}
                    name='targetName'
                    rules={{ required: { value: true, message: `${t('Enter the name of the target')}` } }}
                    render={({ field }) => (
                      <>
                        <TextField placeholder={`${t('Running/Swimming...')}`} {...field} />
                      </>
                    )}
                  />
                </div>
                <div className='flex flex-col w-full mb-4'>
                  <div className={newChallengeTitleStyle}>{t('GOAL')}</div>
                  <TextField
                    type={'number'}
                    placeholder='20'
                    {...register('targetValue', { validate: () => parseFloat(targetValue) !== 0.0 })}
                    value={targetValue}
                    onChange={changeValue}
                  />
                </div>
                <div className='flex flex-col w-full my-auto mx-auto'>
                  <div className={newChallengeTitleStyle}>{t('UNIT OF MEASURE')}</div>
                  <Controller
                    control={control}
                    name='targetUnit'
                    defaultValue='km'
                    render={({ field }) => (
                      <Select {...field}>
                        <option value='km'>{t('km')}</option>
                        <option value='meters'>{t('meters')}</option>
                        <option value='hod.'>{t('hod.')}</option>
                        <option value='min.'>{t('min.')}</option>
                        <option value='pcs'>{t('pcs')}</option>
                      </Select>
                    )}
                  />
                </div>
                <div className='flex flex-row justify-between items-center w-full h-12 my-5'>
                  <div className=' w-full h-12 flex lg:justify-end phone:justify-center justify-center'>
                    <Button
                      className={`${buttonSwapColor} 2xl:w-2/4 xl:w-2/4 lg:w-8/12 phone:w-[130px] w-2/3 md:w-5/12 sm:w-5/12 2xl:h-12 phone:h-full  tablet:w-full desktop:h-10 xl:h-10 lg:h-10 tablet:h-10 h-10 2xl:text-xl xl:text-md phone:text text-[14px]`}
                    >
                      {t('ADD TARGET')}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='2xl:w-[512px] xl:w-5/12 lg:w-5/12 phone:w-full h-[36vh] lg:h-full phone:h-[29vh] sm:h-[40vh]  flex items-end flex-col'>
            <div className='font-normal flex justify-center w-full  border-DarkPrimary text-DarkPrimary h-fit 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px]'>
              <span className='text-DarkPrimary font-semibold'>{t('TARGET')}/</span>
              <span>{t('GOAL')}</span>
              <span className='text-DarkPrimary font-semibold'>/{t('UNIT OF MEASURE')}</span>
            </div>

            <table
              className={`border-0 2xl:w-[512px] h-full  xl:w-full lg:w-full  phone:w-full w-full sm:w-full  lg:border-2 phone:border-0  border-lightBlueButton flex flex-col justify-center items-center overflow-hidden ${roundingForm} `}
            >
              <tr className='w-full h-full overflow-y-auto lg:mr-3  my-5 pt-1 overflow-hidden'>
                {challengeData.challengeTargets.map((target, index) => {
                  return (
                    <>
                      <div className='flex w-full flex-wrap relative justify-center space-x-2 pb-2 ' key={index}>
                        <span
                          className={`${glowField} w-full phone:w-full  2xl:h-[55px] xl:h-[40px] lg:h-[40px] lg:w-5/6 desktop:h-[40px] justify-between items-center text-DarkPrimary font-semibold border-lightGreen border-[2px] text-sm flex align-center active:bg-gray transition duration-300 ease ${chipsRounding} `}
                        >
                          <TargetsTooltip
                            targetName={target.targetName}
                            targetUnit={t(`${target.targetUnit}`)}
                            targetValue={target.targetValue}
                          />

                          <button
                            className='bg-transparent hover focus:outline-none absolute w-fit h-full 2xl:right-8 right-5 cursor-pointer 2xl:scale-100 scale-75'
                            onClick={() => removeTarget(index)}
                          >
                            <div className='w-3'>
                              <RemoveIcon />
                            </div>
                          </button>
                        </span>
                      </div>
                    </>
                  );
                })}
              </tr>
            </table>
            <div className='w-full h-fit flex lg:justify-between phone:justify-center justify-center flex-row items-end 2xl:pt-2 pt-0'>
              <span className='text-DarkPrimary lg:flex hidden justify-start w-full italic'>
                {t('Count')}: {challengeData.challengeTargets.length}
              </span>
              <span className='text-DarkPrimary lg:flex hidden justify-end w-full 2xl:pr-1 pr-0 italic'>
                {t('Clear targets:')}
              </span>
              <span
                className='cursor-pointer 2xl:pb-[5px] pb-0 2xl:scale-110 lg:scale-75'
                onClick={() => {
                  localStorage.removeItem('targets');
                  challengeData.setChallengeTargets([]);
                }}
              >
                <TrashIcon />
              </span>
            </div>
          </div>
        </div>
        <div className='pb-3 relative h-fit flex flex-row w-full justify-between'>
          <div className='text-center w-full flex-col phone:hidden lg:flex hidden justify-start items-start m-auto h-full '>
            <Button
              className={buttonStyleNewChallenge}
              type={'button'}
              onClick={() => {
                localStorage.setItem('targets', JSON.stringify(challengeData.challengeTargets));
                handleBack();
              }}
            >
              {t('PREVIOUS')}
            </Button>
          </div>
          <div className='text-center w-full flex flex-col phone:items-center lg:items-end justify-center items-center m-auto h-full '>
            <Button
              className={`${stepButton} tracking-widest ${rounding} text-white 2xl:text-xl desktop:text-xl tablet:text-xl text-[14px] desktop:font-bold md:font-bold tablet:font-bold font-semibold ${
                !challengeData.challengeTargets.length
                  ? 'bg-gray'
                  : `bg-lightBlueButton hover:bg-darkGreenHoverButton ${buttonTransition}`
              }`}
              disabled={challengeData.challengeTargets.length ? false : true}
              type={'button'}
              onClick={() => {
                if (challengeData) {
                  localStorage.setItem('targets', JSON.stringify(challengeData.challengeTargets));
                  handleNext();
                }
              }}
            >
              {t('NEXT')}
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ChallengeTargetAndUserCard;
