import React from 'react';

const FilledStarIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='none'>
    <path
      d='M16.8262 3.90594L19.6732 10.7511L20.0251 11.5971L20.9384 11.6703L28.3283 12.2627L22.6979 17.0857L22.0021 17.6818L22.2147 18.573L23.9349 25.7843L17.6081 21.9199L16.8262 21.4423L16.0443 21.9199L9.71746 25.7843L11.4376 18.573L11.6502 17.6818L10.9544 17.0857L5.32404 12.2627L12.714 11.6703L13.6273 11.5971L13.9791 10.7511L16.8262 3.90594Z'
      fill='#15CDCA'
      stroke='#15CDCA'
      strokeWidth='3'
    />
  </svg>
);

export default FilledStarIcon;
