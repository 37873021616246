import React from 'react';

const PlusIconWhite: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='25' height='26' viewBox='0 0 25 26' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.4217 1.80078C13.3053 1.80078 14.0217 2.51713 14.0217 3.40078V11.4008H22.0217C22.9053 11.4008 23.6217 12.1171 23.6217 13.0008C23.6217 13.8844 22.9053 14.6008 22.0217 14.6008H14.0217V22.6008C14.0217 23.4844 13.3053 24.2008 12.4217 24.2008C11.538 24.2008 10.8217 23.4844 10.8217 22.6008V14.6008H2.82168C1.93802 14.6008 1.22168 13.8844 1.22168 13.0008C1.22168 12.1171 1.93802 11.4008 2.82168 11.4008H10.8217V3.40078C10.8217 2.51713 11.538 1.80078 12.4217 1.80078Z'
      fill='white'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default PlusIconWhite;
