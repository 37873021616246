import React from 'react';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';
import rounding from 'src/components/RoundingOfAllForms/Rounding';
import { useTranslation } from 'react-i18next';

const LogsHeader: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);

  return (
    <tr className={`flex flex-row w-full z-20 py-[1em] items-center  ${rounding} sticky top-0`}>
      <th className={`${headerStyle}  lg:flex hidden`}>{t('CHALLENGE')}</th>
      <th className={`${headerStyle}`}>{t('TARGET')}</th>
      <th className={headerStyle}>{t('VALUE')}</th>
      <th className={headerStyle}>{t('DATE')}</th>
      <th className={`${headerStyle}  lg:flex hidden `}>{t('INFO')}</th>
      <th className={`${headerStyle}  lg:flex hidden`}>{t('LINK')}</th>
      <th className={`${headerStyle}  lg:flex hidden `}>{t('PHOTO')}</th>
      <th className={`${headerStyle}  lg:flex  lg:w-full  phone:w-[50px] w-[55px]`}></th>
    </tr>
  );
};

export default LogsHeader;
