import React, { useState } from 'react';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import { FieldProps } from 'src/types/commonTypes';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import glowField from '../css/GlowField/GlowField';
import HideEyeIcon from '../Icons/HideEyeIcon';
import ShowEyeIcon from '../Icons/ShowEyeIcon';
import { useTranslation } from 'react-i18next';

export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;
const RegisterLoginPasswordField: React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;
  const [swapEyeIcons, setSwapEyeIcons] = useState<boolean>(false);
  const { t } = useTranslation(['home', 'main']);

  function onClick(): void {
    if (swapEyeIcons == false) {
      setSwapEyeIcons(true);
    } else {
      setSwapEyeIcons(false);
    }
  }
  return (
    <div className='flex-col'>
      <div className='relative'>
        <input
          className={`${glowField} pr-12`}
          value={value ?? ''}
          type={swapEyeIcons ? 'text' : 'password'}
          {...rest}
          ref={ref}
        ></input>
        {error && <span className='text-primary'>{error}</span>}
        <i
          className={`absolute right-2 top-0 flex h-full justify-center items-center ${iconTransition}`}
          onClick={onClick}
        >
          {swapEyeIcons ? (
            <div className='group overflow-visible items-center h-full flex justify-center cursor-pointer'>
              <ShowEyeIcon />
              <LeftSideBottomIconTooltip tooltipContent={t('HIDE')} />
            </div>
          ) : (
            <div className='group overflow-visible items-center h-full flex justify-center cursor-pointer'>
              <HideEyeIcon />
              <LeftSideBottomIconTooltip tooltipContent={t('SHOW')} />
            </div>
          )}
        </i>
      </div>
    </div>
  );
};

export default React.forwardRef(RegisterLoginPasswordField);
