import React from 'react';

const InactiveReward: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='28' height='32' viewBox='0 0 28 32' fill='none'>
    <path
      d='M12.1067 23.1467L5.86667 32L4.26667 27.7333H0L5.44 20.32C6.50667 20.96 7.62667 21.0667 8.42667 21.2267C8.69333 21.28 9.01333 21.3333 9.17333 21.3333C9.28 21.3867 9.54667 21.6 9.70667 21.76C10.3467 22.1867 11.1467 22.8267 12.1067 23.1467ZM22.2933 20.32C21.2267 20.96 20.1067 21.0667 19.3067 21.2267C19.04 21.28 18.6667 21.3333 18.5067 21.3333C18.4 21.3867 18.1333 21.6 17.9733 21.76C17.3867 22.1867 16.64 22.8267 15.6267 23.1467L21.8667 32L23.4667 27.7333H27.7333L22.2933 20.32ZM13.8667 6.4C11.7867 6.4 10.1333 8.05333 10.1333 10.1333C10.1333 12.2133 11.7867 13.8667 13.8667 13.8667C15.9467 13.8667 17.6 12.2133 17.6 10.1333C17.6 8.05333 15.9467 6.4 13.8667 6.4ZM24 10.1333C24 11.4133 22.6133 12.4267 22.1333 13.5467C21.6533 14.72 21.92 16.4267 21.0133 17.28C20.16 18.1333 18.4533 17.92 17.28 18.4C16.16 18.88 15.1467 20.2667 13.8667 20.2667C12.5867 20.2667 11.5733 18.88 10.4533 18.4C9.28 17.92 7.57333 18.1867 6.72 17.28C5.86667 16.4267 6.08 14.72 5.6 13.5467C5.12 12.4267 3.73333 11.4133 3.73333 10.1333C3.73333 8.85333 5.12 7.84 5.6 6.72C6.08 5.54667 5.81333 3.84 6.72 2.98667C7.57333 2.13333 9.28 2.34667 10.4533 1.86667C11.5733 1.38667 12.5867 0 13.8667 0C15.1467 0 16.16 1.38667 17.28 1.86667C18.4533 2.34667 20.16 2.08 21.0133 2.98667C21.8667 3.84 21.6533 5.54667 22.1333 6.72C22.6133 7.84 24 8.85333 24 10.1333ZM20.8 10.1333C20.8 6.29333 17.7067 3.2 13.8667 3.2C10.0267 3.2 6.93333 6.29333 6.93333 10.1333C6.93333 13.9733 10.0267 17.0667 13.8667 17.0667C17.7067 17.0667 20.8 13.9733 20.8 10.1333Z'
      fill='#C2C2C2'
    />
  </svg>
);

export default InactiveReward;
