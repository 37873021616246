import { useRef } from 'react';
import { Parallax, IParallax } from '@react-spring/parallax';
import DesktopParallax from 'src/components/Parallax/DesktopParallax/DesktopParallax';
import SwitchLoginForms from 'src/components/Parallax/SwitchFormsParallax/SwitchFormParallax';
import ScrollButtonParallax from 'src/components/Parallax/ScrollButtonParallax/ScrollButtonParallax';
import MobileLogin from 'src/components/Parallax/MobileLogin/MobileLogin';

function LoginPage(): React.ReactElement {
  const parallax = useRef<IParallax | null>(null);
  return (
    <>
      {window.innerWidth > window.innerHeight ? (
        <Parallax ref={parallax} pages={1.4} className='bg-white w-full h-full scroll-smooth flex'>
          <div className='lg:block hidden'>
            <DesktopParallax />
          </div>
          <div className='lg:flex hidden w-full h-full'>
            <SwitchLoginForms />
          </div>
          <ScrollButtonParallax parallax={parallax} />
        </Parallax>
      ) : (
        <MobileLogin />
      )}
    </>
  );
}
export default LoginPage;
