import React, { createContext, useContext, useState } from 'react';
interface TeamDetailContextProps {
  teamId: number;
  teamName: string;
  setTeamId: (value: number) => void;
  setTeamName: (value: string) => void;
  licence: Date | undefined;
  SetLicence: (value: Date | undefined) => void;
  confirmUserLicence: boolean;
  setConfirmUserLicence: (value: boolean) => void;
  usersWatingForApprove: boolean;
  setUsersWatingForApprove: (value: boolean) => void;
}

interface TeamDetailProviderProps {
  children: React.ReactNode;
}

const defaultState: TeamDetailContextProps = {
  usersWatingForApprove: false || true,
  setUsersWatingForApprove() {
    return;
  },
  confirmUserLicence: false || true,
  setConfirmUserLicence() {
    return;
  },
  teamId: 0,
  licence: new Date(),
  SetLicence: () => {
    return;
  },
  teamName: '',
  setTeamId: () => {
    return;
  },
  setTeamName: () => {
    return;
  },
};

export const TeamDetailContext = createContext<TeamDetailContextProps>(defaultState);

export const TeamDetailProvider: React.FC<TeamDetailProviderProps> = ({ children }) => {
  const [teamId, setTeamId] = useState<number>(0);
  const [teamName, setTeamName] = useState<string>('');
  const [licence, setLicence] = useState<Date | undefined>();
  const [confirmUserLicence, SetConfirmUserLicence] = useState<boolean>(false);
  const [usersWatingForApprove, setUsersWatingForApprove] = useState<boolean>(false);

  const handleSetLicence = (licence: Date | undefined): void => {
    setLicence(licence);
  };
  const handleSetTeamId = (teamId: number): void => {
    setTeamId(teamId);
  };
  const handleSetTeamName = (teamName: string): void => {
    setTeamName(teamName);
  };
  const handleSetConfirmUserLicence = (confirmUserLicence: boolean): void => {
    SetConfirmUserLicence(confirmUserLicence);
  };
  const handleSetUsersWatingForApprove = (usersWatingForApprove: boolean): void => {
    setUsersWatingForApprove(usersWatingForApprove);
  };
  return (
    <TeamDetailContext.Provider
      value={{
        setUsersWatingForApprove: handleSetUsersWatingForApprove,
        usersWatingForApprove: usersWatingForApprove,
        confirmUserLicence: confirmUserLicence,
        setConfirmUserLicence: handleSetConfirmUserLicence,
        licence: licence,
        SetLicence: handleSetLicence,
        teamName: teamName,
        setTeamName: handleSetTeamName,
        teamId,
        setTeamId: handleSetTeamId,
      }}
    >
      {children}
    </TeamDetailContext.Provider>
  );
};

export const useTeam = (): TeamDetailContextProps => useContext(TeamDetailContext);
