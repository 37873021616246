import React from 'react';

export type RowButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const RowButton = React.forwardRef((props: RowButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button
    className='bg-lightGreen  text-white text-sm font-medium w-[160px] h-[40px] rounded-[8px] flex items-center justify-center'
    ref={ref}
    {...props}
  >
    {props.children}
  </button>
));

RowButton.displayName = 'RowButton';

export default RowButton;
