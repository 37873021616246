import React, { createRef, useEffect, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useApiClient } from 'src/api/client';
import AddAvatarIcon from 'src/components/Icons/AddAvatarIcon';
import buttonSwapColor from 'src/pages/HoverButtonsstyle/HooverButtonSwapColor';
import AddAvatarIconMobile from 'src/components/Icons/AddAvatarIconMobile';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import { useTranslation } from 'react-i18next';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import { useToast } from 'src/store/toastContext';

interface AvatarForm {
  avatar: File;
}

const AvatarCard: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);
  const [crop, setCrop] = useState<Crop>();
  const { register, handleSubmit, setValue } = useForm<AvatarForm>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [currentAvatar, setCurrentAvatar] = useState<string | null>(null);
  const [swapElements, setSwapElements] = useState<boolean>(true);
  const [swapButtonLogic, setSwapButtonLogic] = useState<boolean>(true);
  const [hideShowCrop, setHideShowCrop] = useState<boolean>(false);
  const { showToastHandler } = useToast();

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        1,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop(crop);
    setSwapElements(false);
    setHideShowCrop(true);
    setSwapButtonLogic(true);
  };
  const Client = useApiClient();
  const submit: SubmitHandler<AvatarForm> = async (data) => {
    try {
      if (!data.avatar) return;
      if (!completedCrop) return;

      const fd = new FormData();
      fd.append('avatar', data.avatar);
      fd.append('x', completedCrop.x.toString());
      fd.append('y', completedCrop.y.toString());
      fd.append('height', completedCrop.height.toString());
      fd.append('width', completedCrop.width.toString());
      const result = await Client.post('/user/avatar', fd);
      if (result.status === 200) {
        setSwapButtonLogic(true);
        setHideShowCrop(false);
        setSwapElements(true);
        showToastHandler(`${t('Avatar successfully saved')}`, 'success');
        loadAvatar();
        if (imgRef.current) imgRef.current.src = '';
        setCrop(undefined);
      }
    } catch (exc) {
      showToastHandler(`${t('Error saving avatar')}`, 'success');
    }
  };
  const imgRef = createRef<HTMLImageElement>();
  const readBlob = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) resolve(e.target.result as string);
        reject('');
      };
      reader.readAsDataURL(file);
    });

  const loadAvatar = async (): Promise<void> => {
    try {
      const res = await Client.get('/user/avatar');
      if (res.status === 200) {
        setCurrentAvatar(res.data.avatar);
      }
    } catch (ex) {
      console.error('Error while loading avatar');
    }
  };

  useEffect(() => {
    loadAvatar();
  }, []);
  useEffect(() => {
    if (crop) {
      setSwapButtonLogic(false);
      setHideShowCrop(true);
      setSwapElements(false);
    }
  }, [crop]);
  const swapButtonType = swapButtonLogic ? 'file' : 'submit';
  const avatarId = 'avatar_input';
  const swapText = currentAvatar ? t('EDIT AVATAR') : t('INSERT AVATAR');
  const showHideCrop = hideShowCrop ? 'block' : 'hidden';
  const elementSwap = swapElements ? 'block' : 'hidden';
  const srcImg = 'data:image/png;base64, ' + currentAvatar;

  return (
    <div className='w-full h-fit'>
      <form onSubmit={handleSubmit(submit)} className='h-full w-full'>
        <div
          className={`${roundingForm}  bg-white flex  desktop:flex-col xl:flex-col lg:flex-col 2xl:flex-col md:flex-row tablet:flex-row sm:flex-row flex-row h-full md:p-0 desktop:p-2 2xl:p-0 xl:p-0 lg:p-0 tablet:p-0 sm:p- p-0 ${
            hideShowCrop ? 'h-full py-3 px-1 flex-col' : 'flex-row h-16'
          } w-full gap-1 items-center justify-center`}
        >
          {!hideShowCrop ? (
            <div className='flex items-center justify-center w-4/12 desktop:w-full xl:w-full lg:w-full 2xl:w-full md:w-4/12 tablet:w-4/12 sm:w-4/12 h-full'>
              <div
                className={`w-full  h-full desktop:pt-1 2xl:pl-0 desktop:pl-0 xl:pl-0 lg:pl-0 md:pl-4 tablet:pl-4 sm:pl-4 ${elementSwap}`}
              >
                {!currentAvatar ? (
                  <div className=' flex w-full 2xl:h-64 xl:h-64 lg:h-64 desktop:h-64 md:h-full tablet:h-full sm:h-full h-full desktop:items-center desktop:justify-center 2xl:justify-center xl:justify-center lg:justify-center md:justify-start md:items-start tablet:justify-start sm:justify-start sm:items-center justify-start items-center desktop:pl-0 md:pl-0 tablet:pl-0 pl-3'>
                    <label className='cursor-pointer h-fit ' htmlFor={avatarId}>
                      <div
                        className={`${iconTransition} group h-fit desktop:block 2xl:block lg:block xl:block  md:hidden tablet:block sm:hidden hidden pt-10`}
                      >
                        <AddAvatarIcon />
                      </div>
                      <div className='2xl:hidden lg:hidden xl:hidden desktop:hidden md:block tablet:block sm:block block'>
                        <AddAvatarIconMobile />
                      </div>
                    </label>
                    <input
                      {...register('avatar')}
                      accept='image/*'
                      onChange={(e) => {
                        if (e.target.files != null) {
                          readBlob(e.target.files[0]).then((blob) => {
                            if (imgRef.current) imgRef.current.src = blob;
                          });
                          setValue('avatar', e.target.files[0]);
                        }
                      }}
                      className='hidden'
                      id={avatarId}
                      type={swapButtonType}
                    />
                  </div>
                ) : (
                  <div className='2xl:h-64 lg:h-64 xl:h-64 desktop:h-64 md:h-20 tablet:h-20 sm:h-16 h-16 desktop:justify-center xl:justify-center lg:justify-center 2xl:justify-center md:justify-start tablet:justify-start sm:justify-start justify-start desktop:pl-0 md:pl-0 tablet:pl-0 pl-4 items-center flex'>
                    <img
                      className='border  border-border rounded-full desktop:h-fit lg:h-fit xl:h-fit 2xl:h-fit  desktop:w-fit xl:w-fit lg:w-fit 2xl:w-fit md:h-16  tablet:h-14 sm:h-14  h-12'
                      src={srcImg}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className={`flex justify-center items-center flex-col h-fit w-fit bg-primary ${showHideCrop}`}>
            <ReactCrop
              className='h-full w-full'
              onComplete={(c) => setCompletedCrop(c)}
              circularCrop
              crop={crop}
              aspect={1}
              onChange={async (c) => setCrop(c)}
            >
              <img ref={imgRef} className='h-64' onLoad={onImageLoad} alt='Avatar' />
            </ReactCrop>
          </div>

          <div className='flex w-full md:justify-end sm:justify-end items-end 2xl:pb-8 lg:pb-8 desktop:mb-4 xl:mb-0 lg:mb-0 md:mb-0 tablet:mb-0 2xl:px-0 lg:px-0 xl:px-0 desktop:px-0 sm:px-5 px-2 tablet:h-4/6 md:h-4/6 sm:h-4/6 sm:mb-0 h-4/6'>
            <label
              htmlFor={avatarId}
              className={`${buttonSwapColor} flex items-center justify-center cursor-pointer desktop:w-full 2xl:w-full lg:mx-10 2xl:mx-20 lg:w-full xl:w-full md:w-56 tablet:w-56 sm:w-56 sm:h-10 w-56 desktop:h-14 md:h-14 tablet:h-14 h-10`}
            >
              {swapText}
            </label>
            <input
              {...register('avatar')}
              accept='image/*'
              onChange={async (e) => {
                if (e.target.files != null) {
                  readBlob(e.target.files[0]).then((blob) => {
                    if (imgRef.current) imgRef.current.src = blob;
                  });

                  setValue('avatar', e.target.files[0]);
                }
              }}
              className='hidden'
              id={avatarId}
              type={swapButtonType}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AvatarCard;
