import React from 'react';
import PaymentModal from './PaymentModal/PaymentModal';
interface ServiceFormProps {
  closeModal?: () => void;
  teamId: number | null;
  teamName: string | null;
  paymentInstructions: boolean;
}
const Payment: React.FC<ServiceFormProps> = ({ paymentInstructions, closeModal, teamId, teamName }) => {
  return (
    <PaymentModal
      closeModal={closeModal}
      teamId={teamId}
      teamName={teamName}
      paymentInstructions={paymentInstructions}
    />
  );
};
export default Payment;
