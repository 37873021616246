import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiClient } from 'src/api/client';
import buttonStyleNewChallenge from 'src/pages/NewChallengePage/NewChallengeButtonStyle/ButtonStyle';
import { Spinner } from 'src/utils/Spinner';
import Button from '../Button';
interface IPriceList {
  closeModal: () => void;
}
const PriceList: React.FC<IPriceList> = ({ closeModal }) => {
  const { t } = useTranslation(['home', 'main']);
  const Client = useApiClient();
  const [price, setPrice] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.language;
    const userLanguage = browserLanguage.slice(0, 2).toUpperCase();
    const localStorageLang = localStorage.getItem('i18nextLng');
    Client.get(`user/priceList/${localStorageLang || userLanguage}`)
      .then((response) => {
        const { price } = response.data;
        setPrice(price);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className='w-full h-full flex flex-col gap-10 items-center'>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <p className='text-center'>{t('PRICE LIST')}</p>
          <div className='text-center'>
            <p>{t('PriceList PART 1')}</p>
            <p>
              {t('PriceList PART 2')}
              <span>
                <span className='font-bold'>{price}</span>.
              </span>
            </p>
            <p>{t('PriceList PART 3')}</p>
            <p>
              {t('PriceList PART 4')}{' '}
              <a className='underline' href='mailto:mail@evalue.cz'>
                mail@evalue.cz
              </a>
            </p>
          </div>
        </>
      )}
      <Button onClick={closeModal} className={`${buttonStyleNewChallenge}`} type={'button'}>
        {t('ОК')}
      </Button>
    </div>
  );
};
export default PriceList;
