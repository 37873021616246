import React from 'react';
import { ReactComponent as Logo } from '../../../images/icons/ParalaxVectors/Logo.svg';
import TabletGreenMountain from 'src/components/GreenMountainBG/TabletGreenMointain';
import GreenMountains from 'src/components/GreenMountainBG/GreemMountain';
import ForgotPasswordPage from 'src/pages/ForgotPasswordPage';
import Login from 'src/components/Login/Login';
import RegisterPage from 'src/pages/RegisterPage/RegisterPage';
import LoginRegisterFooter from 'src/components/LoginRegisterFooter/LoginRegisterFooter';
import { useLoginContext } from 'src/store/loginPageContext';

const MobileLogin: React.FC = () => {
  const loginContext = useLoginContext();

  return (
    <div className='w-full h-full flex absolute bottom-0 right-0 left-0 top-0 overflow-hidden'>
      <div className='w-full h-full justify-end z-20 items-center flex flex-col'>
        <div className='tablet:flex hidden object-none  h-full w-full absolute'>
          <TabletGreenMountain />
        </div>
        <div className='tablet:hidden flex'>
          <GreenMountains />
        </div>
        <div className='w-full h-full flex flex-col items-center z-20'>
          <div className='w-11/12 h-full flex flex-col items-center justify-center'>
            <Logo className='z-10 tablet:w-8/12 w-11/12 h-4/6' />
            <div className='w-full h-full flex items-center justify-center'>
              {loginContext.isLogin ? (
                <Login />
              ) : loginContext.isForgotPassword ? (
                <RegisterPage />
              ) : (
                <ForgotPasswordPage />
              )}
            </div>

            <div className='w-full lg:h-2/6 tablet:h-1/6 h-fit flex items-center'>
              <LoginRegisterFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileLogin;
