import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import SidePanel from '../../components/SidePanel';
import ChallengeLogCard from './ChallengeLogs';
import { Dialog } from '@headlessui/react';
import ServiceForm from './ActivityForm';
import { SidePanelRoutes } from '../../components/SidePanel';

import { LogsDetailProvider, useActivity } from 'src/store/activityCardContext';

import './ServiceFormCard/Scrollbar.css';
import buttonSwapColor from '../HoverButtonsstyle/HooverButtonSwapColor';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import BlueWavesBG from 'src/components/BlueWavesBGimg/BlueWavesBG';
import RowButton from 'src/components/RowButton';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import componentSpacing from 'src/components/css/ComponentSpacing/ComponentSpacing';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import { useTranslation } from 'react-i18next';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';

const Parameters = {
  heightSize: 'h-5/6',
  flexCol: 'flex-col',
  flexRow: 'flex-row',
  vhSize: '[70vh]',
  pb: 'pb-0',
  pbTitleAndButton: 'pb-2',
  hidden: 'hidden',
  flex: 'flex',
  flexTitle: 'flex',
  ptZero: 'pt-0',
  pbZero: 'pb-2',
};
const HomePage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['home', 'main']);
  const activity = useActivity();
  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    activity.setReload(false);
    setIsOpen(true);
  };
  return (
    <LogsDetailProvider>
      <div
        className={`flex 2xl:${Parameters.flexRow} desktop:${Parameters.flexRow} xl:${Parameters.flexRow} lg:${Parameters.flexRow} tablet:${Parameters.flexCol} md:${Parameters.flexRow} sm:${Parameters.flexCol} ${Parameters.flexCol} z-0 bg-gradient-to-r from-lightBlue h-screen w-full to-darkBlue`}
      >
        <BlueWavesBG />
        <div className='z-20 '>
          <SidePanel activeRoute={SidePanelRoutes.SERVICE_LIST} />
        </div>
        <div className={`z-10 ${Parameters.flex} flex-col h-[100%] w-full`}>
          <div className={`w-full flex ${Parameters.flexCol} h-full bg`}>
            <div
              className={`2xl:${Parameters.heightSize} desktop:${Parameters.heightSize} md:${Parameters.heightSize} xl:${Parameters.heightSize} lg:${Parameters.heightSize} sm:${Parameters.heightSize} tablet:${Parameters.heightSize}  w-full flex flex-col ${componentSpacing}`}
            >
              <MobileNav />
              <div
                className={`flex flex-row justify-between phone:items-center items-center lg:items-end   desktop:${Parameters.pbTitleAndButton}  2xl:${Parameters.pbTitleAndButton} xl:${Parameters.pbTitleAndButton} lg:${Parameters.pbTitleAndButton} tablet:${Parameters.pbZero} md:${Parameters.pbZero} sm:${Parameters.pbZero} ${Parameters.pbZero}`}
              >
                <div className={`${titleStyle} ${mobileTitleStyle} `}> {t('Sports Challenge')}</div>
                <div
                  className={`w-2/6 items-end  2xl:${Parameters.flex} xl:${Parameters.flex} lg:${Parameters.flex} desktop:${Parameters.flex} md:${Parameters.hidden} sm:${Parameters.hidden} ${Parameters.hidden}`}
                >
                  <button
                    type='button'
                    onClick={openModal}
                    className={`${buttonSwapColor} desktop:p-4 tablet:p-4 h-14 w-full items-center text-center ${Parameters.flex} ${Parameters.flexRow} cursor-pointer justify-evenly`}
                  >
                    {t('ADD ACTIVITY')}
                  </button>
                </div>
              </div>
              <div className='w-full h-full flex flex-col justify-start gap-1'>
                <table
                  className={`flex flex-col 2xl:pr-2 xl:pr-2 overflow-hidden desktop:pr-2 lg:pr-2 pr-0 desktop:h-[70vh] 2xl:h-[76vh] lg:h-[76vh] xl:h-[80vh] tablet:h-[100vh]  md:h-full sm:h-full w-full h-[65vh] phone:h-[73vh] ${roundingForm}`}
                >
                  <ChallengeLogCard />
                </table>
                <div
                  className={`w-full h-fit 2xl:${Parameters.hidden} desktop:${Parameters.hidden} xl:${Parameters.hidden} lg:${Parameters.hidden} md:${Parameters.flex} sm:${Parameters.flex} tablet:${Parameters.flex} ${Parameters.flex}`}
                >
                  <RowButton className={`${buttonSwapColor} h-14 w-full`} onClick={openModal}>
                    {t('ADD ACTIVITY')}
                  </RowButton>
                </div>
              </div>
              <div className='h-fit w-full'>
                <div className='h-fit w-full '>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
          <button
            type='button'
            className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
            onClick={closeModal}
          >
            x
          </button>
        </Dialog.Title>
        <ServiceForm closeModal={closeModal} />
      </ModalFromLibrary>
    </LogsDetailProvider>
  );
};

export default HomePage;
