import moment from 'moment';
import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import glowField from '../css/GlowField/GlowField';
import '../DatePicker/Calendar.css';

export type DatePickerProps = Omit<
  FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, Date | null>,
  'onChange'
> & {
  onChange?: (date: Date | null) => void;
  isAllowed: boolean;
  challengeStart: string | undefined;
  logCurrentDate: Date | undefined;
  challengeEnd: string | undefined;
};

const EditActivityDatePicker: React.ForwardRefRenderFunction<HTMLInputElement, DatePickerProps> = (props, ref) => {
  const { value, onChange, isAllowed, challengeStart, challengeEnd, logCurrentDate, ...rest } = props;
  const formatDate = moment.utc(logCurrentDate).local().format('YYYY-MM-DD');
  return (
    <input
      type='date'
      disabled={isAllowed ? true : false}
      max={isAllowed ? formatDate : challengeEnd}
      min={isAllowed ? formatDate : challengeStart}
      className={`${glowField} box-border flex-row bg-white p-1 border-lightBlueButton outline-none border-2 rounded-xl desktop:w-full tablet:w-full md:w-full sm:w-full min-w-[90%] h-14`}
      value={value ? moment(value).format('yyyy-MM-DD') : ''}
      onChange={(e) => {
        const date = e.target.value;
        if (!date) {
          onChange?.(null);
          return;
        }
        const parsedDate = moment(date).toDate();
        onChange?.(parsedDate);
      }}
      {...rest}
      ref={ref}
    ></input>
  );
};

export default React.forwardRef(EditActivityDatePicker);
