import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import glowField from '../css/GlowField/GlowField';
import '../Textfield/RemoveArrows.css';
export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;

const NumberFieldWithUnit: React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;
  return (
    <>
      <input className={`${glowField} bg-white`} value={value ?? ''} {...rest} ref={ref}></input>
      {error && <span className='text-primary'>{error}</span>}
    </>
  );
};

export default React.forwardRef(NumberFieldWithUnit);
