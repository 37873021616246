import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from 'src/api/client';
import BlueWavesBG from 'src/components/BlueWavesBGimg/BlueWavesBG';
import Button from 'src/components/Button';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import SidePanel, { SidePanelRoutes } from 'src/components/SidePanel';
import TextField from 'src/components/Textfield';
import buttonSwapColor from '../HoverButtonsstyle/HooverButtonSwapColor';
import AvatarCard from './AvatarCard';
import ChangePasswordFormCard from './ChangePasswordFormCard';
import RegisterLoginPasswordField from 'src/components/RegisterLoginPasswordField';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import { ChangeLanguage } from './ChangeLanguage';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/store/toastContext';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import PriceList from 'src/components/PriceList/PriceList';

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  newPasswordAgain: string;
}

interface NameForm {
  name: string;
}

const UserPage: React.FC<unknown> = () => {
  const Client = useApiClient();
  const { t } = useTranslation(['home', 'main']);
  const navigate = useNavigate();
  const { showToastHandler } = useToast();
  const [isOpen, setIsOpen] = useState(false);

  const [userName, setUserName] = useState<string>('');
  const loadName = async (): Promise<void> => {
    try {
      const res = await Client.get('user/name');
      setUserName(res.data.name);
      setNameValue('name', res.data.name);
    } catch (exc) {
      console.error('Error while getting name');
    }
  };

  useEffect(() => {
    loadName();
  }, []);

  const { handleSubmit, control, getValues, formState } = useForm<ChangePasswordForm>();

  const { handleSubmit: handleNameSubmit, control: nameControl, setValue: setNameValue } = useForm<NameForm>();

  const [isOldPasswordWrong, setOldPasswordWrong] = useState<boolean>(false);

  const onNameSubmit: SubmitHandler<NameForm> = async (data): Promise<void> => {
    try {
      if (data.name === userName) return;
      await Client.post('user/changeName', { name: data.name });
      showToastHandler(`${t('Name successfully changed')}`, 'success');
      setUserName(data.name);
    } catch (exc) {
      console.error('Error while changing name');
    }
  };

  const onSubmit: SubmitHandler<ChangePasswordForm> = async (data) => {
    if (data.newPassword !== data.newPasswordAgain) return;
    if (data.oldPassword == undefined) return;
    if (data.newPassword == undefined) return;
    if (data.newPasswordAgain == undefined) return;

    try {
      await Client.post('user/changePassword', {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      showToastHandler(`${t('Password changed successfully')}`, 'success');
      navigate('/');
    } catch (error) {
      setOldPasswordWrong(true);
    }
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };

  return (
    <div className='flex desktop:flex-row  md:flex-row tablet:flex-row sm:flex-col flex-col bg-gradient-to-r from-lightBlue to-darkBlue desktop:h-full md:h-screen tablet:h-full h-screen overflow-y-auto'>
      <BlueWavesBG />
      <div className='z-20'>
        <SidePanel activeRoute={SidePanelRoutes.USER} />
      </div>
      <MobileNav />
      <div
        className={`z-20 flex flex-row justify-center m-auto w-full 2xl:flex-row desktop:flex-row lg:flex-row xl:flex-row md:flex-col tablet:flex-col sm:flex-col desktop:h-5/6 2xl:h-full md:h-full py-1 tablet:h-5/6  2xl:pt-10 lg:pt-5 desktop:pt-5 md:pt-16 sm:pt-3 md:pb-6 tablet:py-18 xl:py-5 lg:py-5 sm:h-5/6 h-5/6 px-2 pt-3 `}
      >
        <div className='2xl:space-x-40 xl:space-x-36 space-x-0 desktop:flex md:flex-row lg:flex xl:flex tablet:flex-row  sm:flex-col flex-row  sm:justify-start justify-center xl:justify-center lg:justify-center desktop:justify-center 2xl:justify-center gap-5 xl:gap-3 lg:gap-3 sm:gap-5 h-full w-full overflow-y-auto desktop:pt-0 md:pt-0 sm:pt-0'>
          <div className='2xl:w-[40%] xl:w-[40%] lg:w-full items-center flex flex-col pl-0 desktop:h-full xl:h-full md:h-fit tablet:h-fit h-fit  desktop:w-fit md:w-full tablet:w-full  sm:w-full sm:h-fit w-full gap-5 xl:gap-1 lg:gap-1'>
            <div className={`${titleStyle} flex justify-center`}>{t('Avatar')}</div>
            <div className=' w-full'>
              <AvatarCard />
            </div>
          </div>
          <div className='2xl:w-[40%] xl:w-[40%] flex flex-col desktop:h-full  md:h-fit tablet:h-fit h-fit relative w-full desktop:w-fit  lg:w-full lg:h-full 2xl:h-full  md:w-full tablet:w-full  sm:w-full sm:h-fit 2xl:pt-0 desktop:pt-0 xl:pt-0 lg:pt-0 tablet:pt-5 sm:pt-5 md:pt-5'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='desktop:h-full md:h-full tablet:h-full h-fit sm:h-fit sm:w-full w-full'
            >
              <div className='h-fit flex flex-col 2xl:pt-0 desktop:pt-0 xl:pt-0 lg:pt-0 tablet:pt-3 sm:pt-2 md:pt-2 pt-5'>
                <div className={`${titleStyle} flex justify-center  mb-1`}>{t('Change password')}</div>
                <ChangePasswordFormCard>
                  <div className={`mx-5 phone:mx-5 lg:mx-10 lg:py-6 2xl:mx-20 py-10`}>
                    <div className='grid grid-cols-2 mb-4'>
                      <div className='font-normal text-DarkPrimary text-base my-auto'>{t('OLD PASSWORD:')}</div>
                      <Controller
                        control={control}
                        name='oldPassword'
                        rules={{ required: { value: true, message: `${t('Enter the old password')}` }, minLength: 4 }}
                        render={({ field }) => (
                          <>
                            <RegisterLoginPasswordField {...field} />
                          </>
                        )}
                      />
                    </div>
                    <div className='font-normal grid grid-cols-2 mb-4'>
                      <div className='font-normal text-DarkPrimary text-base my-auto'>{t('NEW PASSWORD:')}</div>
                      <Controller
                        control={control}
                        rules={{
                          required: { value: true, message: `${t('Enter a new password')}` },
                          minLength: { value: 4, message: `${t('The new password is too short')}` },
                        }}
                        name='newPassword'
                        render={({ field }) => (
                          <>
                            <RegisterLoginPasswordField {...field} />
                          </>
                        )}
                      />
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='font-normal text-DarkPrimary text-base my-auto'>{t('PASSWORD AGAIN:')}</div>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                          validate: (val) => val === getValues('newPassword') || `${t("New passwords don't match")}`,
                        }}
                        name='newPasswordAgain'
                        render={({ field }) => (
                          <>
                            <RegisterLoginPasswordField {...field} />
                          </>
                        )}
                      />
                    </div>
                    <div className='text-primary text-sm flex justify-center mt-2'>
                      <div className='flex flex-col items-end'>
                        {isOldPasswordWrong && <span>{t('The old password is not correct')}</span>}
                        {formState.errors.newPassword?.message && <span>{formState.errors.newPassword?.message}</span>}
                        {formState.errors.newPasswordAgain?.message && (
                          <span>{formState.errors.newPasswordAgain?.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-center w-full pb-8'>
                    <Button
                      className={`${buttonSwapColor} w-full mx-5 2xl:mx-20 phone:mx-5 lg:mx-10 desktop:h-14 tablet:h-14 h-10`}
                      type='submit'
                    >
                      {t('CHANGE PASSWORD')}
                    </Button>
                  </div>
                </ChangePasswordFormCard>
              </div>
            </form>
            <div className='h-fit 2xl:pt-0 desktop:pt-0 xl:pt-0 lg:pt-0 tablet:pt-3 sm:pt-2 md:pt-2 pt-5'>
              <div className={`${titleStyle} flex justify-center mb-1`}>{t('Settings')}</div>
              <div className={`w-full justify-end py-3 h-fit ${roundingForm}`}>
                <form onSubmit={handleNameSubmit(onNameSubmit)}>
                  <div className='pt-5 space-y-4 h-full'>
                    <div className='grid grid-cols-2 m-auto  mx-5 2xl:mx-20 phone:mx-5 lg:mx-10 tablet:mx-5'>
                      <div className='font-normal text-DarkPrimary text-base my-auto'>{t('NAME')}</div>
                      <Controller
                        control={nameControl}
                        name='name'
                        defaultValue={userName}
                        render={({ field }) => (
                          <>
                            <TextField type={'text'} {...field} />
                          </>
                        )}
                      />
                    </div>
                    <div className=' flex justify-center items-end w-full pb-8 '>
                      <Button
                        className={`${buttonSwapColor} w-full mx-5 2xl:mx-20 phone:mx-5 lg:mx-10 desktop:h-14 tablet:h-14 h-10 `}
                        type={'submit'}
                      >
                        {t('CHANGE NAME')}
                      </Button>
                    </div>
                  </div>
                </form>
                <div className='flex flex-col 2xl:mx-20 phone:mx-5 lg:mx-10 mx-5 mb-4'>
                  <ChangeLanguage />
                </div>
                <div className='flex flex-col 2xl:mx-20 phone:mx-5 lg:mx-10 mx-5 mb-4'>
                  <div
                    onClick={openModal}
                    className='font-normal text-DarkPrimary text-base my-auto underline cursor-pointer'
                  >
                    {t('PRICE LIST')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
          <button
            type='button'
            className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle absolute right-6 top-6`}
            onClick={closeModal}
          >
            x
          </button>
        </Dialog.Title>
        <PriceList closeModal={closeModal} />
      </ModalFromLibrary>
    </div>
  );
};
export default UserPage;
