import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiClient } from 'src/api/client';
import glowField from '../../../components/css/GlowField/GlowField';
import { useToast } from 'src/store/toastContext';

export const ChangeLanguage = (): JSX.Element => {
  const Client = useApiClient();
  const { t, i18n } = useTranslation(['home', 'main']);
  const { showToastHandler } = useToast();
  const [selectLanguage, setSelectLanguage] = useState<string>();

  const onClickLanguageChange = async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const language = e.target.value;
    await Client.post('user/changeLanguage', { language });
    setSelectLanguage(language);
    i18n.changeLanguage(language);
    showToastHandler(`${t('Language successfully changed')}`, 'success');
  };

  useEffect(() => {
    Client.get(`user/getLanguageByUser`)
      .then((response) => {
        setSelectLanguage(response.data.language);
        i18n.changeLanguage(response.data.language);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [selectLanguage]);

  return (
    <div className='grid grid-cols-2'>
      <div className='font-normal text-DarkPrimary text-base my-auto w-full'>{t('CHANGE LANGUAGE:')}</div>
      <select
        className={`${glowField} w-full text-DarkPrimary`}
        value={selectLanguage}
        defaultValue={selectLanguage}
        onChange={onClickLanguageChange}
      >
        <option value='EN'>English</option>
        <option value='CZ'>Česky</option>
        <option value='BG'>Български</option>
      </select>
    </div>
  );
};

export default ChangeLanguage;
