import addDays from 'date-fns/addDays';
import React, { createContext, useContext, useState } from 'react';
import { ChallengeTarget, ChallengeTeam } from 'src/pages/NewChallengePage/NewChallengeCard';
interface ChallengeTargetsContextProps {
  challengeTargets: ChallengeTarget[];
  newChallengeForm: ChallengeTeam[];
  setChallengeTargets: (targets: ChallengeTarget[]) => void;
  add: (targets: ChallengeTarget) => void;
  setChallengeForm: (challengeForm: ChallengeTeam[]) => void;
  addChallengeForm: (challengeForm: ChallengeTeam) => void;
  teamName: string;
  setTeamName: (value: string) => void;
  selectedTeamName: string;
  setSelectedTeamName: (value: string) => void;
  challengeName: string;
  setChallengeName: (value: string) => void;
  dateFrom: Date;
  setDateFrom: (value: Date) => void;
  dateTo: Date;
  setDateTo: (value: Date) => void;
  deadLineDate: Date;
  setDeadLineDate: (value: Date) => void;
  emails: string[];
  setEmails: (value: string[]) => void;
  teamId: number | null;
  setTeamid: (value: number | null) => void;
  challengeId: number | null;
  setChallengeId: (value: number | null) => void;
  adminTeamEmail: string | null;
  setAdminTeamEmail: (value: string | null) => void;
  description: string | null;
  setDescrtiption: (value: string | null) => void;
  checkBoxState: boolean;
  setCheckBoxState: (value: boolean) => void;
  checkBoxDeadlineDate: boolean;
  setCheckBoxDeadlineDate: (value: boolean) => void;
  checkBoxAutoApprove: boolean;
  setCheckBoxAutoApprove: (value: boolean) => void;
  localStorageData: object | undefined;
  setLocalStorageData: (value: object | undefined) => void;
  removeLocalStorageData: () => void;
  clearAllData: () => void;
  confirmUserLicence: boolean;
  SetConfirmUserLicence: (value: boolean) => void;
  licence: Date | undefined;
  SetLicence: (value: Date | undefined) => void;
}
interface ChallengeTargetsProviderProps {
  children: React.ReactNode;
}
const defaultState: ChallengeTargetsContextProps = {
  licence: new Date(),
  SetLicence: () => {
    return;
  },
  confirmUserLicence: false || true,
  SetConfirmUserLicence() {
    return;
  },
  localStorageData: {},
  setLocalStorageData() {
    return;
  },
  checkBoxState: false || true,
  setCheckBoxState() {
    return;
  },
  checkBoxDeadlineDate: false,
  setCheckBoxDeadlineDate() {
    return;
  },
  checkBoxAutoApprove: false,
  setCheckBoxAutoApprove() {
    return;
  },
  description: '',
  setDescrtiption() {
    return;
  },
  adminTeamEmail: '',
  setAdminTeamEmail() {
    return;
  },
  teamId: 0,
  setTeamid: () => {
    return;
  },
  challengeId: 0,
  setChallengeId: () => {
    return;
  },
  emails: [],
  setEmails: () => {
    return;
  },
  teamName: '',
  setTeamName: () => {
    return;
  },
  selectedTeamName: '',
  setSelectedTeamName: () => {
    return;
  },
  challengeName: '',
  setChallengeName: () => {
    return;
  },
  dateFrom: new Date(),
  setDateFrom: () => {
    return;
  },
  dateTo: new Date(),
  setDateTo: () => {
    return;
  },
  deadLineDate: new Date(),
  setDeadLineDate: () => {
    return;
  },
  challengeTargets: [],
  setChallengeTargets: () => {
    return;
  },
  add: () => {
    return;
  },
  newChallengeForm: [],
  setChallengeForm: () => {
    return;
  },
  addChallengeForm: () => {
    return;
  },
  removeLocalStorageData: () => {
    return;
  },
  clearAllData: () => {
    return;
  },
};
export const ChallengeTargetsContext = createContext<ChallengeTargetsContextProps>(defaultState);
export const ChallengeTargetsProvider: React.FC<ChallengeTargetsProviderProps> = ({ children }) => {
  const removeLocalStorageData = (): void => {
    localStorage.removeItem('data');
    localStorage.removeItem('emails');
    localStorage.removeItem('description');
    localStorage.removeItem('targets');
    localStorage.removeItem('checkBoxState');
    localStorage.removeItem('activeDeadlineDate');
    localStorage.removeItem('autoApprove');
    localStorage.removeItem('confirmLicence');
  };
  const clearAllData = (): void => {
    setAdminTeamEmail('');
    setChallengeName('');
    setTeamName('');
    setDateFrom(new Date());
    setDateTo(addDays(new Date(), 14));
    setDeadLineDate(addDays(new Date(), 16));
    setEmails([]);
    setTeamid(0);
    setChallengeId(0);
    setChallengeTargets([]);
    setDescrtiption('');
    removeLocalStorageData();
    SetConfirmUserLicence(false);
  };

  const [challengeTargets, setChallengeTargets] = useState<ChallengeTarget[]>([]);
  const [newChallengeForm, setChallengeForm] = useState<ChallengeTeam[]>([]);
  const [teamName, setTeamName] = useState<string>('');
  const [selectedTeamName, setSelectedTeamName] = useState<string>('');
  const [challengeName, setChallengeName] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<Date>(new Date());
  const [dateTo, setDateTo] = useState<Date>(addDays(new Date(), 14));
  const [teamId, setTeamid] = useState<number | null>(0);
  const [challengeId, setChallengeId] = useState<number | null>(0);
  const [deadLineDate, setDeadLineDate] = useState<Date>(addDays(new Date(), 16));
  const [emails, setEmails] = useState<string[]>([]);
  const [adminTeamEmail, setAdminTeamEmail] = useState<string | null>('');
  const [description, setDescrtiption] = useState<string | null>('');
  const [checkBoxState, setCheckBoxState] = useState<boolean>(false);
  const [checkBoxDeadlineDate, setCheckBoxDeadlineDate] = useState<boolean>(false);
  const [checkBoxAutoApprove, setCheckBoxAutoApprove] = useState<boolean>(false);
  const [confirmUserLicence, SetConfirmUserLicence] = useState<boolean>(false);
  const [localStorageData, setLocalStorageData] = useState<object | undefined>();
  const [licence, setLicence] = useState<Date | undefined>();
  const handleSetLicence = (licence: Date | undefined): void => {
    setLicence(licence);
  };
  const handleSetConfirmUserLicence = (confirmUserLicence: boolean): void => {
    SetConfirmUserLicence(confirmUserLicence);
  };
  const handleClearAllData = (): void => {
    clearAllData();
  };
  const handleRemoveLocalStorageDate = (): void => {
    removeLocalStorageData();
  };
  const handleSetLocalStorageData = (localStorageData: object | undefined): void => {
    setLocalStorageData(localStorageData);
  };
  const handleSetStateCheckBox = (checkBoxState: boolean): void => {
    setCheckBoxState(checkBoxState);
  };
  const handleSetCheckBoxDeadlineDate = (checkBoxDeadlineDate: boolean): void => {
    setCheckBoxDeadlineDate(checkBoxDeadlineDate);
  };
  const handleSetCheckBoxAutoApprove = (checkBoxAutoApprove: boolean): void => {
    setCheckBoxAutoApprove(checkBoxAutoApprove);
  };
  const handleDescription = (description: string | null): void => {
    setDescrtiption(description);
  };
  const handleSetTeamId = (teamId: number | null): void => {
    setTeamid(teamId);
  };
  const handleSetChallengeId = (challengeId: number | null): void => {
    setChallengeId(challengeId);
  };
  const handleSetTeamAdminEmail = (adminTeamEmail: string | null): void => {
    setAdminTeamEmail(adminTeamEmail);
  };

  const handleSetEmails = (emails: string[]): void => {
    setEmails(emails);
  };
  const handleSetTeamName = (team: string): void => {
    setTeamName(team);
  };
  const handleSetSelectedTeamName = (selectedTeamName: string): void => {
    setSelectedTeamName(selectedTeamName);
  };
  const handleChallengeName = (challengeName: string): void => {
    setChallengeName(challengeName);
  };
  const handelSetDateFrom = (dateFrom: Date): void => {
    setDateFrom(dateFrom);
  };
  const handleSetDateTo = (dateTo: Date): void => {
    setDateTo(dateTo);
  };
  const handleSetDeadLineDate = (deadLineDate: Date): void => {
    setDeadLineDate(deadLineDate);
  };
  const handleSetChallengeTargets = (targets: ChallengeTarget[]): void => {
    setChallengeTargets(targets);
  };
  const add = (target: ChallengeTarget): void => {
    setChallengeTargets([...challengeTargets, target]);
  };
  const handleSetChallengeForm = (challengeForm: ChallengeTeam[]): void => {
    setChallengeForm(challengeForm);
  };

  const addChallengeForm = (challengeForm: ChallengeTeam): void => {
    setChallengeForm([...newChallengeForm, challengeForm]);
  };

  return (
    <ChallengeTargetsContext.Provider
      value={{
        licence: licence,
        SetLicence: handleSetLicence,
        confirmUserLicence: confirmUserLicence,
        SetConfirmUserLicence: handleSetConfirmUserLicence,
        clearAllData: handleClearAllData,
        removeLocalStorageData: handleRemoveLocalStorageDate,
        setLocalStorageData: handleSetLocalStorageData,
        localStorageData: localStorageData,
        checkBoxState: checkBoxState,
        setCheckBoxState: handleSetStateCheckBox,
        checkBoxDeadlineDate: checkBoxDeadlineDate,
        setCheckBoxDeadlineDate: handleSetCheckBoxDeadlineDate,
        checkBoxAutoApprove: checkBoxAutoApprove,
        setCheckBoxAutoApprove: handleSetCheckBoxAutoApprove,
        description: description,
        setDescrtiption: handleDescription,
        adminTeamEmail: adminTeamEmail,
        setAdminTeamEmail: handleSetTeamAdminEmail,
        teamId: teamId,
        setTeamid: handleSetTeamId,
        challengeId: challengeId,
        setChallengeId: handleSetChallengeId,
        emails: emails,
        setEmails: handleSetEmails,
        challengeName: challengeName,
        setChallengeName: handleChallengeName,
        dateFrom: dateFrom,
        setDateFrom: handelSetDateFrom,
        dateTo: dateTo,
        setDateTo: handleSetDateTo,
        deadLineDate: deadLineDate,
        setDeadLineDate: handleSetDeadLineDate,
        challengeTargets,
        teamName: teamName,
        selectedTeamName: selectedTeamName,
        setTeamName: handleSetTeamName,
        setSelectedTeamName: handleSetSelectedTeamName,
        add: add,
        setChallengeTargets: handleSetChallengeTargets,
        newChallengeForm,
        addChallengeForm: addChallengeForm,
        setChallengeForm: handleSetChallengeForm,
      }}
    >
      {children}
    </ChallengeTargetsContext.Provider>
  );
};
export const useChallengeData = (): ChallengeTargetsContextProps => useContext(ChallengeTargetsContext);
