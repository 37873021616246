import Button from 'src/components/Button';
import React, { createRef, useEffect, useState } from 'react';
import glowField from 'src/components/css/GlowField/GlowField';
import TrashIcon from 'src/components/Icons/TrashIcon';
import rounding from 'src/components/RoundingOfAllForms/Rounding';
import roundingFields from 'src/components/RoundingOfAllForms/RoundingFields';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import Textfield from 'src/components/Textfield';
import buttonSwapColor from 'src/pages/HoverButtonsstyle/HooverButtonSwapColor';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import Tooltip from 'src/utils/Tooltip';
import swal from 'sweetalert2';
import GetValidEmails from '../../NewChallengePage/AddUsersPage/SeparateMails';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RemoveIcon } from '../../../images/icons/RemoveIcon.svg';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import fontColor from 'src/components/css/TitleColor/TitleColor';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import stepButton from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/StepButtonsStyle';
import { useApiClient } from 'src/api/client';
import { AxiosError } from 'axios';
import { checkCountOfMembers, checkLicenceIsInvalid, checkMembersAndLicence } from 'src/utils/LicenceValidations';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import { useToast } from 'src/store/toastContext';
import Payment from 'src/components/Payment/Payment';
import { PaymentDetailProvider } from 'src/store/paymentContext';

interface IError {
  message: string;
}
interface IButtonHandleProps {
  editChallenge: () => void;
  loadData: () => void;
}

const EditUserPage = ({ editChallenge, loadData }: IButtonHandleProps): JSX.Element => {
  const { showToastHandler } = useToast();
  const [inputEmail, setInputEmail] = useState<string>('');
  const { t } = useTranslation(['home', 'main']);
  const challengeData = useChallengeData();
  const [emails, setEmails] = useState<string[]>(challengeData.emails || []);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isNotEmail, setIsNotEmail] = useState<boolean>(false);
  const autocompleteRef = createRef<HTMLInputElement>();
  const Client = useApiClient();
  const submit = async (): Promise<void> => {
    try {
      await Client.post('/challenge/edit', {
        challenge_id: challengeData.challengeId,
        team_id: challengeData.teamId,
        team_name: challengeData.teamName,
        challenge_name: challengeData.challengeName,
        date_from: challengeData.dateFrom,
        date_to: challengeData.dateTo,
        deadline_Date: challengeData.deadLineDate,
        emails: challengeData.emails.toString(),
        targets: challengeData.challengeTargets,
        confirmUserLicence: challengeData.confirmUserLicence,
      });
    } catch (exc) {
      const err = exc as AxiosError;
      if (err?.response?.status === 403) {
        const { message } = err.response.data as IError;
        if (message) {
          showToastHandler(message, 'error');
        } else {
          swal.fire({
            title: `${t('The team already exists or you are not an admin')}`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
        autocompleteRef.current?.focus();
      }
    } finally {
      loadData();
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };
  useEffect(() => {
    const isLicenceInvalid = checkLicenceIsInvalid(challengeData.licence);
    const isValidCountOfMembers = checkCountOfMembers(emails.length);
    if (isLicenceInvalid && isValidCountOfMembers && !challengeData.confirmUserLicence) {
      openModal();
    }
    challengeData.setEmails(emails);
  }, [emails]);
  const pasteHandler = (event: React.ClipboardEvent): void => {
    event.preventDefault();
    const pastedEmails = event.clipboardData.getData('text');
    EmailValidator(pastedEmails);
  };
  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLElement>): void => {
    const key = event.key;
    if (['Enter', 'Tab'].includes(key)) {
      onClick();
    }
  };
  const onClick = (): void => {
    EmailValidator(inputEmail);
  };
  function EmailValidator(email: string): void {
    if (!email.length) {
      setIsEmpty(true);
      return;
    }
    const validEmails = GetValidEmails(email.trim());
    if (!validEmails.length) {
      setIsNotEmail(true);
      return;
    }
    const containsEmails: string[] = [];
    validEmails.forEach((email) => {
      const isContains = emails.find((x) => x == email);
      if (!isContains) {
        setIsEmpty(false);
        setIsNotEmail(false);
        setEmails((oldArray) => [...oldArray, email]);
        setInputEmail('');
      } else {
        containsEmails.push(email);
      }
    });

    if (containsEmails.length) {
      swal.fire({
        title: `${containsEmails.length} email/s is exist!`,
        text: `${containsEmails.map((currentMail) => {
          return ' ' + currentMail;
        })}`,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }
  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const enteredName = event.target.value;
    setIsEmpty(false);
    setIsNotEmail(false);
    setInputEmail(enteredName);
  };
  useEffect(() => {
    if (challengeData.confirmUserLicence) {
      showToastHandler(t('ACTIVATE INFO'), 'success');
    }
  }, []);

  return (
    <div
      className={`w-full lg:h-[80vh] phone:h-[90vh] h-[90vh] flex flex-col justify-center items-center lg:px-10 phone:px-5 px-5 `}
    >
      <div className={`${titleStyle} ${mobileTitleStyle}  uppercase`}>{t('Edit users')}</div>
      <div
        className={`bg-white lg:mt-3 phone:mt-10 mt-10  bg-opacity-[0.75] shadow-2xl  lg:shadow-none flex flex-col w-full h-full  justify-end items-center overflow-y-auto overflow-hidden ${padding}  ${roundingForm}`}
      >
        <div
          className={`w-full h-full flex flex-col ${rounding} justify-between gap-2 lg:px-0 phone:px-9 px-7 lg:py-0 phone:pt-5 pt-5 overflow-hidden `}
        >
          <div className='w-full h-fit'>
            <div className='w-5/12 flex flex-row'>
              <p className={`${newChallengeTitleStyle} ${fontColor} w-full item-center`}>{t('ADD USERS:')}</p>
            </div>
            <div className='lg:w-full w-full 2xl:h-14 xl:h-12 lg:h-10 flex h-fit flex-row gap-2'>
              <div className='w-full h-fit flex gap-2'>
                <div className='w-1/3 relative h-fit'>
                  <Textfield
                    className={`${glowField} text-DarkPrimary box-border p-2 flex-row pr-8 ${
                      isEmpty || isNotEmail ? 'border-primary' : 'border-lightBlueButton'
                    }  outline-none border-2 w-full 2xl:h-14 h-12 ${roundingFields}`}
                    placeholder={`${t('Add user email')}`}
                    type='text'
                    id='email'
                    name='email'
                    value={inputEmail}
                    onPaste={pasteHandler}
                    onKeyDown={onKeyDownHandler}
                    onChange={inputHandler}
                  />
                  <div className='w-full flex justify-start h-full'>
                    <span className='text-primary  text-sm w-full'>{isEmpty ? `${t('Field is empty!')}` : ''}</span>
                    <span className='text-primary  text-sm w-full justify-end flex'>
                      {isNotEmail ? `${t('Invalid email!')}` : ''}
                    </span>
                  </div>
                  <div className={`absolute right-2 top-0 flex justify-center items-center my-auto 2xl:h-14 h-10 `}>
                    <span
                      className='flex justify-center bg-lightBlueButton rounded-full w-6 text-white cursor-pointer'
                      onClick={() => {
                        setInputEmail('');
                        setIsEmpty(false);
                        setIsNotEmail(false);
                      }}
                    >
                      x
                    </span>
                  </div>
                </div>

                <div className='w-1/3 2xl:h-14 h-10 flex lg:justify-start justify-end items-center'>
                  <Button
                    className={`${buttonSwapColor} lg:w-1/2 w-full h-full  text-center flex justify-center items-center 2xl:text-xl xl:text-md phone:text text-[14px]`}
                    onClick={onClick}
                  >
                    {t('ADD')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='h-full flex flex-col items-left lg:pt-5 space-y-1 overflow-hidden'>
            <p className={`${newChallengeTitleStyle} ${fontColor}`}>
              {t('EMAILS')}: {emails.length}
            </p>
            <div
              className={`border-lightBlueButton border-2 flex flex-row 2xl:h-[44vh] xl:h-[40vh] phone:h-full lg:h-[32vh] h-full pr-3 items-start w-full ${roundingForm} 2xl:pr-4 xl:pr-2 py-2 overflow-hidden`}
            >
              <div className='flex lg:flex-row tablet:flex-row md:flex-row flex-col 2xl:h-[42vh] xl:h-[38vh] lg:h-[32vh]  phone:h-full h-full overflow-y-auto overflow-hidden w-full items-start'>
                <div className='w-full  flex justify-center lg:py-1 phone:py-0 py-0 items-center'>
                  <p className='w-8/12 flex items-center flex-col' placeholder={`${t('All added users....')}`}>
                    {emails.map((email, index) => {
                      if (index % 2 == 0) {
                        return (
                          <>
                            <div className='flex w-full flex-wrap relative justify-center space-x-2 pb-2' key={index}>
                              <span
                                className={`w-full px-4 py-2 justify-between rounded-full text-DarkPrimary font-semibold border-lightGreen border-[2px] text-sm flex align-center ${glowField} ${
                                  email == challengeData.adminTeamEmail
                                    ? 'bg-grayColor bg-opacity-50'
                                    : 'cursor-pointer bg-white'
                                } active:bg-gray transition duration-300 ease `}
                              >
                                <Tooltip tooltip={email} tooltipContent={email} />
                                <button
                                  className='bg-transparent hover focus:outline-none'
                                  onClick={() => {
                                    const result = challengeData.emails.filter((x) => x !== email);
                                    setEmails(result);
                                  }}
                                >
                                  <div>{email == challengeData.adminTeamEmail ? '' : <RemoveIcon />}</div>
                                </button>
                              </span>
                            </div>
                          </>
                        );
                      }
                    })}
                  </p>
                </div>
                <div className='w-full flex justify-center lg:pt-1 phone:pt-0 pt-0 items-center'>
                  <p className='w-8/12 flex items-center flex-col' placeholder='All added users....'>
                    {emails.map((email, index) => {
                      if (index % 2 != 0) {
                        return (
                          <>
                            <div className='flex w-full flex-wrap relative justify-center space-x-2 pb-2' key={index}>
                              <span
                                className={`px-4 py-2 w-full justify-between rounded-full text-DarkPrimary bg-white font-semibold border-lightGreen border-[2px] text-sm flex align-center cursor-pointer active:bg-gray transition duration-300 ease ${glowField}`}
                              >
                                <Tooltip tooltip={email} tooltipContent={email} />
                                <button
                                  className='bg-transparent hover focus:outline-none'
                                  onClick={() => {
                                    const result = challengeData.emails.filter((x) => x !== email);
                                    setEmails(result);
                                  }}
                                >
                                  <div className='pr-1'>
                                    <RemoveIcon />
                                  </div>
                                </button>
                              </span>
                            </div>
                          </>
                        );
                      }
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className='w-full h-fit justify-center flex lg:items-end items-center lg:justify-end'>
              <span className={`${newChallengeTitleStyle} underline justify-end lg:flex hidden text-DarkPrimary`}>
                {t('CLEAR ALL EMAILS:')}
              </span>
              <span
                className='cursor-pointer'
                onClick={() => {
                  swal
                    .fire({
                      title: `${t('Are you sure you want to delete all emails?')}`,
                      text: `${t("You won't be able to revert this!")}`,
                      showCancelButton: true,
                      cancelButtonText: `${t('CANCEL')}`,
                      confirmButtonText: `${t('YES')}`,
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        if (challengeData.adminTeamEmail) {
                          setEmails([`${challengeData.adminTeamEmail}`]);
                        } else {
                          setEmails([]);
                        }
                      }
                    });
                }}
              >
                <div className='pl-1 2xl:scale-110 lg:scale-75 lg:pt-0 pt-3'>
                  <TrashIcon />
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className='text-center lg:w-1/2 w-full flex flex-col justify-center h-fit items-center my-4'>
          <Button
            className={`${stepButton} tracking-widest ${rounding} text-white 2xl:text-xl desktop:text-xl tablet:text-xl text-[14px] desktop:font-bold md:font-bold tablet:font-bold font-semibold ${
              !challengeData.emails.length ? 'bg-gray' : 'bg-lightBlueButton hover:bg-darkGreenHoverButton'
            }`}
            disabled={challengeData.emails.length ? false : true}
            type={'button'}
            onClick={() => {
              if (challengeData.emails.length !== 0) {
                submit();
                const isLicenceValid = checkMembersAndLicence(emails.length, challengeData.licence);
                if (isLicenceValid || (!isLicenceValid && challengeData.confirmUserLicence)) {
                  challengeData.clearAllData();
                  editChallenge();
                } else {
                  openModal();
                }
              }
            }}
          >
            {t('CONFIRM')}
          </Button>
        </div>
      </div>
      <PaymentDetailProvider>
        <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
          <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
            <button
              type='button'
              className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
              onClick={closeModal}
            >
              x
            </button>
          </Dialog.Title>
          <Payment
            closeModal={closeModal}
            teamId={challengeData.teamId}
            teamName={challengeData.teamName}
            paymentInstructions={false}
          />
        </ModalFromLibrary>
      </PaymentDetailProvider>
    </div>
  );
};
export default EditUserPage;
