import React from 'react';
import './Arrows.css';

import { FieldProps } from 'src/types/commonTypes';
import glowFieldSelect from '../css/GlowField/GlowFieldSelect';
import errorGlowFieldSelect from '../css/GlowField/ErrorGloqFieldSelect';
export type SelectProps = FieldProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement,
  string | number | null
>;

const Select = React.forwardRef((props: SelectProps, ref: React.Ref<HTMLSelectElement>) => {
  const { value, error, ...rest } = props;

  return (
    <select
      className={error ? `${errorGlowFieldSelect} text-errorRed` : `${glowFieldSelect} text-DarkPrimary`}
      value={value ?? ''}
      ref={ref}
      {...rest}
    >
      {props.children}
    </select>
  );
});

Select.displayName = 'Select';

export default Select;
