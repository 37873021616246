import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import textQuestionStyle from '../css/LinksTextStyle/TextQuestionStyle';
import '../Textfield/RemoveArrows.css';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from 'src/store/loginPageContext';

export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;

const RegisterLink: React.FC = () => {
  const useLogin = useLoginContext();
  const { t } = useTranslation(['home', 'main']);
  return (
    <div className='flex flex-row lg:pb-0 lg:pt-5 py-2'>
      <div className={textQuestionStyle}>
        <span>{t("Don't have a registration?")}</span>
      </div>
      <div className={`${textQuestionStyle} underline w-full justify-end items-end flex cursor-pointer`}>
        <span
          onClick={() => {
            useLogin.setIsLogin(false);
            useLogin.setIsForgotPassword(true);
          }}
        >
          {t('Sign up')}
        </span>
      </div>
    </div>
  );
};

export default RegisterLink;
