import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaymentData } from 'src/store/paymentContext';
import glowField from '../../css/GlowField/GlowField';
interface ISelectCurrency {
  adminLanguage: string;
}
const SelectCurencyUnit: React.FC<ISelectCurrency> = ({ adminLanguage }) => {
  const { t } = useTranslation(['home', 'main']);

  const [currency, setCurrency] = useState<string>(adminLanguage);

  const paymentData = usePaymentData();

  const onChangeChangeCurrency = async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const selecteedCurrency = e.target.value;
    paymentData.setCurrency(selecteedCurrency);
    paymentData.setSelectedCurrency(selecteedCurrency);
    setCurrency(selecteedCurrency);
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='flex-row w-full flex'>
        <div className='font-normal text-DarkPrimary text-base my-auto w-full'>{t('SELECT CURRENCY')}</div>
        <div className='w-4/6 h-full justify-end flex items-end'>
          <select
            onChange={onChangeChangeCurrency}
            className={`${glowField} w-full text-DarkPrimary`}
            defaultValue={currency}
            value={currency}
          >
            <option value='EN'>EUR</option>
            <option value='CZ'>CZK</option>
            <option value='BG'>BGN</option>
          </select>
        </div>
      </div>
    </div>
  );
};
export default SelectCurencyUnit;
