import React from 'react';

const RunManSidePanel: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='70' height='56' viewBox='0 0 70 56' fill='none'>
    <path
      d='M10.8785 52.4115C9.44829 51.0669 9.62261 49.0293 11.2679 47.8604L33.2937 32.2124C34.939 31.0435 37.4322 31.186 38.8624 32.5306C40.2926 33.8752 40.1183 35.9128 38.473 37.0816L16.4472 52.7297C14.802 53.8985 12.3088 53.7561 10.8785 52.4115Z'
      fill='white'
    />
    <path
      d='M12.0363 23.3792C11.7503 23.1103 11.7851 22.7028 12.1142 22.469L21.7731 15.607C22.1022 15.3733 22.6008 15.4018 22.8869 15.6707C23.1729 15.9396 23.138 16.3471 22.809 16.5809L13.15 23.4429C12.821 23.6766 12.3224 23.6481 12.0363 23.3792Z'
      fill='white'
    />
    <path
      d='M0.983941 45.9607C0.697897 45.6918 0.732765 45.2843 1.06182 45.0505L10.7208 38.1885C11.0498 37.9547 11.5485 37.9832 11.8345 38.2522C12.1205 38.5211 12.0857 38.9286 11.7566 39.1624L2.09768 46.0244C1.76862 46.2581 1.26998 46.2296 0.983941 45.9607Z'
      fill='white'
    />
    <path
      d='M12.0363 27.8955C11.7503 27.6266 11.7851 27.2191 12.1142 26.9853L21.7731 20.1233C22.1022 19.8896 22.6008 19.9181 22.8869 20.187C23.1729 20.4559 23.138 20.8634 22.809 21.0972L13.15 27.9592C12.821 28.1929 12.3224 28.1644 12.0363 27.8955Z'
      fill='white'
    />
    <path
      d='M0.983941 50.477C0.697897 50.2081 0.732765 49.8006 1.06182 49.5668L10.7208 42.7048C11.0498 42.471 11.5485 42.4995 11.8345 42.7685C12.1205 43.0374 12.0857 43.4449 11.7566 43.6787L2.09768 50.5407C1.76862 50.7744 1.26998 50.7459 0.983941 50.477Z'
      fill='white'
    />
    <path
      d='M61.7623 34.7916C60.5426 36.2682 58.0891 36.6573 56.2822 35.6604L43.1957 28.4409C41.3888 27.4441 40.9128 25.4389 42.1325 23.9623C43.3522 22.4856 45.8058 22.0966 47.6126 23.0934L60.6992 30.3129C62.506 31.3097 62.982 33.3149 61.7623 34.7916Z'
      fill='white'
    />
    <path
      d='M41.9914 44.9854C40.5981 43.6595 40.7708 41.6641 42.3785 40.5116L55.7988 30.8916C57.2805 29.8295 59.4887 29.8094 60.9989 30.8444C62.8881 32.1389 62.9052 34.5094 61.0348 35.8221L47.4923 45.3269C45.8578 46.474 43.395 46.3211 41.9914 44.9854Z'
      fill='white'
    />
    <path
      d='M45.8489 13.7947C47.0444 14.713 46.9698 16.2831 45.6908 17.1229L30.4659 27.1197L28.8871 28.0875L28.3669 28.3912C27.137 29.1091 25.425 28.8584 24.5766 27.8362C24.2966 27.4989 24.1369 27.1046 24.1152 26.6974L24.1143 26.6791C24.0757 25.9547 24.4642 25.2591 25.1591 24.8083L42.3208 13.6743C43.3704 12.9933 44.8724 13.0446 45.8489 13.7947Z'
      fill='white'
    />
    <path
      d='M46.4315 3.9105C48.7091 0.872087 53.5694 -0.082052 57.2872 1.77937C61.0051 3.64079 62.1726 7.61288 59.8949 10.6513C57.6173 13.6897 52.757 14.6438 49.0391 12.7824C45.3213 10.921 44.1538 6.94891 46.4315 3.9105Z'
      fill='white'
    />
    <path
      d='M54.9845 22.0152C54.0875 21.0063 54.361 19.5941 55.5955 18.861L63.898 13.9305C65.1325 13.1974 66.8605 13.421 67.7576 14.4299C68.6546 15.4388 68.381 16.851 67.1465 17.5841L58.844 22.5146C57.6095 23.2477 55.8815 23.0241 54.9845 22.0152Z'
      fill='white'
    />
    <path
      d='M82.6947 43.6713C80.8274 43.6713 79.095 43.404 77.4976 42.8694C75.9227 42.3141 74.5503 41.5327 73.3804 40.5252C72.233 39.5176 71.333 38.3352 70.6806 36.978C70.0281 35.6003 69.7019 34.0889 69.7019 32.4439C69.7019 30.7988 70.0281 29.2977 70.6806 27.9405C71.333 26.5628 72.233 25.3702 73.3804 24.3626C74.5503 23.355 75.9227 22.5839 77.4976 22.0492C79.095 21.494 80.8274 21.2164 82.6947 21.2164C84.8771 21.2164 86.8232 21.566 88.5331 22.2651C90.2655 22.9643 91.7054 23.9719 92.8528 25.2879L88.6006 28.8042C87.8356 27.9817 86.992 27.3545 86.0695 26.9227C85.1696 26.4908 84.1571 26.2749 83.0322 26.2749C82.0648 26.2749 81.1761 26.4189 80.3662 26.7068C79.5562 26.9946 78.8588 27.4162 78.2738 27.9714C77.7113 28.506 77.2614 29.1538 76.9239 29.9146C76.6089 30.6754 76.4514 31.5185 76.4514 32.4439C76.4514 33.3692 76.6089 34.2123 76.9239 34.9731C77.2614 35.734 77.7113 36.392 78.2738 36.9472C78.8588 37.4818 79.5562 37.8931 80.3662 38.181C81.1761 38.4688 82.0648 38.6128 83.0322 38.6128C84.1571 38.6128 85.1696 38.3969 86.0695 37.965C86.992 37.5332 87.8356 36.9061 88.6006 36.0835L92.8528 39.5998C91.7054 40.8953 90.2655 41.9029 88.5331 42.6226C86.8232 43.3217 84.8771 43.6713 82.6947 43.6713Z'
      fill='white'
    />
    <path
      d='M107.273 26.2441C108.69 26.2441 109.972 26.5114 111.12 27.0461C112.267 27.5601 113.167 28.3621 113.82 29.4519C114.495 30.5418 114.832 31.9503 114.832 33.6777V43.2395H108.42V34.6338C108.42 33.4412 108.15 32.5775 107.61 32.0429C107.093 31.4877 106.361 31.2101 105.416 31.2101C104.741 31.2101 104.123 31.354 103.56 31.6419C102.998 31.9092 102.559 32.3308 102.244 32.9065C101.929 33.4823 101.772 34.2328 101.772 35.1582V43.2395H95.3596V20.3528H101.772V31.2718L100.287 29.8838C100.984 28.6705 101.94 27.7658 103.155 27.1694C104.37 26.5525 105.743 26.2441 107.273 26.2441Z'
      fill='white'
    />
    <path
      d='M129.859 43.2395V40.1242L129.421 39.3531V33.616C129.421 32.6906 129.106 31.9812 128.476 31.4877C127.868 30.9736 126.89 30.7166 125.54 30.7166C124.662 30.7166 123.774 30.8502 122.874 31.1175C121.974 31.3643 121.209 31.7139 120.579 32.1663L118.419 28.1873C119.454 27.5704 120.691 27.0975 122.131 26.7684C123.594 26.4189 125.045 26.2441 126.485 26.2441C129.454 26.2441 131.749 26.8713 133.369 28.1256C135.012 29.3594 135.833 31.3026 135.833 33.9552V43.2395H129.859ZM124.46 43.5171C122.997 43.5171 121.76 43.2909 120.748 42.8385C119.735 42.3861 118.959 41.7692 118.419 40.9878C117.902 40.2064 117.643 39.3325 117.643 38.366C117.643 37.3379 117.924 36.4537 118.486 35.7134C119.071 34.9526 119.96 34.3768 121.153 33.9861C122.345 33.5748 123.886 33.3692 125.776 33.3692H130.096V36.4228H126.653C125.618 36.4228 124.887 36.577 124.46 36.8855C124.055 37.1939 123.852 37.6052 123.852 38.1193C123.852 38.6334 124.066 39.0446 124.494 39.3531C124.921 39.6615 125.506 39.8157 126.248 39.8157C126.946 39.8157 127.576 39.6615 128.138 39.3531C128.723 39.024 129.151 38.5305 129.421 37.8725L130.298 40.0316C129.961 41.1832 129.297 42.0571 128.307 42.6534C127.34 43.2292 126.057 43.5171 124.46 43.5171Z'
      fill='white'
    />
    <path d='M139.711 43.2395V20.3528H146.123V43.2395H139.711Z' fill='white' />
    <path d='M150.122 43.2395V20.3528H156.534V43.2395H150.122Z' fill='white' />
    <path
      d='M170.116 43.5171C167.957 43.5171 166.067 43.1469 164.447 42.4067C162.849 41.6458 161.601 40.6177 160.701 39.3222C159.823 38.0062 159.385 36.5154 159.385 34.8497C159.385 33.1841 159.812 31.7036 160.667 30.4081C161.545 29.0921 162.748 28.0742 164.278 27.3545C165.808 26.6142 167.529 26.2441 169.441 26.2441C171.241 26.2441 172.884 26.5834 174.369 27.262C175.854 27.92 177.035 28.8967 177.912 30.1922C178.79 31.4877 179.228 33.0608 179.228 34.9114C179.228 35.1171 179.217 35.3535 179.195 35.6209C179.172 35.8882 179.15 36.1349 179.127 36.3611H164.649V33.2767H175.719L173.289 34.1403C173.311 33.3795 173.154 32.7215 172.816 32.1663C172.501 31.6111 172.051 31.1792 171.466 30.8708C170.904 30.5623 170.24 30.4081 169.475 30.4081C168.71 30.4081 168.035 30.5623 167.45 30.8708C166.888 31.1792 166.449 31.6213 166.134 32.1971C165.819 32.7523 165.662 33.4103 165.662 34.1712V35.0657C165.662 35.8882 165.842 36.5976 166.202 37.1939C166.584 37.7903 167.124 38.2529 167.822 38.5819C168.519 38.8904 169.351 39.0446 170.319 39.0446C171.219 39.0446 171.984 38.9315 172.614 38.7053C173.266 38.4586 173.907 38.0884 174.537 37.5949L177.912 40.8028C177.035 41.687 175.955 42.3656 174.672 42.8385C173.39 43.2909 171.871 43.5171 170.116 43.5171Z'
      fill='white'
    />
    <path
      d='M193.929 26.2441C195.346 26.2441 196.629 26.5114 197.776 27.0461C198.923 27.5601 199.823 28.3621 200.476 29.4519C201.151 30.5418 201.488 31.9503 201.488 33.6777V43.2395H195.076V34.6338C195.076 33.4412 194.806 32.5775 194.266 32.0429C193.749 31.4877 193.018 31.2101 192.073 31.2101C191.398 31.2101 190.779 31.354 190.217 31.6419C189.654 31.9092 189.215 32.3308 188.9 32.9065C188.585 33.4823 188.428 34.2328 188.428 35.1582V43.2395H182.016V26.5217H188.124V31.2718L186.943 29.8838C187.64 28.6705 188.597 27.7658 189.812 27.1694C191.027 26.5525 192.399 26.2441 193.929 26.2441Z'
      fill='white'
    />
    <path
      d='M214.519 49.5009C212.764 49.5009 211.088 49.3159 209.491 48.9457C207.916 48.5756 206.566 48.0307 205.441 47.311L207.77 43.2086C208.535 43.7638 209.479 44.1957 210.604 44.5041C211.752 44.8331 212.865 44.9976 213.945 44.9976C215.678 44.9976 216.926 44.648 217.691 43.9489C218.456 43.2703 218.839 42.273 218.839 40.957V38.9829L219.176 34.2945L219.143 29.5753V26.5217H225.251V40.1859C225.251 43.3526 224.317 45.6968 222.45 47.2184C220.582 48.7401 217.939 49.5009 214.519 49.5009ZM213.405 42.3758C211.786 42.3758 210.289 42.0468 208.917 41.3888C207.567 40.7102 206.476 39.7746 205.643 38.5819C204.834 37.3687 204.429 35.9396 204.429 34.2945C204.429 32.6495 204.834 31.2306 205.643 30.038C206.476 28.8248 207.567 27.8891 208.917 27.2311C210.289 26.5731 211.786 26.2441 213.405 26.2441C214.958 26.2441 216.285 26.532 217.388 27.1077C218.49 27.6629 219.322 28.5369 219.885 29.7295C220.47 30.9222 220.762 32.4439 220.762 34.2945C220.762 36.1452 220.47 37.6669 219.885 38.8595C219.322 40.0522 218.49 40.9364 217.388 41.5122C216.285 42.0879 214.958 42.3758 213.405 42.3758ZM214.924 37.7183C215.689 37.7183 216.364 37.5744 216.949 37.2865C217.556 36.9986 218.029 36.5976 218.366 36.0835C218.726 35.5695 218.906 34.9731 218.906 34.2945C218.906 33.616 218.726 33.0196 218.366 32.5056C218.029 31.9915 217.556 31.6008 216.949 31.3335C216.364 31.0456 215.689 30.9016 214.924 30.9016C214.159 30.9016 213.473 31.0456 212.865 31.3335C212.258 31.6008 211.774 31.9915 211.414 32.5056C211.077 33.0196 210.908 33.616 210.908 34.2945C210.908 34.9731 211.077 35.5695 211.414 36.0835C211.774 36.5976 212.258 36.9986 212.865 37.2865C213.473 37.5744 214.159 37.7183 214.924 37.7183Z'
      fill='white'
    />
    <path
      d='M238.786 43.5171C236.626 43.5171 234.737 43.1469 233.117 42.4067C231.519 41.6458 230.271 40.6177 229.371 39.3222C228.493 38.0062 228.055 36.5154 228.055 34.8497C228.055 33.1841 228.482 31.7036 229.337 30.4081C230.214 29.0921 231.418 28.0742 232.948 27.3545C234.478 26.6142 236.199 26.2441 238.111 26.2441C239.911 26.2441 241.554 26.5834 243.038 27.262C244.523 27.92 245.705 28.8967 246.582 30.1922C247.459 31.4877 247.898 33.0608 247.898 34.9114C247.898 35.1171 247.887 35.3535 247.864 35.6209C247.842 35.8882 247.819 36.1349 247.797 36.3611H233.319V33.2767H244.388L241.959 34.1403C241.981 33.3795 241.824 32.7215 241.486 32.1663C241.171 31.6111 240.721 31.1792 240.136 30.8708C239.574 30.5623 238.91 30.4081 238.145 30.4081C237.38 30.4081 236.705 30.5623 236.12 30.8708C235.558 31.1792 235.119 31.6213 234.804 32.1971C234.489 32.7523 234.332 33.4103 234.332 34.1712V35.0657C234.332 35.8882 234.512 36.5976 234.872 37.1939C235.254 37.7903 235.794 38.2529 236.491 38.5819C237.189 38.8904 238.021 39.0446 238.989 39.0446C239.889 39.0446 240.654 38.9315 241.284 38.7053C241.936 38.4586 242.577 38.0884 243.207 37.5949L246.582 40.8028C245.705 41.687 244.625 42.3656 243.342 42.8385C242.06 43.2909 240.541 43.5171 238.786 43.5171Z'
      fill='white'
    />
    <path
      d='M250.989 43.2395V21.6482H256.49L266.277 36.3303H263.375L272.892 21.6482H278.393L278.46 43.2395H272.318L272.25 30.1922H273.297L266.176 41.1112H263.206L255.883 30.1922H257.165V43.2395H250.989Z'
      fill='white'
    />
    <path
      d='M292.3 43.5171C290.14 43.5171 288.25 43.1469 286.63 42.4067C285.033 41.6458 283.784 40.6177 282.884 39.3222C282.007 38.0062 281.568 36.5154 281.568 34.8497C281.568 33.1841 281.996 31.7036 282.851 30.4081C283.728 29.0921 284.932 28.0742 286.462 27.3545C287.992 26.6142 289.713 26.2441 291.625 26.2441C293.425 26.2441 295.067 26.5834 296.552 27.262C298.037 27.92 299.218 28.8967 300.096 30.1922C300.973 31.4877 301.412 33.0608 301.412 34.9114C301.412 35.1171 301.401 35.3535 301.378 35.6209C301.356 35.8882 301.333 36.1349 301.311 36.3611H286.833V33.2767H297.902L295.472 34.1403C295.495 33.3795 295.337 32.7215 295 32.1663C294.685 31.6111 294.235 31.1792 293.65 30.8708C293.087 30.5623 292.424 30.4081 291.659 30.4081C290.894 30.4081 290.219 30.5623 289.634 30.8708C289.072 31.1792 288.633 31.6213 288.318 32.1971C288.003 32.7523 287.845 33.4103 287.845 34.1712V35.0657C287.845 35.8882 288.025 36.5976 288.385 37.1939C288.768 37.7903 289.308 38.2529 290.005 38.5819C290.703 38.8904 291.535 39.0446 292.502 39.0446C293.402 39.0446 294.167 38.9315 294.797 38.7053C295.45 38.4586 296.091 38.0884 296.721 37.5949L300.096 40.8028C299.218 41.687 298.138 42.3656 296.856 42.8385C295.574 43.2909 294.055 43.5171 292.3 43.5171Z'
      fill='white'
    />
    <path
      d='M244.054 53.521C244.054 52.4563 244.999 51.5932 246.164 51.5932H301.004C302.169 51.5932 303.113 52.4563 303.113 53.521C303.113 54.5857 302.169 55.4488 301.004 55.4488H246.164C244.999 55.4488 244.054 54.5857 244.054 53.521Z'
      fill='white'
    />
  </svg>
);

export default RunManSidePanel;
