import React, { createRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import glowField from 'src/components/css/GlowField/GlowField';
import TrashIcon from 'src/components/Icons/TrashIcon';
import chipsRounding from 'src/components/RoundingOfAllForms/ChipsRounding';
import rounding from 'src/components/RoundingOfAllForms/Rounding';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import Select from 'src/components/Select';
import TextField from 'src/components/Textfield';
import buttonSwapColor from 'src/pages/HoverButtonsstyle/HooverButtonSwapColor';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import TargetsTooltip from 'src/utils/TargetsTooltip';
import { ChallengeTarget } from '../EditChallenge';
import { ReactComponent as RemoveIcon } from '../../../images/icons/RemoveIcon.svg';
import { useTranslation } from 'react-i18next';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import stepButton from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/StepButtonsStyle';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import PencilIcon from 'src/components/Icons/PencilIcon';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import { useApiClient } from 'src/api/client';
import { AxiosError } from 'axios';
import { PaymentDetailProvider } from 'src/store/paymentContext';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import Payment from 'src/components/Payment/Payment';
import { useToast } from 'src/store/toastContext';

interface NewTargetForm {
  targetName: string;
  targetValue: number;
  targetUnit: ChallengeTarget['targetUnit'];
}

interface IButtonHandleProps {
  editChallenge: () => void;
  loadData: () => void;
}

const EditTargets = ({ editChallenge, loadData }: IButtonHandleProps): JSX.Element => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm<NewTargetForm>();
  const { showToastHandler } = useToast();
  const [targetValue, setTargetValue] = useState<string>('');
  const { t } = useTranslation(['home', 'main']);
  const [selectedTargetId, setSelectedTargetId] = useState<number | null>(null);
  const [targetName, setTargetName] = useState<string>();
  const [targetUnit, setTargetUnit] = useState<ChallengeTarget['targetUnit']>('km');
  const [isOpen, setIsOpen] = useState(false);
  const autocompleteRef = createRef<HTMLInputElement>();
  const Client = useApiClient();

  const challengeData = useChallengeData();

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = event.target.value;
    if (val === '') {
      setTargetValue('');
      return;
    }
    const iVal = parseFloat(val);
    if (iVal > Number.MAX_SAFE_INTEGER) return;
    setTargetValue(iVal.toString());
    setValue('targetValue', iVal);
  };
  const onSubmit = async (): Promise<void> => {
    try {
      const result = await Client.post('/challenge/edit', {
        challenge_id: challengeData.challengeId,
        team_id: challengeData.teamId,
        team_name: challengeData.teamName,
        challenge_name: challengeData.challengeName,
        date_from: challengeData.dateFrom,
        date_to: challengeData.dateTo,
        deadline_Date: challengeData.deadLineDate,
        emails: challengeData.emails.toString(),
        targets: challengeData.challengeTargets,
        confirmUserLicence: challengeData.confirmUserLicence,
      });
      if (result.status === 200) {
        challengeData.clearAllData();
        editChallenge();
      }
    } catch (exc) {
      const err = exc as AxiosError;
      if (err?.response?.status === 403) {
        showToastHandler(t('You don`t have licence'), 'error');
        autocompleteRef.current?.focus();
      }
      openModal();
    } finally {
      loadData();
    }

    setTargetValue('');
    setValue('targetName', '');
    setSelectedTargetId(0);
  };

  const pickTargetForEdit = (index: number): void => {
    let t: ChallengeTarget | undefined = undefined;
    setSelectedTargetId(index);
    t = challengeData.challengeTargets[index];
    if (t != null) {
      setValue('targetName', t.targetName);
      setValue('targetUnit', t.targetUnit);
      setValue('targetValue', t.targetValue);
      setTargetValue(t.targetValue.toString());
      setTargetUnit(t.targetUnit);
      setTargetName(t.targetName);
    }
  };

  const editTarget = (): void => {
    if (targetName && targetValue && targetUnit && selectedTargetId != null) {
      const currentTarget = challengeData.challengeTargets[selectedTargetId];
      currentTarget.targetName = targetName;
      currentTarget.targetUnit = targetUnit;
      currentTarget.targetValue = parseFloat(targetValue);
    } else if (!selectedTargetId && targetName && targetUnit && targetValue) {
      challengeData.add({
        targetName: targetName,
        targetUnit: targetUnit as ChallengeTarget['targetUnit'],
        targetValue: parseFloat(targetValue),
      });
    }
    setValue('targetName', '');
    setValue('targetUnit', 'km');
    setValue('targetValue', 0);
    setTargetName('');
    setTargetValue('');
    setTargetUnit('km');
    setSelectedTargetId(null);
  };
  const removeTarget = (index: number): void => {
    challengeData.setChallengeTargets(challengeData.challengeTargets.filter((v, i) => i !== index));
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };
  return (
    <div className='w-full lg:h-[80vh] phone:h-[90vh] h-[90vh] flex  flex-col justify-center items-center lg:px-10 phone:px-5 px-5 '>
      <div className={`${titleStyle} ${mobileTitleStyle}  uppercase `}>{t('Edit targets')}</div>
      <div
        className={`lg:mt-3 phone:mt-10 mt-10  bg-opacity-[0.75] shadow-2xl  lg:shadow-none flex flex-col w-full h-full overflow-hidden  justify-end ${padding}  ${roundingForm} `}
      >
        <div className='h-full flex lg:flex-row flex-col lg:mb-0  lg:justify-between lg:px-0 phone:px-9 px-7 lg:py-0 phone:py-5 py-5 lg:overflow-hidden overflow-y-auto '>
          <form onSubmit={handleSubmit(editTarget)} className='h-fit 2xl:w-[512px] xl:w-5/12 lg:w-5/12 '>
            <div className='h-full w-full flex flex-col phone:w-full text-DarkPrimary'>
              <div className='flex flex-col mb-4 h-full w-full '>
                <div className={newChallengeTitleStyle}>{t('TARGET NAME')}</div>
                <Controller
                  control={control}
                  name='targetName'
                  rules={{ required: { value: true, message: `${t('Enter the name of the target')}` } }}
                  render={({ field }) => (
                    <>
                      <TextField
                        onKeyUp={(event) => {
                          setTargetName(event.target.value);
                        }}
                        placeholder={`${t('Running/Swimming...')}`}
                        {...field}
                        error={errors.targetName?.message}
                      />
                    </>
                  )}
                />
              </div>
              <div className='flex flex-col w-full mb-4'>
                <div className={newChallengeTitleStyle}>{t('GOAL')}</div>
                <Controller
                  control={control}
                  name='targetValue'
                  rules={{
                    required: { value: true, message: `${t('Missing value')}` },
                    pattern: {
                      value: /^[+]?(?:\d+\.?\d*|\.\d+)$/,
                      message: `${t('Please enter a positive number')}`,
                    },
                  }}
                  render={() => (
                    <>
                      <TextField
                        type={'number'}
                        onKeyUp={(event) => {
                          setTargetValue(event.target.value);
                        }}
                        placeholder='20'
                        {...register('targetValue', { validate: () => parseFloat(targetValue) !== 0.0 })}
                        error={errors.targetValue?.message}
                        onChange={changeValue}
                        value={targetValue}
                      />
                    </>
                  )}
                />
              </div>
              <div className='flex flex-col w-full my-auto mx-auto'>
                <div className={newChallengeTitleStyle}>{t('UNIT OF MEASURE')}</div>
                <Controller
                  control={control}
                  name='targetUnit'
                  defaultValue='km'
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue={targetUnit}
                      value={targetUnit}
                      onChange={(event) => {
                        setTargetUnit(event.target.value);
                      }}
                    >
                      <option value='km'>{t('km')}</option>
                      <option value='meters'>{t('meters')}</option>
                      <option value='hod.'>{t('hod.')}</option>
                      <option value='min.'>{t('min.')}</option>
                      <option value='pcs'>{t('pcs')}</option>
                    </Select>
                  )}
                />
              </div>
              <div className='flex flex-row justify-between items-center w-full h-12 my-5'>
                <div className=' w-full h-12 flex lg:justify-end phone:justify-center justify-center'>
                  <Button
                    type='submit'
                    className={`${buttonSwapColor} 2xl:w-2/4 xl:w-2/4 lg:w-8/12 phone:w-[130px] w-2/3 md:w-5/12 sm:w-5/12 2xl:h-12 phone:h-full  tablet:w-full desktop:h-10 xl:h-10 lg:h-10 tablet:h-10 h-10 2xl:text-xl xl:text-md phone:text text-[14px]`}
                  >
                    {t('ADD TARGET')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
          <div className='2xl:w-[512px] xl:w-5/12 lg:w-5/12 phone:w-full lg:overflow-hidden phone:overflow-hidden overflow-visible h-full  flex items-end flex-col'>
            <div className='font-normal flex justify-center w-full  border-DarkPrimary text-DarkPrimary h-fit 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px]'>
              <span className='text-DarkPrimary font-semibold'>{t('TARGET')}/</span>
              <span>{t('GOAL')}</span>
              <span className='text-DarkPrimary font-semibold'>/{t('UNIT OF MEASURE')}</span>
            </div>

            <table
              className={`border-0 2xl:w-[512px] h-full  xl:w-full lg:w-full  phone:w-full w-full sm:w-full  lg:border-2 phone:border-0  border-lightBlueButton flex flex-col justify-center items-center overflow-hidden phone:rounded-none rounded-none ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
            >
              <tr className='w-full lg:h-full phone:h-full h-[30vh] overflow-y-auto lg:mr-3  lg:my-5 phone:my-5 my-2 pt-1 overflow-hidden'>
                {challengeData.challengeTargets.map((target, index) => {
                  return (
                    <>
                      <div className='flex w-full flex-wrap relative justify-center space-x-2 pb-2 ' key={index}>
                        <span
                          className={`${glowField} w-full phone:w-full  2xl:h-[55px] xl:h-[40px] lg:h-[40px] lg:w-5/6 desktop:h-[40px] justify-between items-center text-DarkPrimary font-semibold border-lightGreen border-[2px] text-sm flex align-center active:bg-gray transition duration-300 ease ${chipsRounding} `}
                        >
                          <TargetsTooltip
                            targetName={target.targetName}
                            targetUnit={t(`${target.targetUnit}`)}
                            targetValue={target.targetValue}
                          />
                          <span className='flex w-fit flex-row relative justify-between items-center space-x-4 h-fit pr-4'>
                            <span
                              className='w-fit h-full cursor-pointer 2xl:scale-100 lg:scale-75 scale-50 '
                              onClick={() => pickTargetForEdit(index)}
                            >
                              <PencilIcon />
                            </span>
                            <button
                              className=' w-fit h-full cursor-pointer 2xl:scale-100  scale-75'
                              onClick={() => removeTarget(index)}
                            >
                              <div className='w-3'>
                                <RemoveIcon />
                              </div>
                            </button>
                          </span>
                        </span>
                      </div>
                    </>
                  );
                })}
              </tr>
            </table>
            <div className='w-full h-fit flex lg:justify-between phone:justify-center justify-center flex-row items-end 2xl:pt-2 pt-0'>
              <span className='text-DarkPrimary lg:flex hidden justify-start w-full italic'>
                {t('Count')}: {challengeData.challengeTargets.length}
              </span>
              <span className='text-DarkPrimary lg:flex hidden justify-end w-full 2xl:pr-1 pr-0 italic'>
                {t('Clear targets:')}
              </span>
              <span
                className='cursor-pointer 2xl:pb-[5px] pb-0 2xl:scale-110 lg:scale-75'
                onClick={() => {
                  challengeData.setChallengeTargets([]);
                }}
              >
                <TrashIcon />
              </span>
            </div>
          </div>
        </div>

        <div className='text-center lg:w-1/2 w-full flex flex-col items-center  justify-center m-auto h-fit my-4'>
          <Button
            datatype={'submit'}
            onClick={onSubmit}
            className={`${stepButton} tracking-widest ${rounding} text-white 2xl:text-xl desktop:text-xl tablet:text-xl text-[14px] desktop:font-bold md:font-bold tablet:font-bold font-semibold ${
              !challengeData.challengeTargets.length ? 'bg-gray' : 'bg-lightBlueButton hover:bg-darkGreenHoverButton'
            }`}
            disabled={challengeData.challengeTargets.length ? false : true}
            type={'submit'}
          >
            {t('CONFIRM')}
          </Button>
        </div>
      </div>
      <PaymentDetailProvider>
        <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
          <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
            <button
              type='button'
              className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
              onClick={closeModal}
            >
              x
            </button>
          </Dialog.Title>
          <Payment
            closeModal={closeModal}
            teamId={challengeData.teamId}
            teamName={challengeData.teamName}
            paymentInstructions={false}
          />
        </ModalFromLibrary>
      </PaymentDetailProvider>
    </div>
  );
};

export default EditTargets;
