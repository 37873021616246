import * as moment from 'moment';

export function checkLicenceIsInvalid(licenceValidTo: Date | undefined | null): boolean | null {
  return licenceValidTo == undefined
    ? null
    : licenceValidTo
    ? moment.utc(licenceValidTo, 'DD.MM.YYYY').isBefore(moment.utc(), 'day')
    : true;
}

export function checkCountOfMembers(emails: number): boolean {
  return emails > 10;
}

export function checkMembersAndLicence(emails: number, licenceValidTo: Date | undefined | null): boolean {
  const isLicenceInvalid = checkLicenceIsInvalid(licenceValidTo);
  const membersCount = checkCountOfMembers(emails);
  if (isLicenceInvalid == null && !membersCount) {
    return true;
  } else if (membersCount && isLicenceInvalid == null) {
    return false;
  } else if (membersCount && !isLicenceInvalid) {
    return true;
  } else if (!membersCount && !isLicenceInvalid) {
    return true;
  } else if (membersCount && isLicenceInvalid) {
    return false;
  } else if (!membersCount && isLicenceInvalid) {
    return true;
  } else {
    return false;
  }
}
