import React from 'react';

const LogOutIconWhite: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='24' viewBox='0 0 26 24' fill='none'>
    <path
      d='M1.8002 0.800781C0.91654 0.800781 0.200195 1.51713 0.200195 2.40078V21.6008C0.200195 22.4844 0.91654 23.2008 1.8002 23.2008C2.68385 23.2008 3.4002 22.4844 3.4002 21.6008V2.40078C3.4002 1.51713 2.68385 0.800781 1.8002 0.800781Z'
      fill='#3B5DC4'
    />
    <path
      d='M18.2688 15.6694C17.644 16.2943 17.644 17.3073 18.2688 17.9322C18.8937 18.557 19.9067 18.557 20.5316 17.9322L25.3316 13.1322C25.6316 12.8321 25.8002 12.4251 25.8002 12.0008C25.8002 11.5764 25.6316 11.1695 25.3316 10.8694L20.5316 6.06941C19.9067 5.44457 18.8937 5.44457 18.2688 6.06941C17.644 6.69425 17.644 7.70731 18.2688 8.33215L20.3375 10.4008L8.2002 10.4008C7.31654 10.4008 6.6002 11.1171 6.6002 12.0008C6.6002 12.8844 7.31654 13.6008 8.2002 13.6008H20.3375L18.2688 15.6694Z'
      fill='#3B5DC4'
    />
  </svg>
);

export default LogOutIconWhite;
