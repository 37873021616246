import React from 'react';

const PlusIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 1.80078C13.8837 1.80078 14.6 2.51713 14.6 3.40078V11.4008H22.6C23.4837 11.4008 24.2001 12.1171 24.2001 13.0008C24.2001 13.8844 23.4837 14.6008 22.6 14.6008H14.6V22.6008C14.6 23.4844 13.8837 24.2008 13 24.2008C12.1164 24.2008 11.4 23.4844 11.4 22.6008V14.6008H3.40005C2.51639 14.6008 1.80005 13.8844 1.80005 13.0008C1.80005 12.1171 2.51639 11.4008 3.40005 11.4008H11.4V3.40078C11.4 2.51713 12.1164 1.80078 13 1.80078Z'
      fill='#3B5DC4'
      stroke='#3B5DC4'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default PlusIcon;
