import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ChartBarIconWhite from '../Icons/CharBarWhite';
import ChartBarIcon from '../Icons/ChartBar';
import LogOutIcon from '../Icons/LogOutIcon';
import LogOutIconWhite from '../Icons/LogOutIconWhite';
import PlusIcon from '../Icons/PlusIcon';
import PlusIconWhite from '../Icons/PlusIconWhite';
import ServiceListIcon from '../Icons/ServiceListIcon';
import ServiceListIconWhite from '../Icons/ServiceListIconWhite';
import UserIcon from '../Icons/UserIcon';
import UserIconWhite from '../Icons/UserIconWhite';
import ViewListIcon from '../Icons/viewListIcon';
import ViewListIconWhite from '../Icons/viewListIconWhite';
import classNames from 'classnames/bind';
import RunManSidePanel from '../Icons/RunManSidePanel';
import textStyle from './Styles/TextStyle';
import spaceBetweenICons from './Styles/SpaceBtwIcons';
import allStyles from './Styles/AllStyles';
import rounded from './Styles/Rounding';
import { useTeam } from 'src/store/teamDetailContext';
import { useTranslation } from 'react-i18next';
import Notifications from '../Notification/Notifications';
import { useApiClient } from 'src/api/client';
import NotificationDot from '../Notification/NotificationDot';

interface SidePanelPropTypes {
  activeRoute?: SidePanelRoutes;
}
interface TeamData {
  anyUserWaitingForApproval: boolean;
  role: 'ADMIN' | 'MEMBER';
}
export enum SidePanelRoutes {
  SERVICE_LIST = 'serviceList',
  USER = 'user',
  MY_TEAMS = 'myTeams',
  NEW_CHALLENGE = 'newChallenge',
  MY_CHALLENGES = 'myChallenges',
  REPORTED_ACTIVITY = 'reportedActivity',
}
const SidePanel: React.FC<SidePanelPropTypes> = ({ activeRoute }) => {
  const navigate = useNavigate();
  const [swapIcons, setSwapIcons] = useState(false);
  const team = useTeam();
  const { t } = useTranslation(['home', 'main']);

  function handeMouseEnter(): void {
    return setSwapIcons(true);
  }
  function handleMouseLeave(): void {
    return setSwapIcons(false);
  }
  function swapLogOutIcons(): JSX.Element {
    if (swapIcons) {
      return <LogOutIconWhite />;
    } else {
      return <LogOutIcon />;
    }
  }
  const newChallenge = activeRoute === SidePanelRoutes.NEW_CHALLENGE;
  const serviceList = activeRoute === SidePanelRoutes.SERVICE_LIST;
  const user = activeRoute === SidePanelRoutes.USER;
  const myChallenges = activeRoute === SidePanelRoutes.MY_CHALLENGES;
  const myTeams = activeRoute === SidePanelRoutes.MY_TEAMS;
  const reportedActivity = activeRoute === SidePanelRoutes.REPORTED_ACTIVITY;

  const cx = classNames.bind(allStyles);
  const userSwapIcon = user ? <UserIcon /> : <UserIconWhite />;
  const plusSwapIcon = newChallenge ? <PlusIcon /> : <PlusIconWhite />;
  const myChallengesSwapIcon = myChallenges ? <ChartBarIcon /> : <ChartBarIconWhite />;
  const myTeamsSwapIcon = myTeams ? <ViewListIcon /> : <ViewListIconWhite />;
  const reportedActivitypSwapIcon = reportedActivity ? <ServiceListIcon /> : <ServiceListIconWhite />;
  const serviceListSwapIcon = serviceList ? <ServiceListIcon /> : <ServiceListIconWhite />;

  const classNameUser = cx(user ? 'whiteBackground' : 'default');
  const classNameNewChallenge = cx(newChallenge ? 'whiteBackground' : 'default');
  const classNameServiceList = cx(
    serviceList ? 'whiteBackgroundWithLargeText' : 'whiteBackgroundWithLargeTextWithoutWhite',
  );
  const classNameMyChallenges = cx(myChallenges ? 'whiteBackground' : 'default');
  const classNamemMyTeams = cx(myTeams ? 'whiteBackground' : 'default');
  const classNamemMyTeamsmReportedActivity = cx(reportedActivity ? 'whiteBackground' : 'default');

  const newChallegeBG = cx(newChallenge ? 'textColor' : 'whiteText');
  const classNameFont = cx(newChallenge ? 'fontStyle' : 'whiteText');

  const serviceListBG = cx(serviceList ? 'textColor' : 'whiteText');
  const serviceListFont = cx(serviceList ? 'fontStyle' : 'whiteText');

  const userBg = cx(user ? 'textColor' : 'whiteText');
  const userFont = cx(user ? 'fontStyle' : 'whiteText');

  const myChallengesBg = cx(myChallenges ? 'textColor' : 'whiteText');
  const myChallengesFont = cx(myChallenges ? 'fontStyle' : 'whiteText');

  const myTeamsBG = cx(myTeams ? 'textColor' : 'whiteText');
  const myTeamsFont = cx(myTeams ? 'fontStyle' : 'whiteText');

  const reportedActivityBG = cx(reportedActivity ? 'textColor' : 'whiteText');
  const reportedActivityFont = cx(reportedActivity ? 'fontStyle' : 'whiteText');
  const [notification, setNotification] = useState<boolean>(false);
  const [usersWaiting, setUsersWaiting] = useState<boolean>(false);

  const Client = useApiClient();

  useEffect(() => {
    Client.get('/team/userTeams')
      .then((response) => {
        const data = response.data.teams as TeamData[];
        const notificationStage = data
          .filter((x) => x.anyUserWaitingForApproval === true && x.role === 'ADMIN')
          .map((x) => x.anyUserWaitingForApproval && x.role === 'ADMIN')
          .some((x) => x === true);
        setUsersWaiting(notificationStage);

        const removedNotificationStage = localStorage.getItem('removedNotification');
        if (removedNotificationStage) {
          const parsedRemovedNotification = JSON.parse(removedNotificationStage);
          if (parsedRemovedNotification) {
            setNotification(false);
          } else {
            setNotification(notificationStage);
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <div className='bg-sideMenuColor w-44 h-full z-20 justify-evenly place-items-center flex-col 2xl:flex md:hidden sm:hidden lg:flex tablet:hidden desktop:hidden xl:flex  hidden '>
      <div className='px-8 2xl:scale-100 md:scale-90'>
        <RunManSidePanel />
      </div>
      <div className='w-full 2xl:h-4/6 md:h-4/6 items-start justify-start 2xl:scale-100 xl:scale-95 desktop:scale-100 lg:scale-[0.9]'>
        <div className='flex-col flex h-full  2xl:gap-7 xl:gap-10 lg:gap-10 desktop:gap-6 md:gap-6 tablet:gap-4 sm:gap-3 gap-0 items-center justify-evenly md:scale-95 2xl:scale-100'>
          <div className={classNameNewChallenge}>
            <Link to='/newChallenge/'>
              <div className={spaceBetweenICons}>{plusSwapIcon}</div>
              <span className={`${classNameFont} ${newChallegeBG}`}>{t('New challenge')}</span>
            </Link>
          </div>
          <div className={classNameServiceList}>
            <Link to='/challenge/logs/'>
              <div className={spaceBetweenICons}>{serviceListSwapIcon}</div>
              <span className={`${serviceListBG} ${serviceListFont}`}>{t('Мy activities')}</span>
            </Link>
          </div>
          <div className={classNameUser}>
            <Link to='/user'>
              <div className={spaceBetweenICons}>{userSwapIcon}</div>
              <span className={`${userBg} ${userFont}`}>{t('User')}</span>
            </Link>
          </div>
          <div className={classNameMyChallenges}>
            <Link to='/myChallenges/allUsers'>
              <div className={spaceBetweenICons}>{myChallengesSwapIcon}</div>
              <span className={`${myChallengesBg} ${myChallengesFont}`}>{t('My challenges')}</span>
            </Link>
          </div>
          <div className={`${classNamemMyTeams} relative`}>
            <Link to='/myTeams' onClick={() => team.setTeamId(0)}>
              {notification && <Notifications showNotification={notification} name='removedNotification' />}
              <div className={spaceBetweenICons}>{myTeamsSwapIcon}</div>
              <div>
                <span className={`${myTeamsBG} ${myTeamsFont} relative`}>
                  <div className='bottom-11 right-5 absolute'>{usersWaiting && <NotificationDot />}</div>
                  {t('My teams')}
                </span>
              </div>
            </Link>
          </div>
          <div className={`${classNamemMyTeamsmReportedActivity} relative`}>
            <Link to='/reportedActivity/logs/'>
              <div className={spaceBetweenICons}>{reportedActivitypSwapIcon}</div>
              <div>
                <span className={`${reportedActivityBG} ${reportedActivityFont}`}>{t('Reported activities')}</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className='h-fit w-full flex justify-center items-center 2xl:scale-100 xl:scale-95 desktop:scale-100 lg:scale-[0.9]'>
        <button
          onClick={() => {
            Cookies.remove('token');
            navigate('/login');
          }}
        >
          <div
            onMouseOver={handeMouseEnter}
            onMouseOut={handleMouseLeave}
            className={`desktop:w-32 2xl:w-32 tablet:w-32 md:w-28 2xl:h-full md:h-16 text-white my-1 p-3 font-semibold text-base  hover:text-sideTextMenuColor hover:bg-white ${rounded}
            } `}
          >
            <div className='flex justify-center mb-1'>{swapLogOutIcons()}</div>
            <span className={textStyle}>{t('Logout')}</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SidePanel;
