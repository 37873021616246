import React from 'react';

const EmptyStarIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='none'>
    <path
      d='M16.8262 2.60396L20.1349 10.5591L20.3695 11.1231L20.9783 11.1719L29.5666 11.8604L23.0232 17.4655L22.5593 17.8628L22.7011 18.4569L24.7002 26.8376L17.3474 22.3466L16.8262 22.0282L16.3049 22.3466L8.95218 26.8376L10.9513 18.4569L11.093 17.8628L10.6291 17.4655L4.08578 11.8604L12.674 11.1719L13.2829 11.1231L13.5174 10.5591L16.8262 2.60396Z'
      stroke='#15CDCA'
      strokeWidth='2'
    />
  </svg>
);

export default EmptyStarIcon;
