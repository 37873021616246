import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useApiClient } from 'src/api/client';
import Button from 'src/components/Button';
import titleStyle from 'src/components/css/TitleStyleLoginRegisterPage/TytleStyleLogRegPage';
import distanceFields from 'src/components/DistanceRegLoginFPass/DistanceFields';
import gapStyle from 'src/components/GapBetweenElements/GapBetweenElements';
import RegisterLoginTextField from 'src/components/RegisterLoginTextField';
import ToLoginPage from 'src/components/ToLoginPage/ToLoginPage';
import swal from 'sweetalert2';
import HoverBlueButtons from '../HoverButtonsstyle/HoverBlueButtons';
import { useTranslation } from 'react-i18next';
import formStyle from 'src/components/css/PaddingForLoginRegisterElements/paddingElementStyle/formStyle';
import { ProcessSpiner } from 'src/utils/ProcessSpiner';
import { AxiosError } from 'axios';

type ForgotPassword = {
  email: string;
};
interface IError {
  message: string;
}
function ForgotPasswordPage(): React.ReactElement {
  const apiClient = useApiClient();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ForgotPassword>();

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['home', 'main']);
  const [onSave, setOnSave] = useState<boolean>(false);
  const onSubmit: SubmitHandler<ForgotPassword> = async (data) => {
    setOnSave(true);
    if (data.email == undefined || data.email === '') return;
    setIsLoading(true);
    try {
      const response = await apiClient.post('user/forgotten-password', {
        userEmail: data.email.toLocaleLowerCase().trim(),
      });
      if (response.status === 200) {
        swal.fire({
          timer: 5000,
          customClass: { popup: 'top-52' },
          showConfirmButton: false,
          title: `${t('The password has been sent to your email')}`,
        });
        setIsLoading(false);
        setValue('email', '');
      }
    } catch (exc) {
      const err = exc as AxiosError<IError>;
      const error = err?.response?.data.message;
      if (err.response?.status == 400) {
        swal.fire({
          timer: 5000,
          customClass: { popup: 'top-52' },
          showConfirmButton: false,
          title: error,
        });
      }
      setIsLoading(false);
    }
    setOnSave(false);
  };

  return (
    <div className={`z-30 flex flex-col w-full md:flex-1 justify-center items-center h-full`}>
      <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
        <div className='flex flex-col h-full w-full tablet:w-10/12 justify-between gap-5'>
          <div className={`w-full h-fit flex flex-col ${gapStyle} ${distanceFields}`}>
            <div className='lg:flex tablet:flex hidden flex-row justify-evenly w-full'>
              <h1 className={titleStyle}>{t('Forgot password')}</h1>
            </div>
          </div>
          <div className='w-full h-5/6 flex flex-col items-center justify-start lg:gap-5 gap-3'>
            <div className='w-full h-fit'>
              <div className='text-white font-medium 2xl:text-2xl md:text-xl text-xl w-full h-fit flex items-end justify-start'>
                {t('Email')}
              </div>
              <div className='h-14 w-full'>
                <Controller
                  control={control}
                  name='email'
                  rules={{
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: `${t('Enter E-Mail in the correct form')}`,
                    },
                    required: { value: true, message: `${t('Enter E-Mail')}` },
                  }}
                  render={({ field }) => <RegisterLoginTextField {...field} />}
                />
              </div>
            </div>

            <div className='text-primary text-sm flex justify-end '>
              {errors.email?.message && errors.email.message}
            </div>
            <div className='h-14 w-full flex justify-center items-center'>
              <Button className={HoverBlueButtons} disabled={isLoading} type='submit'>
                {onSave ? (
                  <div className='w-full h-full flex flex-row items-center justify-center cursor-not-allowed'>
                    <div className='w-fit h-5/6 items-center justify-center flex'>
                      <ProcessSpiner />
                    </div>
                    <div className='w-fit h-fit'>{t('PROCESSING...')}</div>
                  </div>
                ) : (
                  `${t('Send Email')}`
                )}
              </Button>
            </div>
            <ToLoginPage />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordPage;
