import { useEffect } from 'react';
import distanceFields from 'src/components/DistanceRegLoginFPass/DistanceFields';
import gapStyle from 'src/components/GapBetweenElements/GapBetweenElements';
import LoginRegisterFooter from 'src/components/LoginRegisterFooter/LoginRegisterFooter';
import { ReactComponent as Logo } from '../../images/icons/ParalaxVectors/Logo.svg';

import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import ButtonMarketingPage from './ButtonMarketingPage';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/store/authContext';
import MountinBGImg from 'src/components/MountainBGImg/MontainImg';

function MarketingPage(): React.ReactElement {
  const { t, i18n } = useTranslation(['home', 'main']);
  const { setAuthLoginToken } = useAuth();

  const navigate = useNavigate();

  const handleSubmit = (): void => {
    setAuthLoginToken('marketingAcception');
    navigate('/login');
  };

  useEffect(() => {
    const userLang = navigator.language;
    i18n.changeLanguage(userLang.slice(0, 2).toUpperCase());
  }, []);

  return (
    <div className='flex 2xl:flex-row md:flex-row sm:flex-row flex-col z-0 bg-gradient-to-r from-lightBlue h-screen to-darkBlue'>
      <MountinBGImg />
      <div className={`w-full h-full z-10 ${gapStyle}`}>
        <div className='flex flex-col h-full 2xl:w-full md:w-full w-full'>
          <div
            className={`w-full h-full flex flex-col bg-tablet:justify-center 2xl:justify-start md:justify-start sm:justify-start items-end justify-evenly ${gapStyle}`}
          >
            <div
              className={`w-full h-full tablet:flex tablet:flex-col xl:gap-10 lg:gap-10 gap-5 tablet:items-center z-10 justify-start items-center flex flex-col ${distanceFields}`}
            >
              <Logo className='lg:hidden flex scale-75' />
              <div className='flex lg:flex-row phone:flex-col flex-col w-full h-full overflow-hidden'>
                <div className='lg:flex phone:hidden hidden flex-col 2xl:w-9/12 xl:w-9/12 lg:w-5/12 h-full 2xl:pl-16 xl:pl-16 lg:pl-8 '>
                  <div className='flex h-full w-8/12 items-start justify-start 2xl:scale-100 xl:scale-100 lg:scale-100 origin-top-left '>
                    <Logo />
                  </div>
                  <div className='flex h-full w-full '>
                    <div className='flex h-full w-full items-end pb-5 justify-start '>
                      <Button onClick={() => handleSubmit()} className={ButtonMarketingPage}>
                        {t('Log in')}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='flex w-fit h-full item-center justify-center lg:px-0 phone:px-5 px-5 lg:pb-0 pb-5 phone:pb-5 overflow-hidden '>
                  <div
                    className={`flex flex-col 2xl:w-9/12 xl:w-10/12 lg:w-11/12 h-full  lg:text-2xl phone:text-sm text-sm overflow-y-auto  2xl:px-20 xl:px-20 lg:px-10 phone:px-5 px-5 text-DarkPrimary font-bold text-center item-center justify-center bg-white bg-opacity-[0.75] shadow-2xl  ${roundingForm}`}
                  >
                    {t('MarketingPageText')}
                  </div>
                </div>
                <div className='lg:hidden phone:flex flex h-fit w-full items-end lg:pb-0 phone:pb-5 pb-10 px-5 justify-start  '>
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className={ButtonMarketingPage}
                  >
                    {t('Log in')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <LoginRegisterFooter />
        </div>
      </div>
    </div>
  );
}
export default MarketingPage;
