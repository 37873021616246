import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { Buffer } from 'buffer';

interface AuthContextProps {
  token: string;
  tokenLogin: string;
  setAuthToken: (value: string, canSaveToken?: boolean) => void;
  setAuthLoginToken: (value: string) => void;
  getUserId: () => number | null;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const defaultState: AuthContextProps = {
  token: '',
  tokenLogin: '',

  setAuthLoginToken: () => {
    return;
  },

  setAuthToken: () => {
    return;
  },
  getUserId: () => null,
};

export const AuthContext = createContext<AuthContextProps>(defaultState);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState(Cookies.get('token') || '');
  const [tokenLogin, setAuthLoginToken] = useState(Cookies.get('tokenLogin') || '');

  function parseJwt(token: string): { userId: number | null } {
    if (!token) {
      return { userId: null };
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(Buffer.from(base64, 'base64').toString());
  }

  const getUserId = (): number | null => {
    return parseJwt(token).userId;
  };

  const handleSetToken = (tokenHandle: string, canSaveToken?: boolean): void => {
    setToken(tokenHandle);
    if (canSaveToken) {
      Cookies.set('token', tokenHandle, { expires: 365, path: '/' });
    } else {
      Cookies.set('token', tokenHandle, { path: '/' });
    }
  };

  const handleSetAuthLoginToken = (tokenHandle: string): void => {
    setAuthLoginToken(tokenHandle);

    Cookies.set('tokenLogin', tokenHandle, { expires: 365, path: '/' });
  };

  return (
    <AuthContext.Provider
      value={{
        tokenLogin,
        token,
        setAuthLoginToken: handleSetAuthLoginToken,
        setAuthToken: handleSetToken,
        getUserId: getUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => useContext(AuthContext);
