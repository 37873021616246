import React from 'react';

const InformationIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29' fill='none'>
    <path
      d='M14.4995 0C6.49249 0 0 6.49204 0 14.4995C0 22.5071 6.49249 28.9991 14.4995 28.9991C22.5085 28.9991 29 22.5071 29 14.4995C29 6.49198 22.5085 0 14.4995 0ZM17.0455 21.1564C16.459 21.8073 16.1229 22.1942 15.4898 22.7994C14.5315 23.7157 13.4435 23.9596 12.5976 23.0552C11.3817 21.7548 12.6569 17.7987 12.688 17.6489C12.9164 16.5974 13.3722 14.4959 13.3722 14.4959C13.3722 14.4959 12.3874 15.0987 11.8028 15.3126C11.3716 15.4701 10.8838 15.2632 10.7632 14.8462C10.6508 14.4598 10.7403 14.214 10.977 13.95C11.5634 13.2996 11.8996 12.9127 12.5327 12.3075C13.4919 11.3908 14.579 11.1473 15.4249 12.0513C16.6408 13.3517 15.7437 15.4912 15.4286 17.0071C15.3975 17.1574 14.6502 20.611 14.6502 20.611C14.6502 20.611 15.635 20.0081 16.2197 19.7939C16.6517 19.6367 17.1396 19.8441 17.2602 20.2607C17.3726 20.6472 17.2822 20.8929 17.0455 21.1564ZM15.4725 9.97526C14.1195 10.094 12.9274 9.09371 12.8086 7.73983C12.6908 6.38736 13.6911 5.19474 15.044 5.07693C16.396 4.95815 17.5891 5.95939 17.707 7.31185C17.8257 8.66437 16.8245 9.85745 15.4725 9.97526Z'
      fill='#15CDCA'
    />
  </svg>
);
export default InformationIcon;
