import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import glowFieldLoginPage from '../css/GlowField/GlowFieldLoginPage';

export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;
const RegisterLoginTextField: React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;
  return (
    <div className='flex-col'>
      <div className='relative'>
        <input className={`${glowFieldLoginPage}`} value={value ?? ''} {...rest} ref={ref} type={'text'}></input>
        {error && <span className='text-primary'>{error}</span>}
      </div>
    </div>
  );
};

export default React.forwardRef(RegisterLoginTextField);
