import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from 'src/api/client';
import Checkbox from 'src/components/Checkbox';
import glowFieldLoginPage from 'src/components/css/GlowField/GlowFieldLoginPage';
import textQuestionStyle from 'src/components/css/LinksTextStyle/TextQuestionStyle';
import titleStyle from 'src/components/css/TitleStyleLoginRegisterPage/TytleStyleLogRegPage';
import distanceFields from 'src/components/DistanceRegLoginFPass/DistanceFields';
import gapStyle from 'src/components/GapBetweenElements/GapBetweenElements';
import LoginLink from 'src/components/LoginLinks/LoginLink';
import RegisterLoginPasswordField from 'src/components/RegisterLoginPasswordField';
import RegisterLoginTextField from 'src/components/RegisterLoginTextField';
import { useAuth } from 'src/store/authContext';
import Button from '../../components/Button';
import HoverBlueButtons from '../HoverButtonsstyle/HoverBlueButtons';
import { useTranslation } from 'react-i18next';
import emailValidationRegex from 'src/validation/emailValidationRegex';
import { useToast } from 'src/store/toastContext';
import formStyle from 'src/components/css/PaddingForLoginRegisterElements/paddingElementStyle/formStyle';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import PriceList from 'src/components/PriceList/PriceList';

type RegisterForm = {
  email: string;
  password: string;
  passwordAgain: string;
  canSaveToken: boolean;
};

function RegisterPage(): React.ReactElement {
  const Client = useApiClient();

  const { handleSubmit, control, formState } = useForm<RegisterForm>();

  const navigate = useNavigate();

  const { setAuthToken } = useAuth();

  const [isLoginInvalid, setIsLoginInvalid] = useState(false);

  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const { showToastHandler } = useToast();

  const [isPasswordDifferent, setIsPasswordDifferent] = useState(false);
  const { t } = useTranslation(['home', 'main']);
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit: SubmitHandler<RegisterForm> = async (data) => {
    setIsPasswordDifferent(false);
    setIsPasswordInvalid(false);
    if (!emailValidationRegex.test(data.email)) {
      setIsLoginInvalid(true);
      return;
    } else {
      setIsLoginInvalid(false);
    }

    if (data.password === '' || data.password === undefined) return;
    if (data.password != data.passwordAgain) {
      setIsPasswordDifferent(true);
      return;
    }

    try {
      const browserLanguage = navigator.language || navigator.language;
      const userLanguage = browserLanguage.slice(0, 2).toUpperCase();
      const response = await Client.post('user/register', {
        userEmail: data.email?.toLocaleLowerCase().trim(),
        userPsw: data.password,
        browserLanguage: userLanguage,
      });
      showToastHandler(`${t('You registered successfully')}`, 'success');
      setAuthToken(response.data.token, data.canSaveToken);
      const responseTeams = await Client.get(`team/loginUser/teams/${data.email}`);
      const teams = responseTeams.data;
      if (teams) {
        navigate('/challenge/logs/');
      } else {
        navigate('/newChallenge/team&Challenge/');
      }
    } catch (error) {
      let err = 'We encountered a problem!';
      if (error instanceof AxiosError) {
        err = error.response?.data.message;
      }
      if (err === 'USER_EXISTS') {
        setIsLoginInvalid(true);
      }

      if (err[0] === 'Heslo je moc krátké') {
        setIsPasswordInvalid(true);
      }

      if (err[0] === 'least one capital letter') {
        setIsPasswordInvalid(true);
      }
    }
  };
  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };
  return (
    <div className={`z-30 flex flex-col w-full md:flex-1 justify-center items-center h-full`}>
      <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
        <div className={`w-full h-full flex flex-col items-center justify-start ${gapStyle}`}>
          <div className={`w-full h-fit z-10 justify-start items-start flex flex-col ${distanceFields}`}>
            <div className='lg:flex tablet:flex hidden flex-row justify-center items-center h-fit w-full'>
              <h1 className={titleStyle}>{t('Sign up')}</h1>
            </div>

            <div className={`flex flex-col justify-evenly h-fit w-full  ${gapStyle} ${distanceFields}`}>
              <div className='flex flex-col'>
                <div className='w-full flex flex-row h-fit'>
                  <label className='text-white font-medium 2xl:text-2xl md:text-xl text-xl w-fit'>{t('Email')}</label>
                  <div className='text-primary font-medium 2xl:text-2xl md:text-xl text-xl w-full h-full flex justify-end'>
                    <span>{isLoginInvalid && `${t('The user already exists!')}`}</span>
                    {formState.errors.email?.message && <span>{formState.errors.email?.message}</span>}
                    {formState.errors.password?.message && <span>{formState.errors.password?.message}</span>}
                    {formState.errors.passwordAgain?.message && <span>{formState.errors.passwordAgain?.message}</span>}
                  </div>
                </div>
                <div className='h-fit w-full'>
                  <Controller
                    control={control}
                    name='email'
                    render={({ field }) => (
                      <RegisterLoginTextField
                        className={`${glowFieldLoginPage} ${
                          isLoginInvalid ? ' border-primary border-[1.5px]' : 'border-lightGreen border-2'
                        }`}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={`flex flex-col justify-evenly h-fit w-full ${gapStyle}`}>
                <div className='w-full h-full'>
                  <label className='text-white font-medium 2xl:text-2xl md:text-xl text-xl'>{t('Password')}</label>
                  <div className='h-fit w-full'>
                    <Controller
                      control={control}
                      name='password'
                      render={({ field }) => (
                        <RegisterLoginPasswordField
                          className={`${glowFieldLoginPage} ${
                            isPasswordInvalid ? 'border-primary border-[1.5px]' : 'border-lightGreen border-2'
                          }`}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-col bg'>
                <div className='w-full flex flex-row'>
                  <div className='w-full h-full'>
                    <label className='text-white font-medium 2xl:text-2xl md:text-xl text-xl'>
                      {t('Password again')}
                    </label>
                  </div>
                  {isPasswordDifferent && (
                    <div className='text-primary font-medium 2xl 2xl:text-2xl md:text-xl text-xl  w-full  h-full flex justify-end'>
                      {t("Passwords don't match")}
                    </div>
                  )}
                </div>

                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name='passwordAgain'
                  render={({ field }) => (
                    <RegisterLoginPasswordField
                      className={`${glowFieldLoginPage} ${
                        isPasswordDifferent ? ' border-primary border-[1.5px]' : 'border-lightGreen border-2'
                      }`}
                      {...field}
                    />
                  )}
                />
              </div>

              {isPasswordInvalid && (
                <div className='text-primary font-medium 2xl 2xl:text-xl md:text-xl text-xl  w-full  h-full flex'>
                  {t('The password must contain at least four characters and one capital letter.')}
                </div>
              )}
              <div className={`flex flex-row  h-full w-full  items-start tablet:pt-2 ${gapStyle}`}>
                <div className='h-full'>
                  <Controller
                    control={control}
                    name='canSaveToken'
                    render={({ field }) => (
                      <>
                        <Checkbox className='bg-white rounded h-full w-6 box-border cursor-pointer' {...field} />
                      </>
                    )}
                  />
                </div>
                <div className='w-full h-fit desktop:pl-0 tablet:pl-1 pl-1 flex'>
                  <span className={textQuestionStyle}>{t('Remember me')}</span>
                  <div
                    onClick={openModal}
                    className={`${textQuestionStyle} justify-end items-end underline flex cursor-pointer`}
                  >
                    {t('PRICE LIST')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full  2xl:h-fit md:h-fit sm:h-fit 2xl:justify-center h-full flex flex-col justify-center ${gapStyle}`}
          >
            <div className='w-full flex justify-center text-primary text-sm 2xl:pt-4 desktop:pt-4 md:pt-4 sm:pt-4 pb-0'>
              <div
                className={`w-full 2xl:h-fit md:h-fit sm:h-fit 2xl:justify-center h-full flex flex-col items-center justify-end`}
              >
                <div className='h-14 w-full flex justify-center items-center'>
                  <Button type='submit' className={HoverBlueButtons}>
                    {t('Sign up')}
                  </Button>
                </div>
                <LoginLink />
              </div>
            </div>
          </div>
        </div>
      </form>
      <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
        <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
          <button
            type='button'
            className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
            onClick={closeModal}
          >
            x
          </button>
        </Dialog.Title>
        <PriceList closeModal={closeModal} />
      </ModalFromLibrary>
    </div>
  );
}
export default RegisterPage;
