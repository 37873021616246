import React, { Fragment, useEffect, useState } from 'react';
import { ChallengeLogsListItem } from 'src/api/types/challenges/ChallengeLogsResponse';
import { useApiClient } from 'src/api/client';
import ChallengeLogService from 'src/components/ChallengeLog';
import { Spinner } from 'src/utils/Spinner';
import LogsHeader from '../LogsHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useActivity } from 'src/store/activityCardContext';
import ChallengeLogServiceMobileVersion from 'src/components/ChallengeLog/challengeLogMobileVersion';
import { Accordion } from '@material-tailwind/react';
import Arrow from 'src/components/Icons/Arrow';
import MissingChallenge from 'src/components/MissingChallenge/MissingChallenge';

const ChallengeLogCard: React.FC = () => {
  const Client = useApiClient();
  const [items, setItems] = useState<ChallengeLogsListItem[]>([]);
  const [isHasMoreData, setIsHasMoreData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [logsAtScroll, setShowPerPage] = useState(15);
  const [open, setOpen] = useState(0);

  const handleOpen = (value: number): void => {
    setOpen(open !== value ? value : 0);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };
  const getItemList = async (): Promise<void> => {
    items;
  };
  const activity = useActivity();
  useEffect(() => {
    if (activity.reload) {
      Client.get(`challenge/logs/${logsAtScroll}/${0}`)
        .then((response) => {
          setItems(response.data.challenges);
          setLoading(false);
          activity.setReload(false);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [activity.reload]);
  const fetchMoreData = async (): Promise<void> => {
    const skippedLogs = items.length;
    const response = await Client.get(`challenge/logs/${logsAtScroll}/${skippedLogs}`);
    const data = response.data.challenges;
    if (data.length != 0) {
      setShowPerPage(15);
      setIsHasMoreData(true);
      setTimeout(async () => {
        data.forEach((element: ChallengeLogsListItem) => {
          setItems((logs) => [...logs, element]);
        });
      }, 100);
    } else {
      setIsHasMoreData(false);
    }
  };
  return (
    <>
      <LogsHeader />
      {items.length < 1 ? (
        <div className='text-center flex h-full justify-center items-center'>
          {loading === true ? <Spinner /> : <MissingChallenge />}
        </div>
      ) : (
        <tr className='w-full h-full'>
          <InfiniteScroll
            dataLength={items.length}
            loader={<Spinner />}
            next={fetchMoreData}
            hasMore={isHasMoreData}
            height={'65vh'}
            endMessage={
              <p className='text-center'>
                <b className='text-gray 2xl:text-xl lg:text-sm xl:text-sm select-none'>You have seen it all...</b>
              </p>
            }
          >
            {items.map((item, index) => {
              return window.innerWidth <= 820 ? (
                <>
                  <Fragment>
                    <Accordion
                      open={open === item.id}
                      animate={customAnimation}
                      onClick={() => handleOpen(item.id)}
                      icon={<Arrow id={item.id} open={open} />}
                    >
                      <ChallengeLogServiceMobileVersion
                        key={item.id}
                        item={item}
                        index={index}
                        getItemList={getItemList}
                        isLastItem={items.length - 1 === index}
                      />
                    </Accordion>
                  </Fragment>
                </>
              ) : (
                <>
                  <ChallengeLogService
                    key={item.id}
                    item={item}
                    index={index}
                    getItemList={getItemList}
                    isLastItem={items.length - 1 === index}
                  />
                </>
              );
            })}
          </InfiniteScroll>
        </tr>
      )}
    </>
  );
};

export default ChallengeLogCard;
