import React, { useEffect, useState } from 'react';
import { useToast } from 'src/store/toastContext';
import { ReactComponent as GreenCancel } from '../../images/icons/GreenCancel.svg';
import { ReactComponent as GreenCheckMark } from '../../images/icons/GreenCheckMark.svg';
import { ReactComponent as ErrorRedIcon } from '../../images/icons/ErrorRedIcon.svg';
import { ReactComponent as RedCancel } from '../../images/icons/RedCancel.svg';

export interface ToastType {
  message: string;
  type: string;
}

interface ToastMessageProps {
  index: number;
  message: string;
  type: string;
  toasts: ToastType[];
}

const ToastMessage: React.FC<ToastMessageProps> = ({ index, message, type }) => {
  const { closeToastHandler } = useToast();
  const [visible, setVisible] = useState(true);
  const [currentBottom, setCurrentBottom] = useState<number>(0);

  const closeToast = (): void => {
    const currentBottom = toastStyle.bottom as number;
    setCurrentBottom(currentBottom);
    setVisible(false);
    closeToastHandler(index);
  };
  const toastStyle: React.CSSProperties = {
    bottom: `${index * 80}px`,
  };

  useEffect(() => {
    if (!visible) {
      toastStyle.bottom = `${currentBottom / 80}px`;
    }
  }, []);

  return (
    <div
      onClick={closeToast}
      id={`toast-${index}`}
      className={`absolute top-auto left-2 flex hover:cursor-pointer z-50 w-full max-w-sm justify-center text-center items-center text-ellipsis p-4 mb-4 text-gray-500 bg-white rounded-3xl shadow transition-opacity ease-in-out duration-1000 ${
        !visible ? `bottom-${currentBottom / 80}px` : `bottom-${index * 80}px`
      }`}
      role='alert'
      style={toastStyle}
    >
      <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg'>
        {type === 'error' ? <ErrorRedIcon /> : <GreenCheckMark />}
      </div>
      <div
        className={`overflow-hidden mx-4 text-sm font-bold ${type === 'error' ? 'text-errorRed' : 'text-DarkPrimary'}`}
      >
        {message}
      </div>
      <button
        type='button'
        className='ml-auto -mx-1.5 -my-1.5'
        data-dismiss-target={`#toast-${index}`}
        onClick={closeToast}
      >
        {type === 'error' ? <RedCancel /> : <GreenCancel />}
      </button>
    </div>
  );
};

export default ToastMessage;
