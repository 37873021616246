import React from 'react';
interface IQrCode {
  qrCodeText: string;
}
const QrCode: React.FC<IQrCode> = ({ qrCodeText }) => {
  return (
    <div className='justify-center flex flex-col h-full w-full items-center gap-10'>
      <img src={qrCodeText} className='w-fit h-fit rounded-[10px]' />
    </div>
  );
};
export default QrCode;
