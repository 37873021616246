import emailValidationRegex from 'src/validation/emailValidationRegex';

function GetValidEmails(emails: string): string[] {
  return emails.split(/[ ,]+/).filter((val, id, array) => {
    if (emailValidationRegex.test(val)) {
      return array.indexOf(val) == id;
    }
  });
}
export default GetValidEmails;
