import React from 'react';

const ActiveCheckBox: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 25 25' fill='none'>
    <rect width='25' height='25' rx='8' fill='#40CDD6' />
    <rect x='1' y='1' width='23' height='23' rx='3' stroke='#40CDD6' strokeWidth='2' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.7399 8.82257C18.045 9.12767 18.045 9.62233 17.7399 9.92743L11.4899 16.1774C11.1848 16.4825 10.6902 16.4825 10.3851 16.1774L7.26007 13.0524C6.95498 12.7473 6.95498 12.2527 7.26007 11.9476C7.56517 11.6425 8.05983 11.6425 8.36493 11.9476L10.9375 14.5201L16.6351 8.82257C16.9402 8.51748 17.4348 8.51748 17.7399 8.82257Z'
      fill='#FCFCFC'
    />
  </svg>
);

export default ActiveCheckBox;
