import React, { useEffect, useState } from 'react';
import buttonStyleNewChallenge from 'src/pages/NewChallengePage/NewChallengeButtonStyle/ButtonStyle';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import { useTeam } from 'src/store/teamDetailContext';
import { useApiClient } from 'src/api/client';
import { Spinner } from 'src/utils/Spinner';
import QrCode from '../../QrCode/QrCode';
import { AxiosError } from 'axios';
import { useToast } from 'src/store/toastContext';
import SelectCurencyUnit from '../PaymentValue/CurrencyUnit';
import { usePaymentData } from 'src/store/paymentContext';
interface ServiceFormProps {
  closeModal?: () => void;
  teamId: number | null;
  teamName: string | null;
  paymentInstructions: boolean;
}
interface IError {
  message: string;
}
const PaymentModal: React.FC<ServiceFormProps> = ({
  closeModal,
  teamId,
  teamName,
  paymentInstructions: sendInstructions,
}) => {
  const { t } = useTranslation(['home', 'main']);
  const paymenData = usePaymentData();
  const [qrCode, setQrCode] = useState<string | null>('');
  const Client = useApiClient();
  const challengeData = useChallengeData();
  const team = useTeam();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLicenceIsBefore30Days, setIsLicenceIsBefore30Days] = useState<boolean | null>(null);
  const [isLincenceBetween30Days, setIsLincenceBetween30Days] = useState<boolean | null>(null);
  const [iban, setIban] = useState<string>();
  const [totalSumToPay, setTotalSumToPay] = useState<string>();
  const [adminEmail, setAdminEmail] = useState<string>();
  const [adminLanguage, setAdminLanguage] = useState<string>();
  const [currencyValue, setCurrencyValue] = useState<string>();
  const { showToastHandler } = useToast();

  useEffect(() => {
    paymenData.clearPaymentData();
    Client.get('user/email').then((response) => {
      const { logedUser, language } = response.data;
      paymenData.setSelectedCurrency(language);
      setCurrencyValue(language);
      setAdminEmail(logedUser);
      setAdminLanguage(language);
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (paymenData.selectedCurrency) {
      Client.get(`/team/generate-qrcode/${teamId}/${teamName}/${paymenData.selectedCurrency}`)
        .then((response) => {
          const { qrCode, isLicenceRequestBefore30Days, isLicenceRequestBetween30Days, iban, sumToPay, currency } =
            response.data;
          setTotalSumToPay(sumToPay);
          setIban(iban);
          setCurrencyValue(currency);
          setIsLicenceIsBefore30Days(isLicenceRequestBefore30Days);
          setIsLincenceBetween30Days(isLicenceRequestBetween30Days);
          setQrCode(qrCode);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    setIsLoading(false);
  }, [paymenData.selectedCurrency]);

  const sendPaymentInstructions = async (confirmBuyingLicence: boolean): Promise<void> => {
    try {
      await Client.post(`/team/sendPaymentInstructions/`, {
        teamId: teamId,
        teamName: teamName,
        confirmUserLicence: confirmBuyingLicence,
        currency: paymenData.selectedCurrency,
        onlyInstructions: sendInstructions,
      });
      showToastHandler(`${t('Sended Payment Instructions')}`, 'success');
    } catch (exc) {
      const err = exc as AxiosError<IError>;
      const error = err?.response?.data.message;

      if (err.response?.status == 400) {
        showToastHandler(`${error}`, 'error');
      }
    }
  };

  return (
    <div className='w-full h-full flex flex-col gap-5 p-5'>
      {((isLicenceIsBefore30Days !== null || isLincenceBetween30Days !== null) && !isLoading) || qrCode ? (
        <>
          {isLicenceIsBefore30Days && (
            <p className='text-center'>
              {t('EXPIRED LICENCE REQUEST')}{' '}
              <span className='font-semibold'>{challengeData.adminTeamEmail || adminEmail}</span>
              {t('5DAYS DEADLINE')}
            </p>
          )}
          {isLincenceBetween30Days && (
            <>
              <p className='text-center'>
                {t('EXPIRED 5 DAYS FREE LICENCE')}
                <span className='font-semibold'>{challengeData.adminTeamEmail || adminEmail}</span>
              </p>
            </>
          )}
          {!isLicenceIsBefore30Days && !isLincenceBetween30Days && (
            <p className='text-center'>
              {t('ACTIVATE INFO')} <span className='font-semibold'>{challengeData.adminTeamEmail || adminEmail}</span>{' '}
              <br></br>
              {t('5DAYS DEADLINE')}
              <br></br>
            </p>
          )}
          {qrCode && paymenData.selectedCurrency === 'CZ' && (
            <div className='items-center flex flex-col gap-3 text-center'>
              <p>{t('OR SCAN CODE')}</p>
              <QrCode qrCodeText={qrCode} />
            </div>
          )}
          <div>
            {iban && (
              <p className='text-center'>
                {t('IBAN')}
                {iban}
              </p>
            )}
            {totalSumToPay && (
              <p className='text-center'>
                {t('Sum to pay')}
                {totalSumToPay} {currencyValue}
              </p>
            )}
            <p className='text-center'>
              {t('Payment reason:')} {t('Licence ChallengeMe')} <span className='font-semibold'>{`"${teamName}"`}</span>
            </p>
          </div>
        </>
      ) : (
        <Spinner />
      )}
      <div className='flex flex-col'>
        {adminLanguage && paymenData.selectedCurrency && <SelectCurencyUnit adminLanguage={adminLanguage} />}
      </div>
      <div className='w-full h-fit flex flex-row justify-between gap-10'>
        <Button
          className={`${buttonStyleNewChallenge}`}
          type={'button'}
          onClick={() => {
            challengeData.SetConfirmUserLicence(false);
            team.setConfirmUserLicence(false);
            localStorage.setItem('confirmLicence', JSON.stringify(false));
            closeModal ? closeModal() : null;
          }}
        >
          {t('CANCEL')}
        </Button>
        <Button
          className={`${buttonStyleNewChallenge} animate-pulse hover:animate-none`}
          type={'button'}
          onClick={() => {
            sendPaymentInstructions(true);
            challengeData.SetConfirmUserLicence(true);
            team.setConfirmUserLicence(true);
            localStorage.setItem('confirmLicence', JSON.stringify(true));
            closeModal ? closeModal() : null;
          }}
        >
          {t('BUY')}
        </Button>
      </div>
    </div>
  );
};

export default PaymentModal;
