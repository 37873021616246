import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import glowField from '../css/GlowField/GlowField';
import '../Textfield/RemoveArrows.css';
export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;

const AddEditTextField: React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;
  return (
    <div className='flex flex-row w-full h-full justify-end '>
      <input
        className={`${glowField} box-border p-2 flex-row  bg-white border-lightBlueButton outline-none border-2 rounded-xl  w-full h-14`}
        value={value ?? ''}
        {...rest}
        ref={ref}
      ></input>
      {error && <span className='text-primary'>{error}</span>}
    </div>
  );
};

export default React.forwardRef(AddEditTextField);
