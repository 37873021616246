import navButtonsDistance from './NavButtonDistance';
import rounded from './Rounding';
import textStyle from './TextStyle';
import whiteBoxWidth from './WhiteBoxWidth';
import whiteText from './WhiteText';

const allStyles = {
  whiteBackground: `bg-white  items-center flex flex-row justify-center   ${whiteText} ${whiteBoxWidth} ${textStyle} ${rounded}`,
  whiteBackgroundWithLargeText: `bg-white  ${whiteText} ${whiteBoxWidth} ${textStyle} ${rounded}`,
  whiteBackgroundWithLargeTextWithoutWhite: `  ${whiteText} ${whiteBoxWidth} ${textStyle} ${rounded}`,
  default: ` ${textStyle} ${navButtonsDistance}`,
  fontStyle: `${textStyle}`,
  textColor: `text-sideTextMenuColor ${textStyle}`,
  whiteText: `${whiteText} ${textStyle} `,
  largeTextOnBar: `${textStyle}`,
};
export default allStyles;
