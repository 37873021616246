import React from 'react';

interface IconProps {
  id: number;
  open: number;
}
const Arrow: React.FC<IconProps> = ({ id, open }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`${
        id === open ? 'rotate-180' : ''
      } h-5   right-4 absolute  transition-transform duration-200  ease-in-out`}
      fill='none'
      viewBox='0 0 24 24'
      stroke='#40CDD6'
      strokeWidth={2}
    >
      <path strokeLinecap='round' strokeLinejoin='round' d='M19 9l-7 7-7-7' />
    </svg>
  );
};

export default Arrow;
