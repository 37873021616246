import React, { useState } from 'react';
import Button from 'src/components/Button';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import roundingFields from 'src/components/RoundingOfAllForms/RoundingFields';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import buttonStyleNewChallenge from '../NewChallengeButtonStyle/ButtonStyle';
import { useTranslation } from 'react-i18next';
import { IButton } from '../ButtonInterface/IButton';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';

const Description = ({ handleNext, handleBack }: IButton): JSX.Element => {
  const challengeData = useChallengeData();

  const { t } = useTranslation(['home', 'main']);
  const [inputText, setInputText] = useState(challengeData.description || localStorage.getItem('description') || '');
  const [isMaxLenght, setMaxLenght] = useState(false);
  const inputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const enteredName = event.target.value;

    setInputText(enteredName);
    if (enteredName.length === 2000) {
      setMaxLenght(true);
    } else {
      setMaxLenght(false);
    }
  };
  return (
    <div className='w-full h-full lg:px-10 lg:py-5 flex  flex-col justify-center items-center'>
      <div className={`${titleStyle} lg:flex hidden uppercase`}>{t('Description')}</div>
      <div
        className={`flex flex-col h-full w-full justify-end ${padding} ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
      >
        <div className='w-full h-full flex flex-col  justify-center'>
          <textarea
            placeholder={`${t('ADD DESCRIPTION FOR YOUR CHALLENGE!')}`}
            maxLength={2000}
            defaultValue={challengeData.description || ''}
            value={inputText}
            onChange={inputHandler}
            className={`border-2  resize-none outline-none p-4 overflow-y-autox  border-lightBlueButton 2xl:h-[50vh] xl:h-[50vh] lg:h-[50vh] h-[30vh] ${roundingFields} ${
              isMaxLenght ? 'border-primary' : 'border-lightBlueButton'
            }`}
          ></textarea>
          <div className='lg:flex hidden w-full justify-end pt-3 text-DarkPrimary'>
            <span className={`${newChallengeTitleStyle} underline justify-end`}>
              {t('Letters')}: {inputText.length}/2000
            </span>
          </div>
        </div>

        <div className='pb-3 relative h-fit flex flex-row  lg:pt-0 pt-4 w-full justify-evenly'>
          <div className='text-center w-full lg:flex hidden flex-col justify-start items-start m-auto h-full '>
            <Button
              className={`${buttonStyleNewChallenge}`}
              type={'button'}
              onClick={() => {
                localStorage.setItem('description', inputText);
                handleBack();
              }}
            >
              {t('PREVIOUS')}
            </Button>
          </div>
          <div className='text-center w-full flex flex-col justify-end lg:items-end items-center  m-auto h-full '>
            <Button
              className={`${buttonStyleNewChallenge}`}
              type={'button'}
              onClick={() => {
                challengeData.setDescrtiption(inputText);
                localStorage.setItem('description', inputText);
                handleNext();
              }}
            >
              {t('NEXT')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Description;
