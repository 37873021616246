import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/store/authContext';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { token, tokenLogin } = useAuth();
  const pathname = useLocation();
  if (pathname.pathname.includes('/team/joinTeam/')) {
    localStorage.setItem('magicLink', pathname.pathname);
  }

  if (!token) {
    if (!tokenLogin) {
      return <Navigate to='/marketing' replace />;
    } else {
      return <Navigate to='/login' replace />;
    }
  }

  return <>{children}</>;
};

export default ProtectedRoute;
