import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useApiClient } from 'src/api/client';
import Button from 'src/components/Button';
import glowField from 'src/components/css/GlowField/GlowField';
import padding from 'src/components/css/MyChallengeLogsStyle/NewChallengePageStyles/PaddingInAllSteps';
import newChallengeTitleStyle from 'src/components/css/TitleStyleNewChallengePage/NewChallengeTitleStyle';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import TargetsTooltip from 'src/utils/TargetsTooltip';
import Tooltip from 'src/utils/Tooltip';
import buttonStyleNewChallenge from '../NewChallengeButtonStyle/ButtonStyle';
import { NewChallengeForm } from '../NewChallengeCard';
import { useTranslation } from 'react-i18next';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { useToast } from 'src/store/toastContext';
import { ProcessSpiner } from 'src/utils/ProcessSpiner';
import { addDays } from 'date-fns';
interface IButton {
  handleBack: () => void;
  handleConfirm: () => void;
}
interface MyResponseData {
  message: string;
}
const Overview = ({ handleConfirm, handleBack }: IButton): JSX.Element => {
  const { showToastHandler } = useToast();
  const { reset } = useForm<NewChallengeForm>();
  const Client = useApiClient();
  const challengeData = useChallengeData();
  const navigate = useNavigate();
  const { t } = useTranslation(['home', 'main']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emails] = useState<string[]>(challengeData.emails || []);

  const onClick = async (): Promise<void> => {
    try {
      const teamName = challengeData.teamId == 0 ? challengeData.teamName : challengeData.selectedTeamName;
      setIsSubmitting(true);
      const response = await Client.post('/challenge/create', {
        team_name: teamName,
        team_id: challengeData.teamId,
        challenge_name: challengeData.challengeName,
        challenge_info: challengeData.description,
        date_from: challengeData.dateFrom,
        date_to: challengeData.dateTo,
        deadline_Date: challengeData.checkBoxDeadlineDate
          ? addDays(challengeData.dateTo, 1)
          : challengeData.deadLineDate,
        chall_insert_log_days_after_activity: challengeData.checkBoxDeadlineDate,
        emails: challengeData.emails.toString(),
        targets: challengeData.challengeTargets,
        confirmUserLicence: challengeData.confirmUserLicence,
        auto_approve: challengeData.checkBoxAutoApprove,
      });
      reset();
      reset({ emails: '' });
      if (response.status == 200) {
        showToastHandler(`${t('You created new challenge!')}`, 'success');
        handleConfirm();
        challengeData.clearAllData();
        challengeData.removeLocalStorageData();
        navigate('/challenge/logs/');
      }
    } catch (exc) {
      setIsSubmitting(false);
      const err = exc as AxiosError<MyResponseData>;
      const error = err?.response?.data.message;
      if (err.response?.status == 400) {
        Swal.fire({
          title: `${error}`,
        });
        return;
      }
      Swal.fire({
        title: `${t('The team already exists or you are not an admin')}`,
      });
      console.error('Something is wrong!');
    }
  };
  return (
    <div className='w-full h-full lg:px-10 lg:py-5 flex  flex-col justify-center items-center  overflow-hidden '>
      <div className={`${titleStyle} lg:flex hidden uppercase`}>{t('Overview')}</div>
      <div
        className={`flex flex-col w-full h-full overflow-hidden justify-end phone:rounded-none rounded-none ${padding} ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
      >
        <div className='h-full flex lg:flex-row flex-col  justify-between lg:overflow-hidden pt-2'>
          <div className='2xl:w-[512px] xl:w-5/12 lg:w-5/12 lg:pb-2  items-center space-y-4  flex flex-col h-full text-DarkPrimary'>
            <div className='w-full h-fit'>
              <div className={newChallengeTitleStyle}>{t('TEAM')}:</div>
              <div className={`${glowField} bg-lightGrayBg`}>
                {challengeData.teamId == 0 ? challengeData.teamName : challengeData.selectedTeamName}
              </div>
            </div>
            <div className='w-full h-fit'>
              <div className={newChallengeTitleStyle}>{t('CHALLENGE NAME')}:</div>
              <div className={`${glowField} bg-lightGrayBg`}>{challengeData.challengeName}</div>
            </div>
            <div className='flex flex-col lg:flex-row lg:space-y-0 space-y-4 2xl:pb-[6px]  lg:space-x-1 pb-0 w-full lg:text-'>
              <div className='flex flex-col w-full justify-end items-center overflow-hidden '>
                <div className={newChallengeTitleStyle}>{t('DATE FROM')}:</div>
                <div className={`${glowField} bg-lightGrayBg lg:pl-1 xl:pl-2 2xl:pl-2`}>
                  {challengeData.dateFrom.toLocaleDateString().slice(0, 10)}
                </div>
              </div>
              <div className='flex flex-col w-full justify-end overflow-hidden'>
                <div className={newChallengeTitleStyle}>{t('DATE TO')}:</div>
                <div className={`${glowField} bg-lightGrayBg lg:pl-1 xl:pl-2 2xl:pl-2`}>
                  {challengeData.dateTo.toLocaleDateString().slice(0, 10)}
                </div>
              </div>
              <div className='flex flex-col w-full justify-end overflow-hidden '>
                <div className={newChallengeTitleStyle}>{t('DEADLINE')}:</div>
                <div
                  className={`${glowField} ${
                    challengeData.checkBoxDeadlineDate ? 'text-gray' : 'text-DarkPrimary'
                  } bg-lightGrayBg lg:pl-1 xl:pl-2 2xl:pl-2`}
                >
                  {challengeData.deadLineDate.toLocaleDateString().slice(0, 10)}
                </div>
              </div>
            </div>
            <div
              className={`border-lightBlueButton text-DarkPrimary lg:pb-8 lg:bg-lightGrayBg bg-inactiveFields outline-none lg:border-2 phone:border-0 border-0 lg:px-5 w-full  lg:h-full   overflow-hidden phone:rounded-none rounded-none ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
            >
              <div
                className={`phone:pl-4 mt-1 font-bold 2xl:text-base border-b-[1px]  border-DarkPrimary ${newChallengeTitleStyle}`}
              >
                {t('E-MAILS')}: {emails.length}
              </div>
              <div className='w-full lg:h-full overflow-y-auto  phone:h-[21vh] sm:h-[30vh] h-[25vh] lg:pb-0 phone:pb-4 space-y-2  pt-1'>
                {emails.map((email, index) => {
                  return (
                    <div
                      key={index}
                      className={`px-4 py-2 lg:w-[20vw] 2xl:w-[20vw] phone:w-full w-full  justify-between rounded-full bg-lightGrayBg lg:bg-white text-DarkPrimary  font-semibold border-lightGreen border-[2px] text-sm flex align-center ${glowField}`}
                    >
                      <Tooltip tooltip={email} tooltipContent={email} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='flex flex-col   justify-start items-center 2xl:w-[512px] xl:w-5/12 lg:w-5/12'>
            <div className='font-normal flex justify-center w-full border-DarkPrimary text-DarkPrimary lg:pt-0 pt-4 h-fit 2xl:text-base xl:text-base lg:text-[16px] phone:text-[17px] text-[15px] 2xl:pl-3'>
              <div className='flex font-bold'>{t('TARGET')}/</div>
              <div className='flex'>{t('GOAL')}</div>
              <div className='flex font-bold'>/{t('UNIT OF MEASURE')}</div>
            </div>
            <div
              className={`lg:border-2 overflow-hidden phone:border-0 border-0 w-full lg:h-full lg:mb-2 h-[20vh] phone:h-[20vh] sm:h-[33vh]  lg:bg-lightGrayBg border-lightBlueButton flex-col py-1 lg:pr-3  phone:rounded-none rounded-none ${roundingForm} lg:bg-opacity-80 bg-opacity-0`}
            >
              <div className='overflow-y-auto  w-full flex flex-col justify-start items-center space-y-2  h-full phone:text-sm'>
                {challengeData.challengeTargets.map((target, index) => {
                  return (
                    <div
                      key={index}
                      className={`px-4 py-1 lg:w-[20vw] 2xl:w-[20vw]  w-full phone:w-full justify-between rounded-full bg-lightGrayBg lg:bg-white text-DarkPrimary font-semibold border-lightGreen border-[2px] text-sm flex align-center ${glowField}`}
                    >
                      <TargetsTooltip
                        targetName={target.targetName}
                        targetValue={target.targetValue}
                        targetUnit={t(`${target.targetUnit}`)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='pb-3 relative h-fit flex flex-row w-full justify-between'>
          <div className='text-center w-full lg:flex hidden flex-col justify-start items-start m-auto h-full'>
            <Button className={buttonStyleNewChallenge} type={'button'} onClick={handleBack}>
              {t('PREVIOUS')}
            </Button>
          </div>
          <div className='text-center w-full animate-pulse flex flex-col justify-end items-center lg:items-end my-auto h-full '>
            <Button className={buttonStyleNewChallenge} type={'button'} disabled={isSubmitting} onClick={onClick}>
              {isSubmitting ? (
                <div className='w-full h-fit flex flex-row items-center justify-center cursor-not-allowed'>
                  <div className='w-2/12 items-center justify-center flex'>
                    <ProcessSpiner />
                  </div>
                  <div className='w h-fit'>{t('PROCESSING...')}</div>
                </div>
              ) : (
                `${t('CONFIRM')}`
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Overview;
