import React from 'react';

const TrashIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29' fill='none'>
    <path
      d='M24.1669 3.6096H18.4883C18.2878 1.58599 16.576 0 14.5005 0C12.4249 0 10.7131 1.58599 10.5126 3.6096H4.83414C3.13032 3.6096 1.74414 4.99578 1.74414 6.6996C1.74414 8.352 3.04791 9.70548 4.68076 9.78575V25.2683C4.68076 27.326 6.35479 29 8.4125 29H20.5886C22.6463 29 24.3203 27.326 24.3203 25.2683V9.78581C25.9532 9.70554 27.2569 8.35206 27.2569 6.69966C27.2569 4.99578 25.8708 3.6096 24.1669 3.6096ZM14.5005 1.89749C15.5279 1.89749 16.3858 2.63581 16.5725 3.6096H12.4284C12.6152 2.63581 13.473 1.89749 14.5005 1.89749ZM22.4228 25.2683C22.4228 26.2798 21.5999 27.1026 20.5885 27.1026H8.41243C7.40101 27.1026 6.57819 26.2798 6.57819 25.2683V9.78967C7.35262 9.78967 21.8171 9.78967 22.4228 9.78967V25.2683ZM24.1669 7.89217C23.9691 7.89217 5.00232 7.89217 4.83414 7.89217C4.1766 7.89217 3.64163 7.35721 3.64163 6.69966C3.64163 6.04212 4.1766 5.50715 4.83414 5.50715H24.1669C24.8244 5.50715 25.3594 6.04212 25.3594 6.69966C25.3594 7.35721 24.8245 7.89217 24.1669 7.89217Z'
      fill='#15CDCA'
    />
    <path
      d='M14.4995 24.814C15.0235 24.814 15.4483 24.3892 15.4483 23.8652V12.8784C15.4483 12.3545 15.0236 11.9297 14.4995 11.9297C13.9756 11.9297 13.5508 12.3545 13.5508 12.8784V23.8652C13.5508 24.3892 13.9756 24.814 14.4995 24.814Z'
      fill='#15CDCA'
    />
    <path
      d='M19.398 24.814C19.9219 24.814 20.3467 24.3892 20.3467 23.8652V12.8784C20.3467 12.3545 19.9219 11.9297 19.398 11.9297C18.874 11.9297 18.4492 12.3545 18.4492 12.8784V23.8652C18.4492 24.3892 18.874 24.814 19.398 24.814Z'
      fill='#15CDCA'
    />
    <path
      d='M9.60304 24.814C10.127 24.814 10.5518 24.3892 10.5518 23.8652V12.8784C10.5518 12.3545 10.127 11.9297 9.60304 11.9297C9.07908 11.9297 8.6543 12.3545 8.6543 12.8784V23.8652C8.6543 24.3892 9.07908 24.814 9.60304 24.814Z'
      fill='#15CDCA'
    />
  </svg>
);

export default TrashIcon;
