import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useApiClient } from 'src/api/client';
import Modal from 'src/components/Modal';
import { useTeam } from 'src/store/teamDetailContext';
import TeamMemberRow from '../TeamMemberRow';
import TeamNameEditor from '../TeamNameEditor';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import swal from 'sweetalert2';
import useDebounce from 'src/utils/useDebounce';
import TextField from '../../../components/Textfield';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'src/utils/Spinner';
import { useTranslation } from 'react-i18next';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import AddMemberIcon from 'src/components/Icons/AddMemberIcon';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import { useToast } from 'src/store/toastContext';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';
import ApproveAllIcon from 'src/components/Icons/ApproveAllIcon';
import RightSideBottomIconTooltip from 'src/utils/RightSideBottomIconTooltipUtils';
import { AxiosError } from 'axios';
import ModalFromLibrary from 'src/components/ModalFromLibrary';
import { Dialog } from '@headlessui/react';
import Payment from 'src/components/Payment/Payment';
import { PaymentDetailProvider } from 'src/store/paymentContext';
import { removedExistedUsers } from 'src/utils/addMemberUtils';

export interface TeamMember {
  isApprovedToJoinTeam: boolean;
  email: string;
  role: 'ADMIN' | 'MEMBER';
  memberId: number;
  userId: number;
  user_name: string;
}

interface AddMemberForm {
  email: string;
}
interface IError {
  message: string;
}

const TeamDetail: React.FC<unknown> = () => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [tempTeamMembers, setTempTeamMembers] = useState<TeamMember[]>([]);
  const { t } = useTranslation(['home', 'main']);
  const { handleSubmit, control, formState, setValue } = useForm<AddMemberForm>();
  const { showToastHandler } = useToast();
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState<boolean>(false);
  const debouncedValue = useDebounce<TeamMember[]>(tempTeamMembers, 1000);
  const [isOpen, setIsOpen] = useState(false);
  const [isLicenceExpired, setIsLicenceExpired] = useState<boolean | null>(false);

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };

  const navigate = useNavigate();

  const moveToMyTeams = (): void => {
    navigate(`/myTeams`);
  };

  useEffect(() => {
    loadData();
  }, [debouncedValue]);
  const [onSave, setOnSave] = useState(false);

  const Client = useApiClient();

  const team = useTeam();

  const approveMember = async (memberId: number): Promise<void> => {
    try {
      const resp = await Client.post('/team/approveUser', { team_user_id: memberId });
      if (resp.status === 200) {
        loadData();
        showToastHandler(`${t('You approved the member successfully')}`, 'success');
      }
    } catch (exc) {
      const err = exc as AxiosError<IError>;
      const error = err?.response?.data.message;
      if (err.response?.status == 400) {
        showToastHandler(`${error}`, 'error');
      }
    }
  };

  const approveAllMember = async (teamId: number, teamMembers: TeamMember[]): Promise<void> => {
    const members = teamMembers.filter((user) => user.isApprovedToJoinTeam === false).map((x) => x.memberId);
    try {
      const resp = await Client.post(
        '/team/approveAllUsers',
        { users_id: members, team_id: teamId },
        { headers: { team_id: teamId } },
      );
      if (resp.status === 200) {
        loadData();
        showToastHandler(`${t('You approved the all members successfully')}`, 'success');
      }
    } catch (exc) {
      const err = exc as AxiosError<IError>;
      const error = err?.response?.data.message;
      if (err.response?.status == 400) {
        showToastHandler(`${error}`, 'error');
      }
    }
  };

  const changeRole = async (memberId: number, title: string, currentRole: string): Promise<void> => {
    const { value: role } = await swal.fire({
      title: title,
      input: 'select',
      inputValue: currentRole,
      inputOptions: {
        ADMIN: `${t('ADMIN')}`,
        MEMBER: `${t('MEMBER')}`,
      },
      cancelButtonText: `${t('CANCEL')}`,
      inputPlaceholder: `${t('Select a role')}`,
      showCancelButton: true,
    });
    try {
      const resp = await Client.post(
        '/team/changeRole',
        { member_id: memberId, role: role },
        { headers: { team_id: team.teamId } },
      );
      if (resp.status === 200) {
        loadData();
        showToastHandler(`${t('Role changed successfully')}`, 'success');
      }
    } catch (exc) {
      console.error('Error while changing role');
    }
  };

  const onSubmit: SubmitHandler<AddMemberForm> = async (data) => {
    setOnSave(true);
    const { emails } = removedExistedUsers(teamMembers, data.email);

    if (emails.invalidEmails.length) {
      showToastHandler(
        `${emails.invalidEmails.length !== 1 ? emails.invalidEmails.length : emails.invalidEmails.join(', ')} ${
          emails.invalidEmails.length !== 1
            ? t('Emails lowerCase') + t('emails is invalid!')
            : t('Email lowerCase') + t('email is invalid!')
        }`,
        'error',
      );
    }
    if (emails.existEmails.length) {
      showToastHandler(
        `${emails.existEmails.length !== 1 ? emails.existEmails.length : emails.existEmails.join(', ')} ${
          emails.existEmails.length !== 1
            ? t('Emails lowerCase') + t('is exist!')
            : t('Email lowerCase') + t('is exist!')
        }`,
        'error',
      );
    }
    if (emails.validEmails.length) {
      showToastHandler(
        `${emails.validEmails.length !== 1 ? emails.validEmails.length : emails.validEmails.join(', ')} ${
          emails.validEmails.length !== 1
            ? t('Emails lowerCase') + t('emails is added successfully!')
            : t('Email lowerCase') + t('email is added successfully!')
        }`,
        'success',
      );
    }
    try {
      if (emails.validEmails.length) {
        await Client.post(
          '/team/addMember',
          { team_id: team.teamId, user_email: emails.validEmails, confirmPayment: team.confirmUserLicence },
          { headers: { team_id: team.teamId } },
        );
        setValue('email', '');
        setIsAddMemberModalOpen(false);
        loadData();
      }
    } catch (exc) {
      const error = exc as AxiosError<IError>;
      const message = error.response?.data.message;
      openModal();
      if (message) {
        showToastHandler(message, 'error');
      }
    } finally {
      setOnSave(false);
    }
    team.setConfirmUserLicence(false);
  };

  const loadData = async (): Promise<void> => {
    if (team.teamId === 0) return;
    try {
      const response = await Client.get(`/team/teamMembers/${team.teamId}`, { headers: { team_id: team.teamId } });
      const data = response.data;
      const isTeamLicenceExpired = response.data.isLicenceExpired;
      const members = data.members as TeamMember[];
      const teamName = data.teamName as string;

      setTeamMembers(members);
      setIsLicenceExpired(isTeamLicenceExpired);
      team.setTeamName(teamName);
      team.SetLicence(data.teamLicence);
    } catch (exc) {
      console.error('Error while loading data' + exc);
    }
  };
  const kickMember = async (memberId: number): Promise<void> => {
    try {
      swal
        .fire({
          title:
            "<h5 style='color: #F16B6B;'>" + `${t('Do you really want to remove a member from the team?')}` + '</h5>',
          showCancelButton: true,
          confirmButtonText: `${t('YES')}`,
          cancelButtonText: `${t('CANCEL')}`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            Client.post(
              '/team/kickMember',
              { user_id: memberId, team_id: team.teamId },
              { headers: { team_id: team.teamId } },
            );
            const temp = teamMembers.filter((tm) => tm.memberId !== memberId);
            setTeamMembers(temp);
            setTempTeamMembers(temp);
            showToastHandler(`${t('Member successfully deleted')}`, 'success');
          }
        });
    } catch (exc) {
      console.error(exc);
    }
  };
  useEffect(() => {
    loadData();
  }, [team.teamId]);
  if (team.teamId === 0) return <></>;

  return (
    <div className='flex flex-col justify-start w-full px-2 tablet:w-full h-screen'>
      <MobileNav />
      <div className='flex flex-col  h-full'>
        <div className='text-3xl text-white primary justify-center items-center flex font-semibold w-full flex-row phone:mt-3 mt-3 lg:px-0 phone:px-10 px-10'>
          <div className='lg:w-full phone:w-10/12 w-10/12 justify-start item-start'>
            <TeamNameEditor teamId={team.teamId} />
          </div>
          <div className='flex flex-row w-fit justify-end items-end'>
            <button
              type='button'
              onClick={() => {
                team.setTeamId(0);
                moveToMyTeams();
              }}
              className={`text-white w-fit btn hover:bg-darkGreenHoverButton btn-sm border-none rounded-lg btn-circle  px-2`}
            >
              x
            </button>
          </div>
        </div>
        <div className={`h-full overflow-hidden my-4 ${roundingForm}`}>
          <div className='w-full h-full '>
            <div className='mt-4 flex flex-row w-full items-center justify-center lg:pr-0  phone:pr-2 pr-2 '>
              <div className={headerStyle}>
                <span
                  className={`${iconTransition} cursor-pointer group lg:scale-100 phone:scale-100 scale-75 w-fit`}
                  onClick={() => approveAllMember(team.teamId, teamMembers)}
                >
                  <ApproveAllIcon />
                  <RightSideBottomIconTooltip tooltipContent={`${t('APPROVE ALL')}`} />
                </span>
              </div>
              <div className={headerStyle}>{t('NAME')}</div>
              <div className={headerStyle}>{t('Email')}</div>
              <div className={headerStyle}>{t('ROLE')}</div>
              <div className='w-full text-center flex flex-row   justify-center items-center h-full '>
                <button
                  onClick={() => (isLicenceExpired ? openModal() : setIsAddMemberModalOpen(true))}
                  className={`${iconTransition} cursor-pointer group justify-center flex flex-row lg:scale-100 phone:scale-75 scale-75  `}
                >
                  <AddMemberIcon />
                  <LeftSideBottomIconTooltip tooltipContent={t('ADD MEMBER')} />
                </button>
              </div>
            </div>

            <div className='w-full h-[80vh] overflow-y-auto'>
              {!team.teamName ? (
                <Spinner />
              ) : (
                <div className='w-full h-full  '>
                  {teamMembers.map((data, index) => (
                    <TeamMemberRow
                      changeRole={changeRole}
                      approveMember={approveMember}
                      kickMember={kickMember}
                      key={data.email + '-KEY'}
                      {...data}
                      isLastItem={teamMembers.length - 1 === index}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          onConfirm={handleSubmit(onSubmit)}
          onClose={() => {
            setValue('email', '');
            setIsAddMemberModalOpen(false);
          }}
          isOpen={isAddMemberModalOpen}
          onSave={onSave}
        >
          <div className='flex flex-col '>
            <div className='pb-4 text-DarkPrimary font  font-style- normal'>
              {t('Enter the E-Mail of the member you want to add.')}
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name='email'
                  rules={{
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: `${t('Please enter a valid email')}`,
                    },
                    required: { value: true, message: `${t("Enter the member's email")}` },
                  }}
                  render={({ field }) => (
                    <>
                      <TextField type={'email'} {...field} />
                    </>
                  )}
                />
                {formState.errors.email?.message && (
                  <span className='text-primary'>{formState.errors.email?.message}</span>
                )}
              </form>
            </div>
          </div>
        </Modal>
      </div>
      <PaymentDetailProvider>
        <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
          <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
            <button
              type='button'
              className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
              onClick={closeModal}
            >
              x
            </button>
          </Dialog.Title>
          <Payment closeModal={closeModal} teamId={team.teamId} teamName={team.teamName} paymentInstructions={true} />
        </ModalFromLibrary>
      </PaymentDetailProvider>
    </div>
  );
};

export default TeamDetail;
