import React from 'react';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';
import ThumbDownFilledIcon from '../Icons/ThumbDownFilledIcon';
import { useTranslation } from 'react-i18next';

const HeaderReportedActivity: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);

  return (
    <tr className='flex flex-row w-full  z-20 py-[1em] items-center sticky  top-0'>
      <th className={headerStyle}>{t('NAME')}</th>
      <th className={headerStyle}>{t('DATE')}</th>
      <th className={headerStyle}>{t('TARGET')}</th>
      <th className={`${headerStyle}  lg:flex hidden `}>{t('VALUE')}</th>
      <th className={`${headerStyle}  lg:flex hidden `}>{t('LINK')}</th>
      <th className={`${headerStyle}  lg:flex hidden `}>{t('PHOTO')}</th>
      <th className={`${headerStyle}  flex`}>
        <span className='flex justify-center  scale-125'>
          <ThumbDownFilledIcon />
        </span>
      </th>
      <th className={`${headerStyle} lg:w-full  phone:w-[70px] w-[60px]`}></th>
    </tr>
  );
};

export default HeaderReportedActivity;
