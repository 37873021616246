import { format, parse } from 'date-fns';
import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import errorGlowField from '../css/GlowField/ErrorGlowField';
import glowField from '../css/GlowField/GlowField';
import roundingFields from '../RoundingOfAllForms/RoundingFields';
import './Calendar.css';

export type DatePickerProps = Omit<
  FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, Date | null>,
  'onChange'
> & {
  onChange?: (date: Date | null) => void;
};

const DatePicker: React.ForwardRefRenderFunction<HTMLInputElement, DatePickerProps> = (props, ref) => {
  const { value, error, onChange, ...rest } = props;

  return (
    <div className='flex flex-col w-full h-fit'>
      <input
        type='date'
        className={`${error ? errorGlowField : glowField}   ${roundingFields}`}
        value={value ? format(value, 'yyyy-MM-dd') : ''}
        onChange={(e) => {
          const date = e.target.value;

          if (!date) {
            onChange?.(null);
            return;
          }
          const prevDate = value ?? new Date();
          const parsedDate = parse(date, 'yyyy-MM-dd', prevDate);
          parsedDate.setHours(
            prevDate.getHours(),
            prevDate.getMinutes(),
            prevDate.getSeconds(),
            prevDate.getMilliseconds(),
          );
          onChange?.(parsedDate);
        }}
        {...rest}
        ref={ref}
      ></input>
    </div>
  );
};

export default React.forwardRef(DatePicker);
