import React from 'react';

const UserIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='26' viewBox='0 0 24 26' fill='none'>
    <path
      d='M11.9998 10.3728C14.6508 10.3728 16.7998 8.13036 16.7998 5.36415C16.7998 2.59793 14.6508 0.355469 11.9998 0.355469C9.34884 0.355469 7.1998 2.59793 7.1998 5.36415C7.1998 8.13036 9.34884 10.3728 11.9998 10.3728Z'
      fill='#3B5DC4'
    />
    <path
      d='M0.799805 25.3989C0.799805 18.9443 5.81421 13.7119 11.9998 13.7119C18.1854 13.7119 23.1998 18.9443 23.1998 25.3989H0.799805Z'
      fill='#3B5DC4'
    />
  </svg>
);

export default UserIcon;
