import { TeamMember } from 'src/pages/MyTeamsPage/TeamDetail';
import emailRegex from './emailRegex';
export interface UsersSorting {
  emails: {
    validEmails: string[];
    invalidEmails: string[];
    existEmails: string[];
  };
}
export function removedExistedUsers(oldUsers: TeamMember[], newEmails: string): UsersSorting {
  const sortedEmails = removeDuplicatedEmails(newEmails);

  const newUsers = [];
  const invalidUsers = [];
  const existEmails = [];
  for (const email of sortedEmails) {
    if (!oldUsers.some((user) => user.email === email) && emailRegex.test(email)) {
      newUsers.push(email);
    } else if (!emailRegex.test(email)) {
      invalidUsers.push(email);
    } else {
      existEmails.push(email);
    }
  }

  const sortingResult: UsersSorting = {
    emails: {
      validEmails: newUsers,
      invalidEmails: invalidUsers,
      existEmails: existEmails,
    },
  };

  return sortingResult;
}

export function removeDuplicatedEmails(emails: string): string[] {
  const splitted = emails.split(/[ ,]+/);
  const uniqueEmails = Array.from(new Set(splitted));
  return uniqueEmails.filter((email) => email !== '');
}
