import React from 'react';
import { ParallaxLayer } from '@react-spring/parallax';
import { ReactComponent as FirstLayer } from '../../../images/icons/ParalaxVectors/FirstLayer.svg';
import { ReactComponent as LayerTwo } from '../../../images/icons/ParalaxVectors/LayerTwo.svg';
import { ReactComponent as ThirdLayer } from '../../../images/icons/ParalaxVectors/ThirdLayer.svg';
import { ReactComponent as FourLayer } from '../../../images/icons/ParalaxVectors/FourLayer.svg';
import { ReactComponent as FiftLayer } from '../../../images/icons/ParalaxVectors/FiftLayer.svg';
import { ReactComponent as SixtLayer } from '../../../images/icons/ParalaxVectors/SixtLayer.svg';
import { ReactComponent as SeventLayer } from '../../../images/icons/ParalaxVectors/SeventLayer.svg';
import { ReactComponent as BirdLayer } from '../../../images/icons/ParalaxVectors/BirdLayer.svg';
import { ReactComponent as WomanVector } from '../../../images/icons/ParalaxVectors/WomanVector.svg';
import { ReactComponent as ManVector } from '../../../images/icons/ParalaxVectors/ManVector.svg';
import { ReactComponent as Cloud } from '../../../images/icons/ParalaxVectors/Cloud.svg';
import { ReactComponent as Logo } from '../../../images/icons/ParalaxVectors/Logo.svg';
import { ReactComponent as CloudTwo } from '../../../images/icons/ParalaxVectors/CloudTwo.svg';
import { ReactComponent as CloudThree } from '../../../images/icons/ParalaxVectors/CloudThree.svg';

const DesktopParallax: React.FC = () => {
  return (
    <>
      <ParallaxLayer offset={-0.01} speed={0.1} factor={1.2}>
        <SeventLayer className='w-full h-full absolute' />
      </ParallaxLayer>
      <ParallaxLayer offset={0.204} speed={-0.05}>
        <ParallaxLayer offset={0.5} factor={1.8} speed={0.08} horizontal={true}>
          <SixtLayer className='w-full h-full absolute' />
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={0.34} speed={0.1}>
        <ParallaxLayer offset={-0.15} speed={-0.1}>
          <FiftLayer className='w-full h-full absolute left-0' />
        </ParallaxLayer>
        <ParallaxLayer offset={0.5} speed={-0.4} horizontal={true}>
          <ParallaxLayer offset={0.1} speed={-0.2} horizontal={false}>
            <BirdLayer className='scale-125' />
          </ParallaxLayer>
        </ParallaxLayer>
        <ParallaxLayer offset={1.1} speed={0.12} horizontal={true}>
          <ParallaxLayer offset={0.1} speed={-0.2}>
            <BirdLayer className='scale-50' />
          </ParallaxLayer>
          <ParallaxLayer offset={-0.2} speed={0.2} horizontal={true}>
            <BirdLayer className='scale-75' />
          </ParallaxLayer>
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={0.55} speed={0.26} factor={1.1}>
        <FourLayer className='w-full h-full absolute right-20' />
      </ParallaxLayer>
      <ParallaxLayer offset={0.74} speed={0.5}>
        <ThirdLayer className='w-full h-full absolute' />
        <ParallaxLayer offset={-0.2} speed={0.1} horizontal={false}>
          <ParallaxLayer horizontal={true} offset={1.4}>
            <BirdLayer />
          </ParallaxLayer>
          <ParallaxLayer horizontal={true} offset={1} speed={-0.1}>
            <ParallaxLayer offset={0.01} speed={-0.01}>
              <BirdLayer className='scale-90' />
            </ParallaxLayer>
          </ParallaxLayer>
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={0.8} speed={0.3}>
        <ParallaxLayer offset={0.9} speed={1.6} horizontal={true} factor={1}>
          <WomanVector className='lg:w-[14rem] xl:w-[20rem] h-full' />
        </ParallaxLayer>
        <ParallaxLayer offset={0.3} speed={0.6}>
          <LayerTwo className='w-full h-full' />
        </ParallaxLayer>
        <ParallaxLayer offset={0.99} speed={1} horizontal={true} factor={1.5}>
          <ManVector className='lg:w-[15rem] xl:w-[20rem] h-full' />
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={0.5} speed={0.1} horizontal={true}>
        <ParallaxLayer offset={0.1} speed={-0.1}>
          <Cloud className='scale-50' />
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={1} speed={0.1} horizontal={true}>
        <ParallaxLayer offset={0.05} speed={-0.1}>
          <CloudThree />
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={1.2} speed={0.2} horizontal={true}>
        <ParallaxLayer offset={0.1} speed={-0.1}>
          <CloudTwo className='scale-150' />
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={0.05} speed={-1.335}>
        <ParallaxLayer horizontal={true} offset={0.1}>
          <Logo className='lg:scale-100 scale-75' />
        </ParallaxLayer>
      </ParallaxLayer>
      <ParallaxLayer offset={0.99} speed={1} factor={1.1}>
        <FirstLayer className='w-full h-full absolute bottom-0' />
      </ParallaxLayer>
    </>
  );
};

export default DesktopParallax;
