import { format } from 'date-fns';
import React from 'react';
import DeleteIcon from 'src/components/Icons/deleteIcon';
import ExternalLinkIcon from 'src/components/Icons/ExternalLinkIcon';
import PictureIcon from 'src/components/Icons/pictureIcon';
import swal from 'sweetalert2';
import { IReportedActivityLog } from '../ReportedActivityPage';
import scaleTextElements from 'src/components/css/ScaleTextAndElements/ScaleTextElements';
import activityLogStyle from 'src/components/css/ActivityLogsStyle/ActivityLogStyle';
import { useApiClient } from 'src/api/client';
import { rowTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/RowsTransition';
import RightSideBottomIconTooltip from 'src/utils/RightSideBottomIconTooltipUtils';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import { useTranslation } from 'react-i18next';
import PictureSVGIcon from 'src/components/Icons/PictureSVGIcon';
import LeftSideTopIconTooltip from 'src/utils/LeftSideTopIconTooltipUtils';
import RightSideTopIconTooltip from 'src/utils/RightSideTopIconTooltipUtils';
import overflowText from 'src/components/css/MyChallengeLogsStyle/OverflowText';

const ReportedActivityRow: React.FC<
  IReportedActivityLog & { isLastItem: boolean; deleteLog: (chl_id: number) => void; isFirstItem: boolean }
> = ({
  user_name,
  isLastItem,
  chl_date,
  chl_img,
  chl_link,
  chl_value,
  cht_name,
  cht_unit,
  user_email,
  chl_negative_feedback,
  chl_id,

  deleteLog,
}) => {
  const { t } = useTranslation(['home', 'main']);
  const Client = useApiClient();
  const getImg = async (): Promise<void> => {
    const response = await Client.get(`reportedActivity/reportedLogImg/${chl_id}`);
    showModal(response.data.photo);
  };
  const showModal = (photo: string): void => {
    swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: PictureSVGIcon,
      html: `<img class='w-full h-full' src="data:image/jpeg;base64,${photo}" />`,
    });
  };

  return (
    <tr className={`${rowTransition} w-full justify-center  items-center flex-row flex py-2`}>
      <td className={`${activityLogStyle}`}>
        <div className={overflowText}>{user_name || user_email}</div>

        {!isLastItem ? (
          <RightSideBottomIconTooltip tooltipContent={user_name || user_email} />
        ) : (
          <RightSideTopIconTooltip tooltipContent={user_name || user_email} />
        )}
      </td>
      <td className={`${activityLogStyle} flex justify-center`}>{format(new Date(chl_date), 'dd.MM.yyyy')}</td>
      <td className={`${activityLogStyle} break-normal flex justify-center`}>
        <div className={overflowText}>{cht_name}</div>
      </td>
      <td className={`${activityLogStyle} flex justify-center w-full `}>
        {chl_value} {t(`${cht_unit}`)}
      </td>
      <td className={`${activityLogStyle} overflow-visible flex justify-center`}>
        {(chl_link && (
          <a
            className={scaleTextElements}
            href={/https?:\/\//.test(chl_link) ? chl_link : `http://${chl_link}`}
            target={'_blank'}
            rel='noreferrer'
          >
            <ExternalLinkIcon />
            {!isLastItem ? (
              <LeftSideBottomIconTooltip tooltipContent={chl_link} />
            ) : (
              <LeftSideTopIconTooltip tooltipContent={chl_link} />
            )}
          </a>
        )) || <span className='font-bold flex justify-center w-full'>-</span>}
      </td>
      <td className={`${activityLogStyle} overflow-visible flex justify-center`}>
        {(chl_img && (
          <span className={`font-bold cursor-pointer ${scaleTextElements}`} onClick={getImg}>
            <PictureIcon />
            {!isLastItem ? (
              <RightSideBottomIconTooltip tooltipContent={t('PHOTO')} />
            ) : (
              <RightSideTopIconTooltip tooltipContent={t('PHOTO')} />
            )}
          </span>
        )) || <span className='font-bold flex justify-center w-full'>-</span>}
      </td>
      <td className={activityLogStyle}>
        <span className={`ml-1 ${scaleTextElements}`}>{chl_negative_feedback}</span>
      </td>
      <td className={`${activityLogStyle} overflow-visible`}>
        <span className={`${scaleTextElements} group`}>
          <button onClick={() => deleteLog(chl_id)}>
            <DeleteIcon />
            {!isLastItem ? (
              <LeftSideBottomIconTooltip tooltipContent={t('Delete')} />
            ) : (
              <LeftSideTopIconTooltip tooltipContent={t('Delete')} />
            )}
          </button>
        </span>
      </td>
    </tr>
  );
};

export default ReportedActivityRow;
