import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute/index';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import TechnicianOrder from './pages/TechnicianOrder/TechnicianOrder';
import { AuthProvider } from './store/authContext';
import UserPage from './pages/ChangePasswordPage';
import MyChallengesPage from './pages/MyChallengesPage/MyChallengesPage';
import MyTeamsPage from './pages/MyTeamsPage/MyTeamsPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import TechnicianOrderFinish from './pages/TechnicianOrder/TechnicianOrderFinish';
import { TechnicianAuthProvider } from './store/technicianAuthContext';
import ReportedActivityPage from './pages/ReportedActivityPage/ReportedActivityPage';
import EditChallengePage from './pages/EditChallengePage/EditChallengePage';
import NewChallengePage from './pages/NewChallengePage/NewChallengePage';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { MyChallengeDetailProvider } from './store/myChallengePageContex';
import { ChallengeTargetsProvider } from './store/challengeTargetsContext';
import { useEffect } from 'react';
import MarketingPage from './pages/MarketingPage/MarketingPage';
import { LoginProvider } from './store/loginPageContext';
import './components/css/PopupSwal2.css';
import { ToastProvider } from './store/toastContext';
import MagicLink from './components/MagicLink';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname != '/newChallenge/' && !window.onbeforeunload) {
      localStorage.removeItem('description');
      localStorage.removeItem('targets');
      localStorage.removeItem('data');
      localStorage.removeItem('emails');
      localStorage.removeItem('checkBoxState');
      localStorage.removeItem('activeDeadlineDate');
      localStorage.removeItem('autoApprove');
      localStorage.removeItem('confirmLicence');
    }
  }, [location.pathname]);
  return (
    <div className='desktop:h-screen desktop:w-screen  tablet:h-screen tablet:w-screen overflow-hidden'>
      <AnimatePresence>
        <ToastProvider>
          <Routes location={location} key={location.pathname}>
            <Route
              path='/technician-order/*'
              element={
                <TechnicianAuthProvider>
                  <Routes>
                    <Route path='/' element={<TechnicianOrder />} />
                    <Route path='/finish' element={<TechnicianOrderFinish />} />
                  </Routes>
                </TechnicianAuthProvider>
              }
            />

            <Route
              path='/*'
              element={
                <AuthProvider>
                  <MagicLink>
                    <Routes>
                      <Route
                        path='/*'
                        element={
                          <ProtectedRoute>
                            <HomePage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/user'
                        element={
                          <ProtectedRoute>
                            <UserPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/myTeams'
                        element={
                          <ProtectedRoute>
                            <MyTeamsPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/myTeams/*'
                        element={
                          <ProtectedRoute>
                            <MyTeamsPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/newChallenge/*'
                        element={
                          <ProtectedRoute>
                            <ChallengeTargetsProvider>
                              <NewChallengePage />
                            </ChallengeTargetsProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/myChallenges/*'
                        element={
                          <ProtectedRoute>
                            <MyChallengeDetailProvider>
                              <MyChallengesPage />
                            </MyChallengeDetailProvider>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/reportedActivity/*'
                        element={
                          <ProtectedRoute>
                            <ReportedActivityPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/editChallenge/:id'
                        element={
                          <ChallengeTargetsProvider>
                            <ProtectedRoute>
                              <EditChallengePage />
                            </ProtectedRoute>
                          </ChallengeTargetsProvider>
                        }
                      />

                      <Route path='/marketing' element={<MarketingPage />} />
                      <Route
                        path='/login'
                        element={
                          <LoginProvider>
                            <LoginPage />
                          </LoginProvider>
                        }
                      />
                      <Route
                        element={
                          <LoginProvider>
                            <RegisterPage />
                          </LoginProvider>
                        }
                      />
                      <Route
                        element={
                          <LoginProvider>
                            <ForgotPasswordPage />
                          </LoginProvider>
                        }
                      />
                    </Routes>
                  </MagicLink>
                </AuthProvider>
              }
            />
          </Routes>
        </ToastProvider>
      </AnimatePresence>
    </div>
  );
};
export default App;
