import * as React from 'react';
import 'react-popper-tooltip/dist/styles.css';

interface tooltipProps {
  tooltipContent: string;
}

const RightSideTopIconTooltip: React.FC<tooltipProps> = (props) => {
  const { tooltipContent } = props;
  return (
    <div className='hidden lg:group-hover:flex phone:group-hover:hidden group-hover:hidden pointer-events-auto absolute -top-8 items-center left-7 justify-center text-left w-fit h-fit rounded bg-lightBlueButton  px-2 py-1 text-sm font-medium text-white  shadow transition-opacity '>
      {tooltipContent}
    </div>
  );
};

export default RightSideTopIconTooltip;
