import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApiClient } from 'src/api/client';
import BlueWavesBG from 'src/components/BlueWavesBGimg/BlueWavesBG';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import SidePanel, { SidePanelRoutes } from 'src/components/SidePanel';
import { useChallengeData } from 'src/store/challengeTargetsContext';
import EditChallenge from './EditChallenge';
import EditChallengeCard from './EditChallengeCard';
import EditTargets from './EditTargetsPage/EditTargets';
import EditUserPage from './EditUserPage';
interface IChallengeResponse {
  chall_id: number;
  chall_name: string;
  chall_start: Date;
  chall_end: Date;
  chall_insert_log_till: Date;
  chall_insert_log_days_after_activity: boolean;
  licenceValidTo: Date | undefined;
  team_name: string;
  team_id: number;

  team_emails: string[];

  targets: ITarget[];
}
interface ITarget {
  targetId: number;
  name: string;
  targetValue: number;
  targetUnit: string;
}
export default function EditChallengePage(): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0);
  const Client = useApiClient();
  const { id } = useParams();
  const challengeData = useChallengeData();
  const [datata, setData] = useState<IChallengeResponse>();

  const loadData = async (): Promise<void> => {
    try {
      const res = await Client.get('/challenge/challenge/' + id);
      if (res.status === 200) {
        const {
          team_emails,
          team_id,
          chall_id,
          team_name,
          chall_end,
          chall_insert_log_till,
          chall_insert_log_days_after_activity,
          chall_name,
          chall_start,
          targets,
          licenceValidTo,
        } = res.data as IChallengeResponse;
        setData(res.data);
        challengeData.setTeamName(team_name);
        challengeData.setTeamid(team_id);
        challengeData.setChallengeId(chall_id);
        challengeData.SetLicence(licenceValidTo);
        challengeData.setCheckBoxDeadlineDate(chall_insert_log_days_after_activity);
        challengeData.setChallengeName(chall_name);
        challengeData.setAdminTeamEmail(team_emails[0]);
        challengeData.setEmails(team_emails);
        if (challengeData.dateFrom.toLocaleDateString() === new Date().toLocaleDateString()) {
          challengeData.setDateFrom(new Date(chall_start));
        }

        if (challengeData.dateTo.toLocaleDateString() === addDays(new Date(), 14).toLocaleDateString()) {
          challengeData.setDateTo(new Date(chall_end));
        }
        if (challengeData.deadLineDate.toLocaleDateString() === addDays(new Date(), 16).toLocaleDateString()) {
          challengeData.setDeadLineDate(new Date(chall_insert_log_till));
        }
        challengeData.setChallengeTargets(
          targets.map((t) => {
            return {
              id: t.targetId,
              targetName: t.name,
              targetValue: t.targetValue,
              targetUnit: t.targetUnit,
            };
          }),
        );
      }
    } catch (err) {
      console.error('Failed fetching info');
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const editChallenge = (): void => {
    setCurrentStep(0);
  };

  const editChallengeCard = (): void => {
    setCurrentStep(1);
  };

  const editUserPage = (): void => {
    setCurrentStep(2);
  };

  const editTargets = (): void => {
    setCurrentStep(3);
  };
  const pages = [
    {
      content: (
        <EditChallenge
          editTargets={editTargets}
          editUserPage={editUserPage}
          editChallengeCard={editChallengeCard}
          data={datata}
        />
      ),
    },
    {
      content: <EditChallengeCard editChallenge={editChallenge} loadData={loadData} />,
    },
    {
      content: <EditUserPage editChallenge={editChallenge} loadData={loadData} />,
    },
    {
      content: <EditTargets editChallenge={editChallenge} loadData={loadData} />,
    },
  ];
  return (
    <div className='flex 2xl:flex-row md:flex-row sm:flex-row flex-col z-0 bg-gradient-to-r from-lightBlue h-screen to-darkBlue'>
      <BlueWavesBG />
      <div className='z-20'>
        <SidePanel activeRoute={SidePanelRoutes.MY_CHALLENGES} />
      </div>
      <div className='flex z-40 w-full justify-center items-center flex-row  h-full overflow-hidden'>
        <MobileNav />
        <div className='w-full h-full flex flex-col  justify-center overflow-hidden'>
          {datata && (
            <>
              {pages.map((step, index) => {
                return (
                  <div key={index}>
                    <div>{currentStep === index ? step.content : null}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
