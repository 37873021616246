import React, { MutableRefObject } from 'react';
import { ReactComponent as ScrollDownArrow } from '../../../images/icons/ParalaxVectors/ScrollDownArrow.svg';
import { IParallax, ParallaxLayer } from '@react-spring/parallax';
interface ParallaxProps {
  parallax: MutableRefObject<IParallax | null>;
}
const ScrollButtonParallax: React.FC<ParallaxProps> = ({ parallax }) => {
  setTimeout(() => {
    if (parallax.current) {
      parallax.current.scrollTo(1.4);
    }
  }, 600);
  return (
    <ParallaxLayer offset={0.5} speed={1} factor={0} className='w-full h-fit flex justify-center rounded-full'>
      <ScrollDownArrow
        onClick={() => {
          if (parallax.current) {
            parallax.current.scrollTo(1);
          }
        }}
        className='animate-bounce cursor-pointer z-30 rounded-full'
      />
    </ParallaxLayer>
  );
};
export default ScrollButtonParallax;
