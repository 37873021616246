import React from 'react';

const PictureIcon: React.FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29' fill='none'>
    <path d='M0 0V29H29V0H0ZM26.3931 26.3931H2.60688V2.60688H26.3931V26.3931Z' fill='#15CDCA' />
    <path
      d='M4.2866 21.8313H24.712C24.9161 21.8313 25.1035 21.7193 25.2002 21.5396C25.2969 21.3605 25.2872 21.1421 25.1748 20.972L18.8364 11.3687C18.5799 10.9797 18.1451 10.7461 17.6789 10.7461C17.2131 10.7471 16.7784 10.9813 16.5223 11.3712L12.0574 18.1661L9.59304 15.3729C9.30695 15.0491 8.88644 14.8766 8.45581 14.9077C8.02466 14.9382 7.63265 15.1683 7.39595 15.5292L3.8234 20.973C3.71142 21.1435 3.70224 21.3609 3.79904 21.5401C3.89561 21.7199 4.08298 21.8313 4.2866 21.8313Z'
      fill='#15CDCA'
    />
    <path
      d='M9.49215 12.79C11.0443 12.79 12.3022 11.5321 12.3022 9.97998C12.3022 8.42785 11.0443 7.16992 9.49215 7.16992C7.94008 7.16992 6.68164 8.4278 6.68164 9.97998C6.6817 11.5321 7.94008 12.79 9.49215 12.79Z'
      fill='#15CDCA'
    />
  </svg>
);

export default PictureIcon;
