import React, { useEffect, useState } from 'react';
import { useApiClient } from 'src/api/client';
import componentSpacing from 'src/components/css/ComponentSpacing/ComponentSpacing';
import mobileTitleStyle from 'src/components/css/TitleStyle/MobileTitleStyle';
import titleStyle from 'src/components/css/TitleStyle/TitleStyle';
import MobileNav from 'src/components/MobileNavBarMenu/MobileNav';
import { useTeam } from 'src/store/teamDetailContext';
import { Spinner } from 'src/utils/Spinner';
import TeamRow from '../TeamRow';
import { useTranslation } from 'react-i18next';
import roundingForm from 'src/components/RoundingOfAllForms/RoundingForm';
import MissingChallenge from 'src/components/MissingChallenge/MissingChallenge';
import LeftSideBottomIconTooltip from 'src/utils/LeftSideIconBottomTooltipUtils';
import { iconTransition } from 'src/pages/NewChallengePage/NewChallengeButtonStyle/IconTransition';
import swal from 'sweetalert2';
import { useToast } from 'src/store/toastContext';
import { ReactComponent as AddNewTeamIcon } from '../../../images/icons/AddNewTeamIcon.svg';
import headerStyle from 'src/components/css/HeadersStyle/HeaderStyle';
interface TeamData {
  teamInvitationLink: string;
  role: 'ADMIN' | 'MEMBER';
  teamName: string;
  teamId: number;
  licenceValidTo: Date | null;
  unApproved_authuser_id: number;
  anyUserWaitingForApproval: boolean;
  auto_approve: boolean;
}
const Parameters = {
  heightSize: 'h-5/6',
  flexCol: 'flex-col',
  flexRow: 'flex-row',
  vhSize: '[75vh]',
  pb: 'pb-0',
  pbTitleAndButton: 'pb-2',
  hidden: 'hidden',
  flex: 'flex',
  flexTitle: 'flex',
  ptZero: 'pt-0',
  pbZero: 'pb-0',
};
const TeamTable: React.FC<unknown> = () => {
  const { t } = useTranslation(['home', 'main']);
  const [teamData, setTeamData] = useState<TeamData[]>([]);
  const [loading, setLoading] = useState(true);
  const [logsLoading, setLogsLoading] = useState(true);
  const { showToastHandler } = useToast();

  const team = useTeam();
  const Client = useApiClient();

  const loadData = async (): Promise<void> => {
    try {
      const response = await Client.get('/team/userTeams');
      const data = response.data.teams as TeamData[];
      setLoading(false);
      setTeamData(data);
      setLogsLoading(false);
    } catch (exc) {
      console.error('Error while loading data');
    }
  };

  const addTeam = async (error: string): Promise<void> => {
    swal
      .fire({
        title: `${t('CREATE TEAM')}`,
        cancelButtonText: `${t('CANCEL')}`,
        confirmButtonText: `${t('YES')}`,
        showCancelButton: true,
        showConfirmButton: true,
        html:
          `<input type="text" placeholder="${t('Enter name')}" class="swal2-input" id="input-name">` +
          `<div class="swal2-checkbox2"><label><input type="checkbox" id="approve-option"><span>${t(
            'ADMIN APPROVAL',
          )}</span></label></div>` +
          `<background style='color: #F16B6B;'>${error}</background>`,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const nameInput = (document.getElementById('input-name') as HTMLInputElement).value;
          const isApproved = (document.getElementById('approve-option') as HTMLInputElement).checked;
          if (nameInput !== '') {
            try {
              const response = await Client.post('/team/create', {
                team_name: nameInput,
                auto_approve: isApproved,
              });
              if (response.status === 200) {
                loadData();
                showToastHandler(`${t('Team is created successfully')}`, 'success');
              }
            } catch (exc) {
              addTeam(`${t('The team already exists')}`);
            }
          } else {
            addTeam(`${t('Enter team name')}`);
          }
        }
      });
  };

  useEffect(() => {
    loadData();
  }, [team.teamName]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      {team.teamId === 0 ? (
        <div className={`flex flex-col justify-start w-full h-full ${componentSpacing} `}>
          <MobileNav />
          <div className={`${titleStyle} ${mobileTitleStyle}`}>{t('My teams')}</div>
          <div className='w-full h-full tablet:pb-2 phone:pb-2 md:pb-2 pb-0 sm:pt-2'>
            <div className={`h-full w-full ${roundingForm}`}>
              <div className='w-full flex sticky top-0  z-20 rounded-full'>
                <div className={headerStyle}>{t('TEAM')}</div>
                <div className={headerStyle}>{t('ROLE')}</div>
                <div className={headerStyle}>{t('LICENCE')}</div>
                <div className={headerStyle}> {t('LINK')}</div>
                <div className={headerStyle}> {t('APPROVE')}</div>
                <div className='font-semibold flex flex-col text-base w-full justify-center items-center text-center h-10'>
                  <button
                    onClick={() => addTeam('')}
                    className={`${iconTransition} cursor-pointer group justify-center flex flex-row lg:scale-100 phone:scale-100 scale-75 `}
                  >
                    <AddNewTeamIcon />
                    <LeftSideBottomIconTooltip tooltipContent={t('ADD NEW TEAM')} />
                  </button>
                </div>
              </div>
              <div
                className={`h-full flex flex-col overflow-hidden desktop:h-${Parameters.vhSize} 2xl:h-${Parameters.vhSize} lg:h-${Parameters.vhSize} xl:h-${Parameters.vhSize} tablet:h-[70vh]  md:h-[73vh] sm:h-[70vh] w-full h-[90vh] phone:h-[77vh] overflow-y-auto`}
              >
                <>
                  {loading ? (
                    <div className='w-full h-full flex justify-center  items-center'>
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {teamData.length == 0 ? (
                        <div className='w-full h-full flex justify-center'>
                          <MissingChallenge />
                        </div>
                      ) : (
                        <>
                          {logsLoading ? (
                            <div className='w-full h-full flex justify-center items-center'>
                              <Spinner />
                            </div>
                          ) : (
                            <>
                              {teamData.length < 1 ? (
                                <div className='text-center flex h-full justify-center items-center lg:text-[14px] text-[10px]'>
                                  {teamData.length == 0 ? (
                                    `${t('No data yet. Add a user as a favorite to make something appear')}`
                                  ) : (
                                    <div className='w-full h-full flex justify-center items-center'>
                                      <Spinner />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                teamData.map((data, index) => (
                                  <TeamRow
                                    loadData={loadData}
                                    key={data.teamName + 'KEY'}
                                    {...data}
                                    anyUserWaitingForApproval={data.anyUserWaitingForApproval}
                                    isLastItem={teamData.length - 1 === index}
                                  />
                                ))
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TeamTable;
