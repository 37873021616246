import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import errorGlowField from '../css/GlowField/ErrorGlowField';
import glowField from '../css/GlowField/GlowField';
import './RemoveArrows.css';
export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;

const TextField: React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;

  return (
    <div className='flex-col flex w-full items-center relative h-fit'>
      <span className='w-full justify-end absolute bottom-6 right-3 h-full lg:flex hidden'>
        {error && <span className='text-errorRed italic'>{error}</span>}
      </span>
      <input className={error ? errorGlowField : glowField} value={value ?? ''} {...rest} ref={ref}></input>
      <span className='w-full justify-center h-full lg:hidden flex'>
        {error && <span className='text-errorRed italic'>{error}</span>}
      </span>
    </div>
  );
};

export default React.forwardRef(TextField);
