import React from 'react';

export type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button className='bg-buttonColor text-white text-xs font-medium w-80 h-9 rounded-md' ref={ref} {...props}>
    {props.children}
  </button>
));

Button.displayName = 'Button';

export default Button;
