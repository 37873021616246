import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useApiClient } from 'src/api/client';
import ExternalLinkIcon from 'src/components/Icons/ExternalLinkIcon';
import PictureIcon from 'src/components/Icons/pictureIcon';
import ThumbDownFilledIcon from 'src/components/Icons/ThumbDownFilledIcon';
import ThumbDownIcon from 'src/components/Icons/ThumbDownIcon';
import ThumbUpFilledIcon from 'src/components/Icons/ThumbUpFilledIcon';
import ThumbUpIcon from 'src/components/Icons/ThumbUpIcon';
import { ReactComponent as DefaultUserAvatar } from '../../../images/icons/DefaultUserAvatar.svg';
import Swal from 'sweetalert2';
import { IChallengeLog, IUsersAvatar } from '../MyChallengesPage';
import scaleTextElements from 'src/components/css/ScaleTextAndElements/ScaleTextElements';
import logStyle from 'src/components/css/MyChallengeLogsStyle/LogStyle';
import overflowText from 'src/components/css/MyChallengeLogsStyle/OverflowText';
import { useTranslation } from 'react-i18next';
import { AccordionBody, AccordionHeader } from '@material-tailwind/react';
import PictureSVGIcon from 'src/components/Icons/PictureSVGIcon';
import { useToast } from 'src/store/toastContext';
const Properties = {
  height: 'h-12',
  width: 'w-12',
  thumbIconsScale: 'desktop:scale-[1.6] md:scale-[1.4] tablet:scale-[1.4] scale-125',
  cursorPointer: 'cursor-pointer',
};
const ChallengeLogRowMobileVersion: React.FC<
  IChallengeLog & {
    isLastItem: boolean;
    usersAvatar: IUsersAvatar[];
    index: number;
    changeLog: (feedback: IChallengeLog['chl_feedback'], chl_id: number, user_id: number) => void;
  }
> = ({
  user_name,
  user_id,
  chl_date,
  chl_img,
  chl_link,
  chl_value,
  cht_name,
  cht_unit,
  user_email,
  chl_feedback,
  chl_id,
  isLastItem,
  usersAvatar,
  changeLog,
}) => {
  const getImg = async (): Promise<void> => {
    const response = await Client.get(`challenge/logImg/${chl_id}`);
    showModal(response.data.photo);
  };
  const showModal = (currentImg: string): void => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: PictureSVGIcon,
      html: `<img class='w-full h-full'  src="data:image/jpeg;base64,${currentImg}" />`,
    });
  };
  const { showToastHandler } = useToast();
  const NegativeLog = async (): Promise<void> => {
    Swal.fire({
      title: `${t('Are you sure you want to mark the activity as incorrect?')}`,
      showCancelButton: true,
      confirmButtonText: `${t('YES')}`,
      cancelButtonText: `${t('CANCEL')}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        chl_feedback !== 'negative' && giveFeedback('negative');
        showToastHandler(`${t('Activity is marked as incorrect')}`, 'success');
      }
    });
  };
  const Client = useApiClient();
  const giveFeedback = async (type: IChallengeLog['chl_feedback']): Promise<void> => {
    try {
      changeLog(type, chl_id, user_id);
      await Client.post('/evaluation/feedback', { chl_id: chl_id, type: type, user_id: user_id });
    } catch (ignore) {
      console.error('Error while giving feedback');
    }
  };
  const { t } = useTranslation(['home', 'main']);
  const [avatar, setAvatar] = useState<string>();
  useEffect(() => {
    for (let current = 0; current < usersAvatar.length; current++) {
      const element = usersAvatar[current];
      if (element.userId == user_id) {
        setAvatar(element.avatar);
        break;
      }
    }
  }, []);

  return (
    <div className='shadow-sm shadow-offset-x-2 '>
      <AccordionHeader className='border-none  justify-between item w-full'>
        <tr className={`flex flex-row items-center justify-center w-full py-[0.7em] h-fit `}>
          <td
            className={`justify-center w-full desktop:${Properties.height} md:${Properties.height} tablet:${
              Properties.height
            } h-10 desktop:flex xl:flex lg:flex 2xl:flex md:flex tablet:flex hidden ${
              isLastItem ? 'rounded-bl-lg' : ''
            }`}
          >
            {avatar ? (
              <img
                className={`rounded-full desktop:${Properties.height}  desktop:${Properties.width}  md:${Properties.height} md:${Properties.width} tablet:${Properties.width} tablet:${Properties.height} w-8 h-8`}
                src={'data:image/png;base64, ' + avatar}
              />
            ) : (
              <div
                className={`rounded-full desktop:${Properties.height}  desktop:${Properties.width}  md:${Properties.height} md:${Properties.width} tablet:${Properties.width} tablet:${Properties.height} w-8 h-8`}
              >
                <DefaultUserAvatar />
              </div>
            )}
          </td>
          <td className={overflowText}>{user_name ? user_name : user_email}</td>
          <td className={`${logStyle} 2xl:flex desktop:flex lg:flex xl:flex hidden`}>
            {format(new Date(chl_date), 'dd.MM.yyyy')}
          </td>
          <td className={`${logStyle} 2xl:hidden desktop:hidden lg:hidden xl:hidden block`}>
            {format(new Date(chl_date), 'dd.MM')}
          </td>
          <td className={overflowText}>{cht_name}</td>
          <td className={logStyle}>{chl_value + ' ' + t(`${cht_unit}`)}</td>
        </tr>
      </AccordionHeader>
      <AccordionBody className='flex flex-row w-full  justify-between items-center'>
        <td className={`${logStyle} flex justify-center relative`}>
          {chl_link && (
            <a
              className={scaleTextElements}
              href={/https?:\/\//.test(chl_link) ? chl_link : `http://${chl_link}`}
              target={'_blank'}
              rel='noreferrer'
            >
              <ExternalLinkIcon />
            </a>
          )}
          {!chl_link && '-'}
        </td>
        <td className='flex justify-center w-full'>
          {chl_img && (
            <span
              onClick={() => getImg()}
              className={`flex items-center ${Properties.cursorPointer} ${scaleTextElements}`}
            >
              <PictureIcon />
            </span>
          )}
          {!chl_img && '-'}
        </td>
        <td className='flex justify-center w-full'>
          <span className='flex justify-center items-center'>
            <span
              onClick={() => {
                NegativeLog();
              }}
              className={`${
                chl_feedback === 'negative'
                  ? `${Properties.thumbIconsScale}`
                  : `${`${Properties.cursorPointer} ${scaleTextElements}`}`
              } transition-transform`}
            >
              {chl_feedback === 'negative' ? <ThumbDownFilledIcon /> : <ThumbDownIcon />}
            </span>
            <span className='mx-1' />
            <span
              onClick={() => {
                if (chl_feedback !== 'positive') giveFeedback('positive');
                showToastHandler(`${t('Activity is marked as positive')}`, 'success');
              }}
              className={`${
                chl_feedback === 'positive'
                  ? `${Properties.thumbIconsScale}`
                  : `${`${Properties.cursorPointer} ${scaleTextElements}`}`
              } transition-transform`}
            >
              {chl_feedback === 'positive' ? <ThumbUpFilledIcon /> : <ThumbUpIcon />}
            </span>
          </span>
        </td>
      </AccordionBody>
    </div>
  );
};

export default ChallengeLogRowMobileVersion;
