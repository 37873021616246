import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LinkStyle from '../MobileNavLinksStyle/LinkStyle';

import { useTeam } from 'src/store/teamDetailContext';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ServiceListIconWhite from 'src/components/Icons/ServiceListIconWhite';
import PlusIconWhite from 'src/components/Icons/PlusIconWhite';
import UserIconWhite from 'src/components/Icons/UserIconWhite';
import ChartBarIconWhite from 'src/components/Icons/CharBarWhite';
import ViewListIconWhite from 'src/components/Icons/viewListIconWhite';
import LogOutIcon from 'src/components/Icons/LogOutIcon';
import { useApiClient } from 'src/api/client';
import NotificationDot from 'src/components/Notification/NotificationDot';
interface TeamData {
  anyUserWaitingForApproval: boolean;
  role: 'ADMIN' | 'MEMBER';
}
const MobileUl: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);
  const team = useTeam();
  const [usersWaiting, setUsersWaiting] = useState<boolean>(false);
  const Client = useApiClient();

  useEffect(() => {
    Client.get('/team/userTeams')
      .then((response) => {
        const data = response.data.teams as TeamData[];
        const notificationStage = data
          .filter((x) => x.anyUserWaitingForApproval === true && x.role === 'ADMIN')
          .map((x) => x.anyUserWaitingForApproval && x.role === 'ADMIN')
          .some((x) => x === true);

        setUsersWaiting(notificationStage);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <ul className='w-full h-full flex items-center'>
      <li className='flex flex-col gap-5 sm:gap-3 md:gap-5 items-center h-5/6 w-full'>
        <Link to='/newChallenge/' className={LinkStyle}>
          <div className='w-2/12 '>
            <PlusIconWhite />
          </div>
          <div className='text-white font-bold '>{t('New challenge')}</div>
        </Link>
        <Link to='/challenge/logs/' className={LinkStyle}>
          <div className='w-2/12 '>
            <ServiceListIconWhite />
          </div>
          <div className='text-white font-bold'>{t('Мy activities')}</div>
        </Link>
        <Link to='/user' className={LinkStyle}>
          <div className='w-2/12 '>
            <UserIconWhite />
          </div>
          <div className='text-white font-bold'>{t('User')}</div>
        </Link>
        <Link to='/myChallenges/allUsers' className={LinkStyle}>
          <div className='w-2/12 '>
            <ChartBarIconWhite />
          </div>
          <div className='text-white font-bold'>{t('My challenges')}</div>
        </Link>
        <Link to='/myTeams/' className={`${LinkStyle} relative flex flex-row`} onClick={() => team.setTeamId(0)}>
          <div className='h-full flex items-center justify-center relative right-3'>
            {usersWaiting && <NotificationDot />}
          </div>
          <div className='w-2/12'>
            <ViewListIconWhite />
          </div>
          <div className='text-white font-bold w-fit'>{t('My teams')}</div>
        </Link>
        <Link to='/reportedActivity/' className={`${LinkStyle} relative`}>
          <div className='w-2/12'>
            <ServiceListIconWhite />
          </div>
          <div className='text-white font-bold'>{t('Reported activities')}</div>
        </Link>
        <Link to='/login' onClick={() => Cookies.remove('token')} className={LinkStyle}>
          <div className='w-2/12 '>
            <LogOutIcon />
          </div>
          <div className='text-white font-bold'>{t('Logout')}</div>
        </Link>
      </li>
    </ul>
  );
};
export default MobileUl;
