import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useApiClient } from 'src/api/client';
import { ChallengeLogsListItem } from 'src/api/types/challenges/ChallengeLogsResponse';
import PreviewServiceForm from '../../pages/HomePage/EditForm';
import DeleteIcon from '../Icons/deleteIcon';
import '../css/Popup.css';
import swal from 'sweetalert2';
import PictureIcon from '../Icons/pictureIcon';
import ExternalLink from '../Icons/ExternalLinkIcon';
import { useActivity } from 'src/store/activityCardContext';
import PictureSVGIcon from '../Icons/PictureSVGIcon';
import PencilIcon from '../Icons/PencilIcon';
import { Dialog } from '@headlessui/react';
import ModalFromLibrary from '../ModalFromLibrary';
import moment from 'moment';
import { Spinner } from 'src/utils/Spinner';
import activityLogStyle from '../css/ActivityLogsStyle/ActivityLogStyle';
import scaleTextElements from '../css/ScaleTextAndElements/ScaleTextElements';
import { useTranslation } from 'react-i18next';
import InformationIcon from '../Icons/InformationIcon';
import { AccordionHeader, AccordionBody } from '@material-tailwind/react';
import { useToast } from 'src/store/toastContext';
import GrayPencilIcon from '../Icons/GrayPencilIcon';
import overflowText from '../css/MyChallengeLogsStyle/OverflowText';

interface ChallengeLogsItemsProps {
  item: ChallengeLogsListItem;
  getItemList: () => void;
  isLastItem?: boolean;
  index: number;
}
const Parameters = {
  borderNone: 'border-none',
  py: 'py-2',
  pySmallScreens: 'py-[0.4em]',
};
const ChallengeLogServiceMobileVersion: React.FC<ChallengeLogsItemsProps> = ({ item, getItemList, isLastItem }) => {
  const { showToastHandler, closeToastHandler } = useToast();
  const activity = useActivity();
  const Client = useApiClient();
  const { t } = useTranslation(['home', 'main']);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = (): void => {
    setIsOpen(false);
  };
  const openModal = (): void => {
    setIsOpen(true);
  };

  const getImg = async (): Promise<void> => {
    const response = await Client.get(`challenge/logImg/${item.id}`);
    showModal(response.data.photo);
  };

  useEffect(() => {
    getItemList();
  }, [pathname]);

  const deleteLog = async (logId: number): Promise<void> => {
    try {
      swal
        .fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Are you sure you want to delete this activity?')}` + '</h5>',
          showCancelButton: true,
          confirmButtonText: `${t('YES')}`,
          cancelButtonText: `${t('CANCEL')}`,
          html: "<background style='color: #F16B6B;'>",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            closeToastHandler(0);
            showToastHandler(`${t('Your activity has been deleted.')}`, 'success');
            await Client.delete(`/challenge/deleteLog/${logId}`);
            activity.setReload(true);
          }
        });
    } catch (error) {
      console.error(
        swal.fire({
          title: "<h5 style='color: #F16B6B;'>" + `${t('Error')}` + '</h5>',
          html: "<div style='color: #F16B6B;'>" + `${t('Enter the correct information!')}` + '</div> ',
        }),
        error,
      );
    }
  };
  const addLinkHttps = useMemo(
    () => (item.link?.match(/^https?:\/\//i) ? item.link : `http://${item.link}`),
    [item.link],
  );
  const showModal = (convertedPhoto: string): void => {
    swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: PictureSVGIcon,
      html: `<img class='w-fit h-fit' src="data:image/jpeg;base64,${convertedPhoto}" />`,
    });
  };

  return (
    <>
      {!item ? (
        <Spinner />
      ) : (
        <div className='shadow-sm shadow-offset-x-2 '>
          <AccordionHeader className='border-none  justify-between item w-full'>
            <td
              key={item.chlngName}
              className={`w-full  justify-between border-none items-center flex flex-row sm:${
                Parameters.pySmallScreens
              } md:${Parameters.pySmallScreens} tablet:${Parameters.pySmallScreens} py-${
                Parameters.pySmallScreens
              } flex md:border-none  border-b-[1px] ${
                typeof item.id === 'number' && pathname.includes(item.id.toString()) ? 'bg-white' : ''
              }`}
            >
              <td className={`${activityLogStyle} md:flex sm:flex justify-center`}>
                <div className={overflowText}>{item.targetName}</div>
              </td>
              <td className={`${activityLogStyle} text-center`}>
                {item.logedValue.slice(0, 2) + t(`${item.logedValue.slice(2)}`)}
              </td>
              <td className={`${activityLogStyle} tablet:flex hidden justify-center`}>
                {moment(item.date, 'DD.MM.yyyy').format('DD.MM.yyyy')}
              </td>
              <td className={`${activityLogStyle} tablet:hidden flex justify-center`}>
                {moment(item.date, 'DD.MM').format('DD.MM')}
              </td>
            </td>
          </AccordionHeader>
          <AccordionBody className='flex flex-col w-full  justify-between items-center'>
            <div className='flex flex-row w-full  justify-between items-center'>
              <td
                className={`${activityLogStyle} md:flex sm:flex justify-center ${isLastItem ? 'rounded-tr-2xl' : ''}`}
              >
                <div className={overflowText}>{item.chlngName}</div>
              </td>
              <td className={`${activityLogStyle} overflow-visible relative hidden phone:hidden justify-center  `}>
                <div className='cursor-pointer group '>
                  <InformationIcon />

                  <div className='pointer-events-auto absolute top-7 items-center justify-center ml-8 text-left w-fit h-fit rounded 	bg-lightBlueButton  px-2 py-1 text-sm font-medium text-white opacity-0 shadow transition-opacity group-hover:opacity-100'>
                    {item.description ? item.description : `${t('MISSING DESCRIPTION')}`}
                  </div>
                </div>
              </td>
              <td className={`${activityLogStyle}  hidden  justify-center items-center `}>
                {(item.link && (
                  <a target={'_blank'} rel='noreferrer' className={scaleTextElements} href={addLinkHttps}>
                    <ExternalLink />
                  </a>
                )) || <span className='font-bold'>-</span>}
              </td>
              <td className={`${activityLogStyle} justify-center items-center flex`}>
                {(item.photo && (
                  <>
                    <span className={`font-bold cursor-pointer ${scaleTextElements}`} onClick={getImg}>
                      <PictureIcon />
                    </span>
                  </>
                )) || <span className='font-bold'>-</span>}
              </td>

              <td className={`${activityLogStyle} flex justify-center`}>
                <span className={scaleTextElements}>
                  <button
                    type='button'
                    onClick={() => {
                      if (!item.isDeadlineExpired) {
                        const formattedDate = new Date(moment(item.date, 'DD.MM.yyyy').format('YYYY-MM-DD'));
                        activity.setWorkout(item.chlngTargetId);
                        activity.setDate(formattedDate);
                        activity.setValue(item.logedValue);
                        activity.setLink(item.link);
                        activity.setDescription(item.description);
                        activity.setImg(item.photo);
                        activity.setlogId(item.id);
                        activity.setUnit(item.targetUnit);
                        openModal();
                      }
                    }}
                  >
                    {item.isDeadlineExpired ? <GrayPencilIcon /> : <PencilIcon />}
                  </button>
                </span>
                <span className={`md:ml-3 sm:ml-2 ml-[2px] ${scaleTextElements}`}>
                  <button onClick={() => deleteLog(item.id)}>
                    <DeleteIcon />
                  </button>
                </span>
              </td>
              <ModalFromLibrary isOpen={isOpen} closeModal={closeModal}>
                <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  <button
                    type='button'
                    className={`text-lightBlueButton btn btn-sm border-none rounded-lg btn-circle  absolute right-6 top-6`}
                    onClick={closeModal}
                  >
                    x
                  </button>
                </Dialog.Title>
                <PreviewServiceForm closeModal={closeModal} />
              </ModalFromLibrary>
            </div>
            {item.description ? (
              <div className={`${activityLogStyle} h-[32px] md:flex sm:flex justify-center flex flex-col w-full `}>
                {item.description}
              </div>
            ) : null}
          </AccordionBody>
        </div>
      )}
    </>
  );
};

export default ChallengeLogServiceMobileVersion;
