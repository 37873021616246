import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import textQuestionStyle from '../css/LinksTextStyle/TextQuestionStyle';
import '../Textfield/RemoveArrows.css';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from 'src/store/loginPageContext';

export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;

const LoginLink: React.FC = () => {
  const { t } = useTranslation(['home', 'main']);
  const useLogin = useLoginContext();

  return (
    <div className='flex flex-row w-full h-fit lg:pb-0 lg:pt-5 py-2'>
      <div className={`${textQuestionStyle}`}>
        <span className='w-full'>{t('Do you have a registration?')}</span>
      </div>
      <div className='w-1/6 flex flex-row justify-end'>
        <div className={`${textQuestionStyle} underline cursor-pointer w-fit flex items-end justify-end`}>
          <span
            onClick={() => {
              useLogin.setIsLogin(true);
              useLogin.setIsForgotPassword(true);
            }}
            className='w-fit'
          >
            {t('Log in')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginLink;
