import React from 'react';
import { ParallaxLayer } from '@react-spring/parallax';
import { Speed } from 'src/utils/ParallaxSpeedByResolution';
import Login from 'src/components/Login/Login';
import RegisterPage from 'src/pages/RegisterPage/RegisterPage';
import ForgotPasswordPage from 'src/pages/ForgotPasswordPage';
import { useLoginContext } from 'src/store/loginPageContext';
import { Factor } from 'src/utils/ParallaxFactor';
import { IsHorizontal } from 'src/utils/ParallaxIsHorizontal';
import LoginRegisterFooter from 'src/components/LoginRegisterFooter/LoginRegisterFooter';

const SwitchLoginForms: React.FC = () => {
  const loginContext = useLoginContext();
  return (
    <ParallaxLayer
      offset={0.99}
      speed={Speed()}
      factor={Factor()}
      horizontal={IsHorizontal()}
      className='duration-300 ease-out w-full justify-center flex'
    >
      <ParallaxLayer offset={0.5}>
        <div className='w-full h-full flex lg:scale-75 scale-100 flex-col items-center justify-center'>
          {loginContext.isLogin ? <Login /> : loginContext.isForgotPassword ? <RegisterPage /> : <ForgotPasswordPage />}
          <div className='w-full h-1/6 flex justify-end items-end'>
            <LoginRegisterFooter />
          </div>
        </div>
      </ParallaxLayer>
    </ParallaxLayer>
  );
};
export default SwitchLoginForms;
